var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade",attrs:{"id":_vm.id,"data-keyboard":"false","data-backdrop":"static"}},[_c('div',{staticClass:"modal-dialog modal-pdf"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('modal-close-button',{on:{"confirmed":_vm.closeModal}}),_c('h4',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.$t("global.createcalendarentry")))])],1),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row px-3 mb-4"},[_c('div',{staticClass:"col-12"},[_vm._v(" "+_vm._s(_vm.$t("global.calendarentryinfotext1"))),_c('br'),_vm._v(_vm._s(_vm.$t("global.calendarentryinfotext2"))+" ")])]),_c('div',{staticClass:"row px-3"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('date-picker',{class:{
                  'border border-2 border-danger border-radius-6':
                    !_vm.dateFormatIsValid(_vm.startdatum),
                },attrs:{"date-format":"dd.mm.yy","initial":_vm.startdatum,"placeholder":" ","show-icon":true,"inModal":true},on:{"update":function (val) { return (_vm.startdatum = val); }}}),_c('label',{class:{ 'text-danger': !_vm.dateFormatIsValid(_vm.startdatum) }},[_vm._v(_vm._s(_vm.$t("global.startdate")))])],1)]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('date-picker',{class:{
                  'border border-2 border-danger border-radius-6':
                    !_vm.dateFormatIsValid(_vm.enddatum),
                },attrs:{"date-format":"dd.mm.yy","initial":_vm.enddatum,"placeholder":" ","show-icon":true,"inModal":true},on:{"update":function (val) { return (_vm.enddatum = val); }}}),_c('label',{class:{ 'text-danger': !_vm.dateFormatIsValid(_vm.enddatum) }},[_vm._v(_vm._s(_vm.$t("global.enddate")))])],1)])]),_c('div',{staticClass:"row px-3"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('label',{class:{ 'text-danger': !_vm.startzeit },attrs:{"for":"timeInput"}},[_vm._v(_vm._s(_vm.$t("global.starttime")))]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.startzeit),expression:"startzeit"}],staticClass:"form-control",class:{
                    'border border-2 border-danger border-radius-6':
                      !_vm.startzeit,
                  },attrs:{"type":"time","id":"timeInput"},domProps:{"value":(_vm.startzeit)},on:{"input":function($event){if($event.target.composing){ return; }_vm.startzeit=$event.target.value}}}),_c('div',{staticClass:"input-group-append text-medium-light h-100 mr-2 d-flex align-items-center"},[(_vm.userTimeZoneShort)?_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.userTimeZoneShort))]):_vm._e(),_c('font-awesome-icon',{staticClass:"m-auto",attrs:{"icon":"fa-solid fa-clock"}})],1)])])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('label',{class:{ 'text-danger': !_vm.endzeit },attrs:{"for":"timeInput"}},[_vm._v(_vm._s(_vm.$t("global.endtime")))]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.endzeit),expression:"endzeit"}],staticClass:"form-control",class:{
                    'border border-2 border-danger border-radius-6': !_vm.endzeit,
                  },attrs:{"type":"time","id":"timeInput"},domProps:{"value":(_vm.endzeit)},on:{"input":function($event){if($event.target.composing){ return; }_vm.endzeit=$event.target.value}}}),_c('div',{staticClass:"input-group-append text-medium-light h-100 mr-2 d-flex align-items-center"},[(_vm.userTimeZoneShort)?_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.userTimeZoneShort))]):_vm._e(),_c('font-awesome-icon',{staticClass:"m-auto",attrs:{"icon":"fa-solid fa-clock"}})],1)])])])]),_c('div',{staticClass:"row px-3"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.ort),expression:"ort",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"placeholder":_vm.$t('global.calender_location')},domProps:{"value":(_vm.ort)},on:{"input":function($event){if($event.target.composing){ return; }_vm.ort=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',[_vm._v(_vm._s(_vm.$t("global.location")))])])])])]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-default",attrs:{"type":"button"},on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.$t("global.close"))+" ")]),_c('button',{staticClass:"btn btn-success",attrs:{"type":"button","disabled":!_vm.formIsValid},on:{"click":_vm.sendeKalendereintrag}},[_vm._v(" "+_vm._s(_vm.$t("global.send"))+" ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }
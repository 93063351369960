<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.scoreinput')"
        :col="3"
      >
        <div class="row mt-2 d-flex justify-content-between">
          <div class="ml-4">
            <button
              class="btn btn-success"
              @click="addNoteneingabe"
              v-if="berechtigungen.b_bildung_noteneingabe.create"
            >
              <font-awesome-icon icon="fa-regular fa-plus" />
              {{ $t("global.scoreinput") }}
            </button>
          </div>
          <div class="mr-4">
            <button
              class="btn btn-danger"
              @click="oeffneLoeschenModal"
              v-if="berechtigungen.b_bildung_noteneingabe.delete"
            >
              <font-awesome-icon icon="fa-duotone fa-trash" />
              <span>{{ $t("global.delete") }}</span>
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              tbody-tr-class="item"
              :items="noteneingabeliste"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              sort-icon-left
              fixed
              selectable
              select-mode="range"
              @row-dblclicked="details"
            >
              <template v-slot:head(selected)="row">
                <b-form-checkbox
                  v-model="row.selected"
                  @input="selectAllRows(row)"
                ></b-form-checkbox>
              </template>
              <template v-slot:cell(selected)="row">
                <b-form-checkbox
                  v-model="row.item.selected"
                  @input="onRowSelected(row)"
                ></b-form-checkbox>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="noteneingabe-loeschen-modal"
      @confirmed="noteneingabeloeschen"
      :multiple="true"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import ContainerHeadline from "@/components/ContainerHeadline";
import LoeschenModal from "@/components/Modals/LoeschenModal";

export default {
  components: {
    ContainerHeadline,
    LoeschenModal,
  },
  props: {
    veranstaltung: {
      type: String,
      required: true,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    klasseObject: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      page: 0,
      infiniteId: +new Date(),
      anzahlProPage: 25,
      sortBy: "",
      sortDesc: false,
      noteneingabeliste: [],
      selected: [],
      selectedIds: [],
    };
  },
  watch: {
    shown(val) {
      if (val && this.noteneingabeliste.length == 0) this.getNotenEingaben();
    },
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
          formatter: (value) => {
            return value ? value : this.$t("global.nodesignationset");
          },
        },
        {
          key: "noteneingabe_typ.bezeichnung",
          sortable: true,
          label: this.$t("global.scoreinputtype"),
        },
        {
          key: "noteneingabe_status.bezeichnung",
          sortable: true,
          label: this.$t("global.scoreinputstatus"),
        },
        {
          key: "sichtbar_von",
          sortable: true,
          label: this.$t("global.visiblefrom"),
        },
        {
          key: "sichtbar_bis",
          sortable: true,
          label: this.$t("global.visibleuntil"),
        },
      ];
    },
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      if (this.suche) params.suche = this.suche;

      return params;
    },
  },
  mounted() {
    if (this.shown) this.getNotenEingaben();
  },
  created() {},
  methods: {
    getNotenEingaben() {
      Api.get("template/noteneingabe/", {
        params: {
          veranstaltung: this.veranstaltung,
          page: this.page,
          count: this.anzahlProPage,
        },
      }).then((response) => {
        //response.data.shift();
        this.noteneingabeliste = response.data;
      });
    },

    details(noteneingabe) {
      this.$router.push({
        name: "templateNoteneingabe",
        params: { id: noteneingabe.id, anzeige: 0 },
      });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "noteneingabe-loeschen-modal");
    },

    noteneingabeloeschen() {
      Api.delete("template/noteneingabe/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.getNotenEingaben();
      });
    },

    addNoteneingabe() {
      Api.post("template/noteneingabe/", {
        noteneingabe_status: this.$CONST("NOTENEINGABESTATI").PREPARED,
        bezeichnung: this.$t("global.newdataset"),
        sichtbar_mit_details: this.$CONST("NOTENSICHTBARKEITEN").NOTVISIBLE,
        noteneingabe_typ: this.$CONST("NOTENEINGABETYPEN").GRADES,
        //praesenzveranstaltung: this.veranstaltung,
        templateVeranstaltungen: [this.veranstaltung],
      })
        .then(() => {
          this.getNotenEingaben();
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.gradessavedsuccessfully"),
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    infiniteHandler($state) {
      if (this.shown) {
        Api.get("template/noteneingabe/", {
          params: this.queryParams,
        }).then((response) => {
          console.log("final");
          if (response.data.length === this.anzahlProPage) {
            this.page += 1;
            this.noteneingabe.push(...response.data);
            $state.loaded();
          } else {
            this.noteneingabe.push(...response.data);
            $state.complete();
          }
        });
      }
    },
    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = true;
          if (!this.selectedIds.includes(gp.id)) {
            this.selectedIds.push(gp.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },
    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },
  },
};
</script>

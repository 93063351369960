<template>
  <div>
    <container-headline
      v-if="istModulfach"
      :headline="$t('global.studyhours')"
      :col="6"
    ></container-headline>
    <container-headline
      v-if="!istModulfach"
      :headline="$t('global.lessons')"
      :col="6"
    ></container-headline>

    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-2">
              <div class="form-group">
                <ValidationProvider
                  rules="required"
                  immediate
                  v-slot="{ passed }"
                >
                  <input
                    v-model.number="fach.lektionen_soll"
                    class="form-control"
                    :readonly="!editable"
                    placeholder=" "
                    v-bind:class="{ 'border-danger': !passed }"
                  />
                  <label v-bind:class="{ 'text-danger': !passed }">{{
                    $t("global.target")
                  }}</label>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-xl-2">
              <div class="form-group">
                <!--<ValidationProvider
                  rules="required"
                  immediate
                  v-slot="{ passed }"
                >-->
                <input
                  v-model.number="fach.lektionen_total"
                  class="form-control"
                  :readonly="!editable"
                  placeholder=" "
                />
                <label>{{ $t("global.total") }}</label>
                <!--</ValidationProvider>-->
              </div>
            </div>
            <div class="col-xl-2" v-if="!isTemplate">
              <div class="form-group">
                <input
                  v-model.number="lektionenDifferenz"
                  class="form-control"
                  placeholder=" "
                  readonly="true"
                />
                <label>{{ $t("global.difference") }}</label>
              </div>
            </div>
            <div class="col-xl-2">
              <div class="form-group">
                <ValidationProvider
                  rules="required"
                  immediate
                  v-slot="{ passed }"
                >
                  <input
                    v-model.number="fach.minimalanwesenheit"
                    class="form-control"
                    :readonly="!editable"
                    placeholder=" "
                    v-bind:class="{ 'border-danger': !passed }"
                  />
                  <label :class="{ 'text-danger': !passed }">{{
                    $t("global.minimumattendance")
                  }}</label>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-xl-1 pl-0 d-flex align-items-center">
              <span>%</span>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-2" v-if="!isTemplate">
              <div class="form-group">
                <input
                  v-model.number="fach.kontaktstudium"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                />
                <label>{{ $t("global.contactstudy") }}</label>
              </div>
            </div>
            <div class="col-xl-2" v-if="!isTemplate">
              <div class="form-group">
                <input
                  v-model.number="fach.angeleitetes_studium"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                />
                <label>{{ $t("global.guidedstudy") }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";

export default {
  name: "FachLektionen",
  components: {
    ContainerHeadline,
  },
  props: {
    isTemplate: {
      type: Boolean,
      required: true,
    },
    fach: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    istModulfach: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    lektionenDifferenz: function () {
      return this.fach.lektionen_soll - this.fach.lektionen_total;
    },
  },
};
</script>

<style lang="scss">
</style>

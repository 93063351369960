export function parseDateString(inString) {
  if (inString) {
    var dateParts = inString.split(".");
    return new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
  }
}

export function parseDateTimeString(inDate, inTime) {
  if (inDate) {
    let newDate = parseDateString(inDate);
    if (inTime) {
      var timeParts = inTime.split(":");
      newDate.setHours(timeParts[0]);
      newDate.setMinutes(timeParts[1]);
      return newDate;
    }
  }
}

import { positionToJson } from "./Positionen";

export var belegToJson = function (self, belegtyp) {
  return {
    abgerufeneBelege: self.abgerufeneBelegIds,
    adresse: {
      adresse: self.adresse,
      adresszusatz1: self.adresszusatz1,
      adresszusatz2: self.adresszusatz2,
      firma: self.firma,
      land: self.land,
      ort: self.ort,
      plz: self.plz,
      strasse: self.strasse,
      zuHaendenVon: self.zuHaendenVon
    },
    abweichendeLieferadresse: self.abweichendeLieferadresse,
    lieferadresse: {
      adresse: self.lieferadresse,
      adresszusatz1: self.lieferadresse_adresszusatz1,
      adresszusatz2: self.lieferadresse_adresszusatz2,
      firma: self.lieferadresse_firma,
      land: self.lieferadresse_land,
      ort: self.lieferadresse_ort,
      plz: self.lieferadresse_plz,
      strasse: self.lieferadresse_strasse,
      zuHaendenVon: self.lieferadresse_zuHaendenVon
    },
    abweichendeBelegadresse: self.abweichendeBelegadresse,
    belegadresse: {
      adresse: self.belegadresse,
      adresszusatz1: self.belegadresse_adresszusatz1,
      adresszusatz2: self.belegadresse_adresszusatz2,
      firma: self.belegadresse_firma,
      land: self.belegadresse_land,
      ort: self.belegadresse_ort,
      plz: self.belegadresse_plz,
      strasse: self.belegadresse_strasse,
      zuHaendenVon: self.belegadresse_zuHaendenVon
    },
    belegnummer: self.belegnummer,
    belegnummerLieferant: self.belegnummerLieferant,
    belegdatumExtern: self.belegdatumExtern,
    belegtyp: self.belegTypId,
    betrefftext: self.betrefftext || "",
    email: self.email,
    erfassung: self.datum,
    lieferantId: self.lieferant ? self.lieferant.id : null,
    lieferbedingungen: self.lieferbedingung,
    mitarbeiter: self.mitarbeiter === "" ? null : self.mitarbeiter,
    positionen: self.$store.state.positionen.map((p, index) => {
      return positionToJson(p, index, belegtyp.lagerpreis);
    }),
    schlusstext: self.schlusstext || "",
    zahlungsbedingungen: self.zahlungsbedingung,
    zahlungsbedingungenId: self.zahlungsbedingungId
  };
};

export var pdfFileName = function (beleg) {
  if (beleg) return `${beleg.belegtyp.kurz}-${beleg.belegnummer}.pdf`;
  return "";
}


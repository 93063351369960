<template>
  <div id="adresse-bearbeiten-modal" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">
            {{
              editAdress.id ? $t("global.editaddress") : $t("global.addaddress")
            }}
          </h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <input
                  type="checkbox"
                  class="mr-2"
                  v-model="editAdress.isKorrespondenz"
                />
                {{ $t("global.correspondenceaddress") }}
                <input
                  type="checkbox"
                  class="ml-2 mr-2"
                  v-model="editAdress.isRechnung"
                />
                {{ $t("global.invoiceaddress") }}
                <input
                  type="checkbox"
                  class="ml-2 mr-2"
                  v-model="editAdress.isKonkordat"
                />
                {{ $t("global.concordat") }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <textarea
                  v-model.trim="editAdress.abteilung"
                  type="text"
                  class="form-control"
                  placeholder=" "
                  rows="1"
                />
                <label>{{ $t("global.additionalinformation") }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <input
                  v-model.trim="editAdress.strasse_nr"
                  type="text"
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.streetandhousenumber") }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <input
                  v-model.trim="editAdress.adresszusatz"
                  type="text"
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.addresssuffix") }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-3">
              <div class="form-group">
                <input
                  v-model.trim="editAdress.plz"
                  type="text"
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.zipcode") }}</label>
              </div>
            </div>
            <div class="col-xl-7">
              <div class="form-group">
                <input
                  v-model.trim="editAdress.ort"
                  type="text"
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.city") }}</label>
              </div>
            </div>
            <div class="col-xl-2">
              <div class="form-group">
                <input
                  v-model.trim="editAdress.kanton"
                  type="text"
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.canton") }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <v-select
                  v-model.trim="adressLand.land"
                  label="name"
                  :options="laender"
                  :placeholder="$t('global.country')"
                  :clearable="false"
                ></v-select>
                <label>{{ $t("global.country") }}</label>
              </div>
            </div>
          </div>
          <div class="row" v-if="firma">
            <div class="col-xl-12">
              <div class="form-group">
                <v-select
                  v-model="standort"
                  label="bezeichnung"
                  :options="standorte"
                  :placeholder="$t('global.location')"
                  :clearable="true"
                ></v-select>
                <label>{{ $t("global.location") }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <textarea
                  v-model.trim="editAdress.bemerkung"
                  type="text"
                  class="form-control"
                  placeholder=" "
                  rows="3"
                />
                <label>{{ $t("global.comment") }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="closeModal">
            {{ $t("global.cancel") }}
          </button>
          <button type="button" class="btn btn-primary" @click="uebernehmen">
            {{ $t("global.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalCloseButton from "@/components/global/ModalCloseButton";

export default {
  name: "AdresseModal",
  components: { ModalCloseButton },
  props: {
    neueAdresse: {
      type: Boolean,
    },
    firma: {
      type: String,
      default: "",
    },
    standorte: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      editAdress: {
        bemerkung: "",
        land: {},
      },
      shown: false,
      land: {},
      adressLand: { land: {} },
      standort: { bezeichnung: "", id: "" },
    };
  },
  computed: {
    laender() {
      if (this.$store) return this.$store.state.laender;
      return [];
    },
    adresstypen() {
      if (this.$store) return this.$store.state.adresstypen;
      return [];
    },
    postleitzahlen: {
      get() {
        return this.$store.state.geschaeftspartner.postleitzahlen;
      },
    },
  },
  watch: {
    ["editAdress.plz"]() {
      if (!this.editAdress.ort) {
        let ort = this.postleitzahlen.find(
          (plz) => plz.plz == this.editAdress.plz
        );

        if (ort != null) this.editAdress.ort = ort.ort;
      }
      if(!this.editAdress.kanton){
        let kanton = this.postleitzahlen.find(
           (plz) => plz.plz == this.editAdress.plz
      );
      if (kanton != null) this.editAdress.kanton = kanton.kanton;
      }
    },
    ["editAdress.ort"]() {
      if (this.editAdress.plz != null && this.editAdress.plz != "") return;
      if (this.editAdress.ort) return;

      let plz = this.postleitzahlen.find(
        (plz) => plz.ort == this.editAdress.ort
      );
      if (plz != null) this.editAdress.plz = plz.plz;
    },

  },
  mounted() {
    this.$bus.$on("adresse-modal-show", (data) => {
      this.editAdress = Object.assign({}, data.adresse);
      this.editAdress.isKorrespondenz = data.isKorrespondenz;
      this.editAdress.isRechnung = data.isRechnung;
      this.editAdress.isKonkordat = data.isKonkordat;
      this.standort = data.standort;

      /*** Default Auswahlwerte setzen */

      if (this.editAdress.land) {
        this.adressLand.land = this.editAdress.land;
      }
      if (!this.editAdress.land) {
        const index = this.laender.findIndex(
          (land) => land.id == this.$CONST("LAENDER").DEFAULT
        );

        if (index != -1) {
          this.editAdress.land = this.laender[index];
          this.adressLand.land = this.laender[index];
        }
      }

      if (!this.shown) this.openModal();
    });
  },
  methods: {
    openModal() {
      this.shown = true;

      $("#adresse-bearbeiten-modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    closeModal() {
      this.shown = false;
      $("#adresse-bearbeiten-modal").modal("hide");
    },

    uebernehmen() {
      //editAdress undefined, geht nie ins adressChanged
      // editAdress.id wird nicht erstellt bei "adressCreated", sondern ein editAdress.key
      this.editAdress.land = this.adressLand.land;
      this.editAdress.standort = this.standort;

      if (this.neueAdresse) {
        this.$emit("adressCreated", this.editAdress);
      } else {
        this.$emit("adressChanged", this.editAdress);
      }
      this.closeModal();
    },
  },
};
</script>

<style>
</style>

<template>
  <div class="row ml-0 mr-0">
    <div class="col-md-12">
      <div class="row">
        <div class="col-12">
          <container-headline
            :headline="
              $t('global.clearingitems') +
              ' (' +
              verrechnungspositionsCount +
              ')'
            "
            :col="18"
          >
          </container-headline>
          <div class="row">
            <div class="col-xl-12 block modal-liste br-t-l-0">
              <div>
                <b-table
                  ref="selectableTable"
                  tbody-tr-class="item"
                  sticky-header
                  :items="verrechnungen"
                  :fields="fields"
                  @row-dblclicked="editieren"
                >
                  <template v-slot:head(selected)="row">
                    <b-form-checkbox
                      v-model="row.selected"
                      @input="selectAllRows(row)"
                    ></b-form-checkbox>
                  </template>
                  <template v-slot:cell(selected)="row">
                    <b-form-checkbox
                      v-model="row.item.selected"
                      @input="onRowSelected(row)"
                    ></b-form-checkbox>
                  </template>
                  <template v-slot:cell(pflicht)="row">
                    <font-awesome-icon
                      v-if="row.item.pflicht"
                      icon="fa-solid fa-check"
                    />
                    <font-awesome-icon
                      v-if="!row.item.pflicht"
                      icon="fa-light fa-times"
                    />
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import ContainerHeadline from "@/components/ContainerHeadline";
import { v4 as uuid } from "uuid";

export default {
  name: "Verrechnungspositionen",
  components: {
    ContainerHeadline,
  },
  props: {
    veranstaltung: {
      type: String,
      required: true,
    },
    shown: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      sortBy: "",
      verrechnungspositionsCount: 0,
      sortDesc: false,
      verrechnungen: [],
      selected: [],
      selectedVpIds: [],
      artikel: [],
      verrechnung: {
        id: null,
        veranstaltungId: this.veranstaltung,
        anzahl: 1.0,
        manueller_preis: null,
        pflicht: true,
        artikelId: null,
        artikel: null,
      },
      positionenKey: uuid(),
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "anzahl",
          sortable: true,
          label: this.$t("global.Number"),
        },
        {
          key: "artikel.bezeichnung",
          sortable: true,
          label: this.$t("global.item"),
        },
        {
          key: "artikel.kurzbezeichnung",
          sortable: true,
          label: this.$t("global.shortdesignation"),
        },
      ];
    },
  },
  created() {},
  watch: {
    shown(val) {
      if (val) this.getVerrechnung();
    },
    selectedVpIds(val) {
      this.$emit("vpGesetzt", val);
    },
  },
  mounted() {
    if (this.shown && this.verrechnungen.length == 0) this.getVerrechnung();
  },
  methods: {
    getVerrechnung() {
      Api.get("/rechnungslegung/verrechnungsposition/", {
        params: { veranstaltung: this.veranstaltung },
      }).then((response) => {
        this.verrechnungen = response.data;
        this.verrechnungspositionsCount = this.verrechnungen.length;
      });
    },

    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = true;
        });
      } else {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = false;
        });
        this.selectedVpIds = [];
      }
      this.$forceUpdate();
    },

    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedVpIds.push(row.item.id);
      } else {
        this.selectedVpIds = this.selectedVpIds.filter(
          (id) => id != row.item.id
        );
      }
    },
    editieren(verrechnung) {
      this.showPositionModal(verrechnung);
    },
  },
};
</script>

<style></style>

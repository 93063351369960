import Questionaire from "@/pages/Questionaire/Questionaire";
import QuestionaireListe from "@/pages/Dashboardlisten/Questionaireliste";


export default [
  /*** Questionaire ***/
  {
    path: "/Questionaireliste",
    name: "questionaire-liste",
    component: QuestionaireListe
  },
  {
    path: "/questionaire/:id?/:anzeige",
    name: "questionaire",
    component: Questionaire,
    props: route => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a
      };
    }
  }
];

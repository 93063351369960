import axios from "axios";
import router from "./Router";

let pdfGeneratorApiConfig = {
	baseURL: process.env.VUE_APP_PDFGENERATORAPI_URL,
	defaultURL: process.env.VUE_APP_SERVER_URL,
	withCredentials: false,
};

const PdfGeneratorApi = axios.create(pdfGeneratorApiConfig);

PdfGeneratorApi.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (error.response) {
			switch (error.response.status) {
				case 400:
					router.push({ path: "/error/400" });
					break;
				case 401:
					if (
						router.currentRoute.name !== "login" &&
						router.currentRoute.name !== "leitsystem" &&
						router.currentRoute.name !== "resetpassword" &&
						router.currentRoute.name !== "newpassword"
					)
						router.push({ name: "login" });
					break;
				case 402:
					router.push({ path: "/error/402" });
					break;
				case 403:
					router.push({ path: "/error/403" });
					break;
				case 404:
					router.push({ path: "/error/404" });
					break;
				case 405:
					router.push({ path: "/error/405" });
					break;
			}
		}
	}
);

export default PdfGeneratorApi;

import {
    SET_BELEG_TYPEN,
    SET_KONTO,
    SET_WAEHRUNG,
    SET_KOSTENSTELLE
} from "./mutations.type";

import { LADE_AUSWAHLWERTE_BELEG } from "./actions.type";

import Api from "../../Api";

export default {
    namespaced: true,
    state: {
        belegtypen: [],
        konten: [],
        waehrungen: [],
        kostenstellen: [],
    },
    mutations: {
        [SET_BELEG_TYPEN](state, val) {
            state.belegtypen = val;
        },
        [SET_KONTO](state, val) {
            state.konten = val;
        },
        [SET_WAEHRUNG](state, val) {
            state.waehrungen = val;
        },
        [SET_KOSTENSTELLE](state, val) {
            state.kostenstellen = val;
        },
    },
    actions: {
        async [LADE_AUSWAHLWERTE_BELEG](context) {
            let response = await Api.get("finanzen/beleg/auswahlwerte/");
            context.commit(SET_BELEG_TYPEN, response.data.belegtypen);
            context.commit(SET_KONTO, response.data.konten);
            context.commit(SET_WAEHRUNG, response.data.waehrungen);
            context.commit(SET_KOSTENSTELLE, response.data.kostenstellen);
        },
    }
};

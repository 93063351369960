export const SET_LEADQUELLEN = "setLeadQuellen";
export const SET_LEADTYPEN = "setLeadTypen";
export const SET_LEADSTAGES = "setLeadStages";
export const SET_KONTAKTROLLEN = "setKontaktRollen";
export const SET_AKTIVITAETTYPEN = "setAktivitaetenTypen";
export const SET_AKTIVITAETSTATI = "setAktivitaetenStati";
export const SET_KAMPAGNESTATI = "setKampagneStati";
export const SET_KAMPANGEEINTRAGSTATI = "setKampagneEintragStati";
export const SET_KAMPAGNETEILNEHMERSTATI = "setKampagneTeilnehmerStati";
export const SET_MITBEWERBERPRODUKTE = "setMitbewerberProdukte";

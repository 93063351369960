import {
    SET_BEITRAG_STATI,
    SET_BEITRAG_INTERVALLE,
    SET_ABRECHNUNGS_PERIODEN,
    SET_ABRECHNUNGS_STATI,
    SET_ANTRAGS_STATI
} from "./mutations.type";

import { LADE_AUSWAHLWERTE_SUBVENTION_BEITRAG } from "./actions.type";

import Api from "../../Api";

export default {
    namespaced: true,
    state: {
        beitragstati: [],
        beitragsintervalle: [],
        abrechnungsstati: [],
        abrechnungsperioden: [],
        antragsstati: []
    },
    mutations: {
        [SET_BEITRAG_STATI](state, val) {
            state.beitragstati = val;
        },
        [SET_BEITRAG_INTERVALLE](state, val) {
            state.beitragsintervalle = val;
        },
        [SET_ABRECHNUNGS_PERIODEN](state, val) {
            state.abrechnungsperioden = val;
        },
        [SET_ABRECHNUNGS_STATI](state, val) {
            state.abrechnungsstati = val;
        },
        [SET_ANTRAGS_STATI](state, val) {
            state.antragsstati = val;
        },
    },
    actions: {
        async [LADE_AUSWAHLWERTE_SUBVENTION_BEITRAG](context) {
            let response = await Api.get("subvention/beitrag/auswahlwerte/");
            context.commit(SET_BEITRAG_STATI, response.data.beitragstati);
            context.commit(SET_BEITRAG_INTERVALLE, response.data.beitragsintervalle);
            context.commit(SET_ABRECHNUNGS_PERIODEN, response.data.abrechnungsperioden);
            context.commit(SET_ABRECHNUNGS_STATI, response.data.abrechnungsstati);
            context.commit(SET_ANTRAGS_STATI, response.data.antragsstati);
        }
    }
};

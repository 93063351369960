<template>
  <div class="list">
    <navbar :docsName="docsName"/>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu-filter>

            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.designation"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                        :tabindex="1"
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.description"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                        :tabindex="2"
                      />
                      <label>{{ $t("global.description") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button />
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              headline="Questionaire"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between">
                  <div class="d-flex align-items-center ml-4">
                    <router-link
                      v-if="berechtigungen.m_korrespondenz.create"
                      class="btn btn-success"
                      :to="{ name: 'questionaire', params: { anzeige: 0 } }"
                    >
                      <font-awesome-icon icon="fa-regular fa-plus" />
                      {{ $t("global.new") }}
                    </router-link>
                    <button
                      class="btn btn-primary ml-2"
                      @click="copy"
                      :disabled="!hasValueToCopy"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-copy"
                        class="mr-2"
                      />
                      {{ $t("global.Copy") }}
                    </button>
                  </div>
                  <div class="mr-4 float-right">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_korrespondenz.delete"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />{{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                    thead-tr-class="pointer"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td class="no-pointer" v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>

                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="mail-loeschen-modal"
      :selectedIds="this.selectedIds"
      :multiple="true"
      @confirmed="qsLoeschen"
    />

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import QuestionaireApi from "@/QuestionaireApi";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";

import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/QuestionaireDashboard";

export default {
  name: "QuestionaireListe",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
    FilterSearchResetButton,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.questionaire");
      },
    };
  },
  mixins: [page, dashboard],
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
  store,
  data() {
    return { hasValueToCopy: false };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "designation",
          sortable: true,
          label: this.$t("global.designation"),
        },
        {
          key: "description",
          sortable: true,
          label: this.$t("global.description"),
        },
      ];
    },
    queryParams: function () {
      var params = {};

      params.designation = this.setQueryParam(this.filters.designation);
      params.description = this.setQueryParam(this.filters.description);

      return params;
    },
  },
  watch: {
    selectedIds(val) {
      val != 0 ? (this.hasValueToCopy = true) : (this.hasValueToCopy = false);
    },
  },
  created() {
    this.initFilter("questionaire-liste", "/assessment/", true);
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
  },
  methods: {
    details(questionaire) {
      this.$router.push({
        name: "questionaire",
        params: { id: questionaire.id, anzeige: 0 },
      });
    },

    copy() {
      console.log(this.selectedIds.join(","));
      QuestionaireApi.get("assessment/", {
        params: { idsToCopy: this.selectedIds.join(",") },
      }).then(() => {
        this.resetLoading(this);
      });
    },
    qsLoeschen() {
      QuestionaireApi.delete("assessment/" + this.selectedIds[0], {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.resetLoading(this);
      });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "mail-loeschen-modal");
    },
  },
};
</script>

<style></style>

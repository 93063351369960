<template>
  <div
    :id="id"
    class="modal fade"
    tabindex="-1"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div
      class="modal-dialog modal-fluid modal-lernzielvereinbarungBewertungFUModal"
    >
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">
            {{ $t("global.learningobjectivesagreementremedation") }}
          </h4>
        </div>
        <div class="modal-body">
          <div class="col-xl-12">
            <!-- PRUEFE DATEN -->
            <div
              class="row"
              v-if="isBusy || ladeFehler || !this.lernzielvereinbarungFU.id"
            >
              <div class="text-center text-primary m-auto my-2" v-if="isBusy">
                <b-spinner class="align-middle"></b-spinner>
              </div>
              <div
                class="text-center text-danger m-auto my-2"
                v-if="ladeFehler && !isBusy"
              >
                <strong>{{ $t("global.errorwhileloading") }}</strong>
              </div>
              <div
                class="text-center text-primary m-auto my-2"
                v-if="!this.lernzielvereinbarungFU.id && !isBusy"
              >
                <strong>{{ $t("global.nodataavailable") }}</strong>
              </div>
            </div>

            <!-- TABELLE -->
            <div
              class="row px-4 mt-4"
              v-if="!isBusy && !ladeFehler && this.lernzielvereinbarungFU.id"
            >
              <!-- INFO -->

              <div class="col-xl-12 mb-5">
                <h4 class="mb-4">
                  {{
                    $t("global.remedationlearningobjectivesagreementprotocol")
                  }}
                </h4>
                <div class="row">
                  <div
                    class="col-6 p-2 border border-dark border-bottom-0 border-right-0 bg-primary text-white font-weight-bold"
                  >
                    <div class="form-group d-flex align-items-center">
                      <span class="col-3">{{ $t("global.studentfm") }}:</span>
                      <input
                        class="form-control d-flex col-9"
                        placeholder="Name, Vorname"
                        v-model="lernzielvereinbarungFU.lernender.personName"
                      />
                    </div>
                  </div>

                  <div class="col-6 p-2 border border-dark border-bottom-0">
                    <div class="form-group d-flex align-items-center">
                      <span class="col-2"
                        >{{ $t("global.teachingtype") }}:</span
                      >
                      <input
                        class="form-control d-flex col-10"
                        placeholder="EBA-Förderunterricht"
                        v-model="lernzielvereinbarungFU.unterrichtsart"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-4 p-2 border border-dark border-bottom-0 border-right-0"
                  >
                    <div class="form-group d-flex align-items-center">
                      <span>{{ $t("global.vocationalschool") }}:</span>
                    </div>
                  </div>
                  <div class="col-8 p-2 border border-dark border-bottom-0">
                    <div class="form-group d-flex align-items-center">
                      <input
                        class="form-control d-flex col-12"
                        placeholder="Name, PLZ Ort"
                        v-model="lernzielvereinbarungFU.berufsschule"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-4 p-2 border border-dark border-bottom-0 border-right-0"
                  >
                    <div class="form-group d-flex align-items-center">
                      <span>Lehrperson Fachkunde:</span>
                    </div>
                  </div>
                  <div
                    class="col-4 p-2 border border-dark border-bottom-0 border-right-0"
                  >
                    <div class="form-group d-flex align-items-center">
                      <input
                        class="form-control d-flex col-12"
                        placeholder="Name, PLZ Ort"
                        v-model="
                          lernzielvereinbarungFU.info.lehrpersonFachkunde
                            .personName
                        "
                      />
                    </div>
                  </div>
                  <div class="col-4 p-2 border border-dark border-bottom-0">
                    <div class="form-group d-flex align-items-center">
                      <span>e-Mail: </span>
                      <input
                        class="form-control d-flex col-11"
                        placeholder="abc@xyz.ch"
                        v-model="
                          lernzielvereinbarungFU.info.lehrpersonFachkunde.email
                        "
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-4 p-2 border border-dark border-bottom-0 border-right-0"
                  >
                    <div class="form-group d-flex align-items-center">
                      <span>Lehrperson ABU:</span>
                    </div>
                  </div>
                  <div
                    class="col-4 p-2 border border-dark border-bottom-0 border-right-0"
                  >
                    <div class="form-group d-flex align-items-center">
                      <input
                        class="form-control d-flex col-12"
                        placeholder="Name, PLZ Ort"
                        v-model="
                          lernzielvereinbarungFU.info.lehrpersonABU.personName
                        "
                      />
                    </div>
                  </div>
                  <div class="col-4 p-2 border border-dark border-bottom-0">
                    <div class="form-group d-flex align-items-center">
                      <span>e-Mail: </span>
                      <input
                        class="form-control d-flex col-11"
                        placeholder="abc@xyz.ch"
                        v-model="
                          lernzielvereinbarungFU.info.lehrpersonABU.email
                        "
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-4 p-2 border border-dark border-bottom-0 border-right-0"
                  >
                    <div class="form-group d-flex align-items-center">
                      <span>Arbeitgeber aktuell:</span>
                    </div>
                  </div>
                  <div class="col-8 p-2 border border-dark border-bottom-0">
                    <div class="form-group d-flex align-items-center">
                      <input
                        class="form-control d-flex col-12"
                        placeholder="Name, PLZ Ort"
                        v-model="lernzielvereinbarungFU.info.arbeitgeber"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4 p-2 border border-dark border-right-0">
                    <div class="form-group d-flex align-items-center">
                      <span>PrA absolviert?</span>
                    </div>
                  </div>
                  <div
                    class="col-4 p-2 border border-dark border-right-0 d-flex"
                  >
                    <div class="form-group d-flex align-items-center">
                      <input
                        class="form-control d-flex mr-2"
                        style="width: 14px"
                        type="radio"
                        name="praAbsolviert"
                        :checked="
                          !lernzielvereinbarungFU.info.pra.von &&
                          !lernzielvereinbarungFU.info.pra.bis &&
                          !lernzielvereinbarungFU.info.pra.berufsbezeichnung
                        "
                      />
                      <span>nein</span>
                    </div>
                  </div>
                  <div class="col-4 p-2 border border-dark d-flex">
                    <div class="form-group d-flex align-items-center">
                      <input
                        class="form-control d-flex mr-2"
                        style="width: 18px"
                        type="radio"
                        name="praAbsolviert"
                        :checked="
                          lernzielvereinbarungFU.info.pra.von ||
                          lernzielvereinbarungFU.info.pra.bis ||
                          lernzielvereinbarungFU.info.pra.berufsbezeichnung
                        "
                      />
                      ja,
                      <input
                        class="form-control d-flex w-25 mx-2"
                        placeholder="JJJJ"
                        v-model="lernzielvereinbarungFU.info.pra.von"
                      />
                      -
                      <input
                        class="form-control d-flex w-25 mx-2"
                        placeholder="JJJJ"
                        v-model="lernzielvereinbarungFU.info.pra.bis"
                      />
                      als
                      <input
                        class="form-control d-flex ml-2"
                        placeholder="Berufsbezeichnung"
                        v-model="
                          lernzielvereinbarungFU.info.pra.berufsbezeichnung
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-12 mb-5">
                <h4>1 Ergebnisse Einstufungstest</h4>

                <div class="row">
                  <div class="col-12 p-0">
                    <textarea
                      class="w-100"
                      placeholder="hier Ergebnisse erfassen"
                      v-model="lernzielvereinbarungFU.ergebnisseEinstufungstest"
                    />
                  </div>
                </div>
              </div>
              <div class="col-xl-12 mb-5">
                <div class="row" style="min-height: 90px">
                  <div
                    class="col-2 align-bottom border border-dark border-right-0 border-top-0 border-left-0"
                  >
                    <h4>2 Lernziele</h4>
                  </div>
                  <div
                    class="col-5 border border-dark border-right-0 align-bottom"
                  >
                    Was soll sich durch die Schulung konkret verbessern?
                  </div>
                  <div class="col-5 border border-dark align-bottom">
                    Wie merken wir, dass die Schulung die Person diesbezüglich
                    weitergebracht hat?
                  </div>
                </div>
                <div
                  class="row"
                  v-for="(fach, index) in lernzielvereinbarungFU.info.faecher"
                  :key="index"
                >
                  <div
                    class="col-2 border border-dark border-right-0 border-top-0"
                  >
                    {{ fach.bezeichnung }}
                  </div>
                  <div
                    class="col-5 border border-dark border-right-0 border-top-0 p-2"
                  >
                    <div class="col-12">
                      <textarea
                        class="w-100"
                        placeholder=" "
                        v-model="fach.lernziel"
                        rows="3"
                      />
                    </div>
                  </div>
                  <div class="col-5 border border-dark p-2 border-top-0">
                    <div class="col-12">
                      <textarea
                        class="w-100"
                        placeholder=" "
                        v-model="fach.bewertungsgrundlage"
                        rows="3"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-12 mb-5">
                <div class="row">
                  <div
                    class="col-2 border border-dark border-right-0 border-bottom-0 d-flex align-items-center"
                  >
                    <span>Datum der Vereinbarung:</span>
                  </div>
                  <div
                    class="col-10 border border-dark border-bottom-0 d-flex align-items-center"
                  >
                    <div class="col-xl-4 form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        placeholer=" "
                        :value="lernzielvereinbarungFU.enddatum"
                        :show-icon="true"
                        @update="
                          (val) =>
                            (lernzielvereinbarungFU.datumVereinbarung = val)
                        "
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-2 border border-dark border-right-0 d-flex align-items-center"
                  >
                    Lehrperson Scala:
                  </div>
                  <div
                    class="col-10 border border-dark d-flex align-items-center"
                  >
                    <div class="col-xl-4 form-group">
                      <select
                        class="col-12"
                        v-model="lernzielvereinbarungFU.lehrperson"
                        placeholder="Name, Vorname"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                        <option
                          v-for="(fach, index) in lernzielvereinbarungFU.info
                            .faecher"
                          :key="index"
                        >
                          {{ fach.verantwortlicher.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            v-if="editable"
            type="button"
            class="btn btn-success"
            @click="speichern"
          >
            {{ $t("global.save") }}
          </button>
          <button
            class="btn btn-primary ml-2"
            @click="excelExport"
            v-if="editable"
          >
            <font-awesome-icon icon="fa-regular fa-file-export" class="mr-1" />
            {{ $t("global.export") }}
          </button>
          <button
            v-if="editable"
            type="button"
            class="btn btn-primary"
            @click="drucken"
          >
            {{ $t("global.print") }}
          </button>
          <button
            v-if="editable"
            type="button"
            class="btn btn-primary"
            @click="vorlageDrucken"
          >
            {{ $t("global.printtemplate") }}
          </button>
          <button type="button" class="btn btn-secondary" @click="closeModal">
            {{ $t("global.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import store from "@/store";
import { modal } from "@/mixins/Modal";

import DatePicker from "@/components/Datepicker";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import FileDownload from "js-file-download";
import ModalCloseButton from "@/components/global/ModalCloseButton.vue";

export default {
  name: "LernzielvereinbarungBewertungFoerderunterrichtModal",
  mixins: [modal],
  components: {
    BSpinner,
    DatePicker,
    ModalCloseButton
  },
  store,
  props: {
    id: {
      type: String,
      required: false,
      default: "lernzielvereinbarung-bewertung-fu-modal",
    },
    lernzielvereinbarungFUID: {
      type: String,
      required: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isBusy: false,
      ladeFehler: false,
      lernzielvereinbarungFU: {
        id: "123",
        lernender: { personName: "asd" },
        unterrichtsart: "asd",
        berufsschule: "asd",
        //bezeichnung: "",
        info: {
          datum: "",
          person: {
            personName: "Name Vorname",
          },
          schule: "",
          unterrichtArt: "",
          lehrpersonFachkunde: { personName: "", email: "abc@xyz.ch" },
          lehrpersonABU: { personName: "", email: "abc@xyz.ch" },
          arbeitgeberAktuell: "test",
          klasse: {
            lehrgang: {},
          },
          pra: {
            von: "JJJJ",
            bis: "JJJJ",
            berufsbezeichnung: "test",
          },
          faecher: [
            {
              //verantwortliche: [{ name: "" }],
              verantwortlicher: { name: "Vorname1, Name1", email: "email1" },
              bezeichnung: "Deutsch",
              lernziel: "",
              bewertungsgrundlage: "",
            },
            {
              //verantwortliche: [{ name: "" }],
              verantwortlicher: { name: "Vorname2, Name2", email: "email2" },
              bezeichnung: "Fachkunde",
              lernziel: "",
              bewertungsgrundlage: "",
            },
            {
              //verantwortliche: [{ name: "" }],
              verantwortlicher: { name: "Vorname3, Name3", email: "email3" },
              bezeichnung: "ABU",
              lernziel: "",
              bewertungsgrundlage: "",
            },
          ],
        },
        ergebnisseEinstufungstest: "sehr gut",
        fortschritte: [
          {
            bezeichnung: "",
            bewertung: {
              lernende: "",
              lehrperson: "",
              kuerzel: "",
            },
          },
        ],
        lernziele: [
          {
            bezeichnung: "",
            bewertung: {
              lernende: "",
              lehrperson: "",
              kuerzel: "",
            },
          },
        ],
        datumVereinbarung: "",
        lehrperson: {
          personName: "Name, Vorname",
        },
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.$bus.$on("openLernzielvereinbarungBewertungFUModal", (ids) => {
      if (ids.modalId == this.id && !this.shown) {
        this.openModal();
        this.getLernzielvereinbarungFU();
      }
    });
  },
  methods: {
    excelExport() {
      /*
        Api.post(
          "/lernzielvereinbarung/export/",
          this.lernzielvereinbarungBewertung,
          { responseType: "blob" } //SWE: notwendig damit OutputExcel nicht beschädigt wird
        )
          .then((response) => {
            this.excelFile = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            FileDownload(
              this.excelFile,
              `Lernzielvereinbarung-${new Date().toLocaleDateString()}.xlsx`
            );
            this.loading = false;
          })
          .catch(() => {
            setTimeout(() => {
              this.$notify({
                type: "error",
                title: this.$t("global.actionfailed"),
                text: "Fehler beim Erstellen der Excel-Datei",
                duration: 7000,
              });
            }, 2000);
            this.loading = false;
          });
          */
    },

    getLernzielvereinbarungFU() {
      this.isBusy = true;

      Api.get("lernzielvereinbarung/lernzielvereinbarungFU/", {
        params: { id: this.lernzielvereinbarungFUID },
      })
        .then((response) => {
          let test = response.data;
          console.log("test " + test);
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
      /*
        this.isBusy = true;
  
        Api.get("lernzielvereinbarung/anmeldung/", {
          params: { anmeldungId: anmeldungId },
        })
          .then((response) => {
            this.lernzielvereinbarungBewertung = response.data;
            this.ladeFehler = false;
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
            this.ladeFehler = true;
          })
          .finally(() => {
            this.isBusy = false;
          });*/
    },
    initializeLernzielvereinbarungBewertungFoerderunterricht(response) {
      /*this.lernzielvereinbarungBewertung = response.data;*/
    },
    setDate(date) {
      /*
        this.lernzielvereinbarungBewertung?.fortschritte?.forEach(
          (fortschritt) => {
            fortschritt.bewertung.datum = date;
          }
        );
  
        this.lernzielvereinbarungBewertung?.lernziele?.forEach((ziel) => {
          ziel.bewertung.datum = date;
        });*/
    },
    closeModal() {
      this.shown = false;
      $("#lernzielvereinbarung-bewertung-fu-modal").modal("hide");
    },
    speichern() {
      /*
        this.isBusy = true;
  
        var json = Object.assign({}, this.lernzielvereinbarungBewertung);
  
        Api.post("lernzielvereinbarung/anmeldung/", json, {
          params: { anmeldungId: this.anmeldungId },
        })
          .then((response) => {
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t(
                "global.learningobjectiveagreementsuccessfullysaved"
              ),
            });
            this.ladeFehler = false;
            this.initializeLernzielvereinbarungBewertung(response);
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
            this.ladeFehler = true;
          })
          .finally(() => {
            this.isBusy = false;
          });*/
    },
    drucken() {
      /*
        this.speichern();
        this.$emit("lernzieleDrucken");
        this.closeModal();*/
    },
    vorlageDrucken() {
      /*
        this.speichern();
        this.$emit("lernzieleVorlageDrucken");
        this.closeModal();*/
    },
  },
};
</script>

<style>
.lernzielbewertungFaecher > div:last-child > div {
  border-bottom: 0 !important;
}
</style>

<template>
  <div class="row mb-2" :id="id">
    <div v-for="(person, index) in personen" :key="index" class="col-xl-6 px-3">
      <div class="form-group mb-0">
        <span
          v-if="editable && index == 0"
          v-on:click="addPerson()"
          class="multiAddIcon"
        >
          <font-awesome-icon icon="fa-regular fa-plus" />
        </span>
        <span
          v-if="editable && index > 0"
          class="multiAddIcon"
          v-on:click="removePerson(index)"
          ><font-awesome-icon icon="fa-regular fa-minus" />
        </span>
        <span
          v-if="editable"
          class="editIcon"
          v-on:click="oeffneGPSucheModal(index)"
          ><font-awesome-icon icon="fa-duotone fa-pencil-alt" />
        </span>
        <span class="openIcon" v-on:click="openPerson(person.id)" v-if="open"
          ><font-awesome-icon icon="fa-duotone fa-arrow-circle-right" />
        </span>
        <input v-model="personNames[index]" class="form-control" readonly />
        <label v-if="index == 0"
          >{{ label }}
          <!--<font-awesome-icon icon="fa-duotone fa-arrow-circle-right" />-->
        </label>
      </div>
    </div>

    <gpSucheModal
      :id="id + '-geschaeftspartner-suche-modal'"
      :filterRollen="rollen"
      @confirmed="onGPResult"
    ></gpSucheModal>
  </div>
</template>

<script>
import gpSucheModal from "@/components/Geschaeftspartner/gpSucheModal";

export default {
  name: "MultiSelectPerson",
  components: { gpSucheModal },
  props: {
    id: {
      type: String,
      default: "multi-select-person",
    },
    personen: {
      type: Array,
      default: function () {
        return [{ personName: this.$t("global.notlinked") }];
      },
    },
    label: {
      type: String,
      default: "Verantwortliche",
    },
    rollen: {
      type: Array,
      required: false,
    },
    open: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      editPerson: null,
    };
  },
  computed: {
    personNames() {
      return this.personen.map((person) =>
        person?.id ? person.personName : this.$t("global.notlinked")
      );
    },
  },
  methods: {
    oeffneGPSucheModal(index) {
      this.editPerson = index;
      this.$bus.$emit(
        "openGeschaeftspartnerModal",
        this.id + "-geschaeftspartner-suche-modal"
      );
    },
    onGPResult(selectedPerson) {
      if (selectedPerson) {
        let tempListe = this.personen;
        this.$set(tempListe, this.editPerson, selectedPerson[0]);
      }
      this.$emit("confirmed", this.personen);
    },
    addPerson() {
      if (this.personen.length < 6) {
        let tempListe = this.personen;
        tempListe.push({ personName: this.$t("global.notlinked") });
      }
    },
    removePerson(index) {
      let tempListe = this.personen;
      tempListe.length == 0
        ? { personName: this.$t("global.notlinked") }
        : tempListe.splice(index, 1);

      this.$emit("confirmed", this.personen);
    },
    openPerson(id) {
      if (id) {
        this.$router.push({
          name: "geschaeftspartner",
          params: { id: id, anzeige: 0 },
        });
      }
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.scoreinput')"
        :col="3"
      >
        <div class="row mt-2 d-flex justify-content-between">
          <div class="ml-4">
            <button
              class="btn btn-success"
              @click="addNoteneingabe"
              v-if="berechtigungen.b_bildung_noteneingabe.create"
            >
              <font-awesome-icon icon="fa-regular fa-plus" />
              {{ $t("global.scoreinput") }}
            </button>
            <!--     <button
              class="btn btn-primary ml-2"
              @click="exportNoten"
              v-if="berechtigungen.b_bildung_noteneingabe.read"
            >
              <font-awesome-icon
                icon="fa-regular fa-file-export"
                class="mr-1"
              />
              {{ $t("global.gradeexport") }}
            </button> -->
          </div>
          <div class="mr-4">
            <button
              class="btn btn-danger"
              @click="oeffneLoeschenModal"
              v-if="berechtigungen.b_bildung_noteneingabe.delete"
            >
              <font-awesome-icon class="mr-1" icon="fa-duotone fa-trash" />
              <span>{{ $t("global.delete") }}</span>
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              tbody-tr-class="item"
              :items="noteneingabeliste"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="isBusy"
              sort-icon-left
              fixed
              show-empty
              selectable
              select-mode="range"
              @row-dblclicked="details"
              thead-tr-class="pointer"
            >
              <template v-slot:head(selected)="row">
                <b-form-checkbox
                  v-model="row.selected"
                  @input="selectAllRows(row)"
                ></b-form-checkbox>
              </template>

              <template v-slot:cell(selected)="row">
                <b-form-checkbox
                  v-model="row.item.selected"
                  @input="onRowSelected(row)"
                ></b-form-checkbox>
              </template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>{{ $t("global.errorwhileloading") }}</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>{{ $t("global.nodataavailable") }}</strong>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="noteneingabe-loeschen-modal"
      @confirmed="noteneingabeloeschen"
      :multiple="true"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import reiter from "@/mixins/Reiter";
//import FileDownload from "js-file-download";

export default {
  components: {
    ContainerHeadline,
    LoeschenModal,
    BSpinner,
  },
  mixins: [reiter],
  props: {
    veranstaltung: {
      type: String,
      required: true,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    klasseObject: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      page: 0,
      infiniteId: +new Date(),
      anzahlProPage: 25,
      sortBy: "",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      noteneingabeliste: [],
      selected: [],
      selectedIds: [],
    };
  },
  watch: {
    shown(val) {
      if (val && this.noteneingabeliste.length == 0) this.getNotenEingaben();
    },
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
          formatter: (value) => {
            return value ? value : this.$t("global.nodesignationset");
          },
        },
        {
          key: "noteneingabe_typ.bezeichnung",
          sortable: true,
          label: this.$t("global.scoreinputtype"),
        },
        {
          key: "noteneingabe_status.bezeichnung",
          sortable: true,
          label: this.$t("global.scoreinputstatus"),
        },
        {
          key: "sichtbar_von",
          sortable: true,
          label: this.$t("global.visiblefrom"),
        },
        {
          key: "sichtbar_bis",
          sortable: true,
          label: this.$t("global.visibleuntil"),
        },
      ];
    },
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      if (this.suche) params.suche = this.suche;

      return params;
    },
  },
  mounted() {
    if (this.shown && this.noteneingabeliste.length == 0)
      this.getNotenEingaben();
  },
  created() {},
  methods: {
    getNotenEingaben() {
      this.isBusy = true;

      Api.get("noteneingabe/", {
        params: {
          veranstaltung: this.veranstaltung,
          page: this.page,
          count: this.anzahlProPage,
        },
      })
        .then((response) => {
          //response.data.shift();
          this.noteneingabeliste = response.data;
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    details(noteneingabe) {
      this.$router.push({
        name: "noteneingabe",
        params: { id: noteneingabe.id, anzeige: 0 },
      });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "noteneingabe-loeschen-modal");
    },

    noteneingabeloeschen() {
      Api.delete("noteneingabe/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.getNotenEingaben();
      });
    },

    /*  exportNoten() {
      let json = this.klasseObject;

      if (json) {
        Api.post("noten/notenexport/", json, { responseType: "blob" })
          .then((response) => {
            let excelFile = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            FileDownload(excelFile, "Subventionen.xlsx");
          })
          .catch((error) => {
            this.$notify(apiErrorToAlert(error));
             setTimeout(() => {
              this.$notify({
                type: "error",
                title: "Aktion fehlgeschlagen",
                text: "Fehler beim Erstellen der Excel-Datei \n -> Excel-Format überprüfen. Datei erneut hochladen.",
                duration: 7000,
              });
            }, 2000); 
          });
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noclassdefined"),
        });
      }
    }, */

    addNoteneingabe() {
      Api.post("noteneingabe/", {
        noteneingabe_status: this.$CONST("NOTENEINGABESTATI").PREPARED,
        // bezeichnung: this.$t("global.newdataset"),
        sichtbar_mit_details: this.$CONST("NOTENSICHTBARKEITEN").NOTVISIBLE,
        noteneingabe_typ: this.$CONST("NOTENEINGABETYPEN").GRADES,
        praesenzveranstaltung: this.veranstaltung,
        veranstaltungen: [this.veranstaltung],
      })
        .then((response) => {
          this.getNotenEingaben();
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.gradessavedsuccessfully"),
          });
          console.log(response.data.id);
          this.$router.push({
            name: "noteneingabe",
            params: { id: response.data.id, anzeige: 0 },
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {});
    },
    infiniteHandler($state) {
      if (this.shown) {
        Api.get("noteneingabe/", {
          params: this.queryParams,
        }).then((response) => {
          console.log("final");
          if (response.data.length === this.anzahlProPage) {
            this.page += 1;
            this.noteneingabe.push(...response.data);
            $state.loaded();
          } else {
            this.noteneingabe.push(...response.data);
            $state.complete();
          }
        });
      }
    },
    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = true;
          if (!this.selectedIds.includes(gp.id)) {
            this.selectedIds.push(gp.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },
    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.activities')"
        :col="3"
      >
        <div class="row mt-2 d-flex justify-content-between">
          <div class="ml-4">
            <button
              class="btn btn-success mr-2"
              @click="neueAktivitaet"
              v-if="editable"
            >
              <font-awesome-icon icon="fa-duotone fa-ticket" class="mr-2" />
              {{ $t("global.newactivity") }}
            </button>
          </div>
          <div class="mr-4">
            <button
              class="btn btn-danger"
              @click="oeffneLoeschenModal"
              v-if="editable"
            >
              <font-awesome-icon class="mr-2" icon="fa-duotone fa-trash" />
              <span>{{ $t("global.delete") }}</span>
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <div>
              <b-table
                ref="selectableTable"
                tbody-tr-class="item"
                style="max-height: 300px"
                responsive
                sticky-header
                :items="aktivitaeten"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                sort-icon-left
                selectable
                select-mode="range"
                @row-dblclicked="details"
              >
                <template v-slot:head(selected)="row">
                  <b-form-checkbox
                    v-model="row.selected"
                    @input="selectAllRows(row)"
                  ></b-form-checkbox>
                </template>
                <template v-slot:cell(selected)="row">
                  <b-form-checkbox
                    v-model="row.item.selected"
                    @input="onRowSelected(row)"
                  ></b-form-checkbox>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="aktivitaet-loeschen-modal"
      @confirmed="aktivitaetLoeschen"
      :selectedIds="selectedIds"
    />

    <aktivitaet-modal
      @aktivitaetCreated="aktivitaetErstellt"
      @aktivitaetChanged="aktivitaetGeaendert"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import ContainerHeadline from "@/components/ContainerHeadline";
import AktivitaetModal from "@/components/crm/AktivitaetModal";
import LoeschenModal from "@/components/Modals/LoeschenModal";

import reiter from "@/mixins/Reiter";
import { apiErrorToAlert } from "@/utils/Errorhandler";

export default {
  components: {
    ContainerHeadline,
    LoeschenModal,
    AktivitaetModal,
  },
  mixins: [reiter],
  props: {
    lead: {
      type: String,
      required: false,
    },
    ansprechpartner: {
      type: Object,
      required: false,
    },
    firma: {
      type: Object,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    shown: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      page: 0,
      anzahlProPage: 200,
      aktivitaeten: [],
      selectedIds: [],
      sortBy: "effektivdatum",
      sortDesc: false,
    };
  },
  watch: {
    shown(val) {
      if (val && this.aktivitaeten.length == 0) this.getAktivitaeten();
    },
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "geplantdatum",
          sortable: true,
          label: this.$t("global.planned"),
          formatter: (value, key, item) => {
            return value ? value + " - " + item.geplantzeit : "-";
          },
        },
        {
          key: "effektivdatum",
          sortable: true,
          label: this.$t("global.effective"),
          formatter: (value, key, item) => {
            return value ? value + " - " + item.effektivzeit : "-";
          },
        },
        {
          key: "typ.bezeichnung",
          sortable: true,
          label: this.$t("global.type"),
        },
        {
          key: "status.bezeichnung",
          sortable: true,
          label: this.$t("global.status"),
        },
        {
          key: "firma.name",
          sortable: true,
          label: this.$t("global.contactcompany"),
        },
        {
          key: "person.personName",
          sortable: true,
          label: this.$t("global.contactperson"),
        },
        {
          key: "verantwortlicher",
          sortable: true,
          label: this.$t("global.Responsible"),
          formatter: (value, key, item) => {
            if (!value) return "";

            let listValue = value.map(function (val) {
              return val.personName;
            });

            return listValue.join(", ");
          },
        },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
      ];
    },
  },
  mounted() {
    if (this.shown) this.getAktivitaeten();
  },
  created() {},
  methods: {
    getAktivitaeten() {
      Api.get("crm/aktivitaet/", {
        params: {
          lead: this.lead,
          page: this.page,
          count: this.anzahlProPage,
        },
      }).then((response) => {
        this.aktivitaeten = response.data;
      });
    },

    details(aktivitaet) {
      this.$bus.$emit("aktivitaet-modal-show", aktivitaet);
    },

    neueAktivitaet() {
      let json = {
        verantwortlicher: [this.angemeldeterMitarbeiter.person],
        person: this.ansprechpartner,
        firma: this.firma,
        neu: true,
      };
      this.$bus.$emit("aktivitaet-modal-show", json);
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "aktivitaet-loeschen-modal");
      else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noactivityiesselectedfordeletion"),
        });
      }
    },

    aktivitaetLoeschen() {
      Api.delete("crm/aktivitaet/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.getAktivitaeten();
      });
    },

    aktivitaetErstellt(aktivitaet) {
      let json = aktivitaet;
      json.lead = this.lead;

      Api.post("crm/aktivitaet/", json)
        .then(() => {
          this.getAktivitaeten();
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.activitysavedsuccessfully"),
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    aktivitaetGeaendert(aktivitaet) {
      let json = aktivitaet;
      json.lead = this.lead;

      Api.put("crm/aktivitaet/", json, { params: { id: aktivitaet.id } })
        .then(() => {
          this.getAktivitaeten();
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.activitysavedsuccessfully"),
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

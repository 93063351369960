import axios from "axios";
import router from "./Router";

let apiLeitsystemConfig = {
  baseURL: process.env.VUE_APP_SERVER_URL,
  defaultURL: process.env.VUE_APP_SERVER_URL,
  withCredentials: false
};

const ApiLeitsystem = axios.create(apiLeitsystemConfig);

ApiLeitsystem.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (!error.response) return
    if (error.response.status === 401) {
      if (router.currentRoute.name !== "leitsystem")
        router.push({ name: "login" });
    }

    return Promise.reject(error);
  }
);

export default ApiLeitsystem;

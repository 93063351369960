import {
    SET_AUFTRAG_STATI,
    SET_BUCHUNG_STATI,
    SET_QUELLE_AUFTRAG,
    SET_ZAHLUNGS_KONDITION,
    SET_ZAHLUNGS_PLAN,
    SET_KONTO,
    SET_WAEHRUNG,
    SET_ZAHLUNGS_VERBINDUNG
} from "./mutations.type";

import { LADE_AUSWAHLWERTE_AUFTRAG } from "./actions.type";

import Api from "../../Api";

export default {
    namespaced: true,
    state: {
        auftragsstati: [],
        buchungsstati: [],
        quellen: [],
        zahlungskonditionen: [],
        zahlungsplaene: [],
        konten: [],
        waehrungen: [],
        zahlungsverbindungen: []
    },
    mutations: {
        [SET_AUFTRAG_STATI](state, val) {
            state.auftragsstati = val;
        },
        [SET_BUCHUNG_STATI](state, val) {
            state.buchungsstati = val;
        },
        [SET_QUELLE_AUFTRAG](state, val) {
            state.quellen = val;
        },
        [SET_ZAHLUNGS_KONDITION](state, val) {
            state.zahlungskonditionen = val;
        },
        [SET_ZAHLUNGS_PLAN](state, val) {
            state.zahlungsplaene = val;
        },
        [SET_KONTO](state, val) {
            state.konten = val;
        },
        [SET_WAEHRUNG](state, val) {
            state.waehrungen = val;
        },
        [SET_ZAHLUNGS_VERBINDUNG](state, val) {
            state.zahlungsverbindungen = val;
        },
    },
    actions: {
        async [LADE_AUSWAHLWERTE_AUFTRAG](context) {
            let response = await Api.get("auftrag/auswahlwerte/");
            context.commit(SET_AUFTRAG_STATI, response.data.auftragsstati);
            context.commit(SET_BUCHUNG_STATI, response.data.buchungsstati);
            context.commit(SET_QUELLE_AUFTRAG, response.data.quellen);
            context.commit(SET_ZAHLUNGS_KONDITION, response.data.zahlungskonditionen);
            context.commit(SET_ZAHLUNGS_PLAN, response.data.zahlungsplaene);
            context.commit(SET_KONTO, response.data.konten);
            context.commit(SET_WAEHRUNG, response.data.waehrungen);
            context.commit(SET_ZAHLUNGS_VERBINDUNG, response.data.zahlungsverbindungen);
        },
    }
};

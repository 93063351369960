import { SET_GESCHAEFTSJAHRE, SET_GESCHAEFTSJAHR } from "./mutations.type";

import { LADE_FINANZ_INFORMATIONEN } from "./actions.type";

import Api from "../../Api";
import { parseDateString } from "@/utils/bxchTools.js";

export default {
  namespaced: true,
  state: {
    geschaeftsjahre: [],
    geschaeftsjahr: null
  },
  mutations: {
    [SET_GESCHAEFTSJAHRE](state, val) {
      state.geschaeftsjahre = val;
    },
    [SET_GESCHAEFTSJAHR](state, val) {
      state.geschaeftsjahr = val;
      state.geschaeftsjahr.start = parseDateString(val.start);
      state.geschaeftsjahr.ende = parseDateString(val.ende);
    }
  },
  actions: {
    async [LADE_FINANZ_INFORMATIONEN](context) {
      let response = await Api.get("finanzen/auswahlwerte/");
      context.commit(SET_GESCHAEFTSJAHRE, response.data.geschaeftsjahre);
      context.commit(SET_GESCHAEFTSJAHR, response.data.geschaeftsjahr);
    }
  }
};

<template>
  <div :id="id" class="modal fade" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">{{ $t("global.duplicatecheck") }}</h4>
        </div>
        <div class="modal-body mx-3">
          <!-- REITER -->
          <div class="row">
            <!-- DUBLETTEN-LISTE REITER -->
            <div
              class="block block-kopf clickable"
              :class="getAktiverReiter(-1)"
              @click="setAktiverReiter(-1)"
            >
              <span class="col primary-headline-text p-0">
                {{ $t("global.duplicates") }}
              </span>
            </div>

            <!-- EINZELNE DUBLETTEN REITER -->

            <div
              class="block block-kopf clickable"
              :class="getAktiverReiter(index)"
              v-for="(tab, index) in reiter"
              :key="'tab-' + index"
            >
              <span
                @click="setAktiverReiter(index)"
                class="col primary-headline-text p-0 mr-2"
              >
                {{ tab.first_name + " " + tab.last_name }}
              </span>
              <span @click="schliesseReiter(index)">
                <font-awesome-icon icon="fa-regular fa-xmark" />
              </span>
            </div>
          </div>

          <!--REITER INHALT-->

          <!-- DUBLETTEN LISTE -->
          <div
            class="row block belegliste belegliste-small br-t-l-0"
            v-if="aktiverReiter === -1"
          >
            <div class="w-100-p overflow-auto max-h-70-vh styled-scrollbar">
              <b-table
                ref="dublettenTable"
                small
                hover
                :items="dubletten"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :busy="isBusy"
                show-empty
                sort-icon-left
                selectable
                select-mode="range"
                @row-dblclicked="oeffneNeuenReiter"
                :tbody-tr-class="rowClass"
              >
                <template v-slot:cell(icon)="row">
                  <div
                    class="w-50 px-3 text-center ml-auto"
                    @click="oeffneNeuenReiterImHintergrund(row.item)"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-up-right-from-square"
                    />
                  </div>
                </template>

                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>

                <template #empty>
                  <div class="text-center text-primary my-2">
                    {{ $t("global.nodatafound") }}
                  </div>
                </template>
              </b-table>
            </div>
          </div>

          <!-- EINZELNE DUBLETTEN -->
          <div
            class="row block belegliste belegliste-small br-t-l-0"
            v-if="dubletten.length > 0 && aktiverReiter != -1"
          >
            <dubletten-eintrag
              :eintrag="reiter[aktiverReiter]"
              :key="aktiverReiter"
              @eintragSchliessen="eintragSchliessen"
            />
          </div>
          <div
            class="mt-3 mb-1 fs-13 text-center white-space-wrap"
            v-if="aktiverReiter != -1"
          >
            {{ $t("global.duplicateshint") }}<br />
            {{ $t("global.duplicateslockedhint") }}
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="closeModal">
            {{ $t("global.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
	
	<script>
import { modal } from "@/mixins/Modal";
import CrmApi from "@/CrmApi";

import ModalCloseButton from "@/components/global/ModalCloseButton.vue";
import { BSpinner } from "bootstrap-vue";

import DublettenEintrag from "@/components/crm/DublettenEintrag.vue";

export default {
  name: "DublettenCheckModal",
  components: {
    ModalCloseButton,
    BSpinner,
    DublettenEintrag,
  },
  mixins: [modal],
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isBusy: false,
      ladeFehler: false,
      sortBy: "person_number",
      sortDesc: false,
      dubletten: [],
      reiter: [],
      aktiverReiter: -1,
    };
  },
  created() {},
  mounted() {
    this.$bus.$on("open-modal", (id) => {
      if (id === "dubletten-check-modal") {
        this.$nextTick(() => {
          this.getDubletten();
        });
      }
    });
  },
  computed: {
    fields() {
      return [
        {
          key: "person_number",
          sortable: true,
          label: this.$t("global.bpartnernum"),
          tdClass: "clickable",
        },
        {
          key: "last_name",
          sortable: true,
          label: this.$t("global.surname"),
          tdClass: "clickable",
        },
        {
          key: "first_name",
          sortable: true,
          label: this.$t("global.firstname"),
          tdClass: "clickable",
        },
        {
          key: "icon",
          sortable: false,
          label: "",
          tdClass: "clickable",
        },
      ];
    },
  },
  methods: {
    getDubletten() {
      this.isBusy = true;
      CrmApi.get("person/")
        .then((response) => {
          this.dubletten = response?.data?.content;
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.ladeFehler = true;
          console.log(e);
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    closeModal() {
      this.shown = false;
      $("#" + this.id).modal("hide");
    },
    getAktiverReiter(index) {
      if (this.aktiverReiter != index) return "bg-grayscale-6";
    },
    setAktiverReiter(index) {
      this.aktiverReiter = index;
    },
    oeffneNeuenReiter(dublette) {
      if (dublette.resolved) return;
      if (!this.reiter.includes(dublette)) {
        this.reiter.push(dublette);
      }
      this.aktiverReiter = this.reiter.indexOf(dublette);
    },
    oeffneNeuenReiterImHintergrund(dublette) {
      if (dublette.resolved) return;
      if (!this.reiter.includes(dublette)) {
        this.reiter.push(dublette);
      } else {
        this.aktiverReiter = this.reiter.indexOf(dublette);
      }
    },
    schliesseReiter(index) {
      if (this.aktiverReiter === index) {
        this.aktiverReiter = this.aktiverReiter - 1;
        if (this.aktiverReiter < -1) this.aktiverReiter = -1;
      }
      this.reiter.splice(index, 1);
    },
    eintragSchliessen() {
      this.$set(
        this.dubletten.find(
          (dublette) => dublette === this.reiter[this.aktiverReiter]
        ),
        "resolved",
        true
      );
      this.schliesseReiter(this.aktiverReiter);
    },
    rowClass(item, type) {
      if (item?.resolved) return "item table-success";
      return "item";
    },
  },
};
</script>
	
	<style>
</style>
	
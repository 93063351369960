<template>
  <div :id="id" class="modal fade" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-pdf">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">{{ $t("global.importesrfile") }}</h4>
        </div>
        <div class="modal-body">
          <div class="col-xl-12">
            <div class="form-group text-center">
              <vue-2-dropzone
                id="dropzone"
                ref="dokumenteUpload"
                class="
                  dropzone-white dropzone-small
                  vue-dropzone
                  dropzone
                  dz-clickable
                "
                :options="dropzoneOptions"
                @vdropzone-success="fileUploaded"
              ></vue-2-dropzone>
              <button class="btn btn-primary mt-2" @click="uploadFiles">
                {{ $t("global.upload") }}
              </button>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="close">
            {{ $t("global.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { modal } from "@/mixins/Modal";
import store from "@/store";
import Api from "@/Api";
import vue2Dropzone from "vue2-dropzone";
import { dropzoneOptionsESR } from "@/config/Dropzone";
import ModalCloseButton from "@/components/global/ModalCloseButton.vue";

export default {
  name: "EsrUploadModal",
  components: {
    vue2Dropzone,
    ModalCloseButton
  },
  mixins: [modal],
  store,
  props: {},
  data() {
    return {
      numPages: 1,
      actualPage: 1,
      dropzoneOptions: dropzoneOptionsESR(Api.defaults.baseURL),
    };
  },
  computed: {},
  methods: {
    close() {
      $("#" + this.id).modal("hide");
    },
    uploadFiles() {
      this.$refs.dokumenteUpload.processQueue();
    },
    fileUploaded() {
      this.$parent.schliesseEsrUploadModal();
    },
  },
};
</script>

<style>
</style>

import {
  SET_JOURNALEINTRAGTYPEN,
  SET_JOURNALEINTRAGSTATI,
  SET_JOUNRALEINTRAGTEMPLATES,
} from "./mutations.type";

import { LADE_AUSWAHLWERTE_JOURNAL } from "./actions.type";

import Api from "../../Api";

export default {
  namespaced: true,
  state: {
    journaleintragtypen: [],
    journaleintragstati: [],
    journaleintragtemplates: [],
  },
  mutations: {
    [SET_JOURNALEINTRAGTYPEN](state, val) {
      state.journaleintragtypen = val;
    },
    [SET_JOURNALEINTRAGSTATI](state, val) {
      state.journaleintragstati = val;
    },
    [SET_JOUNRALEINTRAGTEMPLATES](state, val) {
      state.journaleintragtemplates = val;
    },
  },
  actions: {
    async [LADE_AUSWAHLWERTE_JOURNAL](context) {
      let response = await Api.get("journal/auswahlwerte/");
      context.commit(
        SET_JOURNALEINTRAGTYPEN,
        response.data.journaleintragtypen
      );
      context.commit(
        SET_JOURNALEINTRAGSTATI,
        response.data.journaleintragstati
      );
      context.commit(
        SET_JOUNRALEINTRAGTEMPLATES,
        response.data.journaleintragtemplates
      );
    },
  },
};

import Api from "@/Api";

export default {
  data() {
    return {
      dateCols: [],
      selectedIds: [],
      selectedObjects: [],
      selectAll: null,
      langtextLoopCount: 0,
    };
  },

  computed: {
    angemeldeterMitarbeiter: {
      get() {
        if (this.$store) return this.$store.state.mitarbeiter;
        return null;
      },
      set(value) {
        this.$store.commit("setMitarbeiter", value);
      },
    },
  },
  created() {
    if (!this.angemeldeterMitarbeiter)
      Api.get("aktueller_mitarbeiter/").then((response) => {
        this.angemeldeterMitarbeiter = response.data;
      });
  },
  methods: {
    setHeaders(prefix, headerTexts) {
      if (this.langtextLoopCount < 15) {
        setTimeout(
          function () {
            if (this.$store.state.langtexts.loaded == true) {
              let count = 0;
              headerTexts.forEach((element) => {
                if (element != "" && this.fields.length >= count + 1) {
                  this.fields[count].label =
                    this.$store.state.langtexts.texts[this.$i18n.locale][
                      prefix
                    ][element];
                }
                count++;
              });
            } else {
              this.langtextLoopCount++;
              this.setHeaders(prefix, headerTexts);
            }
          }.bind(this),
          500
        );
      }
    },
    myCompare(itemA, itemB, key) {
      if (!this.dateCols.includes(key)) {
        // Wenn die keys nicht in dem Array dateCols vorhanden, dann Sortierung wie default ausführen
        return false;
      } else {
        // Wandle das formatierte String Datum in Nummern, um es zu vergleichen
        // die zu vergleichenden Werte holen
        let a = itemA[key];
        let b = itemB[key];
        // aufsplitten in ein Array von einzelnen Parts (dd, mm, and yyyy)
        a = a.split(".");
        b = b.split(".");
        // wandle strings in numbers um
        a =
          parseInt(a[2], 10) * 10000 +
          parseInt(a[1], 10) * 100 +
          parseInt(a[0]);
        b =
          parseInt(b[2], 10) * 10000 +
          parseInt(b[1], 10) * 100 +
          parseInt(b[0]);
        // Return das verglichene Resultat
        return a - b;
      }
    },

    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((item) => {
          item.selected = true;
        });
        this.selectedObjects = this.$refs.selectableTable.items;
        this.selectedIds = this.$refs.selectableTable.items.map(
          (item) => item.id
        );

        this.anzahlDaten = this.selectedIds.length;
      } else {
        this.$refs.selectableTable.items.forEach((a) => {
          a.selected = false;
        });
        this.selectedIds = [];
        this.selectedObjects = [];
        this.anzahlDaten = 0;
      }

      this.selectAll = Date.now();
    },

    onRowSelected() {
      if (this.selectAll) {
        let timeDiff = Date.now() - this.selectAll;
        if (timeDiff < 10000) return;
      }

      this.selectedObjects = this.$refs.selectableTable.items.filter(
        (item) => item.selected
      );

      this.selectedIds = this.selectedObjects.map((item) => item.id);
      this.anzahlDaten = this.selectedIds.length;
    },

    sortDates(datumA, datumB, key) {
      if (!key.includes("datum") && !key.includes("date")) {
        return false;
      } else {
        let a = null;
        let b = null;

        if (key.includes(".")) {
          a = this.resolve(key, datumA);
          b = this.resolve(key, datumB);
        } else {
          a = datumA[key];
          b = datumB[key];
        }
        if (a && b) {
          a = a.split(".");
          b = b.split(".");
          a =
            parseInt(a[2], 10) * 10000 +
            parseInt(a[1], 10) * 100 +
            parseInt(a[0]);
          b =
            parseInt(b[2], 10) * 10000 +
            parseInt(b[1], 10) * 100 +
            parseInt(b[0]);
          return a - b;
        } else {
          return false;
        }
      }
    },
  },
};

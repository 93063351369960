import Lead from "@/pages/CRM/Lead";
import Leadliste from "@/pages/Dashboardlisten/Leads";
import Aktivitaet from "@/pages/CRM/Aktivitaet";
import AktivitaetListe from "@/pages/Dashboardlisten/Aktivitaeten";
import Kampagne from "@/pages/CRM/Kampagne";
import KampagneListe from "@/pages/Dashboardlisten/Kampagnen";

export default [
  {
    path: "/CRM/Leadliste",
    name: "lead-liste",
    component: Leadliste,
  },
  {
    path: "/CRM/Lead/:id?/:anzeige",
    name: "lead",
    component: Lead,
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
  },
  {
    path: "/CRM/Lead/:person",
    name: "lead-neu",
    component: Lead,
    props: (route) => {
      return {
        id: null,
        person: route.params.person,
        anzeige: 0,
      };
    },
  },
  {
    path: "/CRM/AktivitaetListe",
    name: "aktivitaet-liste",
    component: AktivitaetListe,
  },
  {
    path: "/CRM/Aktivitaet/:id?/:anzeige",
    name: "aktivitaet",
    component: Aktivitaet,
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
  },
  {
    path: "/CRM/KampagneListe",
    name: "kampagne-liste",
    component: KampagneListe,
  },
  {
    path: "/CRM/Kampagne/:id?/:anzeige",
    name: "kampagne",
    component: Kampagne,
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
  },
];

import {
  SET_AUFTRAGSSTATI,
  SET_BUCHUNGSSTATI,
  SET_KONTEN,
  SET_ZAHLUNGSKONDITIONEN,
  SET_ZAHLUNGSPLAENE,
  SET_ZAHLUNGSVERBINDUNGEN,
  SET_AUFTRAGSQUELLEN,
  SET_MEHRWERTSTEUERCODES,
  SET_KOSTENSTELLEN
} from "./mutations.type";

import { LADE_KATEGORIEN } from "./actions.type";

import Api from "../../Api";

export default {
  namespaced: true,
  state: {
    auftragsstati: [],
    buchungsstati: [],
    zahlungsverbindungen: [],
    konten: [],
    zahlungsplaene: [],
    zahlungskonditionen: [],
    auftragsquellen: [],
    kostenstellen: [],
    mehrwertsteuercodes: []
  },
  mutations: {
    [SET_AUFTRAGSSTATI](state, val) {
      state.auftragsstati = val;
    },
    [SET_BUCHUNGSSTATI](state, val) {
      state.buchungsstati = val;
    },
    [SET_ZAHLUNGSVERBINDUNGEN](state, val) {
      state.zahlungsverbindungen = val;
    },
    [SET_KONTEN](state, val) {
      state.konten = val;
    },
    [SET_ZAHLUNGSPLAENE](state, val) {
      state.zahlungsplaene = val;
    },
    [SET_ZAHLUNGSKONDITIONEN](state, val) {
      state.zahlungskonditionen = val;
    },
    [SET_AUFTRAGSQUELLEN](state, val) {
      state.auftragsquellen = val;
    },
    [SET_MEHRWERTSTEUERCODES](state, val) {
      state.mehrwertsteuercodes = val;
    },
    [SET_KOSTENSTELLEN](state, val) {
      state.kostenstellen = val;
    }
  },
  actions: {
    async [LADE_KATEGORIEN](context) {
      let response = await Api.get("rechnungslegung/kategorien/");
      context.commit(SET_AUFTRAGSSTATI, response.data.auftragsstati);
      context.commit(SET_BUCHUNGSSTATI, response.data.buchungsstati);
      context.commit(
        SET_ZAHLUNGSVERBINDUNGEN,
        response.data.zahlungsverbindungen
      );
      context.commit(SET_KONTEN, response.data.konten);
      context.commit(SET_ZAHLUNGSPLAENE, response.data.zahlungsplaene);
      context.commit(
        SET_ZAHLUNGSKONDITIONEN,
        response.data.zahlungskonditionen
      );
      context.commit(SET_AUFTRAGSQUELLEN, response.data.auftragquellen);
      context.commit(
        SET_MEHRWERTSTEUERCODES,
        response.data.mehrwertsteuerCodes
      );
      context.commit(SET_KOSTENSTELLEN, response.data.kostenstellen);
    }
  }
};

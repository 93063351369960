import {
    SET_ZAHLUNGSEINGANG_STATI,
} from "./mutations.type";

import { LADE_AUSWAHLWERTE_ZAHLUNGSEINGANG } from "./actions.type";

import Api from "../../Api";

export default {
    namespaced: true,
    state: {
        zahlungseingangstati: [],
    },
    mutations: {
        [SET_ZAHLUNGSEINGANG_STATI](state, val) {
            state.zahlungseingangstati = val;
        },
    },
    actions: {
        async [LADE_AUSWAHLWERTE_ZAHLUNGSEINGANG](context) {
            let response = await Api.get("finanzen/zahlungseingang/auswahlwerte/");
            context.commit(SET_ZAHLUNGSEINGANG_STATI, response.data.zahlungseingangstati);
        },
    }
};

import {
  SET_RESSOURCEN_STATI,
  SET_RESSOURCEN_BUCHUNGSTYPEN,
  SET_RESSOURCEN_RAEUME,
  SET_RESSOURCEN_KONFLIKT_STATI,
  SET_RESSOURCEN_SPERRTERMINE,
  SET_RESSOURCEN_PERIODIZITAET,
  SET_RESSOURCEN_STANDORTE,
  SET_RESSOURCEN_EIGENTUMSVERHAELTNISSE,
} from "./mutations.type";

import { LADE_AUSWAHLWERTE_RESSOURCEN } from "./actions.type";

import Api from "../../Api";

export default {
  namespaced: true,
  state: {
    ressourcenstati: [],
    ressourcenbuchungstypen: [],
    raeume: [],
    konfliktstati: [],
    sperrtermine: [],
    periodizitaeten: [],
    standorte: [],
    eigentumsverhaeltnisse: [],
  },
  mutations: {
    [SET_RESSOURCEN_STATI](state, val) {
      state.ressourcenstati = val;
    },
    [SET_RESSOURCEN_BUCHUNGSTYPEN](state, val) {
      state.ressourcenbuchungstypen = val;
    },
    [SET_RESSOURCEN_RAEUME](state, val) {
      state.raeume = val;
    },
    [SET_RESSOURCEN_KONFLIKT_STATI](state, val) {
      state.konfliktstati = val;
    },
    [SET_RESSOURCEN_SPERRTERMINE](state, val) {
      state.sperrtermine = val;
    },
    [SET_RESSOURCEN_PERIODIZITAET](state, val) {
      state.periodizitaeten = val;
    },
    [SET_RESSOURCEN_STANDORTE](state, val) {
      state.standorte = val;
    },
    [SET_RESSOURCEN_EIGENTUMSVERHAELTNISSE](state, val) {
      state.eigentumsverhaeltnisse = val;
    }
  },
  actions: {
    async [LADE_AUSWAHLWERTE_RESSOURCEN](context) {
      let response = await Api.get("ressourcen/auswahlwerte/");
      context.commit(SET_RESSOURCEN_STATI, response.data.ressourcenstati);
      context.commit(
        SET_RESSOURCEN_BUCHUNGSTYPEN,
        response.data.ressourcenbuchungstypen
      );
      context.commit(SET_RESSOURCEN_RAEUME, response.data.raeume);
      context.commit(
        SET_RESSOURCEN_KONFLIKT_STATI,
        response.data.konfliktstati
      );
      context.commit(SET_RESSOURCEN_SPERRTERMINE, response.data.sperrtermine);
      context.commit(
        SET_RESSOURCEN_PERIODIZITAET,
        response.data.periodizitaeten
      );
      context.commit(
        SET_RESSOURCEN_STANDORTE,
        response.data.standorte
      );
      context.commit(
        SET_RESSOURCEN_EIGENTUMSVERHAELTNISSE,
        response.data.eigentumsverhaeltnisse
      );
    }
  }
};

<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">
            {{ $t("global.administerlecturerdeployment") }}
          </h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xl-12 p-0">
              <div class="tab-container p-3">
                <div>
                  <div class="row">
                    <div class="col-xl-12">
                      <div class="block block-kopf col-xl-6">
                        <div>
                          {{ bildungsbezeichnung }}
                        </div>
                      </div>
                      <div class="row col-xl-12">
                        <div class="col-xl-12 block belegliste br-t-l-0">
                          <div class="row p-3">
                            <span class="col-3"
                              >{{ $t("global.lecturers") }}:
                              <v-select
                                v-model="setAll.verantwortlich"
                                class="mt-2 mb-3"
                                label="personName"
                                :options="dozentliste"
                                placeholder="Dozent"
                                @input="setAllDozenten()"
                              >
                                <span slot="no-options">{{
                                  $t("global.taptosearch")
                                }}</span>
                              </v-select>
                            </span>
                            <span class="col-3"
                              >{{ $t("global.planning") }}:
                              <v-select
                                v-model="setAll.planungsstatus"
                                class="mt-2 mb-3"
                                label="bezeichnung"
                                :options="planungsstati"
                                :placeholder="$t('global.status')"
                                @input="setAllPlanungsstatus()"
                              >
                                <span slot="no-options">{{
                                  $t("global.taptosearch")
                                }}</span>
                              </v-select>
                            </span>
                            <span class="col-3"
                              >{{ $t("global.execution") }}:
                              <v-select
                                v-model="setAll.durchfuehrungsstatus"
                                class="mt-2 mb-3"
                                label="bezeichnung"
                                :options="durchfuehrungsstati"
                                :placeholder="$t('global.status')"
                                @input="setAllDurchfuehrungsstatus()"
                              >
                                <span slot="no-options">{{
                                  $t("global.taptosearch")
                                }}</span>
                              </v-select>
                            </span>
                            <span>
                              <div>
                                <button
                                  type="button"
                                  class="btn btn-danger mt-4 ml-5"
                                  @click="loeschen"
                                >
                                  Auswahl Löschen
                                </button>
                              </div>
                            </span>
                          </div>

                          <div>
                            <b-table
                              ref="selectableTable"
                              tbody-tr-class="item"
                              :items="dozenteinsatzliste"
                              :fields="fields"
                              :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDesc"
                              :busy="isBusy"
                              show-empty
                              sort-icon-left
                              selectable
                              fixed
                              responsive
                              select-mode="range"
                            >
                              <template v-slot:head(selected)="row">
                                <b-form-checkbox
                                  v-model="row.selected"
                                  @input="selectAllRows(row)"
                                ></b-form-checkbox>
                              </template>

                              <template v-slot:cell(selected)="row">
                                <b-form-checkbox
                                  v-model="row.item.selected"
                                  @input="onRowSelected(row)"
                                ></b-form-checkbox>
                              </template>

                              <template v-slot:cell(verantwortlich)="row">
                                <div class="form-group">
                                  <v-select
                                    v-model="row.item.personName"
                                    class="mt-2 mb-3"
                                    label="personName"
                                    :options="dozentliste"
                                    placeholder="Dozent"
                                    @input="dozentChanged(row)"
                                  >
                                    <span slot="no-options">{{
                                      $t("global.taptosearch")
                                    }}</span>
                                  </v-select>
                                </div>
                              </template>

                              <template v-slot:cell(planungsstatus)="row">
                                <div class="form-group">
                                  <v-select
                                    v-model="row.item.planungsstatus"
                                    class="mt-2 mb-3"
                                    label="bezeichnung"
                                    :options="planungsstati"
                                    :placeholder="$t('global.status')"
                                    @input="dozentChanged(row)"
                                  >
                                    <span slot="no-options">{{
                                      $t("global.taptosearch")
                                    }}</span>
                                  </v-select>
                                </div>
                              </template>

                              <template v-slot:cell(durchfuehrungsstatus)="row">
                                <div class="form-group">
                                  <v-select
                                    v-model="row.item.durchfuehrungsstatus"
                                    class="mt-2 mb-3"
                                    label="bezeichnung"
                                    :options="durchfuehrungsstati"
                                    :placeholder="$t('global.status')"
                                    @input="dozentChanged(row)"
                                  >
                                    <span slot="no-options">{{
                                      $t("global.taptosearch")
                                    }}</span>
                                  </v-select>
                                </div>
                              </template>

                              <template #table-busy>
                                <div class="text-center text-primary my-2">
                                  <b-spinner class="align-middle"></b-spinner>
                                </div>
                              </template>

                              <template #empty>
                                <div
                                  v-if="ladeFehler"
                                  class="text-center text-danger my-2"
                                >
                                  <strong>{{
                                    $t("global.errorwhileloading")
                                  }}</strong>
                                </div>
                                <div
                                  v-if="!ladeFehler"
                                  class="text-center text-primary my-2"
                                >
                                  <strong>{{
                                    $t("global.nodataavailable")
                                  }}</strong>
                                </div>
                              </template>
                            </b-table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="closeModal">
            {{ $t("global.cancel") }}
          </button>
          <button type="button" class="btn btn-primary" @click="speichern">
            {{ $t("global.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";

import { LADE_AUSWAHLWERTE_DOZENT } from "@/store/dozent/actions.type";

import ModalCloseButton from "@/components/global/ModalCloseButton";

export default {
  name: "DozenteneinsatzAdministrierenModal",
  components: { ModalCloseButton, BSpinner },
  props: {
    id: {
      type: String,
      required: true,
    },
    veranstaltung: {
      type: String,
      required: true,
    },
    bildungsbezeichnung: {
      type: String,
      required: false,
    },
    method: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      shown: false,
      sortBy: "",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      terminliste: [],
      dozentenbuchungen: [],
      dozenteinsatzliste: [
        {
          dozentenbuchung: { person: {} },
          termin: {},
          planungsstatus: {},
          durchfuehrungsstatus: {},
          verantwortlich: {},
          personName: {},
        },
      ],
      dozentenliste: [],
      setAll: {
        verantwortlich: {},
        planungsstatus: {},
        durchfuehrungsstatus: {},
      },
      selected: [],
      selectedIds: [],
      componentKey: 0,
      // dozenteinsatzliste: [], // Hier werden die Dozenten zu einem Termin zugeordnet und in einer Liste gespeichert
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        { key: "termin.von", label: this.$t("global.from"), sortable: true },
        {
          key: "termin.von_uhrzeit",
          label: this.$t("global.TIme"),
          sortable: true,
        },
        { key: "termin.bis", label: this.$t("global.till"), sortable: true },
        {
          key: "termin.bis_uhrzeit",
          label: this.$t("global.TIme"),
          sortable: true,
        },
        {
          key: "personName.personName",
          label: this.$t("global.lecturer"),
          sortable: true,
        },
        {
          key: "planungsstatus",
          label: this.$t("global.planning"),
          sortable: true,
        },
        {
          key: "durchfuehrungsstatus",
          label: this.$t("global.execution"),
          sortable: true,
        },
      ];
    },
    planungsstati: {
      get() {
        return this.$store.state.dozent.planungsstati;
      },
    },
    durchfuehrungsstati: {
      get() {
        return this.$store.state.dozent.durchfuehrungsstati;
      },
    },

    dozentliste: function () {
      return this.dozentenliste;
    },
  },
  watch: {},
  mounted() {
    this.$bus.$on("openDozenteneinsatzAdministrierenModal", () => {
      if (!this.shown) {
        this.openModal();
      }
    });
    this.$bus.$on("getDozentenbuchungen", (data) => {
      this.dozentenbuchungen = data;
    });
  },
  methods: {
    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((item) => {
          item.selected = true;
          if (!this.selectedIds.includes(item.id)) {
            this.selectedIds.push(item.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((item) => {
          item.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },

    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
      this.selectedIds = Array.from(new Set(this.selectedIds));
      //this.selected = Array.from(new Set(this.selected));
    },

    openModal() {
      this.shown = true;

      this.isBusy = true;

      Api.get("termin/", {
        params: { veranstaltung: this.veranstaltung },
      })
        .then((response) => {
          this.terminliste = [];
          response.data.forEach((termin) => {
            this.terminliste.push({
              id: termin.id,
              von: termin.von,
              von_uhrzeit: termin.von_uhrzeit,
              bis: termin.bis,
              bis_uhrzeit: termin.bis_uhrzeit,
              verantwortlich: termin.verantwortlich,
              planungsstatus: this.$CONST("DOZENTENPLANUNGSTATI").OPEN,
              durchfuehrungsstatus: this.$CONST("DOZENTENDURCHFUEHRUNGSTATI")
                .OPEN,
            });

            /*  this.dozenteinsatzliste.push({
            terminId: termin.id,
            dozentId: termin.verantwortlich?.id,
            dozentIdAlt: termin.verantwortlich?.id,
            planungsstatus: this.$CONST("DOZENTENPLANUNGSTATI").OPEN,
            durchfuehrungsstatus: this.$CONST("DOZENTENDURCHFUEHRUNGSTATI")
              .OPEN,
          }); */
          });
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          //this.isBusy = false;
        });

      Api.get("dozenteneinsatz/", {
        params: { veranstaltung: this.veranstaltung },
      })
        .then((response) => {
          //response.data.shift();
          if (response.data.length > 0) {
            response.data.forEach((de) => {
              if (de.termin) {
                let pos = this.terminliste.findIndex(
                  (termin) => termin.id == de.termin.id
                );
                if (pos != -1) {
                  if (this.terminliste[pos].planungsstatus && de.planungsstatus)
                    this.terminliste[pos].planungsstatus = de.planungsstatus.id;

                  if (
                    this.terminliste[pos].durchfuehrungsstatus &&
                    de.durchfuehrungsstatus
                  )
                    this.terminliste[pos].durchfuehrungsstatus =
                      de.durchfuehrungsstatus.id;
                }
              }
            });
            this.dozenteinsatzliste = response.data;
            this.dozenteinsatzliste.forEach((de) => {
              let index = this.dozenteinsatzliste.indexOf(de);
              de.personName = response.data[index].dozentenbuchung.person;
            });
          }
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });

      if (this.planungsstati.length == 0)
        this.$store.dispatch(`dozent/${LADE_AUSWAHLWERTE_DOZENT}`);

      if (this.dozentenliste.length == 0) {
        this.isBusy = true;

        Api.get("dozentenbuchung/", {
          params: { veranstaltung: this.veranstaltung },
        })
          .then((response) => {
            //response.data.shift();
            let dozentenliste = [];
            this.dozentenbuchungen = response.data;
            response.data.forEach((dl) => {
              dozentenliste.push(dl.person);
            });
            this.dozentenliste = dozentenliste;
            this.ladeFehler = false;
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
            this.ladeFehler = true;
          })
          .finally(() => {
            this.isBusy = false;
          });
      }
    },

    closeModal() {
      this.clear();
      this.shown = false;
      $("#" + this.id).modal("hide");
      this.$emit("dozentChanged");
    },

    clear() {
      this.dozenteinsatzliste = [];
    },

    speichern() {
      var json = {
        dozenteinsatzliste: this.dozenteinsatzliste,
        veranstaltung: this.veranstaltung,
      };

      Api.put("/dozenteneinsatz/", json)
        .then(() => {
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: "Dozenteneinsatz erfolgreich gespeichert.",
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.closeModal();
        });
    },

    loeschen() {
      Api.delete("/dozenteneinsatz/", {
        params: { ids: [this.selectedIds].join(",") },
      })
        .then(() => {
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: "Datensätze gelöscht.",
          });
          this.closeModal();
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {});
    },

    dozentChanged(row) {
      let pos = this.dozenteinsatzliste.findIndex(
        (de) => de.id === row.item.id
      );
      let dozentenbuchung = null;
      if (row.item.personName) {
        dozentenbuchung = this.dozentenbuchungen.filter((e) => {
          return e.person.id === row.item.personName.id;
        });
      }
      if (pos != -1) {
        if (dozentenbuchung)
          this.dozenteinsatzliste[pos].dozentenbuchung = dozentenbuchung[0];
        else this.dozenteinsatzliste[pos].dozentenbuchung = null;
      }
    },

    setAllDozenten() {
      if (this.setAll.verantwortlich) {
        let dozentenbuchung = this.dozentenbuchungen.filter((e) => {
          return e.person.id === this.setAll.verantwortlich.id;
        });

        this.dozenteinsatzliste.forEach((dozenteneinsatz) => {
          if (this.selectedIds.some((e) => e === dozenteneinsatz.id)) {
            dozenteneinsatz.dozentenbuchung = dozentenbuchung[0];

            dozenteneinsatz.personName = this.setAll.verantwortlich;
          }
        });
        this.$refs.selectableTable.$forceUpdate();
      }
    },

    setAllPlanungsstatus() {
      if (this.setAll.planungsstatus) {
        this.dozenteinsatzliste.forEach((liste) => {
          if (this.selectedIds.some((e) => e === liste.id)) {
            liste.planungsstatus = this.setAll.planungsstatus;
          }
        });
        this.$refs.selectableTable.$forceUpdate();
      }
    },

    setAllDurchfuehrungsstatus() {
      if (this.setAll.durchfuehrungsstatus) {
        this.dozenteinsatzliste.forEach((liste) => {
          if (this.selectedIds.some((e) => e === liste.id)) {
            liste.durchfuehrungsstatus = this.setAll.durchfuehrungsstatus;
          }
        });
        this.$refs.selectableTable.$forceUpdate();
      }
    },
  },
};
</script>

<style></style>

export var adresseToString = function (adresse) {
  let a = adresse.firma
  /*if (adresse.zuHaendenVon)
    a += `\n${adresse.zuHaendenVon}`*/
  if (adresse.strasse_nr)
    a += `\n${adresse.strasse_nr}`
  if (adresse.adresszusatz1)
    a += `\n${adresse.adresszusatz1}`
  /*if (adresse.adresszusatz2)
    a += `\n${adresse.adresszusatz2}`*/

  a += "\n"

  if (adresse.land)
    a += `${adresse.land}-`

  a += `${adresse.plz} ${adresse.ort}`

  return a;
}

export var addresseToSingleLineString = function (adresse) {
  if (!adresse) return "";
  
  if (!adresse.strasse_nr || !adresse.plz || !adresse.ort)
    return InvalideAdresse;

  let a = ''
  if (adresse.firma){
    a += `${adresse.firma.trim()}`
  }
  /*if (adresse.zuHaendenVon)
    a += ` ${adresse.zuHaendenVon.trim()}`*/
  if (a.trim().length > 0){
    a += ","
  }
  if (adresse.strasse_nr){
    a += ` ${adresse.strasse_nr.trim()}`
  }
  if (adresse.adresszusatz1){
    a += ` ${adresse.adresszusatz1.trim()}`
  }
  /*if (adresse.adresszusatz2)
    a += ` ${adresse.adresszusatz2.trim()}`*/

  a += ", "
  /*if (adresse.land) {
    if (typeof (adresse.land) === "object")
      a += `${adresse.land.alpha2Code}-`
    else
      a += `${adresse.land}-`
  }*/
  if (adresse.plz)
    a += `${adresse.plz.trim()} `
  if (adresse.ort)
    a += `${adresse.ort.trim()}`

  return a.trim();
}

export var addresseToSingleLineStringWithoutFirma = function (adresse) {
  if (!adresse) return "";

  if (!adresse.strasse_nr || !adresse.plz || !adresse.ort)
    return InvalideAdresse;


  let a = ''
  if (adresse.zuHaendenVon)
    a += ` ${adresse.zuHaendenVon.trim()}`

  if (a.trim().length > 0)
    a += ","
  if (adresse.strasse_nr)
    a += ` ${adresse.strasse_nr.trim()}`
  if (adresse.adresszusatz1)
    a += ` ${adresse.adresszusatz1.trim()}`
  if (adresse.adresszusatz2)
    a += ` ${adresse.adresszusatz2.trim()}`

  a += ", "

  if (adresse.land) {
    if (typeof (adresse.land) === "object")
      a += `${adresse.land.alpha2Code}-`
    else
      a += `${adresse.land}-`
  }

  if (adresse.plz)
    a += `${adresse.plz.trim()} `

  if (adresse.ort)
    a += `${adresse.ort.trim()}`

  return a.trim();
}

export default {

  /**
   * gibt die Adresse im Format <stasse_nr>, <plz> <ort> zurück
   */
  toSmallString: (adresse) => {
    if(adresse && adresse.id)
      return `${adresse.strasse_nr}, ${adresse.plz} ${adresse.ort}`
    return '-'
  }
}

const InvalideAdresse = "!! Invalide Adresse !!";

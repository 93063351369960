//export const SET_ABSENDER = "setAbsender";
export const SET_VERARBEITUNGS_STATI = "setVerarbeitungsStati";
export const SET_VERSAND_LISTEN = "setVersandListen";
export const SET_ARCHIVIERUNGS_STATI = "setArchivierungsStati";
export const SET_KORRESPONDENZ_KATEGORIEN = "setKorrespondenzKategorien";
export const SET_UNTERSCHRIFTEN = "setUnterschriften";
export const SET_KORRESPONDENZ_VORLAGEN = "setKorrespondenzVorlagen";
export const SET_MAILVORLAGE_TYPEN = "setMailVorlageTypen";
export const SET_MAIL_VORLAGEN = "setMailVorlagen";
export const SET_NOTIZ_KONTAKTARTEN = "setNotizKontaktArten";
export const SET_NOTIZ_KONTAKTKATEGORIEN = "setNotizKontaktKategorien";

<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar"
            :docsName="docsName"
    />

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel"
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-shortkey.once="['ctrl', 's']"
                    v-if="editable"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.savenote") }}
                  </button>
                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="berechtigungen.m_notizen.delete"
                  >
                    <font-awesome-icon
                      class="mr-1"
                      icon="fa-duotone fa-trash"
                    />
                    <span>{{ $t("global.deletenote") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <notiz-personen
          :notiz="notiz"
          :editable="editable"
          :navbarTitel="navbarTitel"
        />
        <notiz-inhalt
          :notiz="notiz"
          :editable="editable"
          :navbarTitel="navbarTitel"
        />
      </div>
    </ValidationObserver>

    <loeschen-modal id="notiz-loeschen-modal" @confirmed="notizLoeschen" />

    <change-watcher-alert />
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";
import Api from "@/Api";
import store from "@/store";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import page from "@/mixins/Page";

import LoeschenModal from "@/components/Modals/LoeschenModal";

import NotizPersonen from "@/components/Kommunikation/NotizPersonen.vue";
import NotizInhalt from "@/components/Kommunikation/NotizInhalt.vue";

import ChangeWatcher from "@/mixins/ChangeWatcher";
import ChangeWatcherAlert from "@/components/global/ChangeWatcherAlert.vue";

export default {
  name: "Notiz",
  components: {
    HeadMenu,
    Navbar,
    NotizPersonen,
    LoeschenModal,
    ChangeWatcherAlert,
    NotizInhalt,
  },

  mixins: [page, ChangeWatcher],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      notiz: {
        person: { personName: this.$t("global.notlinked") },
        kontakt_durch: { personName: this.$t("global.notlinked") },
        kontaktpersonen: [{ personName: this.$t("global.notlinked") }],
        firmen: [{ firmaName: this.$t("global.notlinked") }],
        kontaktkategorie: { bezeichnung: this.$t("global.notlinked") },
        kontaktart: { bezeichnung: this.$t("global.notlinked") },
        bezeichnung: null,
        datum: new Intl.DateTimeFormat("de-CH").format(new Date()),
      },

      editable: false,
      loading: false,
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.id) {
        return this.$t("global.note");
      } else {
        return this.$t("global.newnote");
      }
    },
  },

  created() {
    if (this.id) {
      Api.get("kontakt/", { params: { id: this.id } }).then((response) => {
        this.initializeNotiz(response.data);
      });
    } else {
      this.initializeNotiz(null);
    }
  },

  methods: {
    abbrechen() {
      this.$router.push({ name: "notizen-liste" });
    },

    initializeNotiz(notiz) {
      if (notiz) {
        this.notiz = notiz;
      } else {
        //console.error("mail ist null");
      }

      this.editable = this.berechtigungen.m_notizen.update;

      if (notiz) this.initChangeWatcher(this.notiz);
    },
    notizLoeschen() {
      Api.delete("kontakt/", {
        params: { ids: this.id },
      }).then(() => {
        this.$router.push({ name: "notizen-liste" });
      });
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "notiz-loeschen-modal");
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;
      var json = Object.assign({}, this.notiz);

      if (!this.id) {
        Api.post("kontakt/", json)
          .then((response) => {
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.notesuccessfullysaved"),
            });

            this.$router.replace({
              name: "notiz",
              params: { id: response.data.id, anzeige: 0 },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("kontakt/", json, { params: { id: this.notiz.id } })
          .then((response) => {
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.notesuccessfullysaved"),
            });
            this.initializeNotiz(response.data);
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <ValidationObserver v-slot="{ invalid }" :disabled="true">
      <div id="massen-anmeldung-modal" class="modal fade">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <modal-close-button @confirmed="closeModal" />
              <h4 class="modal-title">
                {{ $t("global.massregistration") }}
              </h4>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-xl-12">
                  <single-select-veranstaltung
                    id="anmeldung-veranstaltung"
                    :veranstaltung="veranstaltung"
                    :label="$t('global.event')"
                    editable
                    required
                    @confirmed="setVeranstaltung"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" @click="closeModal">
                {{ $t("global.cancel") }}
              </button>
              <button
                type="button"
                :disabled="invalid"
                class="btn btn-primary"
                @click="uebernehmen"
              >
                {{ $t("global.apply") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>
    <message-box
      id="anmeldung-multi-ok-modal"
      :headerText="$t('global.massregistration')"
      :text="messageText"
      :ok="true"
      :cancel="false"
      :repeat="false"
      :okText="$t('global.ok')"
    />
  </div>
</template>

<script>
import Api from "@/Api";

import ModalCloseButton from "@/components/global/ModalCloseButton";
import SingleSelectVeranstaltung from "@/components/global/SingleSelectVeranstaltung.vue";
import MessageBox from "@/components/Modals/Messagebox";

export default {
  name: "MassenAnmeldungModal",
  components: { ModalCloseButton, SingleSelectVeranstaltung, MessageBox },
  props: {},
  data() {
    return {
      personen: [],
      veranstaltung: {},
      shown: false,
      messageText: "",
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.$bus.$on("open-massenanmeldung-modal", (personen) => {
      if (!this.shown) {
        this.openModal(personen);
      }
    });
  },
  methods: {
    openModal(personen) {
      this.shown = true;
      this.personen = personen;

      $("#massen-anmeldung-modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    closeModal() {
      this.shown = false;
      this.$emit("abbrechen");
      $("#massen-anmeldung-modal").modal("hide");
    },

    uebernehmen() {
      var json = [];
      let now = new Date();

      this.personen.forEach((person) => {
        json.push({
          veranstaltung: this.veranstaltung.id,
          anmeldestatus: this.$CONST("ANMELDESTATI").REGISTERED,
          anmeldetyp: this.$CONST("ANMELDETYPEN").REGULAR,
          zahlungsplan: this.$CONST("ZAHLUNGSPLAENE").ONETIME,
          anmeldungsdatum:
            now.getDate() +
            "." +
            (now.getMonth() + 1) +
            "." +
            now.getFullYear(),
          anmeldungsdatum_uhrzeit: now.getHours() + ":" + now.getMinutes(),
          person: person,
        });
      });

      this.closeModal();

      Api.post("anmeldung/multi/", json).then((response) => {
        let messageText =
          this.$store.state.langtexts.texts[this.$i18n.locale].global
            .massregistrationdone;

        this.messageText = messageText
          .replace("<success>", response.data.success)
          .replace("<error>", response.data.error);
        this.$bus.$emit("open-modal", "anmeldung-multi-ok-modal");
      });
    },

    setVeranstaltung(veranstaltung) {
      this.veranstaltung = veranstaltung;
    },
  },
};
</script>

<style>
</style>

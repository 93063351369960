import TorListe from "@/pages/Dashboardlisten/TorListe";
import TorAbschlussSimulieren from "@/components/Tor/TorAbschlussSimulieren"

export default [
	{
		path: "/TorListe",
		name: "tor-liste",
		component: TorListe,
	},
	{
		path: "/TorAbschlussSimulieren",
		name: "tor-abschluss-simulieren",
		component: TorAbschlussSimulieren,
	},

];

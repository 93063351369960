<template>
  <div>
    <container-headline
      headline="Anforderungen für"
      :col="4"
    ></container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-6">
              {{ raster.bezeichnung }}
            </div>
          </div>
         <!-- <div class="row mt-3">
            <div class="col-xl-12">
              <div :key="fachSelect">
                <button
                  v-for="fach in faecher"
                  v-bind:key="fach?.id"
                  class="btn btn-primary mr-2"
                  @click="selectFach(fach)"
                >
                  {{ fach.bezeichnung }}
                </button>
              </div>
            </div>
          </div>-->
        </div>
      </div>
    </div>

    <container-headline headline="Kompetenzen" :col="4"></container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-12">
              <div
                v-for="(bereich, bereichKey) in selectedFachMatrix"
                v-bind:key="bereich.id"
                class="row"
              >
                <div class="col-xl-12">
                  <div class="row">
                    <div
                      class="col-xl-12"
                      style="font-weight: 600; font-size: 14pt"
                    >
                      {{ bereiche[bereichKey] }}
                    </div>
                  </div>
                  <div class="row mt-2" style="font-size: 12pt">
                    <div
                      v-for="(sektion, sektionKey) in bereich"
                      v-bind:key="sektion.id"
                      class="col-xl-12"
                    >
                      <div class="row col-xl-12">
                        <div
                          class="col-xl-12"
                          style="
                            background-color: #B5CFDC;
                            border-top: 2px solid white;
                            border-left: 2px solid white;
                            font-weight: 600;
                          "
                        >
                          {{ sektionen[sektionKey] }}
                        </div>
                       <div class="col-xl-2"  style="
                            font-weight: 600;
                            background-color: #bfbfbf;
                            border-top: 2px solid white;
                            border-left: 2px solid white;
                          ">Subsektion</div>
                      <div
                          v-for="headerstufe in stufen"
                          v-bind:key="headerstufe.id"
                          class="col-xl-2 text-center"
                          style="
                            font-weight: 600;
                            background-color: #bfbfbf;
                            border-top: 2px solid white;
                            border-left: 2px solid white;
                          "
                        >
                          {{ headerstufe }}
                        </div>
                      
                        <div
                          v-for="(subsektion, subsektionKey) in sektion"
                          v-bind:key="subsektion.id"
                          class="col-xl-12"
                        >
                        
                         
                           <div class="row">
                            <div
                              class="col-xl-2"
                              style="
                                background-color: #eaeaea;
                                border-top: 2px solid white;
                                border-left: 2px solid white;
                              "
                            >
                              {{ subsektionen[subsektionKey] }}
                            </div>
                            
                            <div
                              v-for="stufe in subsektion"
                              v-bind:key="stufe.id"
                              class="col-xl-2"
                              style="background-color: #e2f0d9"
                            >
                            
                              <!--{{ stufen[stufekey] }}-->
                              <div class="row">
                                <div
                                  v-for="definition in stufe"
                                  v-bind:key="definition.id"
                                  class="col-xl-12"
                                  style="
                                    border-top: 2px solid white;
                                    border-left: 2px solid white;
                                    padding: 10px;
                                  "
                                >
                                  {{ definition.definition.beschreibung }}
                                </div>
                              </div>
                            </div>
                          </div>
                            
                          
                        </div>
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";

import ContainerHeadline from "@/components/ContainerHeadline";

export default {
  name: "Kompetenzraster",
  components: {
    ContainerHeadline,
  },
  mixins: [],
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      raster: {},
      matrix: [],
      selectedFach: [],
      reverseMatrix: {},
      faecher: {},
      bereiche: {},
      sektionen: {},
      subsektionen: {},
      definitionen: {},
      stufen: {},
      editable: false,
      fachSelect: 1,
    };
  },
  computed: {
    selectedFachMatrix: function () {
      return this.reverseMatrix[this.selectedFach];
    },
  },
  watch: {
    id(val) {
      if (val) {
        this.loadRaster();
      }
    },
  },
  created() {
    if (this.id) {
      this.loadRaster();
    }
  },
  mounted: function () {},
  methods: {
    loadRaster() {
      this.loading = true;

      Api.get("bildung/kompetenzraster", {
        params: { id: this.id },
      }).then((response) => {
        this.initializeRaster(response.data);
      });
    },

    initializeRaster(raster) {
      if (raster) {
        this.raster = raster;
        Api.get("bildung/kompetenzraster/kompetenzdefinition", {
          params: { rasterid: this.id },
        }).then((response) => {
          this.initializeMatrix(response.data);
        });
      } else {
        console.error("kompetenzraster ist null");
      }

      this.loading = false;
    },

    initializeMatrix(matrix) {
      if (matrix) {
        this.matrix = matrix;

        this.selectedFach = null;

        this.matrix.forEach((raster) => {
          let subsektion = raster.definition.subsektion;
          let sektion = subsektion.sektion;
          let bereich = sektion.bereich;
          let fach = raster.definition.fach;
          let stufe = raster.definition.stufe;
          let definition = raster.definition;
          console.log(definition)

          if (!this.faecher[fach?.id]) this.faecher[fach?.id] = fach;
          if (!this.selectedFach) this.selectedFach = fach?.id;

          if (!this.reverseMatrix[fach?.id]) this.reverseMatrix[fach?.id] = {};

          if (!this.reverseMatrix[fach?.id][bereich?.id]) {
            this.reverseMatrix[fach?.id][bereich?.id] = {};
            this.bereiche[bereich?.id] = bereich?.bezeichnung;
          }

          if (!this.reverseMatrix[fach?.id][bereich?.id][sektion?.id]) {
            this.reverseMatrix[fach?.id][bereich?.id][sektion?.id] = {};
            this.sektionen[sektion?.id] = sektion?.bezeichnung;
          }

          if (
            !this.reverseMatrix[fach?.id][bereich?.id][sektion?.id][subsektion?.id]
          ) {
            this.reverseMatrix[fach?.id][bereich?.id][sektion?.id][subsektion?.id] =
              {};
            this.subsektionen[subsektion?.id] = subsektion?.bezeichnung;
          }

          if (
            !this.reverseMatrix[fach?.id][bereich?.id][sektion?.id][subsektion?.id][
              stufe?.id
            ]
          ) {
            this.reverseMatrix[fach?.id][bereich?.id][sektion?.id][subsektion?.id][
              stufe?.id
            ] = {};
            this.stufen[stufe?.id] = stufe?.bezeichnung;
          }

           if (
            !this.reverseMatrix[fach?.id][bereich?.id][sektion?.id][subsektion?.id][
              stufe?.id][definition?.id]
          ) {
            this.reverseMatrix[fach?.id][bereich?.id][sektion?.id][subsektion?.id][stufe?.id][definition?.id] = {};
            this.definitionen[definition?.id] = definition?.beschreibung;
          }

          this.reverseMatrix[fach?.id][bereich?.id][sektion?.id][subsektion?.id][stufe?.id][definition?.id] = raster; //[raster.id]
        });

        this.fachSelect++;
      } else {
        console.error("kompetenzmastrix ist null");
      }
    },

    selectFach(fach) {
      this.selectedFach = fach?.id;
    },
  },
};
</script>

<style>
</style>

<template>
  <div class="form-group">
    <span
      v-if="editable"
      class="editIcon"
      v-on:click="oeffneDozenteneinsatzSelectModal"
      ><font-awesome-icon icon="fa-duotone fa-pencil-alt"
    /></span>
    <span
      v-if="allowOpen && dozenteneinsatzId != null"
      class="openIcon"
      v-on:click="openDozenteneinsatz"
      ><font-awesome-icon icon="fa-duotone fa-arrow-circle-right"
    /></span>
    <ValidationProvider
      :rules="required ? 'required' : ''"
      immediate
      v-slot="{ passed }"
      ><input v-model="dozenteneinsatzId" type="hidden" />
      <b-form-input
        v-model="dozenteneinsatz.id"
        v-bind:class="{ 'border-danger': !passed }"
        readonly
        placeholder=" "
      />
      <label v-bind:class="{ 'text-danger': !passed }">{{ label }}</label>
    </ValidationProvider>

    <dozenteneinsatz-select-modal
      :id="modalId"
      :filterRollen="rollen"
      @confirmed="onDEResult"
    ></dozenteneinsatz-select-modal>
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";

import DozenteneinsatzSelectModal from "@/components/Modals/DozenteneinsatzSelectModal.vue";

export default {
  name: "SingleSelectDozenteneinsatz",
  components: { DozenteneinsatzSelectModal },
  props: {
    id: {
      type: String,
      default: uuid(),
    },
    dozenteneinsatz: {
      type: Object,
      required: false,
      default: function () {
        return { personName: this.$t("global.notlinked") };
      },
    },
    allowOpen: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      required: true,
    },
    rollen: {
      type: Array,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    modalId() {
      return "dozenteneinsatz-modal-" + this.id;
    },
    dozenteneinsatzId() {
      return this.dozenteneinsatz == null ? null : this.dozenteneinsatz.id;
    },
    personName() {
      return this.dozenteneinsatz == null
        ? this.$t("global.notlinked")
        : this.dozenteneinsatz.personName;
    },
  },
  methods: {
    oeffneDozenteneinsatzSelectModal() {
      this.$bus.$emit("openDozenteneinsatzModal", this.modalId);
    },

    onDEResult(selectedDozenteneinsatz) {
      if (selectedDozenteneinsatz) {
        this.$emit("confirmed", selectedDozenteneinsatz[0]);
      }
    },

    openDozenteneinsatz() {
      if (this.dozenteneinsatz.id) {
        this.$router.push({
          name: "dozenteneinsatz",
          params: { id: this.dozenteneinsatz.id, anzeige: 0 },
        });
      }
    },
  },
};
</script>

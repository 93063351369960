<template>
  <div>
    <container-headline
      :headline="$t('global.location')"
      :col="6"
    ></container-headline>

    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div
          class="eingabe-panel-kopf p-3 standortPanel"
          ref="standortPanel"
          :style="
            standorte.length > 4 ? standortPanelStyle : 'height: 246.5px;'
          "
        >
          <div class="row">
            <div class="col-xl-12">
              <button
                class="btn btn-primary btn-sm"
                @click="standortHinzufuegen()"
                :disabled="!editable"
              >
                <font-awesome-icon icon="fa-regular fa-plus" class="mr-1" />
                {{ $t("global.addLocation") }}
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <b-table
                  ref="standorteTable"
                  tbody-tr-class="item"
                  show-empty
                  :items="standorte"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  sort-icon-left
                  borderless
                  thead-class="d-none"
                >
                  <template
                    #cell(bezeichnung)="row"
                    class="d-flex align-items-center"
                  >
                    <input
                      v-model="row.item.bezeichnung"
                      class="form-control"
                      :placeholder="$t('global.newLocation')"
                      :readonly="!editable"
                    />
                  </template>

                  <template
                    #cell(delete)="row"
                    class="d-flex align-items-center"
                  >
                    <button
                      class="btn btn-danger btn-sm"
                      @click="standortLoeschen(row.item)"
                      style="height: 33px"
                      :disabled="!editable"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-1 pl-1"
                      />
                    </button>
                  </template>

                  <template #empty>
                    <div class="text-center text-primary my-2">
                      <strong>{{ $t("global.nodataavailable") }}</strong>
                    </div>
                  </template>

                  <template #table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"
                      :key="field.key"
                      :style="{
                        width: field.key === 'delete' ? '10%' : '90%',
                      }"
                    />
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";

export default {
  name: "Firma-Standorte",
  components: {
    ContainerHeadline,
  },
  mixins: [],
  props: {
    editable: {
      type: Boolean,
      required: true,
    },
    standorte: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sortBy: "",
      sortDesc: false,
      standortPanelStyle: {
        height: "246.5px",
        "overflow-y": "scroll",
      },
    };
  },
  computed: {
    fields() {
      return [
        {
          key: "bezeichnung",
          sortable: true,
          tdClass: "p-0 pb-1 pr-1",
        },
        {
          key: "delete",
          sortable: false,
          label: this.$t("global.delete"),
          tdClass: "p-0 d-flex align-items-center",
        },
      ];
    },
  },
  created() {},
  watch: {},
  mounted: function () {},
  methods: {
    standortLoeschen(item) {
      this.$emit("standortLoeschen", item);
    },
    standortHinzufuegen() {
      this.$emit("standortHinzufuegen");
    },
  },
};
</script>

<style>
/* Firefox */
*.standortPanel {
  scrollbar-width: thin;
  scrollbar-color: #deedf6 #f5fbff;
}

/* Chrome, Edge, und Safari */
*.standortPanel::-webkit-scrollbar {
  width: 12px;
}

*.standortPanel::-webkit-scrollbar-track {
  background: #f5fbff;
  border-radius: 0 0 6px 0;
}

*.standortPanel::-webkit-scrollbar-thumb {
  background-color: #deedf6;
  border-radius: 20px;
  border: 3px solid #f5fbff;
}
</style>

<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">
            <font-awesome-icon v-if="color" :class="textColor" :icon="icon" />
            {{ title }}
          </h4>
        </div>
        <div class="modal-body">
          <p>{{ text }}</p>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="btn btn-default" @click="close">
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/js/bootstrap";
import ModalCloseButton from "@/components/global/ModalCloseButton.vue";
import { modal } from "@/mixins/Modal";

export default {
  name: "AlertModal",
  components: {
    ModalCloseButton
  },
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: null,
    },
    eventName: {
      type: String,
      default: "",
    },
  },
  computed: {
    icon: function () {
      if (this.color) {
        if (this.color === "success") return "fa-solid fa-circle-check";
        if (this.color === "warning") return "fa-regular fa-warning";
        if (this.color === "danger") return "fa-solid fa-circle-xmark";
        /*
        if (this.color === "success") return "fa-check-circle-o";
        if (this.color === "warning") return "fa-warning";
        if (this.color === "danger") return "fa-times-circle-o";*/
      }
      return null;
    },
    textColor: function () {
      if (this.color) return `text-${this.color}`;
      return null;
    },
  },
  methods: {
    close() {
      $("#" + this.id).modal("hide");
      this.$emit(`${this.eventName}`);
    },
  },
};
</script>

<style>
</style>
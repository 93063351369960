import {
  SET_NOTENEINGABE_STATI,
  SET_SICHTBARKEITEN,
  SET_NOTENEINGABE_TYPEN,
  SET_TEXTWAHL_WERTE,
  SET_NOTENSTRUKTUR_ABSCHLUESSE,
  SET_PROMOTION_STATI,
  SET_FAECHER,
  SET_NOTEN_TYPEN
} from "./mutations.type";

import {
  LADE_AUSWAHLWERTE_NOTEN,
  LADE_NOTENSTRUKTUR_ABSCHLUESSE,
  LADE_FAECHER
} from "./actions.type";

import Api from "../../Api";

export default {
  namespaced: true,
  state: {
    noteneingabestati: [],
    sichtbarkeiten: [],
    noteneingabetypen: [],
    textwahlwerte: [],
    abschluesse: [],
    promotionstati: [],
    faecher: [],
    notentypen: [],
  },
  mutations: {
    [SET_NOTENEINGABE_STATI](state, val) {
      state.noteneingabestati = val;
    },
    [SET_SICHTBARKEITEN](state, val) {
      state.sichtbarkeiten = val;
    },
    [SET_NOTENEINGABE_TYPEN](state, val) {
      state.noteneingabetypen = val;
    },
    [SET_NOTEN_TYPEN](state, val) {
      state.notentypen = val;
    },
    [SET_TEXTWAHL_WERTE](state, val) {
      state.textwahlwerte = val;
    },
    [SET_NOTENSTRUKTUR_ABSCHLUESSE](state, val) {
      state.abschluesse = val;
    },
    [SET_PROMOTION_STATI](state, val) {
      state.promotionstati = val;
    },
    [SET_FAECHER](state, val) {
      state.faecher = val;
    }
  },
  actions: {
    async [LADE_AUSWAHLWERTE_NOTEN](context) {
      let response = await Api.get("noten/auswahlwerte/");
      context.commit(SET_NOTENEINGABE_STATI, response.data.noteneingabestati);
      context.commit(SET_SICHTBARKEITEN, response.data.sichtbarkeiten);
      context.commit(SET_NOTENEINGABE_TYPEN, response.data.noteneingabetypen);
      context.commit(SET_TEXTWAHL_WERTE, response.data.textwahlwerte);
      context.commit(SET_PROMOTION_STATI, response.data.promotionstati);
      context.commit(SET_NOTEN_TYPEN, response.data.notentypen);
    },
    async [LADE_NOTENSTRUKTUR_ABSCHLUESSE](context) {
      // Abfrage Abschluss aus der in den Anmeldungen verknüpften Tabelle Notenstruktur mit dem Status "Aktiv"
      let response = await Api.get("notenstruktur/");
      context.commit(SET_NOTENSTRUKTUR_ABSCHLUESSE, response.data);
    },
    async [LADE_FAECHER](context) {
      let response = await Api.get("noten/faecher/");
      context.commit(SET_FAECHER, response.data);
    }
  }
};

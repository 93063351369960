<template>
  <ValidationObserver v-slot="{ invalid }" :disabled="true">
    <div id="mailadresse-bearbeiten-modal" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <modal-close-button @confirmed="closeModal" />
            <h4 class="modal-title">
              {{ editMailAdress.id ? $t("global.editemail") : $t("global.addemail") }}
            </h4>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-xl-12">
                <div class="form-group">
                  <ValidationProvider
                    rules="email|required"
                    immediate
                    v-slot="{ passed }"
                  >
                    <input
                      v-model.trim="editMailAdress.bezeichnung"
                      type="text"
                      class="form-control"
                      v-bind:class="{ 'border-danger': !passed }"
                      placeholder=" "
                    />
                    <label v-bind:class="{ 'text-danger': !passed }"
                      >{{ $t("global.email") }}</label
                    >
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-12">
                <div class="form-group">
                  <v-select
                    v-model="mailtyp"
                    label="bezeichnung"
                    :options="mailtypen"
                    :clearable="false"
                  ></v-select>
                  <label>{{ $t("global.intendeduse") }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeModal">
              {{ $t("global.cancel") }}
            </button>
            <button
              type="button"
              :disabled="invalid"
              class="btn btn-primary"
              @click="uebernehmen"
            >
              {{ $t("global.save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import ModalCloseButton from "@/components/global/ModalCloseButton";

export default {
  name: "MailAdresseModal",
  components: { ModalCloseButton },
  props: {},
  data() {
    return {
      editMailAdress: {},
      mailtyp: null,
      shown: false,
    };
  },
  computed: {
    mailtypen() {
      return this.$store.state.geschaeftspartner.emailtypen;
    },
  },
  watch: {},
  mounted() {
    this.$bus.$on("mailadresse-modal-show", (data) => {
      this.editMailAdress = Object.assign({}, data);

      /*** Default Mailtyp setzen */

      if (!this.editMailAdress.mailtyp) {
        let mailTypDefault = this.$CONST("MAILTYPEN").DEFAULT;

        let index = this.mailtypen.findIndex(
          (mailtyp) => mailtyp.id == mailTypDefault
        );

        index = index == -1 ? 0 : index;

        this.mailtyp = this.mailtypen[index];
        this.editMailAdress.mailtyp = this.mailtypen[index];
      } else {
        this.mailtyp = this.editMailAdress.mailtyp;
      }

      if (!this.shown) this.openModal();
    });
  },

  methods: {
    openModal() {
      this.shown = true;

      $("#mailadresse-bearbeiten-modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    },
    closeModal() {
      this.shown = false;
      $("#mailadresse-bearbeiten-modal").modal("hide");
    },
    uebernehmen() {
      this.editMailAdress.mailtyp = this.mailtyp;

      if (this.editMailAdress.id || this.editMailAdress.key) {
        this.$emit("mailAdressChanged", this.editMailAdress);
      } else {
        this.$emit("mailAdressCreated", this.editMailAdress);
      }

      this.closeModal();
    },
  },
};
</script>

<style>
</style>

<template>
  <div class="form-group">
    <input
      v-if="debitor.id && debitor.person && !debitor.firma"
      v-model="debitor.person.personName"
      class="form-control"
      placeholder=" "
      readonly
    />
    <input
      v-if="debitor.id && debitor.firma && !debitor.person"
      v-model="debitor.firma.name"
      class="form-control"
      placeholder=" "
      readonly
    />
    <input
      v-if="debitor.id && debitor.firma && debitor.person"
      :value="debitor.person.personName + ' / ' + debitor.firma.name"
      class="form-control"
      placeholder=" "
      readonly
    />
    <input
      v-if="!debitor.id"
      class="form-control"
      :placeholder="$t('global.notlinked')"
      readonly
    />
    <label>{{ $t("global.debtor") }}</label>
    <span
      v-if="allowOpen && debitor.id != null"
      class="openIcon"
      v-on:click="openDebitor"
      ><font-awesome-icon icon="fa-duotone fa-arrow-circle-right"
    /></span>
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";

export default {
  name: "SingleSelectDebitor",
  components: {},
  props: {
    id: {
      type: String,
      default: uuid(),
    },
    debitor: {
      type: Object,
      required: true,
    },
    allowOpen: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    /*
    modalId() {
      return "geschaeftspartner-modal-" + this.id;
    },
    */
  },
  methods: {
    openDebitor() {
      if (this.debitor.id) {
        this.$router.push({
          name: "Debitor",
          params: { id: this.debitor.id, anzeige: 0 },
        });
      }
    },
  },
};
</script>

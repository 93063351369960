<template>
  <div
    id="passwort-aendern-modal"
    class="modal fade"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog modal-passwortAendern">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">{{ $t("global.changepassword") }}</h4>
        </div>
        <div class="modal-body">
          <div class="col-xl-12">
            <div>
              <div class="col-xl-12 form-group mb-1">
                <input
                  type="password"
                  name="password"
                  class="form-control"
                  :class="{ valid: passwordValidation.valid }"
                  :required="true"
                  v-model="password"
                  placeholder=""
                />
                <label for="password">{{ $t("global.newpassword") }}</label>
              </div>
              <div class="col-xl-12 form-group">
                <input
                  type="password"
                  name="password2"
                  class="form-control"
                  :required="true"
                  v-model="password2"
                  placeholder=""
                />
                <label for="password2">{{
                  $t("global.confirmpassword")
                }}</label>
              </div>
            </div>
          </div>
          <div class="col-xl-12" style="min-height: 65px">
            <span
              class="px-3 d-flex flex-wrap"
              style="color: #24698c; font-size: 14px"
              >{{ $t("global.atleastupperlowercaselettersnumber") }}</span
            >
            <span
              v-if="notSamePasswords"
              class="px-3 d-flex flex-wrap"
              style="color: red; font-size: 14px"
              >{{ $t("global.passwordsdonotmatch") }}</span
            >
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-success"
            @click="speichern"
            :disabled="
              !passwordValidation.valid || !passwordsFilled || notSamePasswords
            "
          >
            {{ $t("global.save") }}
          </button>
          <button type="button" class="btn btn-default" @click="closeModal">
            {{ $t("global.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import ModalCloseButton from "@/components/global/ModalCloseButton";
import { modal } from "@/mixins/Modal";

import store from "@/store";

export default {
  name: "PasswortAendernModal",
  mixins: [modal],
  components: { ModalCloseButton },
  store,
  props: {},
  data() {
    return {
      pwReset: {},
      password: "",
      password2: "",
      rules: [
        { message: "One lowercase letter required.", regex: /[a-z]+/ },
        { message: "One uppercase letter required.", regex: /[A-Z]+/ },
        { message: "8 characters minimum.", regex: /.{8,}/ },
        { message: "One number required.", regex: /[0-9]+/ },
      ],
    };
  },
  computed: {
    notSamePasswords() {
      if (this.passwordsFilled) {
        return this.password !== this.password2;
      } else {
        return false;
      }
    },
    passwordsFilled() {
      return this.password !== "" && this.password2 !== "";
    },
    passwordValidation() {
      let errors = [];
      for (let condition of this.rules) {
        if (!condition.regex.test(this.password)) {
          errors.push(condition.message);
        }
      }
      if (errors.length === 0) {
        return { valid: true, errors };
      } else {
        return { valid: false, errors };
      }
    },
  },
  mounted() {},
  methods: {
    closeModal() {
      this.shown = false;
      $("#passwort-aendern-modal").modal("hide");
      this.password = "";
      this.password2 = "";
    },
    speichern() {
      this.pwReset = {
        id: this.$store.state.mitarbeiter.id,
        newPw: this.password,
      };

      var json = Object.assign({}, this.pwReset);

      Api.post("changePw/", json).then(() => {
        this.$emit("success");
        this.closeModal();
      });
    },
  },
};
</script>

<style>
</style>

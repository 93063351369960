<template>
  <div>
    <container-headline
      :headline="$t('global.coursedetails')"
      :col="6"
    ></container-headline>

    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <b-form-input
                  v-model.trim="kurs.kontakt_email"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                />
                <label>{{ $t("global.contactemail") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <single-select-person
                id="verantwortlich"
                :editable="true"
                :label="$t('global.responsible')"
                @confirmed="setVerantwortlicher"
                :person="kurs.verantwortlich_kurs"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <textarea
                  v-model.trim="kurs.ort"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                ></textarea>
                <label>{{ $t("global.city") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <textarea
                  v-model.trim="kurs.dauer"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                ></textarea>
                <label>{{ $t("global.duration") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <textarea
                  v-model.trim="kurs.daten"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                ></textarea>
                <label>{{ $t("global.Dates") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <textarea
                  v-model.trim="kurs.zeiten"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                ></textarea>
                <label>{{ $t("global.times") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <textarea
                  v-model.trim="kurs.treffpunkt"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                ></textarea>
                <label>{{ $t("global.meetingpoint") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <textarea
                  v-model.trim="kurs.kosten"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                ></textarea>
                <label>{{ $t("global.costs") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <textarea
                  v-model.trim="kurs.lehrmittel"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                ></textarea>
                <label>{{ $t("global.teachingmaterial") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <textarea
                  v-model.trim="kurs.dokumentation"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                ></textarea>
                <label>{{ $t("global.documentation") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <textarea
                  v-model.trim="kurs.ziele"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                ></textarea>
                <label>{{ $t("global.goals") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <textarea
                  v-model.trim="kurs.inhalte"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                ></textarea>
                <label>{{ $t("global.contents") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <textarea
                  v-model.trim="kurs.zielgruppe"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                ></textarea>
                <label>{{ $t("global.targetgroup") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <date-picker
                  :initial="kurs.anmeldeschluss"
                  :disabled="!editable"
                  date-format="dd.mm.yy"
                  placeholer=" "
                  :show-icon="true"
                  @update="(val) => (kurs.anmeldeschluss = val)"
                />
                <label>{{ $t("global.registrationend") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <textarea
                  v-model.trim="kurs.abmeldung"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                ></textarea>
                <label>{{ $t("global.cancellation") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <textarea
                  v-model.trim="kurs.bemerkung"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                ></textarea>
                <label>{{ $t("global.comment") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <single-select-person
                id="leitung"
                :person="kurs.leitung"
                :editable="true"
                :label="$t('global.management')"
                @confirmed="setLeitung"
              />
            </div>
            <div class="col-xl-6">
              <single-select-person
                id="dozentKurseDetails"
                :person="kurs.dozent"
                :editable="true"
                :label="$t('global.lecturer')"
                @confirmed="setDozent"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";
import SingleSelectPerson from "@/components/global/SingleSelectPerson";
import DatePicker from "@/components/Datepicker";

export default {
  name: "KursDetails",
  components: {
    ContainerHeadline,
    SingleSelectPerson,
    DatePicker,
  },
  data() {
    return {
      filterRollenDozenten: this.$CONST("ROLLEN").DOZENTEN, // führt im single select nicht zur richtigen auswahl
    };
  },
  props: {
    kurs: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    setLeitung(person) {
      this.kurs.leitung = person;
    },
    setDozent(person) {
      this.kurs.dozent = person;
    },
    setVerantwortlicher(person) {
      this.kurs.verantwortlich_kurs = person;
    },
  },
};
</script>

<style lang="scss">
</style>

import { SET_MAILBANNER } from "./mutations.type";

import { LADE_AUSWAHLWERTE_MAIL } from "./actions.type";

import Api from "../../Api";

export default {
  namespaced: true,
  state: {
    mailbanner: []
  },
  mutations: {
    [SET_MAILBANNER](state, val) {
      state.mailbanner = val;
    }
  },
  actions: {
    async [LADE_AUSWAHLWERTE_MAIL](context) {
      let response = await Api.get("mail/auswahlwerte/");
      context.commit(SET_MAILBANNER, response.data.mailbanner);
    }
  }
};

<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.dashboard_subjects')"
        :col="3"
      >
        <div class="row mt-2 d-flex justify-content-between">
          <div class="ml-4">
            <button
              class="btn btn-success"
              @click="erstelleFach"
              v-if="berechtigungen.b_bildung_fach.create"
            >
              <font-awesome-icon icon="fa-regular fa-plus" />
              {{ $t("global.new") }}
            </button>
          </div>

          <div class="mr-4">
            <button
              class="btn btn-danger"
              @click="oeffneLoeschenModal"
              v-if="editable"
            >
              <font-awesome-icon class="mr-1" icon="fa-duotone fa-trash" />
              <span>{{ $t("global.delete") }}</span>
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              tbody-tr-class="item"
              :items="faecher"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="isBusy"
              sort-icon-left
              fixed
              selectable
              select-mode="range"
              show-empty
              @row-dblclicked="oeffneFach"
              thead-tr-class="pointer"
            >
              <template v-slot:head(selected)="row">
                <b-form-checkbox
                  v-model="row.selected"
                  @input="selectAllRows(row)"
                ></b-form-checkbox>
              </template>

              <template v-slot:cell(selected)="row">
                <b-form-checkbox
                  v-model="row.item.selected"
                  @input="onRowSelected(row)"
                ></b-form-checkbox>
              </template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>{{ $t("global.errorwhileloading") }}</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>{{ $t("global.nodataavailable") }}</strong>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="fach-loeschen-modal"
      @confirmed="fachloeschen"
      :selectedIds="Array.from(new Set(selectedIds))"
      :multiple="true"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import reiter from "@/mixins/Reiter";

export default {
  name: "Fachliste",
  components: {
    ContainerHeadline,
    LoeschenModal,
    BSpinner,
  },
  mixins: [reiter],
  props: {
    veranstaltung: {
      type: String,
      required: true,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      page: 0,
      infiniteId: +new Date(),
      anzahlProPage: 50,
      sortBy: "",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      faecher: [],
      selected: [],
      selectedIds: [],
    };
  },
  watch: {
    shown(val) {
      if (val && this.faecher.length == 0) this.getFaecher();
    },
  },
  mounted() {
    if (this.shown && this.faecher.length == 0) this.getFaecher();
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "kuerzel",
          sortable: true,
          label: this.$t("global.shortdesignation"),
        },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
        {
          key: "veranstaltungstyp.bezeichnung",
          sortable: true,
          label: this.$t("global.type"),
        },
        {
          key: "anmeldungen",
          sortable: true,
          label: this.$t("global.dashboard_registrations"),
        },
      ];
    },
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      if (this.suche) params.suche = this.suche;

      return params;
    },
  },
  created() {},
  methods: {
    getFaecher() {
      this.isBusy = true;

      Api.get("veranstaltungfach/", {
        params: {
          veranstaltung: this.veranstaltung,
          page: this.page,
          count: this.anzahlProPage,
          veranstaltungstyp: this.$CONST("VERANSTALTUNGSTYPEN").MODULE,
        },
      })
        .then((response) => {
          this.faecher = response.data;
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    oeffneFach(faecher) {
      this.$router.push({
        name: "fach",
        params: { fachid: faecher.id, anzeige: 0 },
      });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "fach-loeschen-modal");
    },

    fachloeschen() {
      Api.delete("veranstaltungfach/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.getFaecher();
      });
    },

    infiniteHandler($state) {
      console.log("init");

      if (this.shown) {
        Api.get("faecher/", {
          params: this.queryParams,
        }).then((response) => {
          console.log("final");
          if (response.data.length === this.anzahlProPage) {
            this.page += 1;
            this.faecher.push(...response.data);
            $state.loaded();
          } else {
            this.faecher.push(...response.data);
            $state.complete();
          }
        });
      }
    },
    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = true;
          if (!this.selectedIds.includes(gp.id)) {
            this.selectedIds.push(gp.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },
    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },
    erstelleFach() {
      if (this.isBusy) return;
      this.isBusy = true;
      Api.post("veranstaltungen/?parent=" + this.veranstaltung, {
        veranstaltungstyp: this.$CONST("VERANSTALTUNGSTYPEN").MODULE,
      })
        .then((response) => {
          this.$router.push({
            name: "fach",
            params: { fachid: response.data.id, anzeige: 0 },
          });
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.subjectsuccessfullysaved"),
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
  },
};
</script>

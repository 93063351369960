import {
  SET_MAHNSTUFEN_WERTE,
} from "./mutations.type";

import {
  LADE_MAHNSTUFEN_WERTE
} from "./actions.type";

import Api from "../../Api";

export default {
  namespaced: true,
  state: {
    mahnstufenwerte: [],
  },
  mutations: {
    [SET_MAHNSTUFEN_WERTE](state, val) {
      state.mahnstufenwerte = val;
    },
  },
  actions: {
    async [LADE_MAHNSTUFEN_WERTE](context) {
      let response = await Api.get("finanzen/mahnstufen/");
      context.commit(SET_MAHNSTUFEN_WERTE, response.data);
    },
  }
};

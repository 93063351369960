import {
  SET_BILDUNGS_KATEGORIEN,
  SET_PERIODEN,
  SET_VERANSTALTUNGS_STATI,
  SET_LEHRGANG_STATI,
  SET_BILDUNGSGANG_TYPEN,
  SET_VERANSTALTUNGS_TYPEN,
  SET_THEMEN,
  SET_QUALIFIKATIONEN,
  SET_LEHRMETHODEN,
  SET_NIVEAUS,
  SET_FAECHER,
  SET_SEMESTER_TYPEN,
  SET_NOTENTYPEN,
  SET_MODULEINSAETZE,
  SET_MODULGRUPPEN,
  SET_MODULTYPEN,
  SET_KURSVORLAGEN,
  SET_ZIELGRUPPEN,
  SET_SPRACHEN,
  SET_GEBAEUDE,
  SET_MAILVORLAGEN,
  SET_NACHWEISE,
  SET_PRUEFUNGSMODI,
  SET_LEHRGANGSKATEGORIEN,
  SET_LEHRGANGSGRUPPEN,
} from "./mutations.type";

import { LADE_AUSWAHLWERTE_VERANSTALTUNG } from "./actions.type";
import { LADE_KURSVORLAGEN } from "./actions.type";

import Api from "../../Api";

export default {
  namespaced: true,
  state: {
    veranstaltungstypen: [],
    bildungskategorien: [],
    veranstaltungsstati: [],
    lehrgangstati: [],
    bildungsgangtypen: [],
    perioden: [],
    themen: [],
    qualifikationen: [],
    lehrmethoden: [],
    niveau: [],
    semestertypen: [],
    notentypen: [],
    faecher: [],
    moduleinsaetze: [],
    modulgruppen: [],
    modultypen: [],
    kursvorlagen: [],
    zielgruppen: [],
    sprachen: [],
    gebaeude: [],
    mailvorlagen: [],
    nachweise: [],
    pruefungsmodi: [],
    lehrgangsgruppen: [],
    lehrgangskategorien: [],
  },
  mutations: {
    [SET_BILDUNGS_KATEGORIEN](state, val) {
      state.bildungskategorien = val;
    },
    [SET_PERIODEN](state, val) {
      state.perioden = val;
    },
    [SET_LEHRGANG_STATI](state, val) {
      state.lehrgangstati = val;
    },
    [SET_BILDUNGSGANG_TYPEN](state, val) {
      state.bildungsgangtypen = val;
    },
    [SET_VERANSTALTUNGS_STATI](state, val) {
      state.veranstaltungsstati = val;
    },
    [SET_VERANSTALTUNGS_TYPEN](state, val) {
      state.veranstaltungstypen = val;
    },
    [SET_THEMEN](state, val) {
      state.themen = val;
    },
    [SET_QUALIFIKATIONEN](state, val) {
      state.qualifikationen = val;
    },
    [SET_LEHRMETHODEN](state, val) {
      state.lehrmethoden = val;
    },
    [SET_NIVEAUS](state, val) {
      state.niveau = val;
    },
    [SET_SEMESTER_TYPEN](state, val) {
      state.semestertypen = val;
    },
    [SET_NOTENTYPEN](state, val) {
      state.notentypen = val;
    },
    [SET_FAECHER](state, val) {
      state.faecher = val;
    },
    [SET_MODULGRUPPEN](state, val) {
      state.modulgruppen = val;
    },
    [SET_MODULEINSAETZE](state, val) {
      state.moduleinsaetze = val;
    },
    [SET_MODULTYPEN](state, val) {
      state.modultypen = val;
    },
    [SET_KURSVORLAGEN](state, val) {
      state.kursvorlagen = val;
    },
    [SET_ZIELGRUPPEN](state, val) {
      state.zielgruppen = val;
    },
    [SET_SPRACHEN](state, val) {
      state.sprachen = val;
    },
    [SET_GEBAEUDE](state, val) {
      state.gebaeude = val;
    },
    [SET_MAILVORLAGEN](state, val) {
      state.mailvorlagen = val;
    },
    [SET_NACHWEISE](state, val) {
      state.nachweise = val;
    },
    [SET_PRUEFUNGSMODI](state, val) {
      state.pruefungsmodi = val;
    },
    [SET_LEHRGANGSKATEGORIEN](state, val) {
      state.lehrgangskategorien = val;
    },
    [SET_LEHRGANGSGRUPPEN](state, val) {
      state.lehrgangsgruppen = val;
    },
  },
  actions: {
    async [LADE_AUSWAHLWERTE_VERANSTALTUNG](context) {
      let response = await Api.get("veranstaltungen/auswahlwerte/");
      context.commit(SET_BILDUNGS_KATEGORIEN, response.data.bildungskategorien);
      context.commit(SET_PERIODEN, response.data.perioden);
      context.commit(SET_LEHRGANG_STATI, response.data.lehrgangstati);
      context.commit(SET_BILDUNGSGANG_TYPEN, response.data.bildungsgangtypen);
      context.commit(SET_MODULGRUPPEN, response.data.modulgruppe);
      context.commit(SET_MODULEINSAETZE, response.data.moduleinsatz);
      context.commit(SET_MODULTYPEN, response.data.modultyp);

      context.commit(
        SET_VERANSTALTUNGS_STATI,
        response.data.veranstaltungsstati
      );
      context.commit(
        SET_VERANSTALTUNGS_TYPEN,
        response.data.veranstaltungstypen
      );
      context.commit(SET_THEMEN, response.data.themen);
      context.commit(SET_QUALIFIKATIONEN, response.data.qualifikationen);
      context.commit(SET_LEHRMETHODEN, response.data.lehrmethoden);
      context.commit(SET_NIVEAUS, response.data.niveau);
      context.commit(SET_FAECHER, response.data.faecher);
      context.commit(SET_SEMESTER_TYPEN, response.data.semestertypen);
      context.commit(SET_NOTENTYPEN, response.data.notentypen);
      context.commit(SET_ZIELGRUPPEN, response.data.zielgruppen);
      context.commit(SET_SPRACHEN, response.data.sprachen);
      context.commit(SET_GEBAEUDE, response.data.gebaeude);
      context.commit(SET_MAILVORLAGEN, response.data.mailvorlagen);
      context.commit(SET_NACHWEISE, response.data.nachweise);
      context.commit(SET_PRUEFUNGSMODI, response.data.pruefungsmodi);
      context.commit(SET_LEHRGANGSGRUPPEN, response.data.lehrgangsgruppen);
      context.commit(
        SET_LEHRGANGSKATEGORIEN,
        response.data.lehrgangskategorien
      );
    },
    async [LADE_KURSVORLAGEN](context) {
      let response = await Api.get(
        "template/veranstaltung/?veranstaltungstyp=174D91487E8"
      );
      context.commit(SET_KURSVORLAGEN, response.data);
    },
  },
};

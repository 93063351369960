<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">
            {{ $t("global.translate") }}
          </h4>
        </div>
        <div class="modal-body">
          <div class="row ml-0 mr-0">
            <div class="col-md-12">
              <div class="row d-flex justify-content-between">
                <div class="col-xl-5 form-group">
                  <v-select
                    v-if="korrespondenz || mail"
                    v-model="sourceLang"
                    label="kuerzel"
                    :options="availableSourceLangs"
                    :placeholder="$t('global.sourcelanguage')"
                  >
                    <span slot="no-options">{{
                      $t("global.taptosearch")
                    }}</span>
                  </v-select>
                  <label>{{ $t("global.sourcelanguage") }}</label>
                </div>
                <div class="col-xl-2 text-bottom">
                  {{ $t("global.to") }}
                </div>
                <div class="col-xl-5 form-group">
                  <v-select
                    :disabled="!sourceLang"
                    v-model="targetLangs"
                    label="kuerzel"
                    :options="allTargetLangs"
                    :placeholder="$t('global.targetlanguages')"
                    :reduce="(l) => l.kuerzel"
                    multiple
                  >
                    <span slot="no-options">{{
                      $t("global.taptosearch")
                    }}</span>
                  </v-select>
                  <label>{{ $t("global.targetlanguages") }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeModal">
              {{ $t("global.cancel") }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="translate"
              :disabled="!sourceLang || targetLangs.length === 0"
            >
              {{ $t("global.translate") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { modal } from "@/mixins/Modal";
import Api from "@/Api";
import ModalCloseButton from "@/components/global/ModalCloseButton.vue";

export default {
  name: "TranslationModal",
  mixins: [modal],
  components: {ModalCloseButton},
  props: {
    id: {
      type: String,
      required: false,
      default: "translation-modal",
    },
    korrespondenz: {
      type: Object,
      required: false,
    },
    mail: {
      type: Object,
      required: false,
    },
    langs: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      sourceLang: null,
      targetLangs: [],
      allTargetLangs: [],
    };
  },
  computed: {
    availableSourceLangs() {
      if (this.korrespondenz) {
        return this.langs.filter((lang) => {
          return (
            this.korrespondenz.translation[lang.kuerzel].text &&
            this.korrespondenz.translation[lang.kuerzel].betreff
          );
        });
      } else if (this.mail) {
        return this.langs.filter((lang) => {
          return (
            this.mail.translation[lang.kuerzel].text &&
            this.mail.translation[lang.kuerzel].betreff
          );
        });
      } else return [];
    },
  },
  watch: {
    sourceLang(neu) {
      if (neu) {
        this.allTargetLangs = this.langs.filter((lang) => {
          return lang.kuerzel !== neu.kuerzel;
        });
        this.targetLangs = this.targetLangs.filter((lang) => {
          return lang !== neu.kuerzel;
        });
      } else {
        this.allTargetLangs = [];
      }
    },
  },
  methods: {
    translate() {
      this.$emit("translate", this.sourceLang, this.targetLangs);
      this.closeModal();
    },

    abbrechen() {
      this.shown = false;
      this.clear();
      this.hide();
    },
    closeModal() {
      this.shown = false;
      $("#" + this.id).modal("hide");
    },
  },
};
</script>

<style></style>

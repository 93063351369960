<template>
  <div>
    <container-headline
      :headline="$t('global.trainingcourse')"
      :col="6"
    ></container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <span
                  class="openIcon"
                  v-on:click="oeffneBildungsgang"
                  v-if="berechtigungen.b_bildung_bildungsgang.read"
                  ><font-awesome-icon icon="fa-duotone fa-arrow-circle-right" />
                </span>
                <b-form-input
                  v-model="klasse.lehrgang.bezeichnung"
                  class="form-control"
                  readonly
                  placeholder=" "
                />
                <label
                  >{{ $t("global.trainingcourse") }}
                  <font-awesome-icon icon="fa-duotone fa-arrow-circle-right" />
                </label>
              </div>
            </div>
            <div class="col-xl-4">
              <div class="form-group">
                <b-form-input
                  v-model="klasse.lehrgang.lehrgangstatus.bezeichnung"
                  class="form-control"
                  readonly
                  placeholder=" "
                />
                <label>{{ $t("global.status") }}</label>
              </div>
            </div>
            <div class="col-xl-2">
              <div class="form-group" v-if="!isTemplate">
                <b-form-input
                  v-model="klasse.periodenNummer"
                  class="form-control"
                  readonly
                  placeholder=" "
                />
                <label>{{ $t("global.semester") }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";

export default {
  name: "KlasseBildungsgang",
  components: {
    ContainerHeadline,
  },
  props: {
    isTemplate: {
      type: Boolean,
      required: true,
    },
    klasse: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    oeffneBildungsgang() {
      if (this.klasse.lehrgang) {
        this.$router.push({
          name: this.isTemplate ? "template-bildungsgang" : "bildungsgang",
          params: { lehrgangid: this.klasse.lehrgang.id, anzeige: 0 },
        });
      }
    },
  },
};
</script>

<style lang="scss"></style>

<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        v-if="$CONST('CONFIG').NIVEAULH != 'true'"
        class="col-xl-12"
        :headline="$t('global.qualifications')"
        :col="3"
      >
        <button class="btn btn-primary" @click="showQualifikationHinzufuegen">
          <font-awesome-icon icon="fa-regular fa-plus" />{{
            $t("global.addqualification")
          }}
        </button>
      </container-headline>

      <container-headline
        v-if="$CONST('CONFIG').NIVEAULH == 'true'"
        class="col-xl-12"
        headline="Niveau"
        :col="3"
      >
        <button class="btn btn-primary" @click="showQualifikationHinzufuegen">
          <font-awesome-icon icon="fa-regular fa-plus" />Niveau hinzufügen
        </button>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block br-t-l-0">
          <div class="list-group">
            <qualifikation
              v-for="(q, indexQ) in qualifikationen"
              :key="q.key"
              :qualifikation="q"
              :index="indexQ"
              @deleteQualifikation="qualifikationDeleted"
              @updateQualifikation="showQualifikationAendern"
            ></qualifikation>

            <div
              v-if="isBusy && !ladeFehler"
              class="text-center text-primary my-2"
            >
              <b-spinner class="align-middle"></b-spinner>
            </div>

            <div
              class="text-center text-primary my-2"
              v-if="qualifikationen.length == 0 && !isBusy && !ladeFehler"
            >
              <strong>{{ $t("global.noqualificationsavailable") }}</strong>
            </div>

            <div
              class="text-center text-primary my-2"
              v-if="!isBusy && ladeFehler"
            >
              <strong>{{ $t("global.errorloadingqualifications") }}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>

    <qualifikation-modal
      @qchanged="qualifikationAendern"
      @qcreated="qualifikationHinzufuegen"
    ></qualifikation-modal>
  </div>
</template>

<script>
import Api from "@/Api";
import { uuidv4 } from "@/utils/Uuid";

import ContainerHeadline from "@/components/ContainerHeadline";

import Qualifikation from "@/components/Geschaeftspartner/Qualifikation";
import QualifikationModal from "@/components/Geschaeftspartner/QualifikationModal";

import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";

export default {
  components: {
    ContainerHeadline,
    Qualifikation,
    QualifikationModal,
    BSpinner,
  },
  props: {
    person: {
      type: String,
      required: true,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      ladeFehler: false,
      qualifikationen: [],
    };
  },
  watch: {
    shown(val) {
      if (val && this.qualifikationen.length == 0) this.getDaten();
    },
  },
  computed: {},
  mounted() {
    if (this.shown) this.getDaten();
  },
  created() {},
  methods: {
    getDaten() {
      this.isBusy = true;

      Api.get("geschaeftspartner/qualifikation/", {
        params: { gpid: this.person },
      })
        .then((response) => {
          this.qualifikationen = response.data;
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    showQualifikationHinzufuegen() {
      this.$bus.$emit("qualifikation-modal-show", { id: null, delete: null });
    },

    showQualifikationAendern(index) {
      if (this.qualifikationen[index]) {
        this.$bus.$emit(
          "qualifikation-modal-show",
          this.qualifikationen[index]
        );
      }
    },

    qualifikationHinzufuegen(qualifikation) {
      qualifikation.key = uuidv4();
      this.qualifikationen.push(qualifikation);
      this.updateParent();
    },

    qualifikationAendern(updateQualifikation) {
      let pos = this.qualifikationen.findIndex(
        (q) => q.id === updateQualifikation.id
      );

      if (pos != -1) {
        this.$set(this.qualifikationen, pos, updateQualifikation);
        this.updateParent();
      }
    },

    qualifikationDeleted(indexQ) {
      if (this.qualifikationen[indexQ].id) {
        this.qualifikationen[indexQ].delete = true;
      } else {
        this.qualifikationen.splice(indexQ, 1);
      }

      this.updateParent();
    },

    updateParent() {
      this.$emit("updatequalifikationen", {
        qualifikationen: this.qualifikationen,
      });
    },
  },
};
</script>

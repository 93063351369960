<template>
  <div>
    <container-headline
      :headline="$t('global.communicationdetails')"
      :col="6"
    ></container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-4">
              <div class="form-group d-flex align-items-center pos-r">
                <label>{{ $t("global.businessphone") }}</label>
                <div class="input-wrapper d-flex align-items-center pos-r">
                  <vue-tel-input
                    class="phone-input padding-right-35px"
                    id="phone-business"
                    v-model="telefon_g"
                    :disabled="!editable"
                    :tabindex="300"
                    :inputOptions="inputOptions"
                    mode="international"
                  ></vue-tel-input>
                  <div
                    class="call-button pos-a top-0 right-0 bottom-0 d-flex padding-x-10px clickable hover-success"
                    @click="call(telefon_g)"
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-phone-arrow-up-right"
                      class="m-auto"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4" v-if="isPerson">
              <div class="form-group d-flex align-items-center pos-r">
                <label>{{ $t("global.privatephone") }}</label>
                <div class="input-wrapper d-flex align-items-center pos-r">
                  <vue-tel-input
                    class="phone-input padding-right-35px"
                    id="phone-private"
                    v-model="telefon_p"
                    :disabled="!editable"
                    :tabindex="301"
                    :inputOptions="inputOptions"
                    mode="international"
                  ></vue-tel-input>
                  <div
                    class="call-button pos-a top-0 right-0 bottom-0 d-flex padding-x-10px clickable hover-success"
                    @click="call(telefon_p)"
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-phone-arrow-up-right"
                      class="m-auto"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4" v-if="isPerson">
              <div class="form-group d-flex align-items-center pos-r">
                <label>{{ $t("global.mobilephone") }}</label>
                <div class="input-wrapper d-flex align-items-center pos-r">
                  <vue-tel-input
                    class="phone-input padding-right-35px"
                    id="phone-mobile"
                    v-model="telefon_m"
                    :disabled="!editable"
                    :tabindex="302"
                    :inputOptions="inputOptions"
                    mode="international"
                  ></vue-tel-input>
                  <div
                    class="call-button pos-a top-0 right-0 bottom-0 d-flex padding-x-10px clickable hover-success"
                    @click="call(telefon_m)"
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-phone-arrow-up-right"
                      class="m-auto"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6">
              <div
                v-if="
                  !isPerson ||
                  roles.isTraegerschaft ||
                  roles.isLieferant ||
                  roles.isArbeitgeber ||
                  roles.isJobCoach ||
                  roles.isPraxisbildner
                "
                class="form-group"
              >
                <input
                  v-model.trim="person.webseite"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                  :tabindex="303"
                />
                <label>{{ $t("global.website") }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";

export default {
  name: "Geschaeftspartner-KommunikationsAngaben",
  components: {
    ContainerHeadline,
  },
  mixins: [],
  props: {
    person: {
      type: Object,
      required: true,
    },
    isPerson: {
      type: Boolean,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    roles: {
      type: Object,
      default: function () {},
    },
  },
  data() {
    return {
      telefon_g: null,
      telefon_p: null,
      telefon_m: null,
      dropdownOptions: { showFlags: true },
      data: { m: "", g: "", p: "" },
    };
  },
  computed: {
    inputOptions() {
      return { placeholder: this.$t("global.enteraphonenumber") };
    },
  },
  watch: {
    telefon_g: {
      handler: function () {
        //if (val && val != this.person.telefon_g) this.updateCommunication();
        this.updateCommunication();
      },
    },
    telefon_m: {
      handler: function () {
        //if (val && val != this.person.telefon_m) this.updateCommunication();
        this.updateCommunication();
      },
    },
    telefon_p: {
      handler: function () {
        //if (val && val != this.person.telefon_p) this.updateCommunication();
        this.updateCommunication();
      },
    },
    person: {
      deep: true,
      handler: function () {
        this.telefon_g = this.person.telefon_g ? this.person.telefon_g : "";
        this.telefon_m = this.person.telefon_m ? this.person.telefon_m : "";
        this.telefon_p = this.person.telefon_p ? this.person.telefon_p : "";
      },
    },
  },
  created() {},
  methods: {
    updateCommunication() {
      this.$emit("commChanged", {
        g: this.telefon_g ? this.telefon_g : "",
        m: this.telefon_m ? this.telefon_m : "",
        p: this.telefon_p ? this.telefon_p : "",
      });
    },
    call(telefonnummer) {
      if (telefonnummer) window.location.href = `tel:${telefonnummer}`;
    },
  },
};
</script>

<style>
</style>


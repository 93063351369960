<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar"
            :docsName="docsName"
    />
    <div class="container-fluid row">
      <div v-if="showNavbar" class="col-xl-12">
        <div class="row">
          <div class="col-xl-12 block br-t-l-0 mb-3">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.building')"
            ></head-menu>
            <div class="row mt-2">
              <div class="ml-4 mr-2">
                <button
                  v-if="editable"
                  v-shortkey.once="['ctrl', 's']"
                  class="btn btn-success"
                  @click="speichern"
                  @shortkey="speichern"
                >
                  {{ $t("global.save") }}
                </button>
              </div>
              <div class="mr-2">
                <button class="btn btn-primary" @click="abbrechen">
                  <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                    $t("global.tolist")
                  }}
                </button>
              </div>
              <div class="ml-auto mr-3">
                <button
                  class="btn btn-danger"
                  @click="oeffneLoeschenModal"
                  v-if="editable"
                >
                  <font-awesome-icon icon="fa-duotone fa-trash" />
                  {{ $t("global.delete") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- START Linke Seite -->
      <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
        <!-- START Stammdaten -->
        <container-headline
          :headline="$t('global.masterdata')"
          :col="6"
        ></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model.trim="gebaeude.bezeichnung"
                      class="form-control"
                      :readonly="!editable"
                      placeholder=" "
                    />
                    <label>{{ $t("global.designation") }}</label>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model.trim="gebaeude.kurzbezeichnung"
                      :readonly="!editable"
                      class="form-control"
                      placeholder=" "
                    />
                    <label>{{ $t("global.shortdesignation") }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ENDE Stammdaten -->
      </div>
      <!-- ENDE linke Seite -->
      <!-- START Rechte Seite -->
      <div class="col-xl-6 pl-4" :class="{ 'col-xl-12': !showNavbar }">
        <!-- START Gebaeude -->
        <div>
          <container-headline
            :headline="$t('global.building')"
            :col="6"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div
                  v-if="$CONST('CONFIG').APPTYPE === 'Education'"
                  class="row"
                >
                  <div class="col-xl-4">
                    <div class="form-group">
                      <input
                        v-model.trim="gebaeude.adresse.strasse_nr"
                        :readonly="!editable"
                        class="form-control"
                        placeholder=" "
                      />
                      <label>{{ $t("global.streetandhousenumber") }}</label>
                    </div>
                  </div>

                  <div class="col-xl-2">
                    <div class="form-group">
                      <input
                        v-model.trim="gebaeude.adresse.plz"
                        :readonly="!editable"
                        class="form-control"
                        placeholder=" "
                      />
                      <label>{{ $t("global.zipcode") }}</label>
                    </div>
                  </div>

                  <div class="col-xl-3">
                    <div class="form-group">
                      <input
                        v-model.trim="gebaeude.adresse.ort"
                        :readonly="!editable"
                        class="form-control"
                        placeholder=" "
                      />
                      <label>{{ $t("global.city") }}</label>
                    </div>
                  </div>

                  <div class="col-xl-3">
                    <div class="form-group">
                      <v-select
                        v-model.trim="gebaeude.adresse.land"
                        label="name"
                        :readonly="!editable"
                        :options="laender"
                        :placeholder="$t('global.country')"
                        :clearable="false"
                      ></v-select>
                      <label>{{ $t("global.country") }}</label>
                    </div>
                  </div>
                  <!--
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="gebaeude.adresse.bezeichnung"
                        label="bezeichnung"
                        :disabled="true"
                        :options="[]"
                        :reduce="(adr) => adr.id"
                        placeholder="Adresse"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.address") }}</label>
                    </div>
                  </div>-->
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <v-select
                        v-model="gebaeude.standort"
                        label="bezeichnung"
                        :disabled="!editable"
                        :options="standorte"
                        :reduce="(so) => so.id"
                        :placeholder="$t('global.location')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.location") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <v-select
                        v-model="gebaeude.eigentumsverhaeltnis"
                        label="bezeichnung"
                        :disabled="!editable"
                        :options="eigentumsverhaeltnisse"
                        :reduce="(etv) => etv.id"
                        :placeholder="$t('global.ownershipstructure')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.ownershipstructure") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ENDE Gebaude -->
      </div>
      <!-- ENDE Rechte Seite -->
    </div>

    <loeschen-modal id="gebaeude-loeschen-modal" @confirmed="loeschen" />

    <loading-overlay v-show="loading"></loading-overlay>

    <change-watcher-alert />
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import LoadingOverlay from "@/components/global/LoadingOverlay";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import Api from "@/Api";
import store from "@/store";
import page from "@/mixins/Page";

import { LADE_AUSWAHLWERTE_RESSOURCEN } from "@/store/ressourcen/actions.type";

import ChangeWatcher from "@/mixins/ChangeWatcher";
import ChangeWatcherAlert from "@/components/global/ChangeWatcherAlert.vue";

import LoeschenModal from "@/components/Modals/LoeschenModal";

export default {
  name: "Gebaude",
  components: {
    HeadMenu,
    Navbar,
    ContainerHeadline,
    LoadingOverlay,
    ChangeWatcherAlert,
    LoeschenModal,
  },
  metaInfo() {
    const bezeichnung = this.gebaeude.bezeichnung;
    return {
      titleTemplate: () => {
        if (!this.id) {
          return this.$t("global.newbuilding");
        }
        return `${this.$t("global.building")} ${bezeichnung}`;
      },
    };
  },
  mixins: [page, ChangeWatcher],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      gebaeude: {
        bezeichnung: null,
        kurzbezeichnung: null,
        eigentumsverhaeltnis: null,
        adresse: {
          strasse_nr: "",
          plz: "",
          ort: "",
          land: {},
        },
        standort: [],
      },
      adresseKomplett: null,
      adressliste: [],
      standortliste: [],
      eigentumsverhaeltnisliste: [],
      loading: false,
      editable: false,
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.gebaeude) {
        return `${this.$t("global.building")} ${this.gebaeude.bezeichnung}`;
      } else {
        return this.$t("global.newbuilding");
      }
    },
    standorte: {
      get() {
        return this.$store.state.ressourcen.standorte;
      },
    },
    eigentumsverhaeltnisse: {
      get() {
        return this.$store.state.ressourcen.eigentumsverhaeltnisse;
      },
    },
    laender() {
      if (this.$store) return this.$store.state.laender;
      return [];
    },
  },
  created() {
    if (this.id) {
      Api.get("gebaeude/", { params: { id: this.id } }).then((response) => {
        this.initializeGebaeude(response.data);
      });
    }
    this.editable = this.berechtigungen.m_ressourcen.update;
    //ToDo: Adressverknüfung analog zu Maske Person
  },
  mounted: function () {
    if (this.standorte.length == 0) {
      this.$store.dispatch(`ressourcen/${LADE_AUSWAHLWERTE_RESSOURCEN}`);
    }
  },
  methods: {
    abbrechen() {
      this.$router.push({ name: "Gebäudeliste" });
    },
    initializeGebaeude(gebaeude) {
      if (gebaeude) {
        this.gebaeude = gebaeude;
        // abfangen wenn adressen/standort = null ist, gebaeude sollte eigentlich nicht null sein
        this.initChangeWatcher(this.gebaeude);
      } else {
        console.error("Gebäude ist null");
      }
    },
    speichern() {
      if (this.loading) return;

      this.loading = true;
      var json = Object.assign({}, this.gebaeude);

      if (!this.id) {
        Api.post("gebaeude/", json)
          .then((response) => {
            this.initializeGebaeude(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.buildingsavedsuccessfully"),
            });

            this.$router.replace({
              name: "GebäudeMitId",
              params: { id: response.data.id },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("gebaeude/", json, { params: { id: this.id } })
          .then((response) => {
            this.initializeGebaeude(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.buildingsavedsuccessfully"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "gebaeude-loeschen-modal");
    },

    loeschen() {
      Api.delete("gebaeude/", {
        params: { ids: this.id },
      }).then(() => {
        this.$router.push({ name: "Gebäudeliste" });
      });
    },
  },
};
</script>

<style></style>

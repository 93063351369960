<template>
  <div :id="id" class="modal fade" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">{{ $t("global.mergeduplicates") }}</h4>
        </div>
        <div class="modal-body mx-3">
          <div
            class="row block belegliste belegliste-small br-t-l-0"
            v-if="dublette && dublette != {} && !isBusy"
          >
            <dubletten-eintrag
              :eintrag="dublette"
              @eintragSchliessen="eintragSchliessen"
            />
          </div>
          <div v-if="isBusy">
            <b-spinner class="align-middle"></b-spinner>
          </div>
          <div v-if="dublette == {} && !isBusy && !ladeFehler">
            <span>{{ $t("global.nodataavailable") }}</span>
          </div>
          <div v-if="!isBusy && ladeFehler">
            <span>{{ $t("global.errorwhileloading") }}</span>
          </div>
          <div class="mt-3 mb-1 fs-13 text-center white-space-wrap">
            {{ $t("global.duplicateshint") }}<br />
            {{ $t("global.duplicateslockedhint") }}
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="closeModal">
            {{ $t("global.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
	  
	  <script>
import { modal } from "@/mixins/Modal";
import CrmApi from "@/CrmApi";

import ModalCloseButton from "@/components/global/ModalCloseButton.vue";
import { BSpinner } from "bootstrap-vue";

import DublettenEintrag from "@/components/crm/DublettenEintrag.vue";

export default {
  name: "DublettenZusammenfuehrenModal",
  components: {
    ModalCloseButton,
    BSpinner,
    DublettenEintrag,
  },
  mixins: [modal],
  props: {
    id: {
      type: String,
      default: null,
    },
    selectedIds: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      isBusy: false,
      ladeFehler: false,
      dublette: null,
    };
  },
  created() {},
  mounted() {
    this.$bus.$on("open-modal", (id) => {
      if (id === "dubletten-zusammenfuehren-modal") {
        this.$nextTick(() => {
          this.getDublette();
        });
      }
    });
  },
  computed: {},
  methods: {
    getDublette() {
      this.isBusy = true;

      let idList = this.selectedIds.join();

      CrmApi.get("person/", {
        params: { ids: idList },
      })
        .then((response) => {
          this.dublette = response?.data?.content[0];
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.ladeFehler = true;
          console.log(e);
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    eintragSchliessen() {
      this.$notify({
        type: "success",
        title: this.$t("global.actionsuccessful"),
        text: this.$t("global.duplicatemergesuccessful"),
      });
      this.closeModal();
    },
  },
};
</script>
	  
	  <style>
</style>
	  
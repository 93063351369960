import NotenBerechnung from "@/pages/Bildung/Noten/Notenberechnung";
import NotenEingabeliste from "@/pages/Dashboardlisten/Noteneingabeliste";
import NotenEingabe from "@/pages/Bildung/Noten/Noteneingabe";
import TemplateNoteneingabe from "@/pages/Bildung/Template/TemplateNoteneingabe";

export default [
  {
    path: "/noten/notenberechnung/:id/:anzeige",
    name: "noten-berechnung",
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
    component: NotenBerechnung,
  },
  {
    path: "/noten/noteneingabeliste",
    name: "noteneingabe-liste",
    component: NotenEingabeliste,
  },
  {
    path: "/noten/noteneingabe/:id/:anzeige",
    name: "noteneingabe",
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
    component: NotenEingabe,
  },
  {
    path: "/noten/template/noteneingabe/:id/:anzeige",
    name: "templateNoteneingabe",
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
    component: TemplateNoteneingabe,
  },

  /*** Muss überarbeitet werden ***/

  {
    path: "/bildung/notenstrukturliste",
    name: "Notenstrukturliste",
    component: () => import("@/pages/Bildung/Noten/Notenstrukturliste"),
  },

  {
    path: "/bildung/notenstruktur/:id",
    name: "NotenstrukturMitId",
    props: true,
    component: () => import("@/pages/Bildung/Noten/Notenstruktur"),
  },

  {
    path: "/bildung/notenberechnungsliste",
    name: "Notenberechnungsliste",
    component: () => import("@/pages/Bildung/Noten/Notenberechnungsliste"),
  },

  {
    path: "/bildung/notenberechnung/:id",
    name: "NotenberechnungMitId",
    props: true,
    component: () => import("@/pages/Bildung/Noten/Notenberechnung"),
  },

  {
    path: "/bildung/notenliste",
    name: "Notenliste",
    component: () => import("@/pages/Bildung/Noten/Notenliste"),
  },

  {
    path: "/bildung/noten/:id",
    name: "NotenMitId",
    props: true,
    component: () => import("@/pages/Bildung/Noten/Noten"),
  },
];

<template>
  <div
    class="datepicker"
    style="z-index: 2; position: relative"
    :class="{ 'd-inline-block': hidden }"
    :tabindex="isNaN(tabindex) ? parseInt(tabindex) : tabindex"
  >
    <input
      v-show="!hidden"
      v-model="date"
      placeholer=" "
      :disabled="disabled"
      v-bind:class="inputClass + 'form-control input input-with-label'"
      @blur="checkFormat"
    />
    <label
      v-if="label"
      v-bind:class="
        (date === '' || date === null ? 'label-placeholder' : '') +
        (!validation ? ' text-danger' : '')
      "
      >{{ label }}</label
    >
    <font-awesome-icon
      v-if="showIcon && (!clearable || !date)"
      icon="fa-solid fa-calendar"
    />
    <font-awesome-icon
      v-if="clearable && date"
      class="clear-button hover-medium-blue"
      icon="fa-light fa-times"
      @click="clear"
    />
  </div>
</template>
  
  <script>
import "jquery-ui/ui/widgets/datepicker";
import "jquery-ui/themes/base/all.css";

export default {
  name: "DatePicker",
  props: {
    dateFormat: { type: String, required: false, default: "yy-mm-dd" },
    initial: { type: String, required: false, default: "" },
    field: { type: String, required: false, default: "" },
    showIcon: { type: Boolean, required: false, default: false },
    label: { type: String, required: false, default: "" },
    disabled: { type: Boolean, required: false, default: false },
    hidden: { type: Boolean, default: false },
    isButton: { type: Boolean, default: false },
    buttonText: { type: String, default: "" },
    tabindex: { type: [String, Number], default: -1 },
    clearable: { type: Boolean, default: false },
    inputClass: { type: String, default: "" },
    validation: { type: Boolean, default: true },
    inModal: { type: Boolean, default: false },
  },
  data() {
    return {
      date: this.initial ? this.initial : "",
    };
  },
  watch: {
    date(val) {
      //if (val.getTime() === val.getTime()) this.$emit("update", val);
      this.$emit("update", val);
      this.$emit("updatefull", { field: this.field, value: val });
    },
    initial(val) {
      this.date = val;
    },
  },
  mounted: function () {
    var self = this;

    let datepickerConfig = {
      onSelect: function (date) {
        self.$emit("update", date);
        self.date = date;
      },
      buttonText: this.buttonText,
      dateFormat: this.dateFormat,
      autoSize: true,
      firstDay: 1,
      changeMonth: true,
      changeYear: true,
      yearRange: "1901:2099",
      dayNamesMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
      dayNamesShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
      monthNames: [
        this.$t("global.january"),
        this.$t("global.february"),
        this.$t("global.march"),
        this.$t("global.april"),
        this.$t("global.may"),
        this.$t("global.june"),
        this.$t("global.july"),
        this.$t("global.august"),
        this.$t("global.september"),
        this.$t("global.october"),
        this.$t("global.november"),
        this.$t("global.december"),
      ],
      monthNamesShort: [
        "Jan",
        "Feb",
        "Mrz",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Dez",
      ],
    };

    //Funktion behebt Positionierungsfehler in Modal, wenn auf der Seite gescrollt wurde
    if (this.inModal) {
      datepickerConfig.beforeShow = function (input, inst) {
        var rect = input.getBoundingClientRect();
        setTimeout(function () {
          var scrollTop = $("body").scrollTop();
          inst.dpDiv.css({ top: rect.top + input.offsetHeight + scrollTop });
        }, 0);
      };
    }

    if (this.isButton) {
      datepickerConfig.showOn = "button";
      datepickerConfig.buttonImage = null;
    }

    $(this.$el.childNodes[0]).datepicker(datepickerConfig);
  },
  beforeDestroy: function () {
    $(this.$el).datepicker("hide").datepicker("destroy");
  },
  methods: {
    clear() {
      this.date = "";
    },
    checkFormat() {
      if (this.date == null) this.date = "";

      if (
        this.date != "" &&
        !this.date.includes(".") &&
        !this.date.includes("/")
      ) {
        let left = this.date.substring(0, 2);
        let mid = this.date.substring(2, 4);
        let right = this.date.substring(4, 8);

        if (right.length == 2) {
          if (parseInt(right) < 30) right = "20" + right;
          else right = "19" + right;
        }

        this.date = left + "." + mid + "." + right;
      }

      this.$emit("update", this.date);
      this.$emit("updatefull", { field: this.field, value: this.date });
    },
  },
};
</script>
  
  <style lang="scss">
</style>
  
<template>
  <div>
    <container-headline :headline="$t('global.participants')" :col="6"></container-headline>

    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-2">
              <div class="form-group">
                <b-form-input
                  v-model.number="klasse.teilnehmeranzahl_minimal"
                  type="number"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                />
                <label>{{ $t("global.minimal") }}</label>
              </div>
            </div>
            <div class="col-xl-2">
              <div class="form-group">
                <ValidationProvider
                  name="maxTeilnehmer"
                  rules="min_value:1|required"
                  immediate
                  v-slot="{ passed }"
                >
                  <b-form-input
                    v-model.number="klasse.teilnehmeranzahl_maximal"
                    type="number"
                    class="form-control"
                    placeholder=" "
                    v-bind:class="{ 'border-danger': !passed }"
                    :readonly="!editable"
                  />
                  <label v-bind:class="{ 'text-danger': !passed }"
                    >{{ $t("global.maximum") }}</label
                  >
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row" v-if="!isTemplate">
            <div class="col-xl-4">
              <div class="form-group">
                <b-form-input
                  v-model="klasse.anmeldungen"
                  class="form-control"
                  readonly
                  placeholder=" "
                />
                <label
                  >{{ $t("global.registrations") }}
                  <font-awesome-icon icon="fa-duotone fa-arrow-circle-right" />
                </label>
              </div>
            </div>
            <div class="col-xl-4">
              <div class="form-group">
                <b-form-input
                  v-model="klasse.abmeldungen"
                  class="form-control"
                  readonly
                  placeholder=" "
                />
                <label
                  >{{ $t("global.cancellations") }}
                  <font-awesome-icon icon="fa-duotone fa-arrow-circle-right" />
                </label>
              </div>
            </div>
            <div class="col-xl-4">
              <div class="form-group">
                <b-form-input
                  v-model="klasse.warteliste"
                  class="form-control"
                  readonly
                  placeholder=" "
                />
                <label
                  >{{ $t("global.waitinglist") }}
                  <font-awesome-icon icon="fa-duotone fa-arrow-circle-right" />
                </label>
              </div>
            </div>
            <div class="col-xl-6"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";

import { min_value } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("min_value", min_value);

extend("checkTeilnehmer", {
  params: ["maxTeilnehmer"],
  validate: (value, { maxTeilnehmer }) => {
    if (
      maxTeilnehmer === null ||
      maxTeilnehmer === 0 ||
      value <= maxTeilnehmer
    ) {
      return true;
    }
    return false;
  },
  message:
    "Die minimale Anzahl der Teilnehmer darf nicht mehr als das Maximum {maxTeilnehmer} sein.",
});

export default {
  name: "KlasseTeilnehmer",
  components: {
    ContainerHeadline,
  },
  props: {
    isTemplate: {
      type: Boolean,
      required: true,
    },
    klasse: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss">
</style>

<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>

    <navbar v-if="showNavbar"
            :docsName="docsName"
    />

    <div class="container-fluid row">
      <div v-if="showNavbar" class="col-xl-12">
        <div class="row">
          <div class="col-xl-12 block br-t-l-0 mb-3">
            <head-menu
              :col="12"
              class="d-xl-flex"
              headline="Kompetenzraster"
            ></head-menu>
            <div class="row mt-2 d-flex justify-content-between">
              <div class="ml-4">
                <button class="btn btn-primary mr-2" @click="abbrechen">
                  <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                    $t("global.tolist")
                  }}
                </button>
              </div>
              <div class="mr-4"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- START Linke Seite -->
      <div class="col-xl-12 pr-4" :class="{ 'col-xl-12': !showNavbar }">
        <!-- START Verknüpfung -->
        <kompetenzraster :id="id" />

        <!-- ENDE Stammdaten -->
      </div>
      <!-- ENDE linke Seite -->
    </div>

    <!-- MODALS -->

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import page from "@/mixins/Page";

import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";
import Kompetenzraster from "@/components/Bildung/Kompetenzraster/Kompetenzraster.vue";

export default {
  name: "Einstellungen-Kompetenzraster",
  components: {
    HeadMenu,
    Navbar,
    Kompetenzraster,
  },
  mixins: [page],
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      editable: false,
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.raster) {
        return `Kompetenzraster`;
      } else {
        return "Neues Kompetenzraster";
      }
    },
  },
  watch: {},
  created() {},
  mounted: function () {},
  methods: {
    abbrechen() {
      this.$router.push({ name: "einstellungen-kompetenzrasterliste" });
    },
  },
};
</script>

<style>
</style>

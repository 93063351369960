<template>
  <div>
    <container-headline
      :headline="$t('global.masterdata')"
      :col="6"
    ></container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-4">
              <div class="form-group">
                <ValidationProvider
                  rules="required"
                  immediate
                  v-slot="{ passed }"
                >
                  <input
                    v-model.trim="veranstaltung.kuerzel"
                    class="form-control"
                    placeholder=" "
                    v-bind:class="{ 'border-danger': !passed }"
                    :readonly="!editable"
                  />
                  <label v-bind:class="{ 'text-danger': !passed }">{{
                    $t("global.shortdesignation")
                  }}</label>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-xl-8">
              <div class="form-group">
                <ValidationProvider
                  rules="required"
                  immediate
                  v-slot="{ passed }"
                >
                  <input
                    v-model.trim="veranstaltung.bezeichnung"
                    class="form-control"
                    placeholder=" "
                    v-bind:class="{ 'border-danger': !passed }"
                    :readonly="!editable"
                  />
                  <label v-bind:class="{ 'text-danger': !passed }">{{
                    $t("global.designation")
                  }}</label>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <v-select
                  v-model="veranstaltung.veranstaltungsstatus"
                  label="bezeichnung"
                  :options="veranstaltungsstati"
                  :disabled="isAdmin ? false : !editable"
                  :placeholder="$t('global.status')"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.status") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <v-select
                  v-model="veranstaltung.lehrmethode"
                  label="bezeichnung"
                  :options="lehrmethoden"
                  :disabled="!editable"
                  :placeholder="$t('global.teachingmethod')"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.teachingmethod") }}</label>
              </div>
            </div>
          </div>
          <div class="row" v-if="!isTemplate">
            <div class="col-xl-3">
              <div class="form-group">
                <date-picker
                  date-format="dd.mm.yy"
                  :initial="veranstaltung.startdatum"
                  placeholder=" "
                  :show-icon="true"
                  :disabled="!editable"
                  @update="(val) => (veranstaltung.startdatum = val)"
                />
                <label>{{ $t("global.start") }}</label>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="form-group">
                <date-picker
                  date-format="dd.mm.yy"
                  :initial="veranstaltung.enddatum"
                  placeholder=" "
                  :show-icon="true"
                  :disabled="!editable"
                  @update="(val) => (veranstaltung.enddatum = val)"
                />
                <label>{{ $t("global.end") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <single-select-person
                id="verantwortlicher"
                :person="veranstaltung.verantwortlicher"
                :label="$t('global.responsible')"
                :allowOpen="true"
                :editable="editable"
                :required="false"
                @confirmed="setVerantwortlicher"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <textarea
                  v-model.trim="veranstaltung.beschreibung"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                ></textarea>
                <label>{{ $t("global.description") }}</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <ValidationProvider
                  rules="required"
                  immediate
                  v-slot="{ passed }"
                >
                  <v-select
                    v-model="veranstaltung.veranstaltungstyp"
                    label="bezeichnung"
                    :options="veranstaltungstypen"
                    :disabled="!editable"
                    :placeholder="$t('global.eventtype')"
                    v-bind:class="{ 'border-danger': !passed }"
                  >
                    <span slot="no-options">{{
                      $t("global.taptosearch")
                    }}</span>
                  </v-select>
                  <label v-bind:class="{ 'text-danger': !passed }">{{
                    $t("global.eventtype")
                  }}</label>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <v-select
                  v-model="veranstaltung.periode"
                  label="bezeichnung"
                  :options="perioden"
                  :disabled="!editable"
                  :placeholder="$t('global.semester')"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.semester") }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";

import { LADE_AUSWAHLWERTE_VERANSTALTUNG } from "@/store/veranstaltung/actions.type";

import DatePicker from "@/components/Datepicker";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";

export default {
  name: "veranstaltungStammdaten",
  components: {
    ContainerHeadline,
    DatePicker,
    SingleSelectPerson,
  },
  props: {
    isTemplate: {
      type: Boolean,
      required: true,
    },

    veranstaltungstypen: {
      type: Array,
      required: false,
    },

    veranstaltung: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      kurstyp: null,
    };
  },
  watch: {
    veranstaltungsstati: function (val) {
      if (val && !this.veranstaltung.veranstaltungsstatus) {
        let veranstaltungStatusOffen = this.$CONST("VERANSTALTUNGSSTATI").OPEN;

        this.veranstaltung.veranstaltungsstatus = this.veranstaltungsstati.find(
          ({ id }) => id == veranstaltungStatusOffen
        );
      }
    },
    kurstyp: function (val) {
      this.$bus.$emit("setKurstyp", val);
    },
    periode: function (val) {
      this.veranstaltung.periode = val;
    },
  },
  computed: {
    veranstaltungsstati: {
      get() {
        return this.$store.state.veranstaltung.veranstaltungsstati;
      },
    },
    lehrmethoden: {
      get() {
        return this.$store.state.veranstaltung.lehrmethoden;
      },
    },
    perioden: {
      get() {
        return this.$store.state.veranstaltung.perioden;
      },
    },
  },
  mounted: function () {
    if (this.veranstaltungsstati.length == 0)
      this.$store.dispatch(`veranstaltung/${LADE_AUSWAHLWERTE_VERANSTALTUNG}`);

    this.isAdmin =
      this.gruppen.findIndex(
        (gruppe) => gruppe.id == this.$CONST("GRUPPEN").ADMIN
      ) == -1
        ? false
        : true;
  },
  methods: {
    setVerantwortlicher(person) {
      this.veranstaltung.verantwortlicher = person;
    },
  },
};
</script>

<style lang="scss"></style>

<template>
  <div :id="id" class="modal fade" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-pdf">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">{{ $t("global.createcalendarentry") }}</h4>
        </div>
        <div class="modal-body">
          <div class="row px-3 mb-4">
            <div class="col-12">
              {{ $t("global.calendarentryinfotext1") }}<br />{{
                $t("global.calendarentryinfotext2")
              }}
            </div>
          </div>
          <div class="row px-3">
            <div class="col-6">
              <div class="form-group">
                <date-picker
                  date-format="dd.mm.yy"
                  :class="{
                    'border border-2 border-danger border-radius-6':
                      !dateFormatIsValid(startdatum),
                  }"
                  :initial="startdatum"
                  placeholder=" "
                  :show-icon="true"
                  :inModal="true"
                  @update="(val) => (startdatum = val)"
                />

                <label
                  :class="{ 'text-danger': !dateFormatIsValid(startdatum) }"
                  >{{ $t("global.startdate") }}</label
                >
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <date-picker
                  date-format="dd.mm.yy"
                  :class="{
                    'border border-2 border-danger border-radius-6':
                      !dateFormatIsValid(enddatum),
                  }"
                  :initial="enddatum"
                  placeholder=" "
                  :show-icon="true"
                  :inModal="true"
                  @update="(val) => (enddatum = val)"
                />
                <label
                  :class="{ 'text-danger': !dateFormatIsValid(enddatum) }"
                  >{{ $t("global.enddate") }}</label
                >
              </div>
            </div>
          </div>
          <div class="row px-3">
            <div class="col-6">
              <div class="form-group">
                <label for="timeInput" :class="{ 'text-danger': !startzeit }">{{
                  $t("global.starttime")
                }}</label>
                <div class="input-group">
                  <input
                    type="time"
                    class="form-control"
                    :class="{
                      'border border-2 border-danger border-radius-6':
                        !startzeit,
                    }"
                    id="timeInput"
                    v-model="startzeit"
                  />
                  <div
                    class="input-group-append text-medium-light h-100 mr-2 d-flex align-items-center"
                  >
                    <span v-if="userTimeZoneShort" class="mr-2">{{
                      userTimeZoneShort
                    }}</span>
                    <font-awesome-icon
                      icon="fa-solid fa-clock"
                      class="m-auto"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="timeInput" :class="{ 'text-danger': !endzeit }">{{
                  $t("global.endtime")
                }}</label>
                <div class="input-group">
                  <input
                    type="time"
                    class="form-control"
                    :class="{
                      'border border-2 border-danger border-radius-6': !endzeit,
                    }"
                    id="timeInput"
                    v-model="endzeit"
                  />
                  <div
                    class="input-group-append text-medium-light h-100 mr-2 d-flex align-items-center"
                  >
                    <span v-if="userTimeZoneShort" class="mr-2">{{
                      userTimeZoneShort
                    }}</span>
                    <font-awesome-icon
                      icon="fa-solid fa-clock"
                      class="m-auto"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row px-3">
            <div class="col-12">
              <div class="form-group">
                <input
                  v-model.trim="ort"
                  class="form-control"
                  :placeholder="$t('global.calender_location')"
                />
                <label>{{ $t("global.location") }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="closeModal">
            {{ $t("global.close") }}
          </button>
          <button
            type="button"
            class="btn btn-success"
            :disabled="!formIsValid"
            @click="sendeKalendereintrag"
          >
            {{ $t("global.send") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { modal } from "@/mixins/Modal";

import GraphApi from "@/GraphApi";

import ModalCloseButton from "@/components/global/ModalCloseButton.vue";
import DatePicker from "@/components/Datepicker";

export default {
  name: "KalendereintragModal",
  components: {
    ModalCloseButton,
    DatePicker,
  },
  mixins: [modal],
  props: {
    id: {
      type: String,
      default: null,
    },
    absender: {
      type: String,
      default: null,
    },
    inhalt: {
      type: String,
      default: null,
    },
    betreff: {
      type: String,
      default: null,
    },
    empfaenger: {
      type: Array,
      default: null,
    },
    empfaengerFirma: {
      type: Array,
      default: null,
    },
    isMassMail: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      startdatum: null,
      enddatum: null,
      startzeit: null,
      endzeit: null,
      ort: "",
      userTimeZone: null,
      userTimeZoneShort: null,
    };
  },
  mounted() {
    this.getUserTimeZone();
    this.setAttendees();
  },
  computed: {
    formIsValid() {
      if (
        this.dateFormatIsValid(this.startdatum) &&
        this.dateFormatIsValid(this.enddatum) &&
        this.startzeit &&
        this.endzeit &&
        this.absender &&
        this.inhalt &&
        this.betreff &&
        this.attendees?.length > 0
      )
        return true;
      return false;
    },
    attendees() {
      //Bei Einzelmails soll das Feld "Empfaengermail" als Emfpänger verwendet werden
      if (!this.isMassMail) {
        if (this.empfaenger[0] && this.empfaenger[0] != "") {
          return [
            {
              emailAddress: { address: this.empfaenger[0], name: null },
              type: "required",
              status: null,
            },
          ];
        } else {
          return [];
        }
      }
      //Bei Massenmails werden für die Empfänger im array empfaenger bzw. firma die Mailadressen herausgesucht, welche als Hauptadresse hinterlegt sind
      else {
        let attendees = [];

        if (this.empfaenger?.length > 0) {
          this.empfaenger.forEach((empfaenger) => {
            let hauptadresse = empfaenger.mailadressen?.find(
              (mailadresse) => mailadresse.hauptadresse === true
            )?.bezeichnung;
            if (hauptadresse) {
              attendees.push({
                emailAddress: { address: hauptadresse, name: null },
                type: "required",
                status: null,
              });
            }
          });
        } else if (this.empfaengerFirma?.length > 0) {
          this.empfaengerFirma.forEach((empfaenger) => {
            let hauptadresse = empfaenger.mailadressen?.find(
              (mailadresse) => mailadresse.hauptadresse === true
            )?.bezeichnung;
            if (hauptadresse) {
              attendees.push({
                emailAddress: { address: hauptadresse, name: null },
                type: "required",
                status: null,
              });
            }
          });
        }
        return attendees;
      }
    },
  },
  methods: {
    closeModal() {
      this.shown = false;
      $("#" + this.id).modal("hide");
    },
    setAttendees() {},
    sendeKalendereintrag() {
      let json = this.buildEventJson();

      if (json.attendees.length === 0) {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.nomailadressforattandee"),
        });
      } else {
        GraphApi.post("outlook/calendar/", json, {
          params: { emailFrom: this.absender },
        })
          .then((response) => {
            if (response?.data === true) {
              this.$notify({
                type: "success",
                title: this.$t("notification.actionsuccessful"),
                text: this.$t("notification.calendarentrysuccess"),
              });
            } else {
              this.$notify({
                type: "warn",
                title: this.$t("notification.actionfailed"),
                text: this.$t("notification.sendfailed"),
              });
            }
          })
          .finally(() => {
            this.closeModal();
          });
      }
    },
    buildEventJson() {
      return {
        subject: this.betreff,
        body: {
          contentType: "HTML",
          content: this.inhalt,
        },
        start: this.getDateTimeObject(this.startdatum, this.startzeit),
        end: this.getDateTimeObject(this.enddatum, this.endzeit),
        location: { displayName: this.ort },
        locations: [],
        transactionId: "",
        allowNewTimeProposals: true,
        attendees: this.attendees,
      };
    },
    getUserTimeZone() {
      this.userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.userTimeZoneShort = new Date()
        .toLocaleDateString("en-US", {
          day: "2-digit",
          timeZoneName: "short",
        })
        .slice(4);
    },
    getDateTimeObject(datum, zeit) {
      const [tag, monat, jahr] = datum.split(".");
      const [stunde, minute] = zeit.split(":");

      const date = `${jahr}-${monat}-${tag}`;
      const time = `${stunde}:${minute}:00`;

      return { dateTime: `${date}T${time}`, timeZone: this.userTimeZone };
    },
    dateFormatIsValid(datum) {
      const regex = /^\d{2}\.\d{2}\.\d{4}$/; // Regex für dd.mm.yyyy
      return regex.test(datum);
    },
  },
};
</script>
  
  <style>
</style>
  
<template>
  <ValidationObserver v-slot="{ invalid }" :disabled="true">
    <div id="honorar-periode-modal" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <modal-close-button @confirmed="closeModal" />
            <h4 class="modal-title">Honorar Abrechnungsperiode</h4>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-xl-12">
                <div class="form-group">
                  <v-select
                    v-model="abrechnungsperiode"
                    label="bezeichnung"
                    :options="abrechnungsperioden"
                    :clearable="false"
                  ></v-select>
                  <label>Abrechnungsperioden</label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeModal">
              {{ $t("global.cancel") }}
            </button>
            <button
              type="button"
              :disabled="invalid"
              class="btn btn-primary"
              @click="uebernehmen"
            >
              Übernehmen
            </button>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import ModalCloseButton from "@/components/global/ModalCloseButton";

import { LADE_AUSWAHLWERTE_LOHN } from "@/store/lohn/actions.type";

export default {
  name: "LohnansatzModal",
  components: { ModalCloseButton },
  props: {},
  data() {
    return {
      abrechnungsperiode: null,
      shown: false,
    };
  },
  computed: {
    abrechnungsperioden() {
      return this.$store.state.lohn.abrechnungsperioden;
    },
  },
  watch: {},
  mounted() {
    this.$bus.$on("lohnansatz-modal-show", () => {
      if (!this.shown) this.openModal();
    });
  },

  methods: {
    openModal() {
      this.shown = true;

      if (this.abrechnungsperioden.length == 0)
        this.$store.dispatch(`lohn/${LADE_AUSWAHLWERTE_LOHN}`);

      $("#honorar-periode-modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    },
    closeModal() {
      this.shown = false;
      $("#honorar-periode-modal").modal("hide");
    },

    uebernehmen() {
      this.$emit("updated", this.abrechnungsperiode);
      this.closeModal();
    },
  },
};
</script>

<style>
</style>

<template>
  <div class="row mb-2">
    <div v-for="(firma, index) in firmen" :key="index" class="col-xl-6">
      <div class="form-group" style="margin-bottom: 0">
        <span
          v-if="editable && index == 0"
          v-on:click="addFirma()"
          class="multiAddIcon"
        >
          <font-awesome-icon icon="fa-regular fa-plus" />
        </span>
        <span
          v-if="editable"
          class="editIcon"
          v-on:click="oeffneFirmaSucheModal(index)"
          ><font-awesome-icon icon="fa-duotone fa-pencil-alt" />
        </span>
        <span
          v-if="editable && index > 0"
          class="minusIcon"
          v-on:click="removeFirma(index)"
          ><font-awesome-icon icon="fa-regular fa-minus" />
        </span>
        <span class="openIcon" v-on:click="openFirma(firma.id)" v-if="open"
          ><font-awesome-icon icon="fa-duotone fa-arrow-circle-right" />
        </span>
        <input v-model="firma.name" class="form-control" readonly />
        <label v-if="index == 0"
          >{{ label }}
          <font-awesome-icon icon="fa-duotone fa-arrow-circle-right" />
        </label>
      </div>
    </div>
    <firmaSucheModal
      id="firma-suche-modal"
      :filterRollen="rollen"
      @confirmed="onFirmaResult"
    ></firmaSucheModal>
  </div>
</template>

<script>
import firmaSucheModal from "@/components/Geschaeftspartner/firmaSucheModal";

export default {
  name: "MultiSelectFirma",
  components: { firmaSucheModal },
  props: {
    firmen: {
      type: Array,
      default: function () {
        return [{ firmaName: this.$t("global.notlinked") }];
      },
    },
    label: {
      type: String,
      default: "Firmen",
    },
    rollen: {
      type: Array,
      required: false,
    },
    open: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      editFirma: null,
    };
  },

  mounted: function () {
    console.log("in multi: " + this.firmen.length);
  },

  methods: {
    oeffneFirmaSucheModal(index) {
      this.editFirma = index;
      this.$bus.$emit("openFirmaModal", "firma-suche-modal");
    },
    onFirmaResult(selectedFirma) {
      if (selectedFirma) {
        let tempListe = this.firmen;
        this.$set(tempListe, this.editFirma, selectedFirma[0]);
      }
      this.$emit("confirmed", this.firmen);
    },
    addFirma() {
      if (this.firmen.length < 6) {
        let tempListe = this.firmen;
        tempListe.push({ firmaName: this.$t("global.notlinked") });
      }
    },
    removeFirma(index) {
      let tempListe = this.firmen;
      tempListe.length == 0
        ? { firmaName: this.$t("global.notlinked") }
        : tempListe.splice(index, 1);

      this.$emit("confirmed", this.firmen);
    },
    openFirma(id) {
      if (id) {
        this.$router.push({
          name: "firma",
          params: { id: id, anzeige: 0 },
        });
      }
    },
  },
};
</script>

import Comm from "@/Communication";

import { debounce } from "@/utils/Debouncer";

import InfiniteLoading from "vue-infinite-loading";

import MessageboxCount from "@/components/Modals/MessageboxCount.vue";

export default {
  components: {
    InfiniteLoading,
    MessageboxCount,
  },
  data() {
    return {
      commCall: null,
      filterId: null,
      anzahlDaten: null,
      anzahlDatenFilter: null,
      anzahlTotal: null,
      doShift: true,
      page: 1,
      anzahlProPage: 50,
      infiniteId: +new Date(),
      listData: [],
      filterAusgeloest: false,
      filters: {},
      filterValues: {},
      selectedIds: [],
      selectedObjects: [],
      dateCols: [],
      lastcommCall: null, // Sicherheitscheck für Race Condition, ob der erste Call gemacht wurde
      selectAll: null,
      langtextLoopCount: 0,
    };
  },
  watch: {
    /* filters: {
			handler: function () {
				if (this.filterId) {
					this.clearSelectedRows();
					this.listenfilter[this.filterId] = this.filters;
					if (this.lastcommCall) this.resetLoading(this);
				} else {
					console.log("Keine Filter ID gesetzt");
				}
			},
			deep: true,
		}, */
  },
  mounted() {
    this.$bus.$on("searchWithFilter", () => {
      this.filterSearch();
    });

    this.$bus.$on("resetFilter", () => {
      this.clearFilter();
    });
  },
  computed: {
    listenfilter: {
      get() {
        return this.$store.state.filter.listenfilter;
      },
      set(val) {
        this.$store.state.filter.listenfilter = val;
      },
    },

    filterCount: function () {
      var pageCount = {
        page: this.page,
        size: this.anzahlProPage,
      };
      return pageCount;
    },
  },
  methods: {
    delete(path, selectedIds) {
      Comm.delete(path, { data: selectedIds }).then((response) => {
        let success = response.data.success;
        let error = response.data.error;
        if (success && success.length != 0) {
          this.listData = this.removeElementsById(this.listData, success);
        }
        this.$bus.$emit("openMessageBoxCount", {
          id: "count-delete-alert-msgbox",
          successText: "Erfolgreich: ",
          errorText: "Fehlgeschlagen: ",
          successCount: success.length,
          errorsCount: error.length,
        });
      });
    },
    removeElementsById(listData, success) {
      success.forEach(
        (id) => (listData = listData.filter((data) => data.id != id))
      );
      return listData;
    },
    setQueryParam(value) {
      return value !== null && value !== "" ? value : null;
    },

    removeDuplicates(data, key) {
      return [...new Map(data.map((item) => [key(item), item])).values()];
    },

    setFilterDate(data) {
      this.$set(this.filters, data.field, data.value);
      this.$set(this.filterValues, data.field, data.value);
      //if (this.lastcommCall) this.resetLoading(this);
    },

    initFilter(id, commCall, doShift, defaultValues) {
      this.filterId = id;
      this.commCall = commCall;
      this.doShift = doShift;

      this.filters = this.listenfilter[id] ? this.listenfilter[id] : {};

      if (defaultValues) {
        for (const key in defaultValues) {
          this.filters[key] = defaultValues[key];
        }
      }
    },

    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((item) => {
          item.selected = true;
        });
        this.selectedObjects = this.$refs.selectableTable.items;
        this.selectedIds = this.$refs.selectableTable.items.map(
          (item) => item.id
        );

        this.anzahlDaten = this.selectedIds.length;
      } else {
        this.$refs.selectableTable.items.forEach((a) => {
          a.selected = false;
        });
        this.selectedIds = [];
        this.selectedObjects = [];
        this.anzahlDaten = 0;
      }

      this.selectAll = Date.now();
    },

    onRowSelected() {
      if (this.selectAll) {
        let timeDiff = Date.now() - this.selectAll;
        if (timeDiff < 100) return;
      }

      this.selectedObjects = this.$refs.selectableTable.items.filter(
        (item) => item.selected
      );

      this.selectedIds = this.selectedObjects.map((item) => item.id);
      this.anzahlDaten = this.selectedIds.length;
    },

    clearSelectedRows() {
      if (this.$refs.headerCheckbox)
        this.$refs.headerCheckbox.localChecked = false;
    },

    resetLoading: debounce((self) => {
      self.page = 1;
      self.listData = [];
      self.selectedIds = [];
      self.anzahlDaten = 0;
      self.selectedObjects = [];
      self.infiniteId += 1;
    }, 500),

    infiniteHandler($state) {
      if (this.commCall) {
        this.loading = true;
        let filter = { ...this.filterCount, ...this.filterValues };
        this.lastcommCall = JSON.stringify(filter);

        Comm.get(this.commCall, {
          params: filter,
        }).then((response) => {
          if (this.doShift) {
            //this.anzahlDaten = response.data[0].count;
            //response.data.shift();
          }
          if (response.data.content.length === this.anzahlProPage) {
            this.page += 1;
            this.listData.push(...response.data.content);
            this.anzahlDatenFilter = this.listData.length;
            this.anzahlTotal = {
              anzahl: this.listData.length,
              total: response.data.totalElements,
            };
            $state.loaded();
          } else {
            this.listData.push(...response.data.content);
            this.anzahlDatenFilter = this.listData.length;
            this.anzahlTotal = {
              anzahl: this.listData.length,
              total: response.data.totalElements,
            };
            $state.complete();
          }

          this.loading = false;
        });
      } else {
        console.log("Communication Call nicht gesetzt");
      }
    },

    myCompare(itemA, itemB, key) {
      if (!this.dateCols.includes(key)) {
        // Wenn die keys nicht in dem Array dateCols vorhanden, dann Sortierung wie default ausführen
        return false;
      } else {
        // Wandle das formatierte String Datum in Nummern, um es zu vergleichen
        // die zu vergleichenden Werte holen
        let a = itemA[key];
        let b = itemB[key];
        // aufsplitten in ein Array von einzelnen Parts (dd, mm, and yyyy)
        a = a.split(".");
        b = b.split(".");
        // wandle strings in numbers um
        a =
          parseInt(a[2], 10) * 10000 +
          parseInt(a[1], 10) * 100 +
          parseInt(a[0]);
        b =
          parseInt(b[2], 10) * 10000 +
          parseInt(b[1], 10) * 100 +
          parseInt(b[0]);
        // Return das verglichene Resultat
        return a - b;
      }
    },

    filterSearch() {
      if (this.filterId) {
        this.filterAusgeloest = true;
        this.filterValues = this.queryParams;
        this.clearSelectedRows();
        this.listenfilter[this.filterId] = this.filters;
        if (this.lastcommCall) this.resetLoading(this);
      } else {
        console.log("Keine Filter ID gesetzt");
      }
    },

    clearFilter() {
      this.filters = [];
      this.filterValues = {};
      if (this.filterAusgeloest) {
        this.filterAusgeloest = false;
        if (this.lastcommCall) this.resetLoading(this);
      }
    },
  },
};

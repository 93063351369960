<template>
  <div class="row">
    <notifications></notifications>
    <div class="col-xl-12">
      <container-headline class="col-xl-12" :headline="$t('global.attachments')" :col="3">
        <div class="row mt-2 d-flex justify-content-between">
          <div class="ml-4">
            <button
              class="btn btn-primary mr-2"
              @click="oeffneAttachmentModal"
              v-if="editable"
            >
              {{ $t("global.addattachments") }}
            </button>
          </div>
          <div class="ml-4 mr-4">
            <button
              class="btn btn-danger"
              @click="oeffneLoeschenModal"
              v-if="editable"
            >
              <font-awesome-icon icon="fa-duotone fa-trash" />
              <span>{{ $t("global.delete") }}</span>
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              tbody-tr-class="item"
              style="max-height: 300px"
              :items="dokumentenliste"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="isBusy"
              show-empty
              sort-icon-left
              responsive
              selectable
              select-mode="range"
              @row-dblclicked="details"
              small
              thead-tr-class="pointer"
            >
              <template v-slot:head(selected)="row">
                <b-form-checkbox
                  v-model="row.selected"
                  @input="selectAllRows(row)"
                ></b-form-checkbox>
              </template>

              <template v-slot:cell(selected)="row">
                <b-form-checkbox
                  v-model="row.item.selected"
                  @input="onRowSelected(row)"
                ></b-form-checkbox>
              </template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>{{ $t("global.errorwhileloading") }}</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>{{ $t("global.nodataavailable") }}</strong>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="attachment-loeschen-modal"
      @confirmed="attachmentloeschen"
    />

    <pdf-layer
      id="pdf-layer"
      ref="pdfLayer"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
    ></pdf-layer>

    <attachment-modal :id="id" @uploaded="updateListe" />
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import AttachmentModal from "@/components/Kommunikation/AttachmentModal.vue";
import reiter from "@/mixins/Reiter";

import server from "@/server";

export default {
  components: {
    ContainerHeadline,
    AttachmentModal,
    LoeschenModal,
    BSpinner,
  },
  mixins: [reiter],
  props: {
    id: {
      type: String,
      default: null,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      page: 0,
      anzahlProPage: 100,
      isBusy: false,
      ladeFehler: false,
      sortBy: "",
      sortDesc: false,
      pdfFileName: "",
      pdfPath: "",
      dokumentenliste: [],
      selected: [],
      selectedIds: [],
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
        { key: "name", sortable: true, label: this.$t("global.name") },
      ];
    },
  },
  watch: {
    shown(val) {
      if (val && this.dokumentenliste.length == 0) this.getAttachments();
    },
  },
  mounted() {
    if (this.shown && this.dokumentenliste.length == 0) this.getAttachments();
  },
  created() {},
  methods: {
    oeffneAttachmentModal() {
      $("#attachment-modal").modal("show");
    },

    getAttachments() {
      this.isBusy = true;

      Api.get("dokument/", {
        params: { mail: this.id },
      })
        .then((response) => {
          this.dokumentenliste = response.data;
          this.$emit("dokumenteLoaded", response.data);
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    details(dokument) {
      if (dokument.mimetype == "application/pdf") {
        this.pdfPath = server.url + dokument.dokument;
        this.$refs.pdfLayer.show();
      } else {
        this.$router.push({
          path: "/ressourcen/dokument/" + dokument.id,
        });
      }
    },

    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = true;
        });
      } else {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },

    updateListe() {
      console.log("update Liste");
      this.$emit("updateComponent");
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "attachment-loeschen-modal");
      else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nodocumentselected"),
        });
      }
    },

    attachmentloeschen() {
      this.selectedIds = Array.from(new Set(this.selectedIds));

      this.isBusy = true;

      Api.delete("dokument/", {
        params: { ids: this.selectedIds.join(",") },
      })
        .then(() => {
          this.updateListe();
          this.ladeFehler = false;
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: `${this.selectedIds.length} ${this.$t(
              "global.documentsdeleted"
            )}`,
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },
  },
};
</script>

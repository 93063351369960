<template>
  <div class="list">
    <navbar :docsName="docsName"/>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
              :showIcons="false"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="anmeldung"
                        :options="anmeldungen"
                        :placeholder="$t('global.person')"
                        :clearable="true"
                        :getOptionLabel="(person) => person.personName"
                      ></v-select>
                      <label>{{ $t("global.person") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="fach"
                        :options="faecher"
                        :placeholder="$t('global.dashboard_subjects')"
                        :clearable="true"
                        :getOptionLabel="(fach) => fach.bezeichnung"
                      ></v-select>
                      <label>{{ $t("global.dashboard_subjects") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="kuerzel"
                        :options="fach.kuerzels"
                        :placeholder="$t('global.shortdesignation')"
                        :clearable="true"
                        :getOptionLabel="(kuerzel) => kuerzel.kuerzel"
                      ></v-select>
                      <label>{{ $t("global.shortdesignation") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.presence')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row">
                  <div class="ml-4 mr-2"></div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="liste"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :busy="isBusy"
                    show-empty
                    :emptyText="
                      anmeldung.length !== 0 ||
                      fach.length !== 0 ||
                      kuerzel.length !== 0
                        ? $t('global.nodatafound')
                        : $t('global.pleaseselectfromfilter')
                    "
                    sort-icon-left
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                    thead-tr-class="pointer"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td class="no-pointer" v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:cell(abgeschlossen)="row">
                      <font-awesome-icon
                        v-if="row.item.abgeschlossen"
                        style="color: green"
                        icon="fa-solid fa-check"
                      />
                    </template>
                  </b-table>

                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner"></div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loading-overlay v-show="isBusy"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";

import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";

import { LADE_AUSWAHLWERTE_PRAESENZ } from "@/store/praesenz/actions.type"; // ka was wir hier bekommen

export default {
  name: "Praesenzliste",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.presences"); // muss evtl. definiert werden
      },
    };
  },
  mixins: [page, dashboard, filter],
  data() {
    return {
      isBusy: false,
      ladeFehler: false,
      sortBy: "bezeichnung",
      sortDesc: false,
      faecher: [],
      anmeldungsListe: [],
      anmeldungen: [],
      anmeldung: [],
      liste: [],
      praesenzen: [],
      kuerzel: [],
      fach: [],
      faecherListe: [],
      perioden: [],
      periode: null,
      fachid: null,
      oldFachid: null,
      personId: null,
    };
  },
  watch: {
    fach(newFach, oldFach) {
      newFach = newFach === null ? [] : newFach;
      oldFach = oldFach === null ? [] : oldFach;
      this.fach = this.fach === null ? [] : newFach;

      if (newFach[0] !== oldFach[0]) {
        // Bedingung funktioniert nicht
        this.kuerzel = [];
        this.fachid = null;
      }
      if (this.anmeldung.length !== 0) {
        if (newFach.length !== 0) {
          this.liste = this.praesenzen.filter(
            (el) => el.fach.kuerzel === newFach.kuerzel
          );
        } else this.liste = this.praesenzen;
      }
    },

    kuerzel(newKuerzel, oldKuerzel) {
      newKuerzel = newKuerzel === null ? [] : newKuerzel;
      oldKuerzel = oldKuerzel === null ? [] : oldKuerzel;
      this.kuerzel = this.kuerzel === null ? [] : newKuerzel;

      if (newKuerzel.id !== oldKuerzel.id) {
        this.fachid = newKuerzel.id;
        this.getCurrent();
      }
      if (this.anmeldung.length === 0) {
        this.liste = this.praesenzen.filter(
          (el) => el.fach.id === this.kuerzel.id
        );
      }
      if (newKuerzel.length === 0) {
        this.anmeldungen = this.anmeldungsListe;
      }
    },

    anmeldung(newAnmeldung) {
      this.anmeldung = this.anmeldung === null ? [] : newAnmeldung;
      newAnmeldung = newAnmeldung === null ? [] : newAnmeldung;

      if (newAnmeldung.length === 0) {
        this.personId = null;
        this.faecher = this.faecherListe;

        if (this.kuerzel.length === 0) {
          this.liste = [];
        }
      }

      if (!this.fachid) {
        this.fach = [];
        this.kuerzel = [];
        this.personId = newAnmeldung.id !== null ? newAnmeldung.id : null;
        this.getCurrent();
      }
      if (newAnmeldung.length === 0 && this.kuerzel.length !== 0) {
        this.liste = this.praesenzen.filter(
          (el) => el.fach.id === this.kuerzel.id
        );
      }
      if (newAnmeldung.length !== 0 && this.kuerzel.length !== 0) {
        this.liste = this.praesenzen.filter((el) => {
          return el.anmeldung.id === this.anmeldung.id;
        });
      }
    },
  },
  computed: {
    fields() {
      return [
        {
          key: "praesenzstatus.bezeichnung",
          sortable: false,
          searchable: false,
          label: this.$t("global.presencestatus"),
        },
        {
          key: "termin.von",
          sortable: false,
          searchable: false,
          label: this.$t("global.date"),
          formatter: (value) => {
            if (value) {
              return new Date(value).toLocaleDateString();
            } else {
              return "-";
            }
          },
        },
        {
          key: "note",
          sortable: true,
          searchable: false,
          label: this.$t("global.grade"),
          /* tdClass: "text-center",
          thClass: "text-center", */
        },
        {
          key: "fach.bezeichnung",
          sortable: true,
          searchable: false,
          label: this.$t("global.subject"),
        },
        {
          key: "fach.kuerzel",
          sortable: true,
          searchable: false,
          label: this.$t("global.shortdesignation"),
        },
        {
          key: "anmeldung.personName",
          sortable: true,
          searchable: false,
          label: this.$t("global.person"),
        },
        {
          key: "termin.bemerkung_unterricht",
          sortable: true,
          searchable: false,
          label: this.$t("global.comment"),
        },
      ];
    },
    praesenzstati: {
      get() {
        return this.$store.state.praesenz.praesenzstati; // weiss nicht was hier passiert
      },
    },
  },

  created() {},
  mounted() {
    this.$bus.$emit("searchWithFilter");
    this.getInitial();
  },
  methods: {
    getCurrent() {
      if (this.fachid || this.personId) {
        this.isBusy = true;
        this.loading = true;

        Api.get("geschaeftspartner/praesenzen/", {
          params: {
            /* fachBezeichnung: this.fach.bezeichnung,
          kuerzel: this.kuerzel.kuerzel, */
            fachId: this.fachid,
            personId: this.personId,
            page: this.page,
            count: this.anzahlProPage,
          },
        })
          .then((response) => {
            // hier werden die Daten für die Tabelle initialisiert
            this.praesenzen = response.data.praesenzen;
            this.liste = this.praesenzen;
            if (this.personId) {
              this.faecher = response.data.faecher;
              this.kuerzels = response.data.faecher.kuerzel;
            }

            if (response.data.anmeldungen.length !== 0) {
              this.anmeldungen = response.data.anmeldungen;
            }

            this.ladeFehler = false;
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
            this.ladeFehler = true;
          })
          .finally(() => {
            this.isBusy = false;
          });
      }
    },
    getInitial() {
      if (this.anmeldungen.length === 0 || this.faecher.length === 0) {
        this.isBusy = true;
        this.loading = true;

        Api.get("geschaeftspartner/praesenzen/", {
          // Es wird entweder die fachid oder die personid mitgegeben
          params: {
            fachId: this.fachid,
            personId: this.personId,
            page: this.page,
            count: this.anzahlProPage,
          },
        })
          .then((response) => {
            // hier werden die Daten für die Tabelle initialisiert
            this.faecherListe = response.data.faecher;
            this.faecher = this.faecherListe;

            this.anmeldungsListe = response.data.anmeldungen;
            this.anmeldungen = this.anmeldungsListe;

            this.ladeFehler = false;
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
            this.ladeFehler = true;
          })
          .finally(() => {
            this.isBusy = false;
          });
      }
    },

    // on dblclick auf einen Datensatz passiert:
    details(praesenz) {
      this.$router.push({
        name: "anwesenheit",
        params: {
          vid: praesenz.fach.id,
          tid: praesenz.termin.id,
        },
      });
    },

    /* details(praesenz) {
      // hier router push zu geschaeftspartner/personId/16
      this.$router.push({
        name: "anwesenheit",
        params: {
          id: praesenz.id,
        },
      });
    }, */

    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = true;
        });
      } else {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = false;
        });
        this.selectedAnmeldungIds = [];
      }
      this.$forceUpdate();
    },

    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedAnmeldungIds.push(row.item.id);
      } else {
        this.selectedAnmeldungIds = this.selectedAnmeldungIds.filter(
          (id) => id != row.item.id
        );
      }
    },
  },
};
</script>

<style></style>

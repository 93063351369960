import {
    SET_MAHNSTUFE_STATI,
    SET_ZAHLUNG_STATI,
    SET_BUCHUNG_STATI,
    SET_ZAHLUNGSPLAENE,
} from "./mutations.type";

import { LADE_AUSWAHLWERTE_DEBITOR } from "./actions.type";

import Api from "../../Api";

export default {
    namespaced: true,
    state: {
        mahnstufestati: [],
        zahlungstati: [],
        buchungsstati: [],
        zahlungsplaene: [],
    },
    mutations: {
        [SET_MAHNSTUFE_STATI](state, val) {
            state.mahnstufestati = val;
        },
        [SET_ZAHLUNG_STATI](state, val) {
            state.zahlungstati = val;
        },
        [SET_BUCHUNG_STATI](state, val) {
            state.buchungsstati = val;
        },
        [SET_ZAHLUNGSPLAENE](state, val) {
            state.zahlungsplaene = val;
        },
    },
    actions: {
        async [LADE_AUSWAHLWERTE_DEBITOR](context) {
            let response = await Api.get("finanzen/zahlschuld/auswahlwerte/");
            context.commit(SET_MAHNSTUFE_STATI, response.data.mahnstufestati);
            context.commit(SET_ZAHLUNG_STATI, response.data.zahlungstati);
            context.commit(SET_BUCHUNG_STATI, response.data.buchungsstati);
            context.commit(SET_ZAHLUNGSPLAENE, response.data.zahlungsplaene);
        },
    }
};

<template>
  <div class="col-xl-12 pr-4">
    <!--Start PERSON -->
    <container-headline
      v-if="mail.empfaenger.length > 0"
      :headline="$t('global.receiver') + ' (' + mail.empfaenger.length + ')'"
      :col="3"
    >
      <div class="row d-flex">
        <div class="ml-4 mt-3">
          <button
            class="btn btn-success mr-2"
            v-if="editable"
            @click="oeffneGPSucheModal"
          >
            <font-awesome-icon icon="fa-regular fa-plus" />
          </button>
        </div>
        <div class="mr-2 mt-3">
          <button
            class="btn btn-danger"
            @click="oeffneLoeschenModal"
            v-if="editable"
          >
            <font-awesome-icon icon="fa-regular fa-minus" />
          </button>
        </div>

        <div class="col-xl-2">
          <div class="form-group">
            <date-picker
              :disabled="!editable"
              :initial="mail.versanddatum"
              date-format="dd.mm.yy"
              placeholer=" "
              :show-icon="true"
              @update="(val) => setMailVersanddatum(val)"
            />
            <label>{{ $t("global.shippingdate") }}</label>
          </div>
        </div>
        <div class="col-xl-2">
          <div class="form-group">
            <input
              v-model.trim="mail.versandzeit"
              class="form-control"
              :readonly="!editable"
              placeholder="HH:MM"
            />
            <label>{{ $t("global.shippingtime") }}</label>
          </div>
        </div>

        <div class="col-xl-2" v-if="mail.isVorlage">
          <div class="form-group">
            <ValidationProvider rules="required" immediate v-slot="{ passed }">
              <v-select
                v-model="mail.defaultSprache"
                v-bind:class="{ 'border-danger': !passed }"
                label="kuerzel"
                :options="langs"
                :disabled="!editable"
                :placeholder="$t('global.defaultlanguage')"
                :selectable="
                  (lang) =>
                    mail.translation[lang.kuerzel].text &&
                    mail.translation[lang.kuerzel].betreff
                "
                :reduce="(lang) => lang.id"
              >
                <span slot="no-options">{{ $t("global.taptosearch") }}</span>
              </v-select>
              <label v-bind:class="{ 'text-danger': !passed }">{{
                $t("global.defaultlanguage")
              }}</label>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </container-headline>
    <div
      v-if="mail.empfaenger.length > 0"
      class="row block br-t-l-0 mb-3 p-2"
      style="height: 200px; overflow-y: auto"
      :key="receiverKey"
    >
      <div
        v-for="(item, index) in mail.empfaenger"
        v-bind:key="`empfaenger-${index}`"
        class="col-xl-2 p-1"
      >
        <div
          @contextmenu.prevent="openReceiver(item)"
          @click="selectReceiver(item)"
          class="p-1 d-flex align-items-center"
          :style="
            'background-color: ' +
            (item.selected
              ? 'lightgreen; color:white; font-weight: bold'
              : 'white') +
            ';cursor: pointer; font-size: 10pt'
          "
        >
          <span class="mr-auto">{{ item.vorname + " " + item.name }}</span>
          <font-awesome-icon
            v-tooltip.hover
            :title="$t('global.nomainemailadressfound')"
            v-if="!mailadresseVorhanden(item)"
            icon="fa-duotone fa-exclamation-triangle"
            class="mr-1 text-danger fs-17 pr-2"
          />
        </div>
      </div>
    </div>
    <!--ENDE PERSON -->
    <!--Start Firma -->
    <container-headline
      v-if="mail.firma.length > 0"
      :headline="$t('global.receiver') + ' (' + mail.firma.length + ')'"
      :col="3"
    >
      <div class="row mt-2 d-flex">
        <div class="ml-4">
          <button
            class="btn btn-success mr-2"
            v-if="editable"
            @click="oeffneFirmaSucheModal"
          >
            <font-awesome-icon icon="fa-regular fa-plus" />
          </button>
        </div>
        <div class="mr-2">
          <button
            class="btn btn-danger"
            @click="oeffneLoeschenModal"
            v-if="editable"
          >
            <font-awesome-icon icon="fa-regular fa-minus" />
          </button>
        </div>
      </div>
    </container-headline>
    <div
      v-if="mail.firma.length > 0"
      class="row block br-t-l-0 mb-3 p-2"
      style="height: 200px; overflow-y: auto"
      :key="receiverKey"
    >
      <div
        v-for="(item, index) in mail.firma"
        v-bind:key="`firma-${index}`"
        class="col-xl-2 p-1"
      >
        <div
          @click="selectReceiverFirma(item)"
          @contextmenu.prevent="openReceiverFirma(item)"
          class="p-1 d-flex align-items-center"
          :style="
            'background-color: ' +
            (item.selected
              ? 'lightgreen; color:white; font-weight: bold'
              : 'white') +
            ';cursor: pointer; font-size: 10pt'
          "
        >
          <span>{{ item.name }}</span>
          <font-awesome-icon
            v-tooltip.hover
            title="Keine Hauptmailadresse gefunden"
            v-if="!mailadresseVorhanden(item)"
            icon="fa-duotone fa-exclamation-triangle"
            class="ml-auto text-danger fs-17 pr-2"
          />
        </div>
      </div>
    </div>
    <!--ENDE Firma -->
    <gpSucheModal
      v-if="mail.empfaenger.length > 0"
      :id="modalId"
      @confirmed="onGPResult"
    ></gpSucheModal>
    <loeschen-modal
      v-if="mail.empfaenger.length > 0"
      id="empfaenger-loeschen-modal"
      @confirmed="empfaengerLoeschen"
      :multiple="true"
    />

    <firmaSucheModal
      v-if="mail.firma.length > 0"
      :id="modalId"
      @confirmed="onFirmaResult"
    ></firmaSucheModal>
    <loeschen-modal
      v-if="mail.firma.length > 0"
      id="empfaenger-loeschen-modal"
      @confirmed="firmaLoeschen"
      :multiple="true"
    />
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";
import gpSucheModal from "@/components/Geschaeftspartner/gpSucheModal";
import firmaSucheModal from "@/components/Geschaeftspartner/firmaSucheModal";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import { v4 as uuid } from "uuid";
import store from "@/store";
import DatePicker from "@/components/Datepicker";

export default {
  name: "MassenMailSenderEmpfaenger",
  components: {
    ContainerHeadline,
    gpSucheModal,
    LoeschenModal,
    firmaSucheModal,
    DatePicker,
  },
  mixins: [],
  store,
  props: {
    mail: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    langs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filterMitarbeitende: this.$CONST("ROLLEN").MITARBEITER,
      sortBy: "personName",
      receiverKey: uuid(),
      sortDesc: false,
      selected: [],
      selectedIds: [],
      selectedEmpfaenger: [],
      recipients: [],
      modalId: "EmpfaengerHinzufuegen",
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "personName",
          sortable: true,
          label: this.$t("global.receiver"),
        },
        {
          key: "mailadressen[0].bezeichnung",
          sortable: true,
          label: this.$t("global.mailaddress"),
        },
      ];
    },
  },
  watch: {},

  created() {
    this.mail.empfaengermail = null;
  },

  mounted() {},

  methods: {
    openReceiver(receiver) {
      this.$router.push({
        name: "geschaeftspartner",
        params: { id: receiver.id, anzeige: 0 },
      });
    },
    selectReceiver(receiver) {
      let realItem = this.mail.empfaenger.find(
        (item) => item.id == receiver.id
      );
      //realItem.selected = receiver.selected ? false : true;
      this.$set(realItem, "selected", receiver.selected ? false : true);

      //   this.selectedIds = this.mail.empfaenger
      //     .filter((item) => item.selected)
      //     .map((item) => item.id);
      // this.receiverKey = uuid();

      if (this.selectedIds.includes(receiver.id)) {
        let index = this.selectedIds.indexOf(receiver.id);
        this.selectedIds.splice(index, 1);
      } else {
        this.selectedIds.push(receiver.id);
      }

      this.receiverKey = uuid();

      /*var id = this.selectedIds;
    var temp = this.mail.empfaenger.filter(function(temp){ return temp.id == id;} ).pop();

    this.mail.selectedEmpfaenger = temp;
    
    //this.mail.empfaenger = temp;
    this.recipients.push(temp.mailadressen[0].bezeichnung)
    console.log(temp.mailadressen[0].bezeichnung);*/
      if (!this.recipients.includes(receiver.mailadressen[0]?.bezeichnung)) {
        this.recipients.push(receiver.mailadressen[0]?.bezeichnung);
      } else {
        for (var i = 0; i < this.recipients.length; i++) {
          if (this.recipients[i] === receiver.mailadressen[0]?.bezeichnung) {
            this.recipients.splice(i, 1);
          }
        }
      }
      this.mail.empfaengermail = this.recipients.join(", ");

      //Wozu ist dieser emit? durch das ändern des Keys wird das component neu gerendert und alle variablen werden neu leer initialisiert.
      //this.$emit("updated");
    },

    openReceiverFirma(receiver) {
      this.$router.push({
        name: "firma",
        params: { id: receiver.id, anzeige: 0 },
      });
    },

    selectReceiverFirma(receiver) {
      let realItem = this.mail.firma.find((item) => item.id == receiver.id);
      realItem.selected = receiver.selected ? false : true;

      this.selectedIds = this.mail.firma
        .filter((item) => item.selected)
        .map((item) => item.id);
      this.receiverKey = uuid();

      /*var id = this.selectedIds;
    var temp = this.mail.empfaenger.filter(function(temp){ return temp.id == id;} ).pop();

    this.mail.selectedEmpfaenger = temp;
    
    //this.mail.empfaenger = temp;
    this.recipients.push(temp.mailadressen[0].bezeichnung)
    console.log(temp.mailadressen[0].bezeichnung);*/
      if (!this.recipients.includes(receiver.mailadressen[0]?.bezeichnung)) {
        this.recipients.push(receiver.mailadressen[0]?.bezeichnung);
      } else {
        for (var i = 0; i < this.recipients.length; i++) {
          if (this.recipients[i] === receiver.mailadressen[0]?.bezeichnung) {
            this.recipients.splice(i, 1);
          }
        }
      }

      //Wozu ist dieser emit? durch das ändern des Keys wird das component neu gerendert und alle variablen werden neu leer initialisiert.
      //this.$emit("updated");
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0) {
        this.$bus.$emit("openLoeschenModal", "empfaenger-loeschen-modal");
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noreceiverselected"),
        });
      }
    },
    empfaengerLoeschen() {
      this.selectedIds.forEach((id) => {
        this.mail.empfaenger = this.mail.empfaenger.filter(
          (empfaenger) => empfaenger.id != id
        );
      });
      this.receiverKey = uuid();
    },

    firmaLoeschen() {
      this.selectedIds.forEach((id) => {
        this.mail.firma = this.mail.firma.filter((firma) => firma.id != id);
      });
      this.receiverKey = uuid();
    },

    oeffneGPSucheModal() {
      this.$bus.$emit("openGeschaeftspartnerModal", this.modalId);
    },

    oeffneFirmaSucheModal() {
      this.$bus.$emit("openFirmaModal", this.modalId);
    },

    onGPResult(selectedPerson) {
      if (selectedPerson) {
        this.mail.empfaenger.push(selectedPerson[0]);

        this.mail.empfaenger.sort((a, b) => {
          return a.personName > b.personName;
        });

        this.receiverKey = uuid();

        this.$notify({
          type: "success",
          title: this.$t("global.actionsuccessful"),
          text: this.$t("global.receiversuccessfullyadded"),
        });
      }
    },

    onFirmaResult(selectedFirma) {
      if (selectedFirma) {
        this.mail.firma.push(selectedFirma[0]);

        this.mail.firma.sort((a, b) => {
          return a.name > b.name;
        });

        this.receiverKey = uuid();

        this.$notify({
          type: "success",
          title: this.$t("global.actionsuccessful"),
          text: this.$t("global.receiversuccessfullyadded"),
        });
      }
    },
    setAbsender(absender) {
      this.mail.absender = absender;

      if (absender.mailadressen.length > 0)
        this.mail.absendermail = absender.mailadressen[0].bezeichnung;
    },
    mailadresseVorhanden(item) {
      let hauptmailadresse = item.mailadressen.find(
        (adr) => adr.hauptadresse === true
      );

      let kommunikationsadresse = item.kommunikationsmail

      if (hauptmailadresse){
        return true;
      } else if (kommunikationsadresse){
        return true;
      }
      return false;
    },
    setMailVersanddatum(val) {
      this.$set(this.mail, "versanddatum", val);
    },
  },
};
</script>

<style></style>

import {
    SET_QUESTIONTYPES
  } from "./mutations.type";
  
  import { LADE_AUSWAHLWERTE_QUESTIONAIRE } from "./actions.type";
  
  import QuestionaireApi from "../../QuestionaireApi";
  
  export default {
    namespaced: true,
    state: {
      questiontypes: [],
    },
    mutations: {
      [SET_QUESTIONTYPES](state, val) {
        state.questiontypes = val;
      },
      },

    actions: {
      async [LADE_AUSWAHLWERTE_QUESTIONAIRE](context) {
        let response = await QuestionaireApi.get("questiontype/");
        context.commit(
            SET_QUESTIONTYPES,
          response.data.content
        );
      },
    },
  };
  
export default {
  hf: "174D91487DA",
  hfp: "174D91487DC",
  nds: "174D91487E2",
  kurse: "174D91487E8,174D91487E0",
  klasse: "174D91487DA",
  faecher: "174D91487D4",
  bildungsgang: "175B1B2A92D",
  event: "174D91487D8",
  infoanlass: "174D91487E6"
};

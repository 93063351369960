<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">{{ $t("global.splitinvoice") }}</h4>
        </div>
        <div class="modal-body">
          <div class="row ml-0 mr-0">
            <div class="col-md-12">
              <div class="row">
                <div class="col-12">
                  <container-headline
                    headline="Positionen"
                    :col="18"
                  ></container-headline>
                  <!-- hier: Positionen zum splitten anzeigen start-->
                  <div class="row">
                    <div class="col-xl-12 block modal-liste br-t-l-0">
                      <div>
                        <b-table
                          responsive
                          sticky-header
                          tbody-tr-class="item"
                          :items="positionen"
                          :fields="fields"
                        >
                          <template v-slot:cell(anteil)="row">
                            <b-form-input
                              v-model="row.item.anteil"
                              :on-change="checkAnteil(row.index)"
                              size="sm"
                            ></b-form-input>
                          </template>
                        </b-table>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12">
                      <container-headline
                        :headline="$t('global.secondinvoiceparty')"
                        :col="18"
                      ></container-headline>
                      <div class="row mb-3">
                        <div class="col-xl-12 block br-t-l-0">
                          <div class="eingabe-panel-kopf p-3">
                            <div class="row mt-2 mb-3">
                              <div class="col-xl-6">
                                <single-select-person
                                  class="mt-3"
                                  id="personRechnungsplit"
                                  :person="personRechnungsplit"
                                  :allowOpen="false"
                                  :editable="true"
                                  :required="true"
                                  @confirmed="setPersonRechnungsplit"
                                  :label="$t('global.personinvoicesplit')"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeModal">
              {{ $t("global.cancel") }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="rechnungsplitErstellen"
            >
              {{ $t("global.createinvoicesplit") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { modal } from "@/mixins/Modal";
import Api from "@/Api";
import ContainerHeadline from "@/components/ContainerHeadline";
import { LADE_ARTIKEL } from "@/store/artikel/actions.type";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import ModalCloseButton from "@/components/global/ModalCloseButton.vue";

import numeral from "numeral";

export default {
  name: "RechnungsplitModal",
  mixins: [modal],
  components: {
    ContainerHeadline,
    SingleSelectPerson,
    ModalCloseButton
  },
  props: {
    positionen: {
      type: Array,
      default: null,
    },
    auftrag: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      personRechnungsplit: {},
      invalidFields: {},
      positionId: null,
    };
  },
  computed: {
    fields() {
      return [
        { key: "bezeichnung", sortable: true, label: this.$t("global.item") },
        {
          key: "total",
          sortable: true,
          label: this.$t("global.totalgross"),
          formatter: (value) => {
            if (value) {
              return numeral(value).format("#,##0.00");
            } else {
              return "-";
            }
          },
        },
        {
          key: "anteil",
          sortable: true,
          label: this.$t("global.shareinpercent"),
        },
      ];
    },
  },
  watch: {
    position(val) {
      if (val) this.inititalizePosition(val);
      else this.clear();
    },
  },
  async created() {
    await this.$store.dispatch(`artikel/${LADE_ARTIKEL}`);
  },
  methods: {
    speichern() {
      if (this.positionId) {
        Api.put("auftrag/position/", this.positionToJson(), {
          params: { id: this.positionId },
        }).then((response) => {
          this.$emit("position-changed", response.data);
          this.clear();
          this.hide();
        });
      } else {
        Api.post("auftrag/position/", this.positionToJson()).then(
          (response) => {
            this.$emit("position-created", response.data);
            this.clear();
            this.hide();
          }
        );
      }
    },
    checkAnteil(index) {
      let val = this.positionen[index].anteil;
      this.invalidFields["anteil" + index] = false;
      if (val == undefined || val == "") this.positionen[index].anteil = 0;
      else if (val < 0 || val > 100) {
        this.positionen[index].invalid = false;
        this.invalidFields["anteil" + index] = true;
      }
    },
    rechnungsplitErstellen() {
      if (
        this.personRechnungsplit.id &&
        this.positionen.some((el) => el.anteil != 0)
      ) {
        Api.post("auftrag/rechnungsplit/", this.positionen, {
          params: {
            personRechnungsplit: this.personRechnungsplit.id,
            auftragId: this.auftrag.id,
          },
        }).then(() => {
          this.$emit("confirmed");
          this.closeModal();
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.invoicesplitcreated"),
          });
        });
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nopersonselectedornosharespecified"),
        });
      }
    },
    abbrechen() {
      this.clear();
      this.hide();
    },
    calculatePreise() {
      if (this.preis) {
        this.totalNetto = this.preis * this.anzahl - (this.rabattbetrag || 0);
        if (
          this.mehrwertsteuercodeObject &&
          this.mehrwertsteuercodeObject.mehrwertsteuersatz
        ) {
          this.totalMwst =
            (this.totalNetto *
              this.mehrwertsteuercodeObject.mehrwertsteuersatz
                .mehrwertsteuersatz) /
            100;
          this.totalBrutto = this.totalNetto + this.totalMwst;
        } else {
          this.totalMwst = 0;
          this.totalBrutto = this.totalNetto;
        }
      } else {
        this.totalNetto = 0;
        this.totalMwst = 0;
        this.totalBrutto = 0;
      }
    },
    calculateRabattBetrag() {
      if (this.rabattProzent && this.preis) {
        this.rabattbetrag =
          (this.preis * this.anzahl * this.rabattProzent) / 100;
      } else {
        this.rabattbetrag = 0;
      }
    },
    closeModal() {
      this.shown = false;
      $("#rechnungsplit-modal").modal("hide");
    },
    setPersonRechnungsplit(personRechnungsplit) {
      this.personRechnungsplit = personRechnungsplit;
    },
  },
};
</script>

<style></style>

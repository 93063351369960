<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="'Transcript of records'"
        :col="3"
      >
        <div class="row mt-2 d-flex">
          <div class="col-3 ml-4">
            <div class="form-group p-0">
              <v-select
                v-model.trim="filterDegree"
                :options="abschluesse"
                :reduce="(s) => s.id"
                label="designation"
                :placeholder="$t('global.degree')"
              >
                <span slot="no-options">{{ $t("global.nodegreefound") }}</span>
              </v-select>
            </div>
          </div>
          <div class="col-3 ml-4">
            <div class="form-group p-0">
              <v-select
                v-model.trim="filterModul"
                :options="module"
                :reduce="(s) => s.id"
                label="display"
                :placeholder="$t('global.module')"
              >
                <span slot="no-options">{{ $t("global.nomodulefound") }}</span>
              </v-select>
            </div>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <div>
              <b-table
                ref="selectableTable"
                tbody-tr-class="item"
                style="max-height: 300px"
                responsive
                sticky-header
                :items="torgrades"
                :fields="fields"
                :busy="isBusy"
                show-empty
                sort-icon-left
                selectable
                select-mode="range"
                thead-tr-class="pointer"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>

                <template #empty>
                  <div v-if="ladeFehler" class="text-center text-danger my-2">
                    <strong>{{ $t("global.errorwhileloading") }}</strong>
                  </div>
                  <div v-if="!ladeFehler" class="text-center text-primary my-2">
                    <strong>{{ $t("global.nodataavailable") }}</strong>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tor from "@/Tor";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";

import reiter from "@/mixins/Reiter";

export default {
  name: "Torliste",
  components: {
    ContainerHeadline,
    BSpinner,
  },
  mixins: [reiter],
  props: {
    person: {
      type: String,
      required: false,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      page: 0,
      anzahlProPage: 200,
      isBusy: false,
      ladeFehler: false,
      filterDegree: null,
      filterModul: null,
      filterPromotionsblock: null,
      abschluesse: [],
      torgradesFiltered: [],
      defaultTorGrades: [],
      module: [],

      torgrades: [],
      tor: [],
      activeDegreeIndex: 0,
    };
  },
  watch: {
    shown(val) {
      if (val && this.torgrades.length == 0) this.getTor();
    },
    filterDegree(val) {
      if (val) {
        this.filterModul = null;
        this.torgrades = this.tor.find(
          (tor) => tor.degree.id == this.filterDegree
        ).tor_grades;
      } else if (!this.filterModul) {
        this.setTorGrades();
      }
    },
    filterModul(val) {
      if (val) {
        this.filterDegree = null;
        this.torgrades = this.defaultTorGrades.filter(
          (torgrade) => torgrade.subject.id == val
        );
      } else if (!this.filterDegree) {
        this.setTorGrades();
      }
    },
  },
  computed: {
    fields() {
      return [
        {
          key: "subject.short",
          sortable: true,
          searchable: false,
          label: this.$t("global.moduleshorthand"),
        },
        {
          key: "subject.designation",
          sortable: true,
          searchable: false,
          label: this.$t("global.module"),
        },
        {
          key: "timestamp",
          sortable: true,
          searchable: false,
          label: this.$t("global.createdon"),
        },
        {
          key: "subject.cp",
          sortable: true,
          searchable: false,
          label: this.$t("global.possibleCP"),
        },
        {
          key: "cp",
          sortable: true,
          searchable: false,
          label: this.$t("global.reachedCP"),
        },
        {
          key: "definitive_grade",
          sortable: true,
          searchable: false,
          label: this.$t("global.grade"),
        },
        {
          key: "passed",
          sortable: true,
          searchable: false,
          label: this.$t("global.modulegraduation"),
          formatter: (value) => {
            if (value) return this.$t("global.passed");
            else return this.$t("global.educationcourse_failed");
          },
          tdClass: function (value) {
            if (value) {
              return "rounded-0 bg-success-light";
            } else {
              return "rounded-0 bg-danger-light";
            }
          },
        },
      ];
    },
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };
      return params;
    },
  },
  mounted() {
    if (this.shown && this.torgrades.length == 0) this.getTor();
  },
  created() {},
  methods: {
    setFilterModul() {
      this.torgradesFiltered = this.torgrades.filter(
        (torgrade) => torgrade.subject.id == this.filterModul
      );
    },
    getTor() {
      this.isBusy = true;
      Tor.get("torgrades/", { params: { student: this.person } })
        .then(async (response) => {
          this.tor = response.data;

          await this.notenAnzeigeFormatieren();

          this.tor.forEach((data) => {
            this.abschluesse.push(data.degree);
            data.tor_grades.forEach((torgrade) => {
              this.defaultTorGrades.push(torgrade);
              this.module.push(torgrade.subject);
            });
          });

          //Duplikate in module entfernen:
          this.module = [
            ...new Map(this.module.map((m) => [m.id, m])).values(),
          ];

          this.setTorGrades();

          this.ladeFehler = false;
        })
        .catch((e) => {
          this.ladeFehler = true;
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    setTorGrades() {
      this.torgrades = [];
      this.defaultTorGrades.forEach((dtorgrade) => {
        this.torgrades.push(dtorgrade);
      });
    },
    notenAnzeigeFormatieren() {
      //Noten mit Nachkommastellen anzeigen (Konvertiert Number zu String)

      this.tor.forEach((tor) => {
        tor.tor_grades.forEach((torGrade) => {
          torGrade.tor_grade =
            torGrade.tor_grade == 0 ? "-" : torGrade.tor_grade.toFixed(1);
          torGrade.manual_grade =
            torGrade.manual_grade == 0 ? "-" : torGrade.manual_grade.toFixed(1);
          torGrade.definitive_grade =
            torGrade.definitive_grade == 0
              ? "-"
              : torGrade.definitive_grade.toFixed(1);
        });
      });
    },
  },
};
</script>

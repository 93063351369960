<template>
  <div id="lead-kontakt-bearbeiten-modal" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">
            {{
              editKontakt.id
                ? $t("global.editcontact")
                : $t("global.addcontact")
            }}
          </h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xl-6">
              <single-select-firma
                id="kontaktperson-firma"
                :firma="kontaktFirma"
                editable
                :allowOpen="true"
                :label="$t('global.contactcompany')"
                @confirmed="setFirma"
                :tabindex="1"
              />
            </div>
            <div class="col-xl-6">
              <single-select-person
                id="kontaktperson"
                :person="kontaktPerson"
                :firma="kontaktFirma"
                :label="$t('global.contactperson')"
                :allowOpen="false"
                editable
                @confirmed="setPerson"
                :tabindex="2"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <v-select
                  v-model="editKontakt.rolle"
                  label="bezeichnung"
                  :options="rollen"
                  :placeholder="$t('global.role')"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.role") }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="closeModal">
            {{ $t("global.cancel") }}
          </button>
          <button type="button" class="btn btn-primary" @click="uebernehmen">
            {{ $t("global.apply") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalCloseButton from "@/components/global/ModalCloseButton";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import SingleSelectFirma from "@/components/global/SingleSelectFirma.vue";

export default {
  name: "LeadKontaktModal",
  components: { ModalCloseButton, SingleSelectPerson, SingleSelectFirma },
  props: {},
  data() {
    return {
      kontaktPerson: {},
      kontaktFirma: {},
      editKontakt: { person: {}, rolle: {} },
      neuerKontakt: false,
      shown: false,
    };
  },
  computed: {
    rollen() {
      if (this.$store) return this.$store.state.crm.kontaktrollen;
      return [];
    },
  },
  watch: {},
  mounted() {
    this.$bus.$on("lead-kontakt-modal-show", (data) => {
      this.editKontakt = Object.assign({}, data);
      this.kontaktPerson = this.editKontakt.person;
      this.kontaktFirma = this.editKontakt.firma;
      this.neuerKontakt = data ? false : true;

      if (!this.shown) this.openModal();
    });
  },
  methods: {
    setPerson(person) {
      this.kontaktPerson = person;
    },

    setFirma(firma) {
      this.kontaktFirma = firma;
    },

    openModal() {
      this.shown = true;

      $("#lead-kontakt-bearbeiten-modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    closeModal() {
      this.shown = false;
      $("#lead-kontakt-bearbeiten-modal").modal("hide");
    },

    uebernehmen() {
      this.editKontakt.person = this.kontaktPerson;
      this.editKontakt.firma = this.kontaktFirma;
      if (this.neuerKontakt) {
        this.$emit("kontaktCreated", this.editKontakt);
      } else {
        this.$emit("kontaktChanged", this.editKontakt);
      }
      this.closeModal();
    },
  },
};
</script>

<style></style>

import {
  //SET_ABSENDER,
  SET_VERARBEITUNGS_STATI,
  SET_VERSAND_LISTEN,
  SET_ARCHIVIERUNGS_STATI,
  SET_KORRESPONDENZ_KATEGORIEN,
  SET_UNTERSCHRIFTEN,
  SET_KORRESPONDENZ_VORLAGEN,
  SET_MAILVORLAGE_TYPEN,
  SET_MAIL_VORLAGEN,
  SET_NOTIZ_KONTAKTARTEN,
  SET_NOTIZ_KONTAKTKATEGORIEN
} from "./mutations.type";

import {
  LADE_AUSWAHLWERTE_KORRESPONDENZ,
  LADE_VERSANDLISTEN,
  LADE_KORRESPONDENZ_VORLAGEN,
  LADE_AUSWAHLWERTE_MAIL,
  LADE_MAIL_VORLAGEN,
  LADE_AUSWAHLWERTE_NOTIZEN
} from "./actions.type";

import Api from "../../Api";

export default {
  namespaced: true,
  state: {
    //absender: [],
    verarbeitungsstati: [],
    versandlisten: [],
    archivierungsstati: [],
    kategorien: [],
    unterschriften: [],
    vorlagen: [],
    mailvorlagetypen: [],
    mailvorlagen: [],
    kontaktarten: [],
    kontaktkategorien: []
  },
  mutations: {
    /*
    [SET_ABSENDER](state, val) {
      state.absender = val;
    },
    */
    [SET_VERARBEITUNGS_STATI](state, val) {
      state.verarbeitungsstati = val;
    },
    [SET_VERSAND_LISTEN](state, val) {
      state.versandlisten = val;
    },
    [SET_ARCHIVIERUNGS_STATI](state, val) {
      state.archivierungsstati = val;
    },
    [SET_KORRESPONDENZ_KATEGORIEN](state, val) {
      state.kategorien = val;
    },
    [SET_UNTERSCHRIFTEN](state, val) {
      state.unterschriften = val;
    },
    [SET_KORRESPONDENZ_VORLAGEN](state, val) {
      state.vorlagen = val;
    },
    [SET_MAILVORLAGE_TYPEN](state, val) {
      state.mailvorlagetypen = val;
    },
    [SET_MAIL_VORLAGEN](state, val) {
      state.mailvorlagen = val;
    },
    [SET_NOTIZ_KONTAKTARTEN](state, val) {
      state.kontaktarten = val;
    },
    [SET_NOTIZ_KONTAKTKATEGORIEN](state, val) {
      state.kontaktkategorien = val;
    }
  },
  actions: {
    async [LADE_AUSWAHLWERTE_KORRESPONDENZ](context) {
      let response = await Api.get("korrespondenz/auswahlwerte/");
      context.commit(SET_VERARBEITUNGS_STATI, response.data.verarbeitungsstati);
      context.commit(SET_ARCHIVIERUNGS_STATI, response.data.archivierungsstati);
      context.commit(SET_KORRESPONDENZ_KATEGORIEN, response.data.kategorien);
      context.commit(SET_UNTERSCHRIFTEN, response.data.unterschriften);
    },
    async [LADE_VERSANDLISTEN](context) {
      let response = await Api.get("versand/");
      context.commit(SET_VERSAND_LISTEN, response.data);
    },
    async [LADE_KORRESPONDENZ_VORLAGEN](context) {
      let response = await Api.get("korrespondenz/vorlagen/");
      context.commit(SET_KORRESPONDENZ_VORLAGEN, response.data);
    },
    async [LADE_AUSWAHLWERTE_MAIL](context) {
      let response = await Api.get("mail/auswahlwerte/");
      context.commit(SET_MAILVORLAGE_TYPEN, response.data.mailvorlagetypen);
    },
    async [LADE_MAIL_VORLAGEN](context) {
      let response = await Api.get("mail/vorlagen/");
      context.commit(SET_MAIL_VORLAGEN, response.data);
    },
    async [LADE_AUSWAHLWERTE_NOTIZEN](context) {
      let response = await Api.get("kontakt/auswahlwerte/");
      context.commit(SET_NOTIZ_KONTAKTARTEN, response.data.kontaktarten);
      context.commit(
        SET_NOTIZ_KONTAKTKATEGORIEN,
        response.data.kontaktkategorien
      );
    }
  }
};

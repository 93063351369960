<template>
  <div class="form-group">
    <span v-if="editable" class="editIcon" v-on:click="oeffneVSModal"
      ><font-awesome-icon icon="fa-duotone fa-pencil-alt"
    /></span>
    <span v-if="allowOpen" class="openIcon" v-on:click="openVeranstaltung"
      ><font-awesome-icon icon="fa-duotone fa-arrow-circle-right"
    /></span>
    <ValidationProvider
      :rules="required ? 'required' : ''"
      immediate
      v-slot="{ passed }"
      ><input v-model="veranstaltung.id" type="hidden" />
      <b-form-input
        v-model="veranstaltungTitel"
        v-bind:class="{ 'border-danger': !passed }"
        readonly
        placeholder=" "
      />
      <label v-bind:class="{ 'text-danger': !passed }">{{ label }}</label>
    </ValidationProvider>

    <veranstaltung-select-modal
      :id="modalId"
      :filterTypen="typen"
      :filterStati="filterStati"
      :filterVeranstaltung="veranstaltung"
      @confirmed="onVSResult"
      remove
    ></veranstaltung-select-modal>
  </div>
</template>

<script>
import VeranstaltungSelectModal from "@/components/Modals/VeranstaltungSelectModal.vue";
import { v4 as uuid } from "uuid";

export default {
  name: "MultiSelectVeranstaltung",
  components: { VeranstaltungSelectModal },
  props: {
    id: {
      type: String,
      default: uuid(),
    },
    veranstaltung: {
      type: Object,
      required: false,
      default: function () {
        return { veranstaltungTitel: this.$t("global.notlinked") };
      },
    },
    allowOpen: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      required: true,
    },
    typen: {
      type: Array,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    filterStati: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      editVeranstaltung: null,
    };
  },
  computed: {
    modalId() {
      return "veranstaltung-modal-" + this.id;
    },
    veranstaltungTitel: function () {
      if (!this.veranstaltung || !this.veranstaltung.id)
        return this.$t("global.notlinked");

      return (
        (this.veranstaltung.kuerzel ? this.veranstaltung.kuerzel + " / " : "") +
        this.veranstaltung.bezeichnung
      );
    },
  },
  mounted: function () {},
  methods: {
    oeffneVSModal() {
      this.$bus.$emit("sucheModalOpen");
      $("#" + this.modalId).modal("show");
    },

    onVSResult(selectedVeranstaltung) {
      if (selectedVeranstaltung) {
        this.$emit("confirmed", selectedVeranstaltung[0]);
      }
    },

    openVeranstaltung() {
      if (!this.veranstaltung.id) return;

      let typ = this.veranstaltung.veranstaltungstyp;

      if (!typ || typ.id == this.$CONST("VERANSTALTUNGSTYPEN").STUDIES) {
        this.$router.push({
          name: "klasse",
          params: { klassenid: this.veranstaltung.id, anzeige: 0 },
        });
      } else if (typ.id == this.$CONST("VERANSTALTUNGSTYPEN").MODULE) {
        this.$router.push({
          name: "fach",
          params: { fachid: this.veranstaltung.id, anzeige: 0 },
        });
      } else if (
        typ.id == this.$CONST("VERANSTALTUNGSTYPEN").COURSE ||
        typ.id == this.$CONST("VERANSTALTUNGSTYPEN").PRECOURSE ||
        typ.id == this.$CONST("VERANSTALTUNGSTYPEN").TRAINING
      ) {
        this.$router.push({
          name: "kurs",
          params: { kursid: this.veranstaltung.id, anzeige: 0 },
        });
      } else if (typ.id == this.$CONST("VERANSTALTUNGSTYPEN").INFO) {
        this.$router.push({
          name: "infoanlass",
          params: { infoanlassid: this.veranstaltung.id, anzeige: 0 },
        });
      } else if (typ.id == this.$CONST("VERANSTALTUNGSTYPEN").EVENT) {
        this.$router.push({
          name: "event",
          params: { eventid: this.veranstaltung.id, anzeige: 0 },
        });
      } else if (
        typ.id == this.$CONST("VERANSTALTUNGSTYPEN").STUDIES_NDS ||
        typ.id == this.$CONST("VERANSTALTUNGSTYPEN").STUDIES_HFP
      ) {
        this.$router.push({
          name: "klasse",
          params: { klassenid: this.veranstaltung.id, anzeige: 0 },
        });
      } /* else {
        console.log(typ.id);
        console.log("Veranstaltungstyp nicht identifiziert");
      } */
    },
  },
};
</script>

<template>
  <div class="col-xl-12 pr-4">
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-12">
              <div class="row">
                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-model.trim="mail.empfaenger"
                      class="form-control"
                      placeholder=" "
                    />
                    <label>{{ $t("global.receiveremail") }}</label>
                  </div>
                </div>
                <div class="col-xl-2">
                  <div class="form-group">
                    <input
                      v-if="mail.absenderadresse && lang"
                      v-model.trim="mail.absenderadresse[lang.kuerzel].text"
                      class="form-control"
                      placeholder=" "
                    />
                    <label>{{ $t("global.senderemail") }}</label>
                  </div>
                </div>
                <div class="col-xl-2">
                  <div class="form-group">
                    <input
                      v-if="mail.antwortan && lang"
                      v-model.trim="mail.antwortan[lang.kuerzel].text"
                      class="form-control"
                      placeholder=" "
                    />
                    <label>{{ $t("global.answerto") }}</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12">
                  <div class="form-group">
                    <input
                      v-if="mail.translation && lang"
                      v-model.trim="mail.translation[lang.kuerzel].betreff"
                      class="form-control"
                      placeholder=" "
                    />
                    <label>{{ $t("global.subject") }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row" v-if="mail.translation && lang">
            <div class="col-xl-12">
              <div class="form-group">
                <ckeditor
                  :editor="editor"
                  v-model="mail.translation[lang.kuerzel].text"
                  :config="editorConfig"
                ></ckeditor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";

//import page from "@/mixins/Page";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "MailVorlageInhalt",
  components: {},
  mixins: [],
  store,
  props: {
    mail: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    /*     navbarTitel: {
      type: String,
      required: false,
    }, */
    lang: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      //inhalt: "<p>Content of the editor.</p>",
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "outdent",
            "indent",
            "|",
            "insertTable",
            "blockQuote",
            "|",
            "undo",
            "redo",
          ],
        },
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
      },
      toggleOpen: false,
    };
  },
  computed: {},
  watch: {},

  created() {},

  mounted: function () {},

  methods: {},
};
</script>

<style></style>

import Vue from 'vue';
import numeral from 'numeral';

Vue.filter('price', value => {
  if (isNaN(value) || !isFinite(value)) return '';
  return numeral(value).format('0,0.00')
});

Vue.filter('accuratePrice', value => {
  if (isNaN(value) || !isFinite(value)) return '';
  return numeral(value).format('0,0.00[0000]')
});

Vue.filter('amount', value => {
  if (isNaN(value) || !isFinite(value)) return '';
  return numeral(value).format('0.[00000]')
});

import Mahnungen from "@/pages/Dashboardlisten/Mahnungen";
import Mahnung from "@/pages/Finanzen/Mahnung";

export default [
  {
    path: "/Finanzen/Mahnungen/",
    name: "mahnungen",
    component: Mahnungen
  },

  {
    path: "/Finanzen/Mahnung/:mahnungid?/:anzeige",
    name: "mahnung",
    component: Mahnung,
    props: route => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        mahnungid: route.params.mahnungid,
        anzeige: isNaN(a) ? 0 : a
      };
    }
  }
];

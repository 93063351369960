<template>
  <div>
    <container-headline
      :headline="$t('global.subsidydata')"
      :col="6"
    ></container-headline>
    <div class="row">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-6">
              <div class="form-check">
                <input
                  v-model.trim="subvention.neueintritt"
                  class="form-check-input"
                  :disabled="!editable"
                  type="checkbox"
                />
                <label>{{ $t("global.newentry") }}</label>
              </div>
            </div>

            <div class="col-xl-6" />

            <div class="col-xl-4">
              <div class="form-group">
                <v-select
                  v-model="subvention.subventionsstatus"
                  label="bezeichnung"
                  :disabled="!editable"
                  :options="subventionsstati"
                  placeholder="Typ"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.subsidystatus") }}</label>
              </div>
            </div>

            <div class="col-xl-4">
              <div class="form-group">
                <date-picker
                  date-format="dd.mm.yy"
                  :initial="subvention.kostengutsprache"
                  placeholer=" "
                  :disabled="!editable"
                  :show-icon="true"
                  @update="(val) => (subvention.kostengutsprache = val)"
                />
                <label>{{ $t("global.costapproval") }}</label>
              </div>
            </div>

            <div class="col-xl-4">
              <div class="form-group">
                <v-select
                  v-model="subvention.subventionskanton"
                  label="bezeichnung"
                  :disabled="!editable"
                  :options="subventionskantone"
                  placeholder="Typ"
                >
                  <span slot="no-options"
                    >{{ $t("global.subsidycanton") }} -
                    {{ $t("global.taptosearch") }}</span
                  >
                </v-select>
                <label>{{ $t("global.subsidycanton") }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";
import DatePicker from "@/components/Datepicker";

import { LADE_AUSWAHLWERTE_SUBVENTION } from "@/store/subvention/actions.type";

export default {
  name: "Subventionsdaten",
  components: {
    ContainerHeadline,
    DatePicker,
  },
  props: {
    subvention: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    subventionsstati: {
      get() {
        return this.$store.state.subvention.subventionsstati;
      },
    },
    subventionskantone: {
      get() {
        return this.$store.state.subvention.subventionskantone;
      },
    },
  },
  watch: {
    subventionskantone: function (val) {
      this.$emit("kantoneChanged", val);
    },
  },
  mounted: function () {
    if (this.subventionsstati.length == 0)
      this.$store.dispatch(`subvention/${LADE_AUSWAHLWERTE_SUBVENTION}`);
  },
};
</script>
<style scoped></style>

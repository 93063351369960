import {
    SET_NOTENEINGABE_STATI, SET_NOTENEINGABE_TYPEN
} from "./mutations.type";

import { LADE_AUSWAHLWERTE_NOTENEINGABE } from "./actions.type";

import Api from "../../Api";

export default {
    namespaced: true,
    state: {
        noteneingabestati: [],
        noteneingabetypen: []
    },
    mutations: {
        [SET_NOTENEINGABE_STATI](state, val) {
            state.noteneingabestati = val;
        },
          [SET_NOTENEINGABE_TYPEN](state, val) {
            state.noteneingabetypen = val;
        },
    },
    actions: {
        async [LADE_AUSWAHLWERTE_NOTENEINGABE](context) {
            let response = await Api.get("noteneingabe/auswahlwerte/");
            context.commit(SET_NOTENEINGABE_STATI, response.data.noteneingabestati);
            context.commit(SET_NOTENEINGABE_TYPEN, response.data.noteneingabetypen);
        },
    }
};

import Produkt from "@/pages/Produkt/Produkt";
import Produktgruppe from "@/pages/Produkt/Produktgruppe";
import Produktliste from "@/pages/Dashboardlisten/Produktliste";
import Produktgruppenliste from "@/pages/Dashboardlisten/Produktgruppenliste";

export default [
	{
		path: "/produktliste",
		name: "produkt-liste",
		component: Produktliste,
	},

	{
		path: "/produkt/:produktid?",
		name: "produkt",
		props: (route) => {
			return {
				produktid: route.params.produktid,
			};
		},
		component: Produkt,
	},

	{
		path: "/produktgruppenliste",
		name: "produktgruppen-liste",
		component: Produktgruppenliste,
	},

	{
		path: "/produktgruppe/:produktgruppeid?/:anzeige",
		name: "produktgruppe",
		props: (route) => {
			const a = parseInt(route.params.anzeige, 10);
			return {
				produktgruppeid: route.params.produktgruppeid,
				anzeige: isNaN(a) ? 0 : a,
			};
		},
		component: Produktgruppe,
	},
];

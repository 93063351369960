import {
  SET_DOZENTEN_STATI,
  SET_ABRECHNUNGS_STATI,
  SET_PLANUNGS_STATI,
  SET_DURCHFUEHRUNGS_STATI,
  SET_DOZENTENBUCHUNG_TYPEN
} from "./mutations.type";

import { LADE_AUSWAHLWERTE_DOZENT } from "./actions.type";

import Api from "../../Api";

export default {
  namespaced: true,
  state: {
    dozentenstati: [],
    abrechnungsstati: [],
    planungsstati: [],
    durchfuehrungsstati: [],
    dozentenbuchungtypen: []
  },
  mutations: {
    [SET_DOZENTEN_STATI](state, val) {
      state.dozentenstati = val;
    },
    [SET_ABRECHNUNGS_STATI](state, val) {
      state.abrechnungsstati = val;
    },
    [SET_PLANUNGS_STATI](state, val) {
      state.planungsstati = val;
    },
    [SET_DURCHFUEHRUNGS_STATI](state, val) {
      state.durchfuehrungsstati = val;
    },
    [SET_DOZENTENBUCHUNG_TYPEN](state, val) {
      state.dozentenbuchungtypen = val;
    }
  },
  actions: {
    async [LADE_AUSWAHLWERTE_DOZENT](context) {
      let response = await Api.get("dozent/auswahlwerte/");
      context.commit(SET_DOZENTEN_STATI, response.data.dozentenstati);
      context.commit(SET_DOZENTENBUCHUNG_TYPEN, response.data.dozentenbuchungtyp);
      context.commit(SET_ABRECHNUNGS_STATI, response.data.abrechnungsstati);
      context.commit(SET_PLANUNGS_STATI, response.data.planungsstati);
      context.commit(
        SET_DURCHFUEHRUNGS_STATI,
        response.data.durchfuehrungsstati
      );
    }
  }
};

<template>
  <div>
    <container-headline :headline="$t('global.participants')" :col="6"></container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-3">
              <div class="form-group">
                <input
                  v-model.number="fach.teilnehmeranzahl_minimal"
                  class="form-control"
                  placeholder=""
                  :readonly="!editable"
                />
                <label>{{ $t("global.minimal") }}</label>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="form-group">
                <ValidationProvider
                  rules="required"
                  immediate
                  v-slot="{ passed }"
                >
                  <input
                    v-model.number="fach.teilnehmeranzahl_maximal"
                    class="form-control"
                    v-bind:class="{ 'border-danger': !passed }"
                    :readonly="!editable"
                  />
                  <label v-bind:class="{ 'text-danger': !passed }"
                    >{{ $t("global.maximum") }}</label
                  >
                </ValidationProvider>
              </div>
            </div>
            <div class="col-xl-3" v-if="!isTemplate">
              <div class="form-group">
                <input
                  v-model.number="fach.anmeldungen"
                  class="form-control"
                  readonly="true"
                />
                <label>{{ $t("global.registrations")}}</label>
              </div>
            </div>
            <div class="col-xl-3" v-if="!isTemplate">
              <div class="form-group">
                <input
                  v-model.number="fach.abmeldungen"
                  class="form-control"
                  readonly="true"
                />
                <label>{{ $t("global.cancellations") }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";

export default {
  name: "Teilnehmer",
  components: {
    ContainerHeadline,
  },
  props: {
    isTemplate: {
      type: Boolean,
      required: true,
    },
    fach: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    oeffneBildungsgang() {
      if (this.fach.lehrgang) {
        this.$router.push({
          name: "template-bildungsgang",
          params: { lehrgangid: this.fach.lehrgang.id, anzeige: 0 },
        });
      }
    },
  },
};
</script>

<style lang="scss">
</style>

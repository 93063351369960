<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.bankdetails')"
        :col="3"
      >
        <button
          class="btn btn-primary"
          @click="showBankverbindungHinzufuegen"
          v-if="editable"
        >
          <font-awesome-icon icon="fa-regular fa-plus" />{{ $t("global.addbankdetails") }}
        </button>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block br-t-l-0">
          <div :key="bankverbindungenkey" class="list-group">
            <bankverbindung
              v-for="(bv, indexBV) in bankverbindungen"
              :key="bv.key"
              :bankverbindung="bv"
              :index="indexBV"
              :editable="editable"
              @deleteBankverbindung="bankverbindungDeleted"
              @bankverbindungAendern="showBankverbindungAendern"
            ></bankverbindung>
            <div
              v-if="isBusy && !ladeFehler"
              class="text-center text-primary my-2"
            >
              <b-spinner class="align-middle"></b-spinner>
            </div>

            <div
              class="text-center text-primary my-2"
              v-if="bankverbindungen.length == 0 && !isBusy && !ladeFehler"
            >
              <strong>{{ $t("global.nobankdetailsavailable") }}</strong>
            </div>

            <div
              class="text-center text-primary my-2"
              v-if="!isBusy && ladeFehler"
            >
              <strong>{{ $t("global.errorloadingbankdetails") }}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bankverbindung-modal
      method="bankverbindungHinzugfuegt"
      @bankverbindungCreated="bankverbindungHinzufuegen"
      @bankverbindungChanged="bankverbindungAendern"
    ></bankverbindung-modal>
  </div>
</template>

<script>
import Api from "@/Api";

import ContainerHeadline from "@/components/ContainerHeadline";
import { uuidv4 } from "@/utils/Uuid";

import BankverbindungModal from "@/components/Modals/BankverbindungModal";
import Bankverbindung from "@/components/Geschaeftspartner/Bankverbindung";

import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";

export default {
  components: {
    ContainerHeadline,
    BankverbindungModal,
    Bankverbindung,
    BSpinner,
  },
  props: {
    person: {
      type: String,
      required: true,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      bankverbindungen: [],
      bankverbindungenkey: uuidv4(),
      isBusy: false,
      ladeFehler: false,
    };
  },
  watch: {
    shown(val) {
      if (val && this.bankverbindungen.length == 0) this.getDaten();
    },
  },
  computed: {},
  mounted() {
    if (this.shown) this.getDaten();
  },
  created() {},
  methods: {
    getDaten() {
      if (this.person) {
        this.isBusy = true;

        Api.get("/geschaeftspartner/bankverbindung/", {
          params: { gpid: this.person },
        })
          .then((response) => {
            this.bankverbindungen = response.data;
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
            this.ladeFehler = true;
          })
          .finally(() => {
            this.isBusy = false;
          });
      }
    },

    bankverbindungDeleted(indexBV) {
      if (this.bankverbindungen[indexBV].id) {
        this.bankverbindungen[indexBV].delete = true;
        this.bankverbindungen[indexBV].key = uuidv4();
        this.updateParent();
      }
    },

    showBankverbindungAendern(indexBV) {
      if (this.bankverbindungen[indexBV]) {
        this.bankverbindungen[indexBV].neueBankverbindung = false;
        this.$bus.$emit(
          "bankverbindung-modal-show",
          this.bankverbindungen[indexBV]
        );
      }
    },

    bankverbindungAendern(updateBankverbindung) {
      let pos = this.bankverbindungen.findIndex(
        (bv) => bv.id === updateBankverbindung.id
      );

      if (pos != -1) {
        this.bankverbindungen[pos] = updateBankverbindung;
        this.bankverbindungenkey = uuidv4();
        this.updateParent();
      }
    },

    showBankverbindungHinzufuegen() {
      this.$bus.$emit("bankverbindung-modal-show", {
        neueBankverbindung: true,
      });
    },

    bankverbindungHinzufuegen(bankverbindung) {
      this.bankverbindungenkey = uuidv4();
      this.bankverbindungen.push(bankverbindung);
      this.updateParent();
    },

    updateParent() {
      this.$emit("updatebankverbindung", {
        bankverbindungen: this.bankverbindungen,
      });
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.additionalregistrationinformation')"
        :col="3"
      >
        <div class="row mt-2 d-flex">
          <div class="ml-4">
            <button class="btn btn-success mr-2" @click="speichern">
              {{ $t("global.save") }}
            </button>
            <button
              class="btn btn-primary mr-2"
              @click="
                exportToExcelWithPlugin(
                  {
                    additional_offers_overview: additional_offers_overview,
                    participants_list: participants_list,
                  },
                  `additional-info?eventid=${klassenid}`,
                  'Zusatzangebote'
                )
              "
            >
              <span class="mr-2"
                ><font-awesome-icon icon="fa-regular fa-file-spreadsheet"
              /></span>
              {{ $t("global.export") }}
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <div>
              <b-table
                ref="selectableTable"
                tbody-tr-class="item no-pointer"
                style="max-height: 2500px"
                responsive
                sticky-header
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :busy="isBusy"
                show-empty
                sort-icon-left
                selectable
                select-mode="range"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>

                <template #empty>
                  <div v-if="ladeFehler" class="text-center text-danger my-2">
                    <strong>{{ $t("global.errorwhileloading") }}</strong>
                  </div>
                  <div v-if="!ladeFehler" class="text-center text-primary my-2">
                    <strong>{{ $t("global.nodataavailable") }}</strong>
                  </div>
                </template>

                <template
                  v-for="field in fields"
                  v-slot:[`cell(${field.key})`]="{ item }"
                >
                  <template v-if="field.key === 'name'">
                    {{ item.name }}
                  </template>
                  <template v-else-if="field.key === 'remark'">
                    {{ item.remark }}
                  </template>
                  <template v-else>
                    <div :key="field.key">
                      <template v-if="item[field.key]">
                        <template
                          v-if="item[field.key].inputType === 'singleline'"
                        >
                          <div class="form-group col-12 minw-150-px">
                            <input
                              class="form-control"
                              v-model="item[field.key].value"
                              type="text"
                              @input="
                                handleInputChange($event, item[field.key])
                              "
                            />
                          </div>
                        </template>
                        <template
                          v-else-if="item[field.key].inputType === 'multiline'"
                        >
                          <div class="form-group col-12 minw-150-px">
                            <textarea
                              class="form-control"
                              v-model="item[field.key].value"
                              @input="
                                handleInputChange($event, item[field.key])
                              "
                            />
                          </div>
                        </template>
                        <template
                          v-else-if="item[field.key].inputType === 'date'"
                        >
                          <div class="form-group col-12 minw-150-px">
                            <date-picker
                              date-format="dd.mm.yy"
                              :initial="item[field.key].value"
                              placeholer=" "
                              :show-icon="true"
                              @update="
                                (val) => handleDateChange(val, item[field.key])
                              "
                            />
                          </div>
                        </template>
                        <template
                          v-else-if="item[field.key].inputType === 'checkbox'"
                        >
                          <div class="form-group col-12 minw-150-px">
                            <input
                              class="form-control maxh-20-px"
                              type="checkbox"
                              :checked="item[field.key].value"
                              v-model="item[field.key].value"
                              @change="
                                handleInputChange($event, item[field.key])
                              "
                            />
                          </div>
                        </template>
                        <template
                          v-else-if="item[field.key].inputType === 'order'"
                        >
                          <div class="form-group col-12 minw-150-px">
                            <input
                              class="form-control maxh-20-px"
                              type="checkbox"
                              v-model="item[field.key].value"
                              :checked="item[field.key].value"
                              @change="
                                handleOrderChange($event, item[field.key])
                              "
                            />
                          </div>
                        </template>
                      </template>
                    </div>
                  </template>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PortalApi from "@/PortalApi";
import { BSpinner } from "bootstrap-vue";
import DatePicker from "@/components/Datepicker";
import ContainerHeadline from "@/components/ContainerHeadline";

import reiter from "@/mixins/Reiter";
import dashboard from "@/mixins/Dashboard";
import page from "@/mixins/Page";
import excelExport from "@/mixins/ExcelExport";

export default {
  name: "Zusatzangebote",
  components: {
    ContainerHeadline,
    DatePicker,
    BSpinner,
  },
  mixins: [reiter, page, dashboard, excelExport],
  props: {
    klassenid: {
      type: String,
      required: false,
    },
    lehrgangid: {
      type: String,
      required: false,
    },
    klassenkuerzel: {
      type: String,
      required: false,
    },
    shown: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      page: 0,
      anzahlProPage: 200,
      sortBy: "name",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      additional_offers_overview: [],
      participants_list: [],
    };
  },
  watch: {
    //Ist die Klasse mit einem anderen Reiter geladen und der Zusatzangaben-Reiter wird angeklickt soll der GET ausgeführt werden
    shown(val) {
      if (
        val &&
        this.additional_offers_overview.length == 0 &&
        this.klassenid &&
        this.lehrgangid
      )
        this.getZusatzangebote();
    },
    //Wird die Klasse mit dem Zusatzangaben-Reiter geladen, soll der GET ausgeführt werden, sobald die benötigten props geladen sind
    lehrgangid(val) {
      if (
        val &&
        this.shown &&
        this.klassenid &&
        this.additional_offers_overview.length == 0
      )
        this.getZusatzangebote();
    },
  },
  computed: {
    fields() {
      const nameField = {
        key: "name",
        label: "Name",
      };

      const remarkField = {
        key: "remark",
        label: "Bemerkung",
      };

      const additionalOffersFields = this.additional_offers_overview.map(
        (offer) => ({
          key: offer.parameter_name,
          label: offer.designation,
        })
      );

      return [nameField, remarkField, ...additionalOffersFields];
    },
    items() {
      const rows = [];

      let counter = 0;

      for (const participant of this.participants_list) {
        const row = {
          name: `${participant.last_name} ${participant.first_name}`,
          remark: participant.remark,
        };

        for (const offer of this.additional_offers_overview) {
          const correspondingItem = participant.additional_offers_list.find(
            (item) => item.form_field_id === offer.id
          );

          if (correspondingItem) {
            const inputValue =
              offer.input_type === "checkbox" || offer.input_type === "order"
                ? correspondingItem.input_boolean
                : offer.input_type === "date"
                ? correspondingItem.input_date
                : correspondingItem.input_string;

            //Neue erstellte Felder auf dem Anmeldeformular besitzen noch keine ID. Um die Verbindung von dem Item Array (für die b-table)
            //mit dem participants_list array herzustellen, ist diese aber notwendig. Deswegen wird hier eine placeholder ID gesetzt,
            //welche aber vor dem PUT wieder entfernt werden soll
            if (!correspondingItem.id) {
              correspondingItem.id = counter;
            }

            row[offer.parameter_name] = {
              value: inputValue,
              inputType: offer.input_type,
              itemId: correspondingItem.id,
              fieldName: offer.parameter_name,
              clearingPosition: offer.full_clearing_position || null,
            };
          } else {
            row[offer.parameter_name] = null;
          }
        }

        rows.push(row);

        counter++;
      }

      return rows;
    },
  },
  mounted() {},
  created() {},
  methods: {
    handleInputChange(event, item) {
      const newValue =
        item.inputType === "checkbox"
          ? event.target.checked
          : event.target.value;

      // Update the value in the items array
      item.value = newValue;

      // Find and update the corresponding item in participants_list
      const participant = this.participants_list.find((p) =>
        p.additional_offers_list.some(
          (i) => i.id === item.itemId && i.parameter_name === item.fieldName
        )
      );

      if (participant) {
        const correspondingItem = participant.additional_offers_list.find(
          (i) => i.id === item.itemId && i.parameter_name === item.fieldName
        );

        if (correspondingItem) {
          if (item.inputType === "checkbox") {
            correspondingItem.input_boolean = newValue;
          } else {
            correspondingItem.input_string = newValue;
          }
        }
      }
    },
    handleDateChange(val, item) {
      // Update the value in the items array
      item.value = val;

      // Find and update the corresponding item in participants_list
      const participant = this.participants_list.find((p) =>
        p.additional_offers_list.some(
          (i) => i.id === item.itemId && i.parameter_name === item.fieldName
        )
      );

      if (participant) {
        const correspondingItem = participant.additional_offers_list.find(
          (i) => i.id === item.itemId && i.parameter_name === item.fieldName
        );

        if (correspondingItem) {
          correspondingItem.input_date = val;
        }
      }
    },
    handleOrderChange(event, item) {
      const newValue = event.target.checked;

      // Update the value in the items array
      item.value = newValue;

      // Find and update the corresponding item in participants_list
      const participant = this.participants_list.find((p) =>
        p.additional_offers_list.some(
          (i) => i.id === item.itemId && i.parameter_name === item.fieldName
        )
      );

      if (participant) {
        const correspondingItem = participant.additional_offers_list.find(
          (i) => i.id === item.itemId && i.parameter_name === item.fieldName
        );

        if (correspondingItem) {
          correspondingItem.input_boolean = newValue;

          if (newValue && !correspondingItem.full_clearing_position) {
            correspondingItem.full_clearing_position = {
              id: null,
              count: item.clearingPosition.count,
              price: item.clearingPosition.price,
              item: item.clearingPosition.item,
            };
          }

          //   if (newValue) {
          //     // Set the full_clearing_position details if checkbox is checked
          //     if (correspondingItem.full_clearing_position) {
          //       correspondingItem.full_clearing_position.count =
          //         item.clearingPosition.count;
          //       correspondingItem.full_clearing_position.price =
          //         item.clearingPosition.price;
          //       correspondingItem.full_clearing_position.item =
          //         item.clearingPosition.item;
          //     } else {
          //       correspondingItem.full_clearing_position = {
          //         id: null,
          //         count: item.clearingPosition.count,
          //         price: item.clearingPosition.price,
          //         item: item.clearingPosition.item,
          //       };
          //     }
          //   }
        }
      }
    },

    getZusatzangebote() {
      if (this.klassenid && this.lehrgangid) {
        this.isBusy = true;

        PortalApi.get("additional-offers/", {
          params: {
            eventid: this.klassenid,
            educationcourseid: this.lehrgangid,
          },
        })
          .then((response) => {
            this.additional_offers_overview =
              response.data?.additional_offers_overview;
            this.participants_list = response.data?.participants_list;
            this.ladeFehler = false;
          })
          .catch((e) => {
            console.log(e);
            this.ladeFehler = true;
          })
          .finally(() => {
            this.isBusy = false;
          });
      }
    },
    speichern() {
      this.isBusy = true;

      PortalApi.put("additional-offers/", this.zusatzangebotToJson(), {})
        .then((response) => {
          this.additional_offers_overview =
            response.data?.additional_offers_overview;
          this.participants_list = response.data?.participants_list;

          this.ladeFehler = false;
        })
        .catch((e) => {
          console.log(e);
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    zusatzangebotToJson() {
      let partlist = JSON.parse(JSON.stringify(this.participants_list));

      partlist.forEach((participant) => {
        participant.additional_offers_list.forEach((offer) => {
          // Überprüfen, ob die ID manuell gesetzt wurde
          if (offer.id !== null && (!isNaN(offer.id) || offer.id.length <= 3)) {
            offer.id = null; // Setze die ID auf null zurück
          }
        });
      });

      return {
        additional_offers_overview: this.additional_offers_overview,
        participants_list: partlist,
      };
    },
  },
};
</script>

<template>
  <div class="row">
    <notifications></notifications>
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.notes')"
        :col="3"
      >
        <div class="row d-flex justify-content-between">
          <div class="ml-4">
            <button
              class="btn btn-success"
              @click="neueNotiz"
              v-if="editable || berechtigungen.m_notizen.create"
            >
              <font-awesome-icon
                icon="fa-duotone fa-comments-alt"
                class="mr-2"
              />
              <span>{{ $t("global.new") }}</span>
            </button>
          </div>
          <div class="mr-4"></div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              tbody-tr-class="item"
              style="max-height: 300px"
              :items="notizenliste"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="isBusy"
              show-empty
              sort-icon-left
              responsive
              selectable
              select-mode="range"
              @row-dblclicked="details"
              small
              thead-tr-class="pointer"
            >
              <template v-slot:head(selected)="row">
                <b-form-checkbox
                  v-model="row.selected"
                  @input="selectAllRows(row)"
                ></b-form-checkbox>
              </template>

              <template v-slot:cell(selected)="row">
                <b-form-checkbox
                  v-model="row.item.selected"
                  @input="onRowSelected(row)"
                ></b-form-checkbox>
              </template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>{{ $t("global.errorwhileloading") }}</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>{{ $t("global.nodataavailable") }}</strong>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal id="notiz-loeschen-modal" @confirmed="notizloeschen" />
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import reiter from "@/mixins/Reiter";

export default {
  name: "NotizenListe",
  components: {
    ContainerHeadline,
    LoeschenModal,
    BSpinner,
  },
  mixins: [reiter],
  props: {
    person: {
      type: String,
      required: false,
    },
    firma: {
      type: String,
      required: false,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      page: 0,
      anzahlProPage: 100,
      sortBy: "",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      notizenliste: [],
      selected: [],
      selectedIds: [],
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        { key: "datum", sortable: true, label: this.$t("global.date") },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
        {
          key: "kontaktart.bezeichnung",
          sortable: true,
          label: this.$t("global.contacttype"),
        },
        {
          key: "kontaktkategorie.bezeichnung",
          sortable: true,
          label: this.$t("global.contactcategory"),
        },
        {
          key: "kontakt_durch.personName",
          sortable: true,
          label: this.$t("global.contactby"),
        },
      ];
    },
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      if (this.suche) params.suche = this.suche;

      return params;
    },
  },
  watch: {
    shown(val) {
      if (val && this.notizenliste.length == 0) this.getNotizen();
    },
  },
  mounted() {
    if (this.shown && this.notizenliste.length == 0) this.getNotizen();
  },
  created() {},
  methods: {
    getNotizen() {
      this.isBusy = true;

      Api.get("kontakt/", {
        params: { person: this.person, firma: this.firma },
      })
        .then((response) => {
          this.notizenliste = response.data;
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    details(notiz) {
      this.$router.push({
        name: "notiz",
        params: { id: notiz.id, anzeige: 0 },
      });
    },

    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = true;
        });
      } else {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },

    neueNotiz() {
      let json = {
        kontaktpersonen: [this.person],
        firmen: [this.firma],
        kontakt_durch: this.angemeldeterMitarbeiter.person,
        person: this.angemeldeterMitarbeiter.person,
        beschreibung: "",
        datum: new Intl.DateTimeFormat("de-CH").format(new Date()),
      };

      Api.post("kontakt/", json, { params: {} }).then((response) => {
        this.$router.push({
          name: "notiz",
          params: {
            id: response.data.id,
            anzeige: 0,
          },
        });
      });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "notiz-loeschen-modal");
      else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nonoteselected"),
        });
      }
    },

    notizloeschen() {
      Api.delete("kontakt/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.$emit("updateComponent");
        this.getNotizen();
      });
      this.$notify({
        type: "success",
        title: this.$t("global.actionsuccessful"),
        text: `${this.selectedIds.length} ${this.$t("global.notesdeleted")}`,
      });
    },

    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },
  },
};
</script>

<template>
  <div id="kompetenz-raster-modal" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content" style="width: 1000px">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4>Kompetenzraster</h4>
        </div>
        <div class="modal-body">
          <kompetenzraster :id="id" />
        </div>
        <div class="modal-footer">
          <button class="btn btn-default" @click="closeModal">
            Schliessen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalCloseButton from "@/components/global/ModalCloseButton";
import Kompetenzraster from "@/components/Bildung/Kompetenzraster/Kompetenzraster.vue";

export default {
  name: "KompetenzrasterModal",
  components: {
    Kompetenzraster,
    ModalCloseButton,
  },
  props: {},
  data() {
    return {
      shown: false,
      id: null,
    };
  },
  computed: {},
  mounted() {
    this.$bus.$on("kompetenzraster-modal-show", (id) => {
      if (!this.shown) {
        this.id = id;
        this.openModal();
      }
    });
  },
  methods: {
    openModal() {
      this.shown = true;

      $("#kompetenz-raster-modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    closeModal() {
      this.shown = false;
      $("#kompetenz-raster-modal").modal("hide");
    },
  },
};
</script>

<style>
</style>

<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.lecturerdeployment')"
        :col="3"
      >
        <div class="row mt-2 d-flex justify-content-between">
          <div class="ml-4"></div>

          <div class="mr-4">
            <button
              class="btn btn-danger"
              @click="oeffneLoeschenModal"
              v-if="berechtigungen.m_dozierende.delete"
            >
              <font-awesome-icon class="mr-1" icon="fa-duotone fa-trash" />
              <span>{{ $t("global.delete") }}</span>
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              tbody-tr-class="item"
              :items="dozenteneinsaetze"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-compare="sortTermine"
              :busy="isBusy"
              show-empty
              sort-icon-left
              fixed
              selectable
              select-mode="range"
              @row-dblclicked="details"
              thead-tr-class="pointer"
            >
              <template v-slot:head(selected)="row">
                <b-form-checkbox
                  v-model="row.selected"
                  @input="selectAllRows(row)"
                ></b-form-checkbox>
              </template>

              <template v-slot:cell(selected)="row">
                <b-form-checkbox
                  v-model="row.item.selected"
                  @input="onRowSelected(row)"
                ></b-form-checkbox>
              </template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>{{ $t("global.errorwhileloading") }}</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>{{ $t("global.nodataavailable") }}</strong>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="dozenteneinsatz-loeschen-modal"
      @confirmed="dozenteneinsatzloeschen"
      :selectedIds="selectedIds"
      :multiple="true"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import reiter from "@/mixins/Reiter";

export default {
  components: {
    ContainerHeadline,
    LoeschenModal,
    BSpinner,
  },
  mixins: [reiter],
  props: {
    dozentenbuchung: {
      type: String,
      required: false,
    },
    termin: {
      type: String,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    shown: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      page: 0,
      dozenteneinsaetze: [],
      infiniteId: +new Date(),
      anzahlProPage: 50,
      sortBy: "",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      kategorieProp: this.kategorieProp,
      selected: [],
      selectedIds: [],
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "termin.von",
          sortable: true,
          label: this.$t("global.date"),
        },
        {
          key: "termin.von_uhrzeit",
          sortable: true,
          label: this.$t("global.Time"),
        },

        {
          key: "termin.lektionen",
          sortable: true,
          label: this.$t("global.lessons"),
        },
        {
          key: "dozentenbuchung.person.personName",
          sortable: true,
          label: this.$t("global.lecturers"),
        },
        {
          key: "planungsstatus.bezeichnung",
          sortable: true,
          label: this.$t("global.statusplanning"),
        },
        {
          key: "durchfuehrungsstatus.bezeichnung",
          sortable: true,
          label: this.$t("global.statusexecution"),
        },
      ];
    },
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      return params;
    },
  },
  created() {},
  mounted() {
    if (this.shown && this.dozenteneinsaetze.length == 0)
      this.getDozentenEinsaetze();
  },
  watch: {
    shown(val) {
      if (val && this.dozenteneinsaetze.length == 0)
        this.getDozentenEinsaetze();
    },
  },
  methods: {
    getDozentenEinsaetze() {
      this.isBusy = true;

      Api.get("dozenteneinsatz/", {
        params: { dozentenbuchung: this.dozentenbuchung, termin: this.termin },
      })
        .then((response) => {
          //response.data.shift();
          this.dozenteneinsaetze = response.data;
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    details(dozenteneinsaetze) {
      this.$router.push({
        name: "dozenteneinsatz",
        params: { id: dozenteneinsaetze.id, anzeige: 0 },
      });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "dozenteneinsatz-loeschen-modal");
    },

    dozenteneinsatzloeschen() {
      Api.delete("dozenteneinsatz/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.getDozentenEinsaetze();
      });
    },

    infiniteHandler($state) {
      console.log("init");
      Api.get("dozenteneinsatz/", {
        params: this.queryParams,
      }).then((response) => {
        console.log("final");
        if (response.data.length === this.anzahlProPage) {
          this.page += 1;
          this.dozenteneinsaetze.push(...response.data);
          $state.loaded();
        } else {
          this.dozenteneinsaetze.push(...response.data);
          $state.complete();
        }
      });
    },
    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = true;
          if (!this.selectedIds.includes(gp.id)) {
            this.selectedIds.push(gp.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },
    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },
    sortTermine(datumA, datumB, key) {
      if (key != "termin.von") {
        return false;
      } else {
        let a = null;
        let b = null;

        if (key.includes(".")) {
          a = this.resolveTermin(key, datumA);
          b = this.resolveTermin(key, datumB);
        } else {
          a = datumA[key];
          b = datumB[key];
        }
        if (a && b) {
          a = a.split(".");
          b = b.split(".");
          a =
            parseInt(a[2], 10) * 10000 +
            parseInt(a[1], 10) * 100 +
            parseInt(a[0]);
          b =
            parseInt(b[2], 10) * 10000 +
            parseInt(b[1], 10) * 100 +
            parseInt(b[0]);
          return a - b;
        } else {
          return false;
        }
      }
    },
    resolveTermin(path, obj = self, separator = ".") {
      var properties = Array.isArray(path) ? path : path.split(separator);
      return properties.reduce((prev, curr) => prev && prev[curr], obj);
    },
  },
};
</script>

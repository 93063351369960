<template>
  <div class="form-group">
    <input
      v-model.trim="uhrzeit"
      class="form-control"
      :placeholder="placeholder"
      :readonly="readonly"
      v-bind:class="inputClass + ' form-control'"
      @blur="checkFormat"
    />
    <label v-if="label">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: "TimeField",
  props: {
    placeholder: { type: String, required: false, default: " " },
    initial: { type: String, required: false, default: "" },
    label: { type: String, required: false, default: "" },
    readonly: { type: Boolean, required: false, default: false },
    inputClass: { type: String, required: false, default: "" },
  },
  data() {
    return {
      uhrzeit: this.initial ? this.initial : "",
    };
  },
  watch: {
    initial(val) {
      this.uhrzeit = val;
    },
  },
  mounted: function () {
    //var self = this;
  },
  methods: {
    clear() {
      this.urhzeit = "";
    },
    checkFormat() {
      if (this.uhrzeit) {
        if (this.uhrzeit != "" && !this.uhrzeit.includes(":")) {
          let left = this.uhrzeit.substring(0, 2);
          let right = this.uhrzeit.substring(2, 4);

          this.uhrzeit = left + ":" + right;
        }
      }
      this.$emit("update", this.uhrzeit);
    },
  },
};
</script>

<style lang="scss">
</style>

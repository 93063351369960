<template>
  <div class="modal" tabindex="-1" :id="id" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered" :class="[sizeClass]">
      <div class="modal-content bg-ice">
        <div class="modal-header bg-cosmos p-3">
          <span>{{ headerText }}</span>
          <modal-close-button @confirmed="onCancel" />
        </div>
        <div class="modal-body">
          <p v-if="text !== null">{{ text }}</p>
          <slot></slot>
        </div>
        <div class="modal-footer" :class="{ 'd-none': noFooter }">
          <div class="row w-100">
            <div class="col-12 text-center">
              <button
                v-show="cancel"
                type="button"
                class="btn btn-danger mr-3"
                data-dismiss="modal"
                @click="onCancel"
              >
                <span class="font-khand font-weight-bold">{{
                  cancelText
                }}</span>
              </button>
              <button
                v-show="repeat"
                type="button"
                class="btn btn-primary mr-3"
                data-dismiss="modal"
                @click="onRepeat"
              >
                <span class="font-khand font-weight-bold">{{
                  repeatText
                }}</span>
              </button>
              <button
                v-show="ok"
                type="button"
                class="btn btn-success"
                @click="onOk"
              >
                <span class="font-khand font-weight-bold">{{ okText }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { modal } from "../../mixins/Modal";
import ModalCloseButton from "@/components/global/ModalCloseButton";

export default {
  name: "Messagebox",
  components: {
    ModalCloseButton,
  },
  props: {
    headerText: {
      type: String,
      required: true,
    },
    ok: {
      type: Boolean,
      default: false,
    },
    cancel: {
      type: Boolean,
      default: true,
    },
    repeat: {
      type: Boolean,
      default: false,
    },
    okText: {
      type: String,
      default: "OK",
    },
    cancelText: {
      type: String,
      default: "Abbrechen",
    },
    repeatText: {
      type: String,
      default: "Wiederholen",
    },
    text: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
    noFooter: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [modal],
  computed: {
    sizeClass() {
      if (this.size) return `modal-${this.size}`;
      else return "";
    },
  },
  methods: {
    onCancel() {
      this.closeModal();
      this.$emit("cancel");
    },
    onOk() {
      this.closeModal();
      this.$emit("ok");
    },
    onRepeat() {
      this.closeModal();
      this.$emit("repeat");
    },
  },
};
</script>

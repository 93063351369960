<template>
  <div id="korrespondenz-vorlage-modal" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <ValidationObserver v-slot="{ invalid }" :disabled="true">
          <div class="modal-header">
            <modal-close-button @confirmed="closeModal" />
            <h4 class="modal-title">{{ $t("global.selecttemplate") }}</h4>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-xl-12">
                <span
                  >{{
                    $t(
                      "global.selectthetemplatethatyouwanttouseforthecurrentcorrespondence"
                    )
                  }}</span
                >
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-xl-12">
                <div class="form-group">
                  <ValidationProvider
                    rules="required"
                    immediate
                    v-slot="{ passed }"
                  >
                    <v-select
                      v-model="vorlage"
                      class="mt-2 mb-3"
                      v-bind:class="{ 'border-danger': !passed }"
                      label="bezeichnung"
                      :options="vorlagen"
                      placeholder="Vorlage"
                    >
                      <span slot="no-options">{{
                        $t("global.taptosearch")
                      }}</span>
                    </v-select>
                    <label v-bind:class="{ 'text-danger': !passed }">{{
                      $t("global.template")
                    }}</label>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeModal">
              {{ $t("global.cancel") }}
            </button>
            <button
              type="button"
              :disabled="invalid"
              class="btn btn-primary"
              @click="uebernehmen"
            >
              {{ $t("global.apply") }}
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import Comm from "@/Communication";

import ModalCloseButton from "@/components/global/ModalCloseButton";

import { LADE_KORRESPONDENZ_VORLAGEN } from "@/store/korrespondenz/actions.type";

export default {
  name: "KorrespondenzVorlageModal",
  components: {
    ModalCloseButton,
  },
  props: {},
  data() {
    return {
      shown: false,
      vorlage: null,
    };
  },
  computed: {
    vorlagen: {
      get() {
        return this.$store.state.korrespondenz.vorlagen;
      },
    },
  },
  watch: {},
  mounted() {
    this.$bus.$on("open-korrespondenz-vorlage-modal", () => {
      if (!this.shown) this.openModal();
    });
  },
  methods: {
    openModal() {
      if (this.vorlagen.length == 0)
        this.$store.dispatch(`korrespondenz/${LADE_KORRESPONDENZ_VORLAGEN}`);

      this.shown = true;

      $("#korrespondenz-vorlage-modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    },
    closeModal() {
      this.shown = false;
      $("#korrespondenz-vorlage-modal").modal("hide");
    },
    uebernehmen() {
      Comm.get("correspondence-template/" + this.vorlage.id).then(
        (response) => {
          this.$emit("confirmed", response.data);
          this.closeModal();
        }
      );
    },
  },
};
</script>

<style></style>

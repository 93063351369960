<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.Templatessubjects')"
        :col="3"
      >
        <div class="row">
          <div class="ml-4 mr-2">
            <button
              class="btn btn-success"
              @click="erstelleTemplateFach"
              v-if="editable"
            >
              <font-awesome-icon icon="fa-regular fa-plus" />
              {{ $t("global.new") }}
            </button>
          </div>

          <div class="mr-2">
            <button
              class="btn btn-primary"
              @click="kopiereFach"
              v-if="editable"
            >
              <font-awesome-icon icon="fa-duotone fa-copy" class="mr-2" />
              {{ $t("global.Copy") }}
            </button>
          </div>

          <div class="mr-2">
            <button
              class="btn btn-danger"
              @click="oeffneLoeschenModal"
              v-if="editable"
            >
              <font-awesome-icon icon="fa-duotone fa-trash" />
              {{ $t("global.delete") }}
            </button>
          </div>
        </div>
      </container-headline>

      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              tbody-tr-class="item"
              :items="faecher"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              sort-icon-left
              fixed
              selectable
              select-mode="range"
              @row-dblclicked="openFach"
            >
              <template v-slot:head(selected)="header">
                <b-form-checkbox
                  ref="headerCheckbox"
                  v-model="header.selected"
                  @input="selectAllRows(header)"
                ></b-form-checkbox>
              </template>

              <template v-slot:cell(selected)="row">
                <b-form-checkbox
                  v-model="row.item.selected"
                  @input="onRowSelected(row)"
                ></b-form-checkbox>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal id="fach-loeschen-modal" @confirmed="fachloeschen" />
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import ContainerHeadline from "@/components/ContainerHeadline";

import reiter from "@/mixins/Reiter";

import LoeschenModal from "@/components/Modals/LoeschenModal";

export default {
  components: {
    ContainerHeadline,
    LoeschenModal,
  },
  mixins: [reiter],
  props: {
    klasse: {
      type: Object,
      required: true,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      page: 0,
      infiniteId: +new Date(),
      anzahlProPage: 50,
      sortBy: "",
      sortDesc: false,
      faecher: [],
      selected: [],
      selectedIds: [],
    };
  },
  watch: {
    shown(val) {
      if (val && this.faecher.length == 0) this.getFaecher();
    },
    klasse(val) {
      if (val) this.getFaecher();
    },
  },
  mounted() {
    if (this.shown) this.getFaecher();
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "kuerzel",
          sortable: true,
          label: this.$t("global.shortdesignation"),
        },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
        {
          key: "lektionen_soll",
          sortable: true,
          label: this.$t("global.lessonstarget"),
        },

        {
          key: "veranstaltungsstatus.bezeichnung",
          sortable: true,
          label: this.$t("global.type"),
        },
      ];
    },
    queryParams: function () {
      return {
        veranstaltung: this.klasse.id,
        page: this.page,
        count: this.anzahlProPage,
        veranstaltungstyp: this.$CONST("VERANSTALTUNGSTYPEN").MODULE,
      };
    },
  },
  created() {},
  methods: {
    getFaecher() {
      if (this.klasse.id)
        Api.get("template/veranstaltungfach/", {
          params: this.queryParams,
        }).then((response) => {
          this.faecher = response.data;
        });
    },
    openFach(faecher) {
      this.$router.push({
        name: "template-fach",
        params: { fachid: faecher.id, anzeige: 0 },
      });
    },
    infiniteHandler($state) {
      if (this.shown) {
        Api.get("template/veranstaltungfach/", {
          params: this.queryParams,
        }).then((response) => {
          console.log("final");
          if (response.data.length === this.anzahlProPage) {
            this.page += 1;
            this.faecher.push(...response.data);
            $state.loaded();
          } else {
            this.faecher.push(...response.data);
            $state.complete();
          }
        });
      }
    },
    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = true;
          if (!this.selectedIds.includes(gp.relrecordid)) {
            this.selectedIds.push(gp.relrecordid);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },

    erstelleTemplateFach() {
      if (this.loading) return;
      this.loading = true;

      var json = {
        lehrgang: this.klasse.lehrgang.id,
        veranstaltungstyp: this.$CONST("VERANSTALTUNGSTYPEN").MODULE,
        verantwortlicher: this.klasse.verantwortlicher.id,
        minTeilnehmer: this.klasse.minTeilnehmer,
        maxTeilnehmer: this.klasse.maxTeilnehmer,
      };

      Api.post("template/veranstaltung/?parent=" + this.klasse.id, json)
        .then((response) => {
          this.$router.push({
            name: "template-fach",
            params: { fachid: response.data.id, anzeige: 0 },
          });
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.subjectsuccessfullysaved"),
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    kopiereFach() {
      if (this.selectedIds.length == 0) return;

      if (this.loading) return;

      this.selectedIds.forEach((id) => {
        Api.get("template/veranstaltung/", {
          params: { id: id },
        }).then((response) => {
          response.data.bezeichnung =
            "Kopie von [" + response.data.bezeichnung + "]";

          response.data.veranstaltungsstatus =
            response.data.veranstaltungsstatus.id;

          response.data.veranstaltungstyp = response.data.veranstaltungstyp.id;
          response.data.lehrgang = response.data.lehrgang.id;
          response.data.lehrmethode = response.data.lehrmethode?.id;
          response.data.verantwortlicher = response.data.verantwortlicher?.id;

          response.data.themen = response.data.themen.map((thema) => thema.id);
          response.data.qualifikationen = response.data.qualifikationen.map(
            (qualifikation) => qualifikation.id
          );

          Api.post(
            "template/veranstaltung/?parent=" + this.klasse.id,
            response.data
          ).then(() => {
            this.getFaecher();
          });
        });
      });
      this.$notify({
        type: "success",
        title: this.$t("global.actionsuccessful"),
        text: this.$t("global.templatessuccessfullycopied"),
      });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "fach-loeschen-modal");
    },

    fachloeschen() {
      Api.delete("template/veranstaltungfach/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.getFaecher();
      });
    },
  },
};
</script>

<template>
  <div>
    <container-headline headline="Lektionen" :col="6"></container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-2">
              <div class="form-group">
                <ValidationProvider
                  rules="required"
                  immediate
                  v-slot="{ passed }"
                >
                  <input
                    v-model.number="veranstaltung.lektionen_soll"
                    class="form-control"
                    :readonly="!editable"
                    placeholder=" "
                    v-bind:class="{ 'border-danger': !passed }"
                  />
                  <label v-bind:class="{ 'text-danger': !passed }">{{ $t("global.target") }}</label>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-xl-2">
              <div class="form-group">
                <ValidationProvider
                  rules="required"
                  immediate
                  v-slot="{ passed }"
                >
                  <input
                    v-model.number="veranstaltung.lektionen_total"
                    class="form-control"
                    :readonly="!editable"
                    placeholder=" "
                    v-bind:class="{ 'border-danger': !passed }"
                  />
                  <label :class="{ 'text-danger': !passed }">{{ $t("global.total") }}</label>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-xl-2" v-if="!isTemplate">
              <div class="form-group">
                <input
                  v-model.number="veranstaltung.lektionen_differenz"
                  class="form-control"
                  placeholder=" "
                  readonly="true"
                />
                <label>{{ $t("global.difference") }}</label>
              </div>
            </div>
            <div class="col-xl-2 pr-1">
              <div class="form-group">
                <input
                  v-model.number="veranstaltung.minimalanwesenheit"
                  class="form-control"
                  :readonly="!editable"
                  placeholder=" "
                />
                <label>{{ $t("global.minimumattendance") }}</label>
              </div>
            </div>
            <div class="col-xl-1 pl-0 d-flex align-items-center">
              <span>%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";

export default {
  name: "veranstaltungLektionen",
  components: {
    ContainerHeadline,
  },
  props: {
    isTemplate: {
      type: Boolean,
      required: true,
    },
    veranstaltung: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss">
</style>

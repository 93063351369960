import {
  SET_ARTIKELTYPEN,
  SET_ARTIKELEINHEITEN,
  SET_KONTO,
  SET_KOSTENSTELLE,
  SET_ARTIKEL,
  SET_MEHRWERTSTEUERCODE
} from "./mutations.type";

import { LADE_AUSWAHLWERTE_ARTIKEL, LADE_ARTIKEL } from "./actions.type";

import Api from "../../Api";

export default {
  namespaced: true,
  state: {
    artikeltypen: [],
    artikeleinheiten: [],
    artikel: [],
    konten: [],
    kostenstellen: [],
    mehrwertsteuercodes: []
  },
  mutations: {
    [SET_ARTIKELTYPEN](state, val) {
      state.artikeltypen = val;
    },
    [SET_ARTIKELEINHEITEN](state, val) {
      state.artikeleinheiten = val;
    },
    [SET_ARTIKEL](state, val) {
      state.artikel = val;
    },
    [SET_KONTO](state, val) {
      state.konten = val;
    },
    [SET_KOSTENSTELLE](state, val) {
      state.kostenstellen = val;
    },
    [SET_MEHRWERTSTEUERCODE](state, val) {
      state.mehrwertsteuercodes = val;
    }
  },
  actions: {
    async [LADE_AUSWAHLWERTE_ARTIKEL](context) {
      let response = await Api.get("artikel/auswahlwerte/");
      context.commit(SET_ARTIKELTYPEN, response.data.artikeltypen);
      context.commit(SET_ARTIKELEINHEITEN, response.data.artikeleinheiten);
      context.commit(SET_KONTO, response.data.konten);
      context.commit(SET_KOSTENSTELLE, response.data.kostenstellen);
      context.commit(SET_MEHRWERTSTEUERCODE, response.data.mehrwertsteuercodes);
    },
    async [LADE_ARTIKEL](context) {
      let response = await Api.get("artikel/");
      context.commit(SET_ARTIKEL, response.data);
    }
  }
};

<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
          <div class="modal-header">
            <modal-close-button @confirmed="closeModal" />
            <h4 class="modal-title">{{ $t("skjv.createquestion") }}</h4>
          </div>
          <div class="modal-body">
            <div class="col-xl-12 pr-4">
              <!-- START Stammdaten -->
              <container-headline
                :headline="$t('global.masterdata')"
                :col="6"
              ></container-headline>
              
         <div class="row mb-3">
                <div class="col-xl-12 block br-t-l-0">
                  <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <v-select
                      v-model="questiontype"
                      label="designation"
                      :options="questiontypes"
                      :placeholder="$t('global.type')"
                      required
                    >
                      <span slot="no-options">{{
                        $t("global.taptosearch")
                      }}</span>
                    </v-select>
                    <label v-bind:class="{ 'text-danger': !typeValidated }">{{ $t("global.type") }}</label>
                  </div>
                </div>
                 <div class="col-xl-3">
                  <div class="form-group">
                    <input
                       class="form-control"
                       placeholder=" "
                       v-model.number="sort"
                    />
                    <label>{{ $t("global.sorting") }}</label>
                    </div>
                </div>
              </div>
                    <div class="row">
                      <div class="col-xl-12">
                        <div class="form-group">
                          <textarea
                            v-model="designation"
                            class="form-control"
                            placeholder=" "
                            rows="2"
                          ></textarea>
                          <label v-bind:class="{ 'text-danger': !desValidated }">{{ $t("global.designation") }}</label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-12">
                        <div class="form-group">
                          <textarea
                            v-model="description"
                            class="form-control"
                            placeholder=" "
                            rows="4"
                          ></textarea>
                          <label>{{ $t("global.comment") }}</label>
                        </div>
                      </div>
                    </div>
                    <div v-if="questiontype.id == '1864FF4F374'" class="row"><!--Range-->
                      <div class="col-xl-6">
                        <div class="form-group">
                          <input
                            v-model="rangestart"
                            class="form-control"
                            placeholder=""
                          />
                          <label
                            >{{ $t("skjv.start") }}
                            <!-- {{ dozent1PersonName }} --></label
                          >
                        </div>
                      </div>
                    <div class="col-xl-6">
                        <div class="form-group">
                          <input
                            v-model="rangeend"
                            class="form-control"
                            placeholder=""
                          />
                          <label
                            >{{ $t("skjv.end") }}
                            <!-- {{ dozent1PersonName }} --></label
                          >
                        </div>
                      </div>
                    </div>
                    <div v-if="questiontype.id == '18692CEBDE2'" class="row"><!--Multiplechoice-->
                      <div class="col-xl-6">
                        <div class="form-group">
                          <input
                            v-model="answercount"
                            class="form-control"
                            placeholder=""
                          />
                          <label
                            >{{ $t("skjv.answercount") }}
                            <!-- {{ dozent1PersonName }} --></label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ENDE Stammdaten -->
            
 <div v-if="questiontype.id == '18692CEBDE2' || questiontype.id == '1864FF4DEB1'"><!--Multiplechoice || Singlechoice-->
    <container-headline
      :headline="$t('skjv.answers')"
      :col="6"
    ></container-headline>

    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div
          class="eingabe-panel-kopf p-3 abteilungPanel"
          ref="abteilungPanel"
          :style="
            answers.length > 4 ? abteilungPanelStyle : 'height: 246.5px;'
          "
        >
          <div class="row">
            <div class="col-xl-12">
              <button
                class="btn btn-primary btn-sm"
                @click="answerHinzufuegen()"
              >
                <font-awesome-icon icon="fa-regular fa-plus" class="mr-1" />
                {{ $t("global.new") }}
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <b-table
                  ref="standorteTable"
                  tbody-tr-class="item"
                  show-empty
                  :items="answers"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  sort-icon-left
                  borderless
                  thead-class="d-none"
                >
                  <template
                    #cell(designation)="row"
                    class="d-flex align-items-center"
                  >
                    <input
                      v-model="row.item.designation"
                      class="form-control"
                      :placeholder="$t('skjv.answer')"
                    />
                  </template>

                  <template
                    #cell(delete)="row"
                    class="d-flex align-items-center"
                  >
                    <button
                      class="btn btn-danger btn-sm"
                      @click="answerLoeschen(row.item)"
                      style="height: 33px"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-1 pl-1"
                      />
                    </button>
                  </template>

                  <template #empty>
                    <div class="text-center text-primary my-2">
                      <strong>{{ $t("global.nodataavailable") }}</strong>
                    </div>
                  </template>

                  <template #table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"
                      :key="field.key"
                      :style="{
                        width: field.key === 'delete' ? '10%' : '90%',
                      }"
                    />
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeModal">
              {{ $t("global.cancel") }}
            </button>
            <button
              type="button"
              :disabled="!typeValidated || !desValidated"
              class="btn btn-primary"
              @click="speichern"
            >
              {{ $t("global.save") }}
            </button>
          </div>
      </div>
    </div>
    <loading-overlay v-if="loading"></loading-overlay>
  </div>
</template>


<script>
import QuestionaireApi from "@/QuestionaireApi";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import ContainerHeadline from "@/components/ContainerHeadline";

import store from "@/store";

import page from "@/mixins/Page";

import ModalCloseButton from "@/components/global/ModalCloseButton";

import LoadingOverlay from "@/components/global/LoadingOverlay";

import { LADE_AUSWAHLWERTE_QUESTIONAIRE } from "@/store/questionaire/actions.type";

export default {
  name: "NeueFrageModal",
  components: {
    ContainerHeadline,
    ModalCloseButton,
    LoadingOverlay,
  },
    mixins: [page],
  store,
  props: {
    id: {
      type: String,
      required: true,
    },
    questionaire: {
      type: String,
      required: false,
    },
    question: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      sortBy: "",
      sortDesc: false,
      loading: false,
      shown: false,
      rangestart: "",
      rangeend: "",
      answercount: "",
      description: "",
      designation: "",
      questiontype: "",
      sort: "",
      answers: [],
      answerstodelete: [],
          abteilungPanelStyle: {
        height: "246.5px",
        "overflow-y": "scroll",
      },
      typeValidated: false,
      desValidated: false
    };
  },watch: {
    questiontype(val) {
      if(val){
        this.typeValidated = true;
      }else{
        this.typeValidated = false;
      }
    },
    designation(val) {
      if(val){
        this.desValidated = true;
      }else{
        this.desValidated = false;
      }
    },
  },
  computed: {
        fields() {
      return [
        {
          key: "designation",
          sortable: true,
          label: this.$t("global.designation"),
          tdClass: "p-0 pb-2 pr-1 pl-3",
        },
        {
          key: "questiontype.designation",
          sortable: true,
          label: this.$t("global.type"),
          tdClass: "p-0 pb-2 pr-1 pl-3",
        },
        {
          key: "delete",
          sortable: false,
          label: this.$t("global.delete"),
          tdClass: "p-0 pb-2 pl-3 d-flex align-items-center",
        },
      ];
    },
    mitarbeiter: {
      get() {
        return this.$store.state.mitarbeiter;
      },
    },
    questiontypes: {
      get() {
        return this.$store.state.questionaire.questiontypes;
      },
    },
  },
  mounted() {

    if (this.questiontypes.length == 0)
      this.$store.dispatch(
        `questionaire/${LADE_AUSWAHLWERTE_QUESTIONAIRE}`
      );

    this.$bus.$on("openNeueFrageModal", (id) => {
      this.description = "";
      this.designation = "";
      this.answers = [];
      this.rangestart = "";
      this.rangeend = "";
      this.answercount = "";
      this.questiontype = "";
      this.questionID = "";
      this.validated = false;
      this.sort = 0;
      this.answerstodelete = [];
      if (!this.shown) this.openModal(id);
    });


    this.$bus.$on("editQuestions", (id, item) => {
      if (!this.shown) this.openModal(id);
        if (item) {
      this.description = item.description;
      this.designation = item.designation;
      this.answers = item.answers;
      this.rangestart = item.rangestart;
      this.rangeend = item.rangeend;
      this.answercount = item.answercount;
      this.questiontype = item.questiontype;
      this.questionID = item.id;
      this.sort = item.sort;
      this.answerstodelete = [];
    }
    });
  },
  methods: {

        openModal(id) {
      this.shown = true;

      const thisId = id ? id : this.id;

      this.reserviert_durch = this.mitarbeiter.person;

      $("#" + thisId).modal({
        backdrop: "static",
        keyboard: false,
      });
    },
    

    closeModal() {
      this.shown = false;
      $("#" + this.id).modal("hide");
    },

    answerLoeschen(answer) {
      const indexToRemove = this.answers.findIndex(item => item.id === answer.id);

      // Check if the item with the given "id" exists in the array
      if (indexToRemove !== -1) {
        // Remove the item from the array
        this.answerstodelete.push(this.answers[indexToRemove]);
        this.answers.splice(indexToRemove, 1);
      }
    },
    answerHinzufuegen() {
      this.answers.push({ id: "", designation: "" });
    },


    speichern() {
      if (this.loading) return;

      this.loading = true;
      var json = {
      questionaire : this.questionaire,
      description : this.description,
      designation : this.designation,
      answers : this.answers,
      rangestart : this.rangestart,
      rangeend : this.rangeend,
      answercount : this.answercount,
      questiontype : this.questiontype,
      answerstodelete : this.answerstodelete,
      sort : this.sort,
      };

      if (!this.questionID) {
        QuestionaireApi.post("question/" + "?qs=" + json.questionaire, json)
          .then((response) => {
            //this.initializeQuestionaire(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.questionsavedsuccessfully"),
            });

          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
            this.$emit("confirmed");
          });
      } else {
        QuestionaireApi.put("question/" + this.questionID, json)
          .then((response) => {
            //this.initializeQuestionaire(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.questionsavedsuccessfully"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
            this.$emit("confirmed");
          });
      }
      
    },
  },
};
</script>

<style>
/* Firefox */
*.abteilungPanel {
  scrollbar-width: thin;
  scrollbar-color: #deedf6 #f5fbff;
}

/* Chrome, Edge, und Safari */
*.abteilungPanel::-webkit-scrollbar {
  width: 12px;
}

*.abteilungPanel::-webkit-scrollbar-track {
  background: #f5fbff;
  border-radius: 0 0 6px 0;
}

*.abteilungPanel::-webkit-scrollbar-thumb {
  background-color: #deedf6;
  border-radius: 20px;
  border: 3px solid #f5fbff;
}
</style>

<template>
  <div id="beziehung-bearbeiten-modal" class="modal fade">
    <div class="modal-dialog modal-lg">
      <ValidationObserver v-slot="{ passed }">
        <div class="modal-content">
          <div class="modal-header">
            <modal-close-button @confirmed="closeModal" />
            <h4 class="modal-title">
              {{
                beziehung.id
                  ? $t("global.editrelationship")
                  : $t("global.addrelationship")
              }}
            </h4>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-xl-6">
                <div class="form-group">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ passed }"
                    ref="provider1"
                  >
                    <v-select
                      v-model="beziehung.relationart"
                      label="bezeichnung"
                      :options="relationartenAuswahl"
                      :clearable="false"
                      @input="resetGP"
                    ></v-select>
                    <label v-bind:class="{ 'text-danger': !passed }">{{
                      $t("global.relationshipbetween")
                    }}</label>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="form-group">
                  <v-select
                    v-model="beziehung.relationsstatus"
                    label="bezeichnung"
                    :options="relationsstati"
                    :clearable="false"
                  ></v-select>
                  <label>{{ $t("global.status") }}</label>
                </div>
              </div>
            </div>
            <div class="row" v-if="beziehung.relationart.id">
              <div class="col-xl-5">
                <!-- Person - Firma || Person - Person -->
                <single-select-person
                  v-show="beziehung.relationart.gp1typ.id == '17EF8844E6B'"
                  id="beziehungGP1"
                  :person="beziehung.defaultGP"
                  :label="$t('global.person')"
                  :editable="!beziehung.defaultGP"
                  :allowOpen="false"
                  @confirmed="setPerson"
                  :tabindex="8"
                  required
                />
                <!-- Firma - Firma || Firma - Person -->
                <single-select-firma
                  v-show="beziehung.relationart.gp1typ.id == '17EF88469AE'"
                  id="beziehungFirma1"
                  :firma="beziehung.defaultGP"
                  :label="$t('global.company')"
                  :editable="!beziehung.defaultGP"
                  :allowOpen="false"
                  @confirmed="setFirma"
                  :tabindex="8"
                  required
                />
              </div>
              <div class="col-xl-2 text-center my-auto">
                {{ $t("global.with") }}
              </div>
              <div class="col-xl-5">
                <!-- Firma - Person || Person - Person -->
                <single-select-person
                  v-if="beziehung.relationart.gp2typ.id == '17EF8844E6B'"
                  id="beziehungGP2"
                  :person="beziehung.partner"
                  :label="$t('global.person')"
                  editable
                  :allowOpen="false"
                  @confirmed="setPartnerPerson"
                  :tabindex="8"
                  required
                />
                <!-- Person - Firma || Firma - Firma -->
                <single-select-firma
                  v-if="beziehung.relationart.gp2typ.id == '17EF88469AE'"
                  id="beziehungFirma2"
                  :firma="beziehung.partner"
                  :label="$t('global.company')"
                  editable
                  :allowOpen="false"
                  @confirmed="setPartnerFirma"
                  :tabindex="8"
                  required
                />
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <div class="form-group">
                  <ValidationProvider
                    ref="provider2"
                    rules="required"
                    v-slot="{ passed }"
                  >
                    <v-select
                      v-model="beziehung.relationstyp"
                      label="bezeichnung"
                      :options="relationtypen"
                      :clearable="false"
                    ></v-select>
                    <label v-bind:class="{ 'text-danger': !passed }">{{
                      $t("global.typeofrelationship")
                    }}</label>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6">
                <div
                  v-if="
                    (beziehung.relationart.id == '18040DDBB0E' &&
                      beziehung.relationstyp.id == '17F20BA418B') ||
                    (beziehung.relationart.id == '180BD27BB10' &&
                      beziehung.relationstyp.id == '17F20BA418B')
                  "
                  class="form-check"
                  v-tooltip
                  title="Wenn ausgewählt, wird die Korrespondenz-Adresse der Firma als Kommunikationsadresse verwendet."
                >
                  <input
                    type="checkbox"
                    v-model="beziehung.use_kommunikationsadresse"
                    id="use_kommunikationsadresse"
                    class="form-check-input"
                    @input="onCheckboxChange"
                  />
                  <label>Korrespondenzadresse aus Firma verwenden</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-xl-6"
                v-if="
                  beziehung.relationstyp.id == '17F20BA418B' ||
                  beziehung.relationstyp.id == '1821FF40243'
                "
              >
                <div class="form-group">
                  <v-select
                    v-model="beziehung.abteilung"
                    label="bezeichnung"
                    :options="
                      defaultGP.abteilungen
                        ? defaultGP.abteilungen
                        : beziehung.partner.abteilungen
                        ? beziehung.partner.abteilungen
                        : []
                    "
                  ></v-select>
                  <label>{{ $t("global.companies_department") }}</label>
                </div>
              </div>
              <div
                class="col-xl-6"
                v-if="
                  beziehung.relationstyp.id == '17F20BA418B' ||
                  beziehung.relationstyp.id == '1821FF40243'
                "
              >
                <div class="form-group">
                  <v-select
                    v-model="beziehung.einsatzort"
                    label="bezeichnung"
                    :options="
                      defaultGP.standorte
                        ? defaultGP.standorte
                        : beziehung.partner.standorte
                        ? beziehung.partner.standorte
                        : []
                    "
                  ></v-select>
                  <label>{{ $t("global.location") }}</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6">
                <div class="form-group">
                  <date-picker
                    date-format="dd.mm.yy"
                    :initial="beziehung.gueltig_ab"
                    placeholder=" "
                    :show-icon="true"
                    :in-modal="true"
                    @update="(val) => (beziehung.gueltig_ab = val)"
                  />
                  <label>{{ $t("global.validfrom") }}</label>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="form-group">
                  <date-picker
                    date-format="dd.mm.yy"
                    :initial="beziehung.gueltig_bis"
                    placeholder=" "
                    :show-icon="true"
                    :in-modal="true"
                    @update="(val) => (beziehung.gueltig_bis = val)"
                  />
                  <label>{{ $t("global.validuntil") }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeModal">
              {{ $t("global.cancel") }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              :disabled="!passed"
              @click="uebernehmen"
            >
              {{ $t("global.save") }}
            </button>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import ModalCloseButton from "@/components/global/ModalCloseButton";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import SingleSelectFirma from "@/components/global/SingleSelectFirma.vue";
import DatePicker from "@/components/Datepicker";

export default {
  name: "BeziehungEintragModal",
  components: {
    ModalCloseButton,
    SingleSelectPerson,
    SingleSelectFirma,
    DatePicker,
  },
  props: {
    defaultGP: {
      type: Object,
      required: true,
    },
    gptyp: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      relationartenAuswahl: [],
      beziehung: {
        relationart: {
          gp1typ: { id: "" },
          gp2typ: { id: "" },
        },
        relationsstatus: {},
        defaultGP: {},
        firma: {},
        geschaeftspartner: {},
        firma2: {},
        relation_mit: {},
        partner: {},
        relationstyp: {},
        gueltig_ab: null,
        gueltig_bis: null,
        abteilung: {},
        einsatzort: {},
        use_kommunikationsadresse: false,
      },
      neueBeziehung: false,
      shown: false,
    };
  },
  computed: {
    relationarten() {
      if (this.$store) return this.$store.state.geschaeftspartner.relationarten;
      this.setRelationartenAuswahl();
      return [];
    },
    relationtypen() {
      if (this.$store) return this.$store.state.geschaeftspartner.relationtypen;
      return [];
    },
    relationsstati() {
      if (this.$store)
        return this.$store.state.geschaeftspartner.relationsstati;
      return [];
    },
    gp1Typ() {
      return this.relationart && this.relationart.gp1typ
        ? this.relationart.gp1typ.id
        : null;
    },
    gp2Typ() {
      return this.relationart && this.relationart.gp2typ
        ? this.relationart.gp2typ.id
        : null;
    },
  },
  watch: {
    ["beziehung.gueltig_bis"]: {
      handler: function (val) {
        if (val) {
          let heute = new Date();
          let [day, month, year] = val.split(".");
          let relationsstatus = {
            bezeichnung: "Archiviert",
            id: "18094869562",
          };
          if (heute > new Date(+year, +month - 1, +day)) {
            this.beziehung.relationsstatus = relationsstatus;
          } else {
            this.beziehung.relationsstatus = null;
          }
        }
      },
    },
  },
  mounted() {
    this.$bus.$on("beziehung-modal-show", (data) => {
      this.setRelationartenAuswahl();
      this.beziehung.defaultGP = data;
      this.neueBeziehung = true;
      if (!this.shown) this.openModal();
    });
    this.$bus.$on("beziehung-editieren", (beziehung) => {
      this.setRelationartenAuswahl();
      this.beziehung = beziehung;
      this.neueBeziehung = false;
      if (!this.shown) this.openModal();
    });
  },
  methods: {
    resetGP() {
      //Wechsel des Partners auf einen anderen Partnertyp -> Partner zurücksetzen
      if (
        this.beziehung?.relationart?.gp2typ?.bezeichnung !=
        this.beziehung.partner?.partnerTyp
      ) {
        this.beziehung.partner = {
          personName: this.$t("global.notlinked"),
          name: this.$t("global.notlinked"),
        };
      }
    },
    resetBeziehung() {
      this.beziehung = {
        relationart: {
          gp1typ: { id: "" },
          gp2typ: { id: "" },
        },
        relationsstatus: {},
        defaultGP: {},
        firma: {},
        geschaeftspartner: {},
        firma2: {},
        relation_mit: {},
        partner: {},
        relationstyp: {},
        gueltig_ab: null,
        gueltig_bis: null,
        use_kommunikationsadresse: false,
      };
    },
    setPerson(gp) {
      this.beziehung.defaultGP = gp;
    },

    setFirma(firma) {
      this.beziehung.defaultGP = firma;
    },

    setRelationartenAuswahl() {
      if (this.gptyp == "firma") {
        this.relationartenAuswahl = this.relationarten.filter(
          (art) => art.id == "18040DD8589" || art.id == "180BD27BB10"
        );
      } else {
        this.relationartenAuswahl = this.relationarten.filter(
          (art) => art.id == "18040DDBB0E" || art.id == "18098547520"
        );
      }
    },

    setPartnerPerson(partner) {
      this.beziehung.partner = partner;
      this.beziehung.partner.partnerTyp = "person";
    },

    setPartnerFirma(partner) {
      this.beziehung.partner = partner;
      this.beziehung.partner.partnerTyp = "firma";
    },

    openModal() {
      this.shown = true;

      $("#beziehung-bearbeiten-modal").modal({
        backdrop: "static",
        keyboard: false,
      });
      if (this.beziehung.relationart.id) {
        this.$refs.provider1.setFlags({
          passed: true,
        });
      }
      if (this.beziehung.relationstyp.id) {
        this.$refs.provider2.setFlags({
          passed: true,
        });
      }
    },

    closeModal() {
      this.shown = false;
      this.$refs.provider1.reset();
      this.$refs.provider2.reset();
      $("#beziehung-bearbeiten-modal").modal("hide");
      this.resetBeziehung();
    },

    onCheckboxChange() {
      if (this.use_kommunikationsadresse) {
        // Checkbox is enabled
        this.use_kommunikationsadresse = false;
      } else {
        // Checkbox is disabled
        this.use_kommunikationsadresse = true;
      }
    },

    uebernehmen() {
      if (this.neueBeziehung) {
        this.$emit("beziehungCreated", this.beziehung);
      } else {
        this.$emit("beziehungChanged", this.beziehung);
      }

      this.closeModal();
      this.resetBeziehung();
    },
  },
};
</script>

<style></style>

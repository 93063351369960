<template>
  <div v-if="klasse.faecher && klasse.faecher.length != 0">
    <div class="mr-1">
      <!-- TITEL MIT COLORPICKER -->

      <div class="row mx-0">
        <div class="block block-kopf">
          <span class="col primary-headline-text p-0">{{
            klasse.bezeichnung
          }}</span>

          <span
            class="clickable color-block-layer"
            v-if="semesterAnzeige.endnotenReiter == false"
            @click="toggleColorPanel"
            :style="{
              backgroundColor: this.promotionsstruktur.semester[index].color
                ? this.promotionsstruktur.semester[index].color
                : '#deedf6',
            }"
          >
          </span>
          <span
            v-show="showPanel"
            @focusout="toggleColorPanel"
            style="outline: none"
            ref="colorPanel"
            tabindex="0"
          >
            <color-panel
              v-model="klasse.color"
              @change="setColor(klasse.color)"
            ></color-panel
          ></span>
        </div>
      </div>

      <!-- DRAG-FELDER SEMESTER -->

      <div v-if="semesterAnzeige.endnotenReiter == false">
        <div class="row d-flex mb-3 mx-0">
          <div class="col-xl-12 block br-t-l-0">
            <b-collapse visible v-bind:id="'collapse-' + index">
              <div class="eingabe-panel-kopf p-3">
                <div
                  class="d-flex flex-wrap"
                  style="min-height: 100%"
                  @drag="setDropzoneColor()"
                  @drop="setDefaultDropzoneColor()"
                  @dragenter.prevent
                  @dragover.prevent
                >
                  <div
                    v-for="(fach, fachIndex) in klasse.faecher"
                    :key="fachIndex"
                    class="
                      m-1
                      p-2
                      eingabe-panel-kopf
                      d-flex
                      align-items-center
                      text-primary
                    "
                    :style="[
                      styleDragElement,
                      {
                        backgroundColor: promotionsstruktur.semester[index]
                          .color
                          ? promotionsstruktur.semester[index].color
                          : '#deedf6',
                      },
                    ]"
                    draggable
                    filter=".action-button"
                    @dragstart="startDrag($event, fach, 'fach')"
                  >
                    {{ fach.bezeichnungKuerzel }}
                  </div>
                </div>
              </div>

              <!-- DRAG-FELDER NOTENEINGABEN -->

              <div
                class="row my-1 d-flex align-items-center"
                v-if="
                  klasse.noteneingaben ? klasse.noteneingaben.length > 0 : false
                "
              >
                <hr
                  class="col"
                  style="border-top: 1px solid #f5fbff; margin-left: 1.5rem"
                />
                <span
                  class="col text-center"
                  style="
                    color: #f5fbff;
                    background-color: #b5cfdc;
                    border: none;
                    outline: none;
                    height: 100% !important;
                  "
                >
                  {{ $t("global.scoreentries") }}
                </span>
                <hr
                  class="col"
                  style="border-top: 1px solid #f5fbff; margin-right: 1.5rem"
                />
              </div>
              <div
                class="eingabe-panel-kopf p-3"
                v-if="
                  klasse.noteneingaben ? klasse.noteneingaben.length > 0 : false
                "
              >
                <div
                  class="d-flex flex-wrap"
                  style="min-height: 100%"
                  @drag="setDropzoneColor()"
                  @drop="setDefaultDropzoneColor()"
                  @dragenter.prevent
                  @dragover.prevent
                >
                  <div
                    v-for="(
                      noteneingabe, noteneingabeIndex
                    ) in klasse.noteneingaben"
                    :key="noteneingabeIndex"
                    class="
                      m-1
                      p-2
                      eingabe-panel-kopf
                      d-flex
                      align-items-center
                      text-primary
                    "
                    :style="[
                      styleDragElement,
                      {
                        backgroundColor: promotionsstruktur.semester[index]
                          .color
                          ? promotionsstruktur.semester[index].color
                          : '#deedf6',
                      },
                    ]"
                    draggable
                    filter=".action-button"
                    @dragstart="startDrag($event, noteneingabe, 'noteneingabe')"
                  >
                    {{ noteneingabe.bezeichnung }}
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <div
            class="
              clickable
              col-1
              rounded-bottom
              d-flex
              justify-content-center
              p-1
              ml-auto
              mr-2
            "
            style="background-color: #b5cfdc"
            v-b-toggle="'collapse-' + index"
            @click.prevent
          >
            <div class="when-open">
              <font-awesome-icon icon="fa-regular fa-angle-up" size="lg" />
            </div>
            <div class="when-closed">
              <font-awesome-icon icon="fa-regular fa-angle-down" size="lg" />
            </div>
          </div>
        </div>
      </div>

      <!-- DRAG-FELDER ENDNOTE -->

      <div v-if="semesterAnzeige.endnotenReiter == true">
        <div class="row d-flex mb-3 mx-0">
          <div class="col-xl-12 block br-t-l-0">
            <b-collapse visible v-bind:id="'collapse-' + 'endnote-' + index">
              <div class="row mb-1 d-flex align-items-center">
                <hr
                  class="col"
                  style="border-top: 1px solid #f5fbff; margin-left: 1.5rem"
                />
                <span
                  class="col text-center"
                  style="
                    color: #f5fbff;
                    background-color: #b5cfdc;
                    border: none;
                    outline: none;
                    height: 100% !important;
                  "
                >
                  {{ $t("global.ownsectiongroups") }}
                </span>
                <hr
                  class="col"
                  style="border-top: 1px solid #f5fbff; margin-right: 1.5rem"
                />
              </div>

              <!-- DRAG-FELDER SEKTIONSGRUPPEN -->

              <div class="eingabe-panel-kopf p-3">
                <div
                  class="d-flex flex-wrap"
                  style="min-height: 100%"
                  @drag="setDropzoneColor()"
                  @drop="setDefaultDropzoneColor()"
                  @dragenter.prevent
                  @dragover.prevent
                >
                  <div
                    v-for="(sektionsgruppe, index2) in promotionsstruktur
                      .semester[index].sektionsgruppen"
                    :key="index2"
                    class="
                      m-1
                      p-2
                      eingabe-panel-kopf
                      d-flex
                      align-items-center
                      text-primary
                    "
                    :style="[
                      styleDragElement,
                      {
                        backgroundColor: klasse.color
                          ? klasse.color
                          : '#deedf6',
                      },
                    ]"
                    draggable
                    filter=".action-button"
                    @dragstart="
                      startDrag($event, sektionsgruppe, 'sektionsgruppe')
                    "
                  >
                    {{ sektionsgruppe.bezeichnung }}
                  </div>
                </div>
              </div>

              <!-- DRAG-FELDER FAECHER -->

              <div class="row my-1 d-flex align-items-center">
                <hr
                  class="col"
                  style="border-top: 1px solid #f5fbff; margin-left: 1.5rem"
                />
                <span
                  class="col text-center"
                  style="
                    color: #f5fbff;
                    background-color: #b5cfdc;
                    border: none;
                    outline: none;
                    height: 100% !important;
                  "
                >
                  {{ $t("global.subjects") }}
                </span>
                <hr
                  class="col"
                  style="border-top: 1px solid #f5fbff; margin-right: 1.5rem"
                />
              </div>
              <div class="eingabe-panel-kopf p-3">
                <div
                  class="d-flex flex-wrap"
                  style="min-height: 100%"
                  @drag="setDropzoneColor()"
                  @drop="setDefaultDropzoneColor()"
                  @dragenter.prevent
                  @dragover.prevent
                >
                  <div
                    v-for="(fach, fachIndex) in klasse.faecher"
                    :key="fachIndex"
                    class="
                      m-1
                      p-2
                      eingabe-panel-kopf
                      d-flex
                      align-items-center
                      text-primary
                    "
                    :style="[
                      styleDragElement,
                      {
                        backgroundColor: promotionsstruktur.semester[index]
                          .color
                          ? promotionsstruktur.semester[index].color
                          : '#deedf6',
                      },
                    ]"
                    draggable
                    filter=".action-button"
                    @dragstart="startDrag($event, fach, 'fach')"
                  >
                    {{ fach.bezeichnungKuerzel }}
                  </div>
                </div>
              </div>

              <!-- DRAG-FELDER NOTENEINGABEN -->

              <div
                class="row my-1 d-flex align-items-center"
                v-if="
                  klasse.noteneingaben ? klasse.noteneingaben.length > 0 : false
                "
              >
                <hr
                  class="col"
                  style="border-top: 1px solid #f5fbff; margin-left: 1.5rem"
                />
                <span
                  class="col text-center"
                  style="
                    color: #f5fbff;
                    background-color: #b5cfdc;
                    border: none;
                    outline: none;
                    height: 100% !important;
                  "
                >
                  {{ $t("global.scoreentries") }}
                </span>
                <hr
                  class="col"
                  style="border-top: 1px solid #f5fbff; margin-right: 1.5rem"
                />
              </div>
              <div
                class="eingabe-panel-kopf p-3"
                v-if="
                  klasse.noteneingaben ? klasse.noteneingaben.length > 0 : false
                "
              >
                <div
                  class="d-flex flex-wrap"
                  style="min-height: 100%"
                  @drag="setDropzoneColor()"
                  @drop="setDefaultDropzoneColor()"
                  @dragenter.prevent
                  @dragover.prevent
                >
                  <div
                    v-for="(
                      noteneingabe, noteneingabeIndex
                    ) in klasse.noteneingaben"
                    :key="noteneingabeIndex"
                    class="
                      m-1
                      p-2
                      eingabe-panel-kopf
                      d-flex
                      align-items-center
                      text-primary
                    "
                    :style="[
                      styleDragElement,
                      {
                        backgroundColor: promotionsstruktur.semester[index]
                          .color
                          ? promotionsstruktur.semester[index].color
                          : '#deedf6',
                      },
                    ]"
                    draggable
                    filter=".action-button"
                    @dragstart="startDrag($event, noteneingabe, 'noteneingabe')"
                  >
                    {{ noteneingabe.bezeichnung }}
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <div
            class="
              clickable
              col-1
              rounded-bottom
              d-flex
              justify-content-center
              p-1
              ml-auto
              mr-2
            "
            style="background-color: #b5cfdc"
            v-b-toggle="'collapse-' + 'endnote-' + index"
            @click.prevent
          >
            <div class="when-open">
              <font-awesome-icon icon="fa-regular fa-angle-up" size="lg" />
            </div>
            <div class="when-closed">
              <font-awesome-icon icon="fa-regular fa-angle-down" size="lg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ColorPanel } from "one-colorpicker";

export default {
  name: "VorlagePromotionsstrukturNotenuebersicht",
  components: {
    "color-panel": ColorPanel,
  },
  props: {
    klassen: {
      Semesternotensektion: { type: Array },
    },
    klasse: {
      Semesternotensektion: { type: Array },
    },
    styleDragElement: { type: Object, required: true },
    styleDropzone: { type: Object, required: true },
    promotionsstruktur: { type: Object, required: true },
    index: { type: Number, required: true },
    startDrag: { type: Function },
    setDropzoneColor: { type: Function, required: true },
    setDefaultDropzoneColor: { type: Function, required: true },
    dragObject: { type: Object },
    semesterAnzeige: { type: Object, required: true },
  },
  data() {
    return {
      showPanel: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    toggleColorPanel() {
      this.showPanel = !this.showPanel;
      if (this.showPanel == true) {
        this.$nextTick(() => this.$refs.colorPanel.focus());
      }
    },

    setColor(color) {
      this.promotionsstruktur.semester[this.index].color = color;
      this.$emit("farbeGeaendert");
    },
  },
};
</script>
<style lang="scss">
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.color-block-layer {
  display: inline-block;
  position: relative;
  height: 20px !important;
  width: 20px !important;
  margin-left: 12px;
  border: 2px solid #124862;
  border-radius: 5px;
  top: 10%;
}

.one-colorpanel {
  position: absolute;
  z-index: 2;
  left: 30%;
}
</style>

<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">{{ $t("global.simulatedegrees") }}</h4>
        </div>
        <div class="modal-body">
          <div class="col-xl-12 p-0">
            <div class="form-group">
              <v-select
                v-model="abschluss"
                :options="abschluesse"
                label="designation"
                :placeholder="$t('global.degree')"
              >
                <span slot="no-options">{{ $t("global.nodegreefound") }}</span>
              </v-select>
              <label>{{ $t("global.degree") }}</label>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="btn btn-default" @click="close">
            {{ $t("global.cancel") }}
          </button>
          <button
            :disabled="!abschluss"
            type="button"
            class="btn btn-success"
            @click="simulieren"
          >
            {{ $t("global.simulate") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { modal } from "@/mixins/Modal";
import ModalCloseButton from "@/components/global/ModalCloseButton.vue";


export default {
  name: "TorAbschlussWaehlenModal",
  components:{ ModalCloseButton },
  mixins: [modal],
  data() {
    return {
      abschluss: null,
    };
  },
  props: {
    id: {
      type: String,
      default: "tor-abschluss-waehlen-modal",
    },
    abschluesse: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.$bus.$on("openAbschlussWaehlenModal", (ids) => {
      if (ids.modalId == this.id && !this.shown) {
        this.openModal();
      }
    });
  },
  computed: {},
  methods: {
    close() {
      this.closeModal();
      this.$router.push({ name: "Startseite" });
    },
    simulieren() {
      this.$emit("setAbschluss", this.abschluss);
      this.closeModal();
      this.abschluss = null;
    },
  },
};
</script>

  <style>
</style>

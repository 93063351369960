import Artikelliste from "@/pages/Dashboardlisten/Artikelliste";
import Artikel from "@/pages/Finanzen/Artikel";

export default [
    {
        path: "/Finanzen/Artikelliste",
        name: "artikelliste",
        component: Artikelliste
    },
    {
        path: "/Finanzen/Artikel/:artikelid?/:anzeige",
        name: "artikel",
        component: Artikel,
        props: route => {
            const a = parseInt(route.params.anzeige, 10);
            return {
                artikelid: route.params.artikelid,
                anzeige: isNaN(a) ? 0 : a
            };
        }
    },
];

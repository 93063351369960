import Dozentenbuchung from "@/pages/Bildung/Dozent/Dozentenbuchung";
import DozentenbuchungListe from "@/pages/Dashboardlisten/Dozentenbuchungsliste";
import DozenteneinsatzListe from "@/pages/Dashboardlisten/Dozenteneinsatzliste";
import Dozenteneinsatz from "@/pages/Bildung/Dozent/Dozenteneinsatz";
import Praesenzliste from "@/pages/Dashboardlisten/Praesenzliste"

export default [
  {
    path: "/Dozierende/praesenzliste",
    name: "praesenz-liste",
    component: Praesenzliste
  },
  {
    path: "/Bildung/Dozentenbuchungsliste",
    name: "dozentenbuchung-liste",
    component: DozentenbuchungListe,
  },
  {
    path: "/Bildung/Dozentenbuchung/:id?/:anzeige",
    name: "dozentenbuchung",
    component: Dozentenbuchung,
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
  },
  {
    path: "/Bildung/Dozenteneinsatzliste",
    name: "dozenteneinsatz-liste",
    component: DozenteneinsatzListe,
  },
  {
    path: "/Bildung/Dozenteneinsatz/:id?/:anzeige",
    name: "dozenteneinsatz",
    component: Dozenteneinsatz,
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
  },

  /*** Muss überarbeitet werden ***/

  {
    path: "/bildung/dozentenbuchung/:id",
    name: "DozentenbuchungMitId",
    props: true,
    component: () => import("@/pages/Bildung/Dozent/Dozentenbuchung"),
  },
  {
    path: "/bildung/dozenteneinsatzliste",
    name: "Dozenteneinsatzliste",
    component: () => import("@/pages/Dashboardlisten/Dozenteneinsatzliste"),
  },
];

import { SET_FILTER } from "./mutations.type";

export default {
  namespaced: true,
  state: {
    listenfilter: {}
  },
  mutations: {
    [SET_FILTER](state, val) {
      state.listenfilter = val;
    }
  }
};

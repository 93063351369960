const dayInMillis = 86400000;

Date.prototype.addDays = function (days) {
  return new Date(this.getTime() + days * dayInMillis);
};

Date.prototype.toGermanDateString = function () {
  return this.toLocaleDateString("de-DE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

Date.prototype.getLastDayInMonth = function () {
  return 32 - new Date(this.getFullYear(), this.getMonth(), 32).getDate();
};

Date.prototype.getLastDateInMonth = function () {
  return new Date(
    this.getFullYear(),
    this.getMonth(),
    this.getLastDayInMonth()
  );
};

Date.prototype.getFirstDateInMonth = function () {
  return new Date(this.getFullYear(), this.getMonth(), 1);
};

Date.prototype.getLastDateInYear = function () {
  return new Date(this.getFullYear(), 11, 31);
};

Date.prototype.getPreviousDate = function () {
  return new Date(this.getFullYear(), this.getMonth(), this.getDate() - 1);
};

<template>
  <div>
    <!-- TITEL SEKTIONSGRUPPE -->

    <div class="ml-1">
      <div class="row mx-0">
        <div class="block block-kopf col-5 d-flex align-items-center">
          <label class="col p-0 mb-0">
            <input
              class="col-11 primary-headline-text p-0 float-lef"
              type="text"
              :placeholder="$t('global.ownsectiongroup')"
              v-model="sektionsgruppe.bezeichnung"
              style="
                color: #124862;
                background-color: #b5cfdc;
                border: none;
                outline: none;
                height: 100% !important;
              "
            />
            <font-awesome-icon
              class="clickable"
              icon="fa-regular fa-pencil"
              :style="{ top: '18%', left: '95%' }"
            />
          </label>
        </div>
      </div>

      <!-- SEKTIONSGRUPPE INKL. BUTTONS -->

      <div class="row mx-0 mb-3">
        <div class="col-xl-12 block br-t-l-0">
          <b-collapse
            visible
            v-bind:id="'collapse-' + 'sektionsgruppe' + sektionsgruppenIndex"
          >
            <div class="row mx-0">
              <div
                v-for="(sektion, index) in sektionsgruppe.sektionen"
                :key="index"
                class="col-12 px-1 pt-1 pb-0"
              >
                <!-- SEKTIONSTITEL -->

                <div class="row mb-1 d-flex align-items-center">
                  <hr
                    class="col"
                    style="border-top: 1px solid #f5fbff; margin-left: 1.5rem"
                  />
                  <label class="col p-0 m-0">
                    <input
                      class="col text-center"
                      type="text"
                      :placeholder="$t('global.section')"
                      v-model="sektion.bezeichnung"
                      style="
                        color: #f5fbff;
                        background-color: #b5cfdc;
                        border: none;
                        outline: none;
                        height: 100% !important;
                      "
                    />

                    <font-awesome-icon
                      icon="fa-regular fa-pencil"
                      class="col-1 p-0 position-absolute clickable"
                      :style="{ color: '#f5fbff', top: '22%', right: '2%' }"
                    />
                  </label>
                  <hr
                    class="col"
                    style="border-top: 1px solid #f5fbff; margin-right: 1.5rem"
                  />
                </div>

                <!-- SEKTION SEMESTER -->
                <div class="row mx-0">
                  <div class="col-2 order-2 p-0 pl-1 d-flex flex-wrap">
                    <div
                      class="eingabe-panel-kopf p-3 mb-1 d-flex align-items-center"
                    >
                      <input
                        v-model.number="sektion.gewichtung"
                        class="form-control"
                        :placeholder="$t('global.weighting')"
                      />
                    </div>
                  </div>
                  <div class="col-10 mx-0 p-0">
                    <div
                      v-for="(
                        sektionsfeld, sektionsfeldIndex
                      ) in sektion.sektionsfelder"
                      :key="sektionsfeldIndex"
                    >
                      <div
                        class="col-12 eingabe-panel-kopf p-3 mb-1 float-left"
                      >
                        <div class="row mx-0 mt-2">
                          <div
                            class="col-9 d-flex flex-wrap p-3 mb-3"
                            style="min-height: 60px"
                            :style="styleDropzone"
                            @drag="setDropzoneColor()"
                            @drop="
                              onDrop(index, sektionsfeldIndex),
                                setDefaultDropzoneColor()
                            "
                            @dragenter.prevent
                            @dragover.prevent
                          >
                            <div v-if="endnotenReiter == false">
                              <div
                                v-for="(
                                  fach, fachIndex
                                ) in sektionsfeld.faecher"
                                :key="fachIndex"
                                :style="[
                                  styleDragElement,
                                  {
                                    backgroundColor: fach.color
                                      ? fach.color
                                      : '#deedf6',
                                  },
                                ]"
                                class="m-1 px-2 eingabe-panel-kopf d-inline-flex align-items-center text-primary"
                                draggable
                                filter=".action-button"
                                @dragstart="startDrag($event, fach, 'fach')"
                              >
                                <span>
                                  {{ fach.bezeichnungKuerzel }}
                                </span>
                                <div
                                  style="
                                    border-left: 1px solid #124862;
                                    height: 5%;
                                  "
                                  class="clickable d-flex align-items-center float-right py-3 px-2 ml-3"
                                  @click="
                                    deleteFach(
                                      index,
                                      sektionsfeldIndex,
                                      fachIndex,
                                      'fach'
                                    )
                                  "
                                >
                                  <font-awesome-icon
                                    icon="fa-light fa-times"
                                    size="sm"
                                    class="ml-1"
                                  />
                                </div>
                              </div>

                              <div
                                v-for="(
                                  noteneingabe, noteneingabeIndex
                                ) in sektionsfeld.noteneingaben"
                                :key="'Noteneingabe' + noteneingabeIndex"
                                :style="[
                                  styleDragElement,
                                  {
                                    backgroundColor: noteneingabe.color
                                      ? noteneingabe.color
                                      : '#deedf6',
                                  },
                                ]"
                                class="m-1 px-2 eingabe-panel-kopf d-inline-flex align-items-center text-primary"
                                draggable
                                filter=".action-button"
                                @dragstart="
                                  startDrag(
                                    $event,
                                    noteneingabe,
                                    'noteneingabe'
                                  )
                                "
                              >
                                <span>
                                  {{ noteneingabe.bezeichnung }}
                                </span>
                                <div
                                  style="
                                    border-left: 1px solid #124862;
                                    height: 5%;
                                  "
                                  class="clickable d-flex align-items-center float-right py-3 px-2 ml-3"
                                  @click="
                                    deleteFach(
                                      index,
                                      sektionsfeldIndex,
                                      noteneingabeIndex,
                                      'noteneingabe'
                                    )
                                  "
                                >
                                  <font-awesome-icon
                                    icon="fa-light fa-times"
                                    size="sm"
                                    class="ml-1"
                                  />
                                </div>
                              </div>
                            </div>

                            <!-- SEKTION ENDNOTE -->

                            <div v-if="endnotenReiter == true">
                              <div
                                v-for="(
                                  fach, endnotenfachIndex
                                ) in sektionsfeld.faecher"
                                :key="'endnotenfach ' + endnotenfachIndex"
                                :style="[
                                  styleDragElement,
                                  {
                                    backgroundColor: fach.color
                                      ? fach.color
                                      : '#deedf6',
                                  },
                                ]"
                                class="m-1 px-2 eingabe-panel-kopf d-inline-flex align-items-center text-primary"
                                draggable
                                filter=".action-button"
                                @dragstart="startDrag($event, fach, 'fach')"
                              >
                                <span>
                                  {{ fach.bezeichnung }}
                                </span>
                                <div
                                  style="
                                    border-left: 1px solid #124862;
                                    height: 5%;
                                  "
                                  class="clickable d-flex align-items-center float-right py-3 px-2 ml-3"
                                  @click="
                                    deleteFach(
                                      index,
                                      sektionsfeldIndex,
                                      endnotenfachIndex,
                                      'fach'
                                    )
                                  "
                                >
                                  <font-awesome-icon
                                    icon="fa-light fa-times"
                                    size="sm"
                                    class="ml-1"
                                  />
                                </div>
                              </div>

                              <div
                                v-for="(
                                  endnotensektionsgruppe,
                                  endnotensektionsgruppeIndex
                                ) in sektionsfeld.endnotensektionsgruppen"
                                :key="endnotensektionsgruppeIndex"
                                :style="[
                                  styleDragElement,
                                  {
                                    backgroundColor:
                                      endnotensektionsgruppe.color
                                        ? endnotensektionsgruppe.color
                                        : '#deedf6',
                                  },
                                ]"
                                class="m-1 px-2 eingabe-panel-kopf d-inline-flex align-items-center text-primary"
                                draggable
                                filter=".action-button"
                                @dragstart="
                                  startDrag(
                                    $event,
                                    endnotensektionsgruppe,
                                    'sektionsgruppe'
                                  )
                                "
                              >
                                <span>
                                  {{ endnotensektionsgruppe.bezeichnung }}
                                </span>
                                <div
                                  style="
                                    border-left: 1px solid #124862;
                                    height: 5%;
                                  "
                                  class="clickable d-flex align-items-center float-right py-3 px-2 ml-3"
                                  @click="
                                    deleteFach(
                                      index,
                                      sektionsfeldIndex,
                                      endnotensektionsgruppeIndex,
                                      'sektionsgruppe'
                                    )
                                  "
                                >
                                  <font-awesome-icon
                                    icon="fa-light fa-times"
                                    size="sm"
                                    class="ml-1"
                                  />
                                </div>
                              </div>

                              <div
                                v-for="(
                                  endnoteneingabe, endnoteneingabeIndex
                                ) in sektionsfeld.endnotenNoteneingaben"
                                :key="'Endnoteneingabe' + endnoteneingabeIndex"
                                :style="[
                                  styleDragElement,
                                  {
                                    backgroundColor: endnoteneingabe.color
                                      ? endnoteneingabe.color
                                      : '#deedf6',
                                  },
                                ]"
                                class="m-1 px-2 eingabe-panel-kopf d-inline-flex align-items-center text-primary"
                                draggable
                                filter=".action-button"
                                @dragstart="
                                  startDrag(
                                    $event,
                                    endnoteneingabe,
                                    'noteneingabe'
                                  )
                                "
                              >
                                <span>
                                  {{ endnoteneingabe.bezeichnung }}
                                </span>
                                <div
                                  style="
                                    border-left: 1px solid #124862;
                                    height: 5%;
                                  "
                                  class="clickable d-flex align-items-center float-right py-3 px-2 ml-3"
                                  @click="
                                    deleteFach(
                                      index,
                                      sektionsfeldIndex,
                                      endnoteneingabeIndex,
                                      'noteneingabe'
                                    )
                                  "
                                >
                                  <font-awesome-icon
                                    icon="fa-light fa-times"
                                    size="sm"
                                    class="ml-1"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- SEKTIONSFELD DROPDOWN / BUTTONS / GEWICHTUNG -->
                          <div
                            class="col-1 mr-2 d-flex align-items-center position-absolute"
                            style="top: 5px; left: 0"
                          >
                            <select
                              style="
                                background-color: #f5fbff;
                                color: #b5cfdc;
                                border: none;
                                width: fit-content;
                              "
                              class="clickable"
                              v-if="endnotenReiter == false"
                              name="notentyp"
                              v-model="sektionsfeld.notentyp"
                              label="bezeichnung"
                            >
                              <option :value="null" disabled selected hidden>
                                {{ $t("global.scoretype") }}
                              </option>
                              <option :value="{}" disabled selected hidden>
                                {{ $t("global.scoretype") }}
                              </option>
                              <option
                                :value="sektionsfeld.notentyp"
                                disabled
                                selected
                                hidden
                              >
                                {{
                                  sektionsfeld.notentyp
                                    ? sektionsfeld.notentyp.bezeichnung
                                    : $t("global.scoretype")
                                }}
                              </option>
                              <option
                                v-for="option in notentypen"
                                :key="option.bezeichnung"
                                :value="{
                                  id: option.id,
                                  bezeichnung: option.bezeichnung,
                                }"
                              >
                                <span>
                                  {{ option.bezeichnung }}
                                </span>
                              </option>
                            </select>
                          </div>
                          <div
                            class="col-3 p-0 pl-3 py-5 d-flex align-items-center"
                          >
                            <input
                              v-model.number="sektionsfeld.gewichtung"
                              class="form-control"
                              placeholder="Gewichtung"
                            />
                            <div class="pl-3 d-block m-auto">
                              <div
                                class="clickable"
                                @click="addSektionsfeld(index)"
                              >
                                <font-awesome-icon
                                  icon="fa-duotone fa-layer-plus"
                                  :style="{ color: '#b5cfdc' }"
                                />
                              </div>
                              <div
                                class="clickable"
                                @click="
                                  removeSektionsfeld(index, sektionsfeldIndex)
                                "
                              >
                                <font-awesome-icon
                                  icon="fa-duotone fa-trash"
                                  :style="{ color: '#b5cfdc' }"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-12 mr-2 d-flex align-items-center position-absolute"
                            style="bottom: 0; left: 0"
                          >
                            <hr
                              class="col"
                              style="
                                border-top: 1px solid #124862;
                                margin-left: 1.5rem;
                              "
                            />
                            <label class="col-5 p-0 m-0">
                              <input
                                class="text-center w-100"
                                type="text"
                                :placeholder="$t('global.sectionfield')"
                                v-model="sektionsfeld.bezeichnung"
                                style="
                                  color: #124862;
                                  background-color: #f5fbff;
                                  border: none;
                                  outline: none;
                                  height: 100% !important;
                                "
                              />
                              <font-awesome-icon
                                icon="fa-regular fa-pencil"
                                class="p-0 position-absolute clickable"
                                :style="{
                                  color: '#124862',
                                  top: '22%',
                                  right: '2%',
                                }"
                              />
                            </label>
                            <hr
                              class="col"
                              style="
                                border-top: 1px solid #124862;
                                margin-right: 1.5rem;
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>

        <!-- SEKTIONEN HINZUFÜGEN/ENTFERNEN/AUSBLENDEN -->

        <div
          class="col-1 rounded-bottom d-flex justify-content-center mr-auto ml-2 p-1"
          style="background-color: #b5cfdc; height: 45px"
        >
          <div
            class="clickable d-flex align-items-center mr-2"
            @click="addSektion"
          >
            <font-awesome-icon icon="fa-regular fa-plus" size="sm" />
          </div>
          <div
            class="clickable d-flex align-items-center"
            @click="removeSektion"
          >
            <font-awesome-icon icon="fa-regular fa-minus" size="sm" />
          </div>
        </div>
        <div
          class="col-4 rounded-bottom d-flex justify-content-center ml-auto mr-2 py-1 px-2"
          style="background-color: #b5cfdc; height: 45px"
        >
          <!--  NOTENEINGABE & BERECHNUNG CHECKBOX - FUNKTION VORERST RAUSGENOMMEN, WIRD EVTL. IN ZUKUNFT WIEDER GEBRAUCHT
          <div class="d-flex justify-content-center align-items-center mx-2">
            <b-form-checkbox v-model="sektionsgruppe.relevanzNoteneingabe">
              <span> Noteneingabe </span>
            </b-form-checkbox>
          </div>

          <div class="d-flex justify-content-center align-items-center mx-2">
            <b-form-checkbox v-model="sektionsgruppe.relevanzBerechnung">
              <span> Berechnung </span>
            </b-form-checkbox>
          </div>
        -->
          <div class="d-flex flex-wrap mx-2">
            <input
              v-model.number="sektionsgruppe.gesamtgewichtung"
              class="form-control mb-4"
              :placeholder="$t('global.totalweighting')"
            />
          </div>
          <div
            class="clickable d-flex justify-content-center align-items-center mx-2"
            style="width: 40px"
            v-b-toggle="'collapse-' + 'sektionsgruppe' + sektionsgruppenIndex"
            @click.prevent
          >
            <div class="when-open">
              <font-awesome-icon icon="fa-regular fa-angle-up" size="lg" />
            </div>
            <div class="when-closed">
              <font-awesome-icon icon="fa-regular fa-angle-down" size="lg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VorlagePromotionsstrukturNotensektion",
  components: {},
  props: {
    styleDragElement: {
      type: Object,
    },
    styleDropzone: {
      type: Object,
    },
    sektionsgruppe: {
      type: Object,
      required: true,
    },
    sektionsgruppenIndex: {
      type: Number,
      required: true,
    },
    dragObject: {
      type: Object,
    },
    startDrag: {
      type: Function,
      required: true,
    },
    semesterAnzeige: {
      type: Object,
      required: true,
    },
    notentypen: {
      type: Array,
    },
    endnotenReiter: { type: Boolean, required: true },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    onDrop(index, sektionsfeldIndex) {
      //Sektionsfeldbezeichnung setzen
      let sektionsfeldBezeichnung =
        this.sektionsgruppe.sektionen[index].sektionsfelder[sektionsfeldIndex]
          .bezeichnung;
      if (
        sektionsfeldBezeichnung == null ||
        sektionsfeldBezeichnung == "" ||
        sektionsfeldBezeichnung == "Sektionsfeld"
      ) {
        this.sektionsgruppe.sektionen[index].sektionsfelder[
          sektionsfeldIndex
        ].bezeichnung = this.dragObject.bezeichnung;
      }

      //dragObject anhand Typ ins entsprechende Array füllen
      if (this.dragObject.typ == "sektionsgruppe") {
        this.sektionsgruppe.sektionen[index].sektionsfelder[
          sektionsfeldIndex
        ].endnotensektionsgruppen.push(this.dragObject);
      } else if (this.dragObject.typ == "noteneingabe") {
        if (this.endnotenReiter == true) {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].endnotenNoteneingaben.push(this.dragObject);
        } else {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].noteneingaben.push(this.dragObject);
        }
      } else {
        if (this.endnotenReiter == true) {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].faecher.push(this.dragObject);
        } else {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].faecher.push(this.dragObject);
        }
      }
      /*
      if (!this.dragObject.istNoteneingabe) {
        let bezeichnung =
          this.sektionsgruppe.sektionen[index].sektionsfelder[sektionsfeldIndex]
            .bezeichnung;
        if (this.endnotenReiter == false) {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].faecher.push(this.dragObject);

          if (
            bezeichnung == null ||
            bezeichnung == "" ||
            bezeichnung == "Sektionsfeld"
          ) {
            this.sektionsgruppe.sektionen[index].sektionsfelder[
              sektionsfeldIndex
            ].bezeichnung = this.dragObject.bezeichnung;
          }
        } else {
          if ("durchschnitt" in this.dragObject) {
            //this.dragObject.hasOwnProperty("durchschnitt")) {
            this.sektionsgruppe.sektionen[index].sektionsfelder[
              sektionsfeldIndex
            ].endnotensektionsgruppen.push(this.dragObject);
            if (
              bezeichnung == null ||
              bezeichnung == "" ||
              bezeichnung == "Sektionsfeld"
            ) {
              this.sektionsgruppe.sektionen[index].sektionsfelder[
                sektionsfeldIndex
              ].bezeichnung = this.dragObject.bezeichnung;
            }
          } else {
            this.dragObject.istFach = true;
            this.sektionsgruppe.sektionen[index].sektionsfelder[
              sektionsfeldIndex
            ].endnotensektionsgruppen.push(this.dragObject);
            if (
              bezeichnung == null ||
              bezeichnung == "" ||
              bezeichnung == "Sektionsfeld"
            ) {
              this.sektionsgruppe.sektionen[index].sektionsfelder[
                sektionsfeldIndex
              ].bezeichnung = this.dragObject.bezeichnung;
            }
          }
        }
      } else {
        let bezeichnung =
          this.sektionsgruppe.sektionen[index].sektionsfelder[sektionsfeldIndex]
            .bezeichnung;
        if (this.endnotenReiter == false) {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].noteneingaben.push(this.dragObject);

          if (
            bezeichnung == null ||
            bezeichnung == "" ||
            bezeichnung == "Sektionsfeld"
          ) {
            this.sektionsgruppe.sektionen[index].sektionsfelder[
              sektionsfeldIndex
            ].bezeichnung = this.dragObject.bezeichnung;
          }
        } else {
          //durchschnitt abfragen?
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].endnotenNoteneingaben.push(this.dragObject);

          if (
            bezeichnung == null ||
            bezeichnung == "" ||
            bezeichnung == "Sektionsfeld"
          ) {
            this.sektionsgruppe.sektionen[index].sektionsfelder[
              sektionsfeldIndex
            ].bezeichnung = this.dragObject.bezeichnung;
          }
        }
      }*/
    },
    setDropzoneColor() {
      this.styleDropzone.borderStyle = "dashed";
      this.styleDropzone.borderColor = "#b5cfdc";
    },
    setDefaultDropzoneColor() {
      this.styleDropzone.borderColor = "#f5fbff";
    },
    deleteFach(index, sektionsfeldIndex, objektIndex, typ) {
      if (typ === "sektionsgruppe") {
        if (this.endnotenReiter == true) {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].endnotensektionsgruppen.splice(objektIndex, 1);
        }
      } else if (typ === "noteneingabe") {
        if (this.endnotenReiter == true) {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].endnotenNoteneingaben.splice(objektIndex, 1);
        } else {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].noteneingaben.splice(objektIndex, 1);
        }
      } else {
        if (this.endnotenReiter == true) {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].faecher.splice(objektIndex, 1);
        } else {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].faecher.splice(objektIndex, 1);
        }
      }

      /*
      if (this.endnotenReiter == false) {
        if (istNoteneingabe == true) {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].noteneingaben.splice(fachIndex, 1);
        } else {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].faecher.splice(fachIndex, 1);
        }
      } else {
        if (istNoteneingabe == true) {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].endnotenNoteneingaben.splice(fachIndex, 1);
        } else {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].endnotensektionsgruppen.splice(fachIndex, 1);
        }
      }*/
    },
    addSektion() {
      if (this.endnotenReiter == false) {
        this.sektionsgruppe.sektionen.push({
          bezeichnung: "",
          sektionsfelder: [
            {
              bezeichnung: "",
              faecher: [],
              gewichtung: "",
              notentyp: { bezeichnung: "Notentyp" },
            },
          ],
        });
      } else {
        this.sektionsgruppe.sektionen.push({
          bezeichnung: "",
          sektionsfelder: [
            {
              bezeichnung: "",
              faecher: [],
              endnotensektionsgruppen: [],
              gewichtung: "",
            },
          ],
        });
      }
    },
    removeSektion() {
      this.sektionsgruppe.sektionen.pop();
    },
    addSektionsfeld(index) {
      if (this.endnotenReiter == false) {
        this.sektionsgruppe.sektionen[index].sektionsfelder.push({
          bezeichnung: "",
          faecher: [],
          gewichtung: "",
          notentyp: { bezeichnung: "Notentyp" },
        });
      } else {
        this.sektionsgruppe.sektionen[index].sektionsfelder.push({
          bezeichnung: "",
          faecher: [],
          endnotensektionsgruppen: [],
          gewichtung: "",
        });
      }
    },
    removeSektionsfeld(index, sektionsfeldIndex) {
      if (this.sektionsgruppe.sektionen[index].sektionsfelder.length > 1) {
        this.sektionsgruppe.sektionen[index].sektionsfelder.splice(
          sektionsfeldIndex,
          1
        );
      }
    },
  },
};
</script>

<style lang="scss">
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>



<template>
  <div :key="key" class="mt-70-px">
    <navbar :docsName="docsName"/>
    <div class="container-fluid tile-container">
      <div class="row pt-4">
        <!-- LEFT COLUMN -->

        <div class="col-xl-6 col-lg-12">
          <div class="row">
            <!-- CRM -->
            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-12">
                  <div class="tile-categorie-frame">
                    <div class="tile-categorie">
                      <div class="header">
                        <span>{{ $t("global.templateseducation") }}</span>
                      </div>
                      <div class="body">
                        <div class="row">
                          <div class="col-xl-3 col-lg-12 col-md-12">
                            <div class="tile-frame left-aligned h-1">
                              <router-link
                                :to="{ name: 'template-bildungsgang-liste' }"
                              >
                                <div class="tile bg-sand inverted">
                                  <div class="icon-container fa-container">
                                    <font-awesome-icon
                                      icon="fa-duotone fa-graduation-cap"
                                      fixed-width
                                    />
                                  </div>
                                  <div class="title">
                                    {{ $t("global.templateseducationcourse") }}
                                  </div>
                                </div>
                              </router-link>
                            </div>
                          </div>
                          <div class="col-xl-3 col-lg-12 col-md-12">
                            <div class="tile-frame left-aligned h-1">
                              <router-link
                                :to="{ name: 'template-klassen-liste' }"
                              >
                                <div class="tile bg-sand inverted">
                                  <div class="icon-container fa-container">
                                    <font-awesome-icon
                                      icon="fa-duotone fa-users-class"
                                      fixed-width
                                    />
                                  </div>
                                  <div class="title">
                                    {{ $t("global.templateclasses") }}
                                  </div>
                                </div>
                              </router-link>
                            </div>
                          </div>
                          <div class="col-xl-3 col-lg-12 col-md-12">
                            <div class="tile-frame left-aligned h-1">
                              <router-link
                                :to="{ name: 'template-faecher-liste' }"
                              >
                                <div class="tile bg-sand inverted">
                                  <div class="icon-container fa-container">
                                    <font-awesome-icon
                                      icon="fa-duotone fa-books"
                                      fixed-width
                                    />
                                  </div>
                                  <div class="title">
                                    {{ $t("global.Templatessubjects") }}
                                  </div>
                                </div>
                              </router-link>
                            </div>
                          </div>
                          <div class="col-xl-3 col-lg-12 col-md-12">
                            <div class="tile-frame left-aligned h-1">
                              <router-link
                                :to="{ name: 'template-kurs-liste' }"
                              >
                                <div class="tile bg-sand inverted">
                                  <div class="icon-container fa-container">
                                    <font-awesome-icon
                                      icon="fa-duotone fa-graduation-cap"
                                      fixed-width
                                    />
                                  </div>
                                  <div class="title">Vorlagen Kurse</div>
                                </div>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row pt-4">
                <div class="col-lg-12">
                  <div class="tile-categorie-frame">
                    <div class="tile-categorie">
                      <div class="header">
                        <span>{{ $t("global.competencegrid") }}</span>
                      </div>
                      <div class="body">
                        <div class="row">
                          <div class="col-xl-12">
                            <div class="tile-frame centered h-1">
                              <router-link
                                :to="{
                                  name: 'einstellungen-kompetenzrasterliste',
                                }"
                              >
                                <div class="tile bg-light-blue inverted">
                                  <div class="icon-container fa-container">
                                    <font-awesome-icon
                                      icon="fa-duotone fa-memo"
                                      class="mr-2"
                                      fixed-width
                                      transform="right-2 shrink-3"
                                    />
                                  </div>
                                  <div class="title">
                                    <div>{{ $t("global.competencegrid") }}</div>
                                  </div>
                                </div>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row pt-4">
                <div class="col-lg-12">
                  <div class="tile-categorie-frame">
                    <div class="tile-categorie">
                      <div class="header">
                        <span>{{ $t("global.dashboard_education") }}</span>
                      </div>
                      <div class="body">
                        <div class="row">
                          <div class="col-xl-6">
                            <div class="tile-frame left-aligned h-1">
                              <router-link
                                :to="{ name: 'einstellungen-perioden' }"
                              >
                                <div class="tile bg-light-blue inverted">
                                  <div class="icon-container fa-container">
                                    <font-awesome-icon
                                      icon="fa-duotone fa-memo"
                                      class="mr-2"
                                      fixed-width
                                      transform="right-1 shrink-3"
                                    />
                                  </div>
                                  <div class="title">
                                    <div>{{ $t("global.periods") }}</div>
                                  </div>
                                </div>
                              </router-link>
                            </div>
                          </div>
                          <div class="col-xl-6">
                            <div class="tile-frame left-aligned h-1">
                              <router-link
                                :to="{ name: 'einstellungen-sperrtage' }"
                              >
                                <div class="tile bg-light-blue inverted">
                                  <div class="icon-container fa-container">
                                    <font-awesome-icon
                                      icon="fa-duotone fa-memo"
                                      class="mr-2"
                                      fixed-width
                                      transform="right-1 shrink-3"
                                    />
                                  </div>
                                  <div class="title">
                                    <div>{{ $t("global.blockingdays") }}</div>
                                  </div>
                                </div>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row pt-4">
                <div class="col-lg-12">
                  <div class="tile-categorie-frame">
                    <div class="tile-categorie">
                      <div class="header">
                        <span>{{ $t("global.finance") }}</span>
                      </div>
                      <div class="body">
                        <div class="row">
                          <div class="col-xl-12 col-lg-12 col-md-12">
                            <div class="tile-frame centered h-1">
                              <router-link :to="{ name: 'artikelliste' }">
                                <div class="tile bg-light-mint inverted">
                                  <div class="icon-container fa-container">
                                    <font-awesome-icon
                                      icon="fa-duotone fa-shopping-basket"
                                      fixed-width
                                    />
                                  </div>
                                  <div class="title">
                                    {{ $t("global.items") }}
                                  </div>
                                </div>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-6 col-lg-12">
          <div class="row">
            <div class="col-xl-12">
              <div class="tile-categorie-frame">
                <div class="tile-categorie">
                  <div class="header">
                    <span>{{ $t("global.dashboard_resources") }}</span>
                  </div>
                  <div class="body">
                    <div class="row">
                      <div class="col-xl-6 col-lg-6 col-md-12">
                        <div class="tile-frame left-aligned h-1">
                          <router-link :to="{ name: 'Raumliste' }">
                            <div class="tile bg-light-lila inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-duotone fa-chalkboard"
                                  fixed-width
                                />
                              </div>
                              <div class="title">{{ $t("global.room") }}</div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-12">
                        <div class="tile-frame left-aligned h-1">
                          <router-link :to="{ name: 'Gebäudeliste' }">
                            <div class="tile bg-light-lila inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-duotone fa-school"
                                  fixed-width
                                />
                              </div>
                              <div class="title">
                                {{ $t("global.building") }}
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-12 col-lg-12">
              <div class="row pt-4">
                <div class="col-xl-12">
                  <div class="tile-categorie-frame">
                    <div class="tile-categorie">
                      <div class="header">
                        <span>{{ $t("global.grades") }}</span>
                      </div>
                      <div class="body">
                        <div class="row">
                          <div class="col-xl-6 col-lg-6 col-md-12">
                            <div class="tile-frame left-aligned h-1">
                              <router-link :to="{ name: 'noteneingabe-liste' }">
                                <div class="tile bg-sand inverted">
                                  <div class="icon-container fa-container">
                                    <font-awesome-icon
                                      icon="fa-duotone fa-star"
                                      fixed-width
                                    />
                                  </div>
                                  <div class="title">
                                    {{ $t("global.scoreinput") }}
                                  </div>
                                </div>
                              </router-link>
                            </div>
                          </div>
                          <div class="col-xl-6 col-lg-6 col-md-12">
                            <div class="tile-frame left-aligned h-1">
                              <router-link :to="{ name: 'Notenliste' }">
                                <div class="tile bg-sand inverted">
                                  <div class="icon-container fa-container">
                                    <font-awesome-icon
                                      icon="fa-regular fa-list-check"
                                      fixed-width
                                      transform="shrink-2"
                                    />
                                  </div>
                                  <div class="title">
                                    {{ $t("global.grades") }}
                                  </div>
                                </div>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <!--
                    <div class="row">
                      <div class="col-xl-6 col-lg-6 col-md-12">
                        <div class="tile-frame centered h-1">
                          <router-link :to="{ name: 'Notenpromotionsliste' }">
                            <div class="tile bg-pink inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-regular fa-list-check"
                                  fixed-width
                                  transform="shrink-2"
                                />
                              </div>
                              <div class="title">Notenpromotion</div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                    </div>
                    -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pt-4">
                <div class="col-lg-12">
                  <div class="tile-categorie-frame">
                    <div class="tile-categorie">
                      <div class="header">
                        <span>{{ $t("global.correspondence") }}</span>
                      </div>
                      <div class="body">
                        <div class="row">
                          <div class="col-xl-6 col-lg-12 col-md-12">
                            <div class="tile-frame left-aligned h-1">
                              <router-link
                                :to="{ name: 'mail-vorlagen-liste' }"
                              >
                                <div class="tile bg-light-pink inverted">
                                  <div class="icon-container fa-container">
                                    <font-awesome-icon
                                      icon="fa-regular fa-text"
                                    />
                                  </div>
                                  <div class="title">
                                    {{ $t("global.mailtemplates") }}
                                  </div>
                                </div>
                              </router-link>
                            </div>
                          </div>
                          <div class="col-xl-6 col-lg-12 col-md-12">
                            <div class="tile-frame left-aligned h-1">
                              <router-link
                                :to="{ name: 'korrespondenz-vorlagen-liste' }"
                              >
                                <div class="tile bg-light-pink inverted">
                                  <div class="icon-container fa-container">
                                    <font-awesome-icon
                                      icon="fa-regular fa-text"
                                    />
                                  </div>
                                  <div class="title">
                                    {{ $t("global.Correspondencetemplates") }}
                                  </div>
                                </div>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-xl-12"
              v-if="berechtigungen.m_geschaeftspartner.create"
            >
              <div class="row pt-4">
                <div class="col-lg-12">
                  <div class="tile-categorie-frame">
                    <div class="tile-categorie">
                      <div class="header">
                        <span>{{ $t("global.excelImport") }}</span>
                      </div>
                      <div class="body">
                        <div class="row">
                          <div class="col-xl-4">
                            <div class="tile-frame centered h-1">
                              <div
                                class="tile bg-grey-green inverted cursor-pointer"
                                @click="
                                  oeffneExcelUploadModal(excelUploadGPModalId)
                                "
                              >
                                <div class="icon-container fa-container">
                                  <font-awesome-icon
                                    icon="fa-duotone fa-file-spreadsheet"
                                    fixed-width
                                    transform="shrink-3"
                                  />
                                </div>
                                <div class="title">
                                  {{ $t("global.gpExcelImport") }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-xl-4">
                            <div class="tile-frame centered h-1">
                              <div
                                class="tile bg-grey-green inverted cursor-pointer"
                                @click="
                                  oeffneExcelUploadModal(excelUploadMailModalId)
                                "
                              >
                                <div class="icon-container fa-container">
                                  <font-awesome-icon
                                    icon="fa-duotone fa-file-spreadsheet"
                                    fixed-width
                                    transform="shrink-3"
                                  />
                                </div>
                                <div class="title">Mail Excel import</div>
                              </div>
                            </div>
                          </div>
                          <div class="col-xl-4">
                            <div class="tile-frame centered h-1">
                              <div
                                class="tile bg-grey-green inverted cursor-pointer"
                                @click="
                                  oeffneExcelUploadModal(
                                    excelUploadMailHauptadresseId
                                  )
                                "
                              >
                                <div class="icon-container fa-container">
                                  <font-awesome-icon
                                    icon="fa-duotone fa-file-spreadsheet"
                                    fixed-width
                                    transform="shrink-3"
                                  />
                                </div>
                                <div class="title">
                                  Mail Haupadresse Excel import
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12 col-lg-12">
          <div class="row pt-4">
            <div class="col-xl-12">
              <div class="tile-categorie-frame">
                <div class="tile-categorie">
                  <div class="header">
                    <span>{{ $t("global.selection") }}</span>
                  </div>
                  <div class="body">
                    <div class="row">
                      <div
                        class="col-xl-2"
                        v-for="(
                          selectionValueElement, index
                        ) in selectionValueList"
                        :key="'selectionValueElement-' + index"
                      >
                        <div class="tile-frame left-aligned h-1">
                          <router-link
                            :to="{
                              name: 'einstellungen-auswahlwerte',
                              params: {
                                tileId: selectionValueElement.id,
                              },
                            }"
                          >
                            <div
                              class="tile inverted"
                              :class="'bg-brigther-dark-blue'"
                            >
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  :icon="selectionValueElement.icon"
                                  class="mr-2"
                                  fixed-width
                                  transform="right- shrink-3"
                                />
                              </div>
                              <div class="title">
                                <div>
                                  {{ selectionValueElement.bezeichnung }}
                                </div>
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                      <!-- <dashboard-einstellung
                        :color="'bg-brigther-dark-blue'"
                        :icon="'fa-duotone fa-award'"
                        :auswahlwerte="this.$t('global.events')"
                        :router-name="'einstellungen-veranstaltungen'"
                      ></dashboard-einstellung>
                      <dashboard-einstellung
                        :color="'bg-brigther-dark-blue'"
                        :icon="'fa-duotone fa-memo'"
                        :auswahlwerte="this.$t('global.item')"
                        :router-name="'einstellungen-artikel'"
                      ></dashboard-einstellung>
                      <dashboard-einstellung
                        :color="'bg-brigther-dark-blue'"
                        :icon="'fa-duotone fa-file-invoice-dollar'"
                        :auswahlwerte="this.$t('global.order')"
                        :router-name="'einstellungen-auftrag'"
                      ></dashboard-einstellung>
                      <dashboard-einstellung
                        :color="'bg-brigther-dark-blue'"
                        :icon="'fa-duotone fa-receipt'"
                        :auswahlwerte="this.$t('global.receipts')"
                        :router-name="'einstellungen-belege'"
                      ></dashboard-einstellung>
                      <dashboard-einstellung
                        :color="'bg-brigther-dark-blue'"
                        :icon="'fa-duotone fa-money-bill'"
                        :auswahlwerte="this.$t('global.debtor')"
                        :router-name="'einstellungen-debitor'"
                      ></dashboard-einstellung>
                      <dashboard-einstellung
                        :color="'bg-brigther-dark-blue'"
                        :icon="'fa-duotone fa-user-clock'"
                        :auswahlwerte="this.$t('global.fees')"
                        :router-name="'einstellungen-lohn'"
                      ></dashboard-einstellung>
                      <dashboard-einstellung
                        :color="'bg-brigther-dark-blue'"
                        :icon="'fa-duotone fa-wallet'"
                        :auswahlwerte="this.$t('global.payments')"
                        :router-name="'einstellungen-zahlungen'"
                      ></dashboard-einstellung>
                      <dashboard-einstellung
                        :color="'bg-brigther-dark-blue'"
                        :icon="'fa-duotone fa-user-friends'"
                        :auswahlwerte="this.$t('global.businesspartner')"
                        :router-name="'einstellungen-geschaeftspartner'"
                      ></dashboard-einstellung>
                      <dashboard-einstellung
                        :color="'bg-brigther-dark-blue'"
                        :icon="'fa-duotone fa-address-book'"
                        :auswahlwerte="this.$t('global.journal')"
                        :router-name="'einstellungen-journal'"
                      ></dashboard-einstellung>
                      <dashboard-einstellung
                        :color="'bg-brigther-dark-blue'"
                        :icon="'fa-duotone fa-user-clock'"
                        :auswahlwerte="this.$t('global.lecturer')"
                        :router-name="'einstellungen-dozent'"
                      ></dashboard-einstellung>
                      <dashboard-einstellung
                        :color="'bg-brigther-dark-blue'"
                        :icon="'fa-duotone fa-star'"
                        :auswahlwerte="this.$t('global.grades')"
                        :router-name="'einstellungen-noten'"
                      ></dashboard-einstellung>
                      <dashboard-einstellung
                        :color="'bg-brigther-dark-blue'"
                        :icon="'fa-duotone fa-star'"
                        :auswahlwerte="this.$t('global.scoreinput')"
                        :router-name="'einstellungen-noteneingabe'"
                      ></dashboard-einstellung>
                      <dashboard-einstellung
                        :color="'bg-brigther-dark-blue'"
                        :icon="'fa-duotone fa-calendar-check'"
                        :auswahlwerte="this.$t('global.dashboard_presence')"
                        :router-name="'einstellungen-praesenz'"
                      ></dashboard-einstellung>
                      <dashboard-einstellung
                        :color="'bg-brigther-dark-blue'"
                        :icon="'fa-duotone fa-address-book'"
                        :auswahlwerte="this.$t('global.subsidies')"
                        :router-name="'einstellungen-subventionen'"
                      ></dashboard-einstellung>
                      <dashboard-einstellung
                        :color="'bg-brigther-dark-blue'"
                        :icon="'fa-duotone fa-address-book'"
                        :auswahlwerte="this.$t('global.subsidycontributions')"
                        :router-name="'einstellungen-subventionsbeitraege'"
                      ></dashboard-einstellung>
                      <dashboard-einstellung
                        :color="'bg-brigther-dark-blue'"
                        :icon="'fa-duotone fa-sign-in-alt'"
                        :auswahlwerte="this.$t('global.registrations')"
                        :router-name="'einstellungen-anmeldungen'"
                      ></dashboard-einstellung>
                      <dashboard-einstellung
                        :color="'bg-brigther-dark-blue'"
                        :icon="'fa-regular fa-tachometer-alt'"
                        :auswahlwerte="this.$t('global.leads')"
                        :router-name="'einstellungen-crm'"
                      ></dashboard-einstellung>
                      <dashboard-einstellung
                        :color="'bg-brigther-dark-blue'"
                        :icon="'fa-duotone fa-calendar-day'"
                        :auswahlwerte="this.$t('global.Resources')"
                        :router-name="'einstellungen-ressourcen'"
                      ></dashboard-einstellung>
                      <dashboard-einstellung
                        :color="'bg-brigther-dark-blue'"
                        :icon="'fa-duotone fa-envelope-open-text'"
                        :auswahlwerte="this.$t('global.correspondence')"
                        :router-name="'einstellungen-korrespondenz'"
                      ></dashboard-einstellung> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <excel-upload-modal :id="excelUploadGPModalId" :path="excelUploadGpPath" />
    <excel-upload-modal
      :id="excelUploadMailModalId"
      :path="excelUploadMailPath"
    />
    <excel-upload-modal
      :id="excelUploadMailHauptadresseId"
      :path="excelUploadMailHauptadressePath"
    />
    <messagebox-table :close="true" :cancel="false" />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import page from "@/mixins/Page";
import store from "@/store";
import { LADE_AUSWAHLWERTE_EINSTELLUNGEN } from "@/store/einstellungendashboard/actions.type";

import ExcelUploadModal from "@/components/Modals/ExcelUploadModal";
import MessageboxTable from "@/components/Modals/MessageboxTable";

import { uuidv4 } from "@/utils/Uuid";

export default {
  name: "Startseite",
  components: {
    Navbar,
    ExcelUploadModal,
    MessageboxTable,
  },
  mixins: [page],
  store,
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX:" + this.$t("global.educationsettings");
      },
    };
  },
  data() {
    return {
      key: uuidv4(),
      excelUploadGPModalId: "excel-modal-gp",
      excelUploadMailModalId: "excel-modal-mail",
      excelUploadMailHauptadresseId: "excel-modal-mail-hauptadresse",
      excelUploadGpPath: `/geschaeftspartner/excel-upload/`,
      excelUploadMailPath: `/geschaeftspartner/excel-upload/mail/`,
      excelUploadMailHauptadressePath: `/geschaeftspartner/excel-upload/hauptadresse/`,
    };
  },
  computed: {
    selectionValueList: {
      get() {
        return this.$store.state.einstellungendashboard.kacheln;
      },
    },
  },
  created() {
    if (this.selectionValueList.length == 0) {
      this.$store.dispatch(
        `einstellungendashboard/${LADE_AUSWAHLWERTE_EINSTELLUNGEN}`
      );
    }
  },
  mounted() {},
  methods: {
    oeffneExcelUploadModal(id) {
      $("#" + id).modal("show");
    },
    schliesseExcelUploadModal(id, response) {
      response.errors.shift();
      $("#" + id).modal("hide");
      this.$notify({
        type: "success",
        title: this.$t("global.actionsuccessful"),
        text: this.$t("global.excelUploadSuccess"),
      });
      this.$bus.$emit("openMessageBoxTable", {
        text: `Erfolgreich für: ${response.success.length}\n\nFolgende Zeilen konnten nicht importiert werden:`,
        headerText: "Excel Upload",
        fields: this.setFields(response.errors),
        items: response.errors,
      });
    },
    setFields(array) {
      let fields = [];
      let field = {
        key: "",
        label: "",
      };
      if (array.length > 0) {
        let object = array[0];
        Object.entries(object).forEach((entry) => {
          field.key = entry.key;
          field.label = entry.key;
          fields.push(field);
        });
      }
      return fields;
    },
  },
};
</script>

<style lang="scss"></style>

<template>
  <div class="row mb-2">
    <div
      v-for="(veranstaltung, index) in veranstaltungen"
      :key="index"
      class="col-xl-6"
    >
      <div class="form-group" style="margin-bottom: 0">
        <span
          class="multiAddIcon"
          v-if="editable && index == 0"
          v-on:click="addVeranstaltung()"
        >
          <font-awesome-icon icon="fa-regular fa-plus" />
        </span>
        <span
          v-if="editable && index > 0"
          class="minusIcon"
          v-on:click="removeVeranstaltung(index)"
          ><font-awesome-icon icon="fa-regular fa-minus" />
        </span>
        <span v-if="editable" class="editIcon" v-on:click="oeffneVSModal(index)"
          ><font-awesome-icon icon="fa-duotone fa-pencil-alt" />
        </span>
        <span class="openIcon" v-on:click="openVeranstaltung(veranstaltung)"
          ><font-awesome-icon icon="fa-duotone fa-arrow-circle-right" />
        </span>
        <input v-model="veranstaltung.title" class="form-control" readonly />
        <label v-if="index == 0"
          >{{ $t("global.events") }}
          <font-awesome-icon icon="fa-duotone fa-arrow-circle-right"
        /></label>
      </div>
    </div>

    <veranstaltung-select-modal
      id="vsModal"
      :filterTypen="typen"
      @confirmed="onVSResult"
    ></veranstaltung-select-modal>
  </div>
</template>

<script>
import VeranstaltungSelectModal from "@/components/Modals/VeranstaltungSelectModal.vue";

export default {
  name: "MultiSelectVeranstaltung",
  components: { VeranstaltungSelectModal },
  props: {
    veranstaltungen: {
      type: Array,
      required: true,
    },
    route: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      default: true,
    },
    typen: {
      type: Array,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      editVeranstaltung: null,
    };
  },
  mounted: function () {},
  methods: {
    oeffneVSModal(index) {
      this.editVeranstaltung = index;
      $("#vsModal").modal("show");
    },
    onVSResult(selectedVeranstaltung) {
      if (selectedVeranstaltung) {
        let tempVeranstaltungen = this.veranstaltungen;
        this.$set(
          tempVeranstaltungen,
          this.editVeranstaltung,
          selectedVeranstaltung[0]
        );
      }
    },
    addVeranstaltung() {
      let tempVeranstaltungen = this.veranstaltungen;
      if (tempVeranstaltungen.length < 6)
        tempVeranstaltungen.push({ bezeichnung: this.$t("global.notlinked") });
    },
    removeVeranstaltung(index) {
      let tempVeranstaltungen = this.veranstaltungen;
      tempVeranstaltungen =
        tempVeranstaltungen.length == 0
          ? { bezeichnung: this.$t("global.notlinked") }
          : tempVeranstaltungen.splice(index, 1);

      this.veranstaltungen = tempVeranstaltungen;
    },
    openVeranstaltung(veranstaltung) {
      if (veranstaltung?.id) {
        if (veranstaltung.veranstaltungstyp.id == "174D91487DA") {
          //Bildungsgang
          this.$router.push({
            path: "/Bildung/Klasse/" + veranstaltung.id + "/0",
          });
        } else if (veranstaltung.veranstaltungstyp.id == "174D91487D4") {
          //Fach
          this.$router.push({
            path: "/Bildung/Fach/" + veranstaltung.id + "/0",
          });
        } else {
          this.$router.push({
            path: this.route + veranstaltung.id + "/0",
          });
        }
      }
    },
  },
};
</script>

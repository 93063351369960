<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">
            {{ $t("global.createsettlement") }}
          </h4>
        </div>
        <div class="modal-body">
          <div class="row ml-0 mr-0">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12">
                  <container-headline
                    headline="Abrechnung"
                    :col="6"
                  ></container-headline>
                  <div class="row mb-3">
                    <div class="col-xl-12 block br-t-l-0">
                      <div class="eingabe-panel-kopf p-3">
                        <div class="row">
                          <div class="col-xl-6">
                            <div class="form-group">
                              <v-select
                                v-model="abrechnung.subventionsbeitrag"
                                label="bezeichnung"
                                :disabled="!editable"
                                :options="subventionsbeitraege"
                                :placeholder="$t('global.subsidycontribution')"
                                :clearable="false"
                              >
                              </v-select>
                              <label>{{ $t("global.subsidycontribution") }}</label>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xl-6">
                            <div class="form-group">
                              <input
                                v-model="abrechnung.subventionsbeitrag.betrag"
                                class="form-control"
                                placeholder=" "
                                readonly="true"
                              />
                              <label>{{ $t("global.amout") }}</label>
                            </div>
                          </div>
                          <div class="col-xl-6">
                            <div class="form-group">
                              <input
                                v-model="
                                  abrechnung.subventionsbeitrag
                                    .subventionskanton.bezeichnung
                                "
                                class="form-control"
                                placeholder=" "
                                readonly="true"
                              />
                              <label>{{ $t("global.subsidycanton") }}</label>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xl-4">
                            <div class="form-group">
                              <date-picker
                                date-format="dd.mm.yy"
                                :initial="abrechnung.antrag"
                                placeholer=" "
                                :disabled="!editable"
                                :show-icon="true"
                                @update="(val) => (abrechnung.antrag = val)"
                              />
                              <label>{{ $t("global.request") }}</label>
                            </div>
                          </div>
                          <div class="col-xl-4">
                            <div class="form-group">
                              <date-picker
                                date-format="dd.mm.yy"
                                :initial="abrechnung.abrechnung"
                                placeholer=" "
                                :disabled="!editable"
                                :show-icon="true"
                                @update="(val) => (abrechnung.abrechnung = val)"
                              />
                              <label>{{ $t("global.settlement") }}</label>
                            </div>
                          </div>
                          <div class="col-xl-4">
                            <div class="form-group">
                              <v-select
                                v-model="abrechnung.periode"
                                label="bezeichnung"
                                :disabled="!editable"
                                :options="abrechnungsperioden"
                                :placeholder="$t('global.period')"
                              >
                              </v-select>
                              <label>{{ $t("global.period") }}</label>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xl-4">
                            <div class="form-group">
                              <v-select
                                v-model="abrechnung.antragsstatus"
                                label="bezeichnung"
                                :disabled="!editable"
                                :options="antragsstati"
                                :placeholder="$t('global.requeststatus')"
                              >
                              </v-select>
                              <label>{{ $t("global.requeststatus") }}</label>
                            </div>
                          </div>
                          <div class="col-xl-4">
                            <div class="form-group">
                              <v-select
                                v-model="abrechnung.abrechnungsstatus"
                                label="bezeichnung"
                                :disabled="!editable"
                                :options="abrechnungsstati"
                                :placeholder="$t('global.settlementstatus')"
                              >
                              </v-select>
                              <label>{{ $t("global.settlementstatus") }}</label>
                            </div>
                          </div>
                          <div class="col-xl-4">
                            <div class="form-group">
                              <input
                                v-model="abrechnung.betrag"
                                class="form-control"
                                placeholder=" "
                                :readonly="!editable"
                              />
                              <label>{{ $t("global.settlementamount") }}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="closeModal">
            Abbrechen
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="speichern"
            :disabled="checkFields"
          >
            Erstellen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { modal } from "@/mixins/Modal";
import Api from "@/Api";
import ContainerHeadline from "@/components/ContainerHeadline";
import DatePicker from "@/components/Datepicker";
import ModalCloseButton from "@/components/global/ModalCloseButton.vue";

import { LADE_AUSWAHLWERTE_SUBVENTION_BEITRAG } from "@/store/subventionbeitrag/actions.type";

export default {
  name: "SubventionsabrechnungErstellenModal",
  mixins: [modal],
  components: {
    ContainerHeadline,
    DatePicker,
    ModalCloseButton
  },
  props: {
    editable: {
      type: Boolean,
      required: true,
    },
    subvention: {
      type: String,
      required: true,
    },
    /*
    subventionsstati: {
      type: Array,
    },
    ausbildungsstati: {
      type: Array,
    },*/
  },
  data() {
    return {
      subventionsbeitraege: [],
      abrechnung: {
        abrechnungsnummer: 13133131313,
        subventionsbeitrag: {
          bezeichnung: "",
          subventionskanton: {
            bezeichnung: "",
            id: "",
          },
        },
        antrag: "",
        abrechnung: "",
        periode: {
          bezeichnung: "",
          id: "",
        },
        antragsstatus: {
          bezeichnung: "",
          id: "",
        },
        abrechnungsstatus: {
          bezeichnung: "",
          id: "",
        },
        subvention: {},
        //betrag: 99999,
      },
      /*
      count: 0,
      beitragskriterium: {
        subventionsstatus: {},
        ausbildungstatus: {},
        stichtag: "",
        beitragsintervall: {},
        antragsdatum: "",
        antragsstatus: {},
        abrechnungsdatum: "",
        abrechnungsperiode: {},
        abrechnungsstatus: {},
      },*/
    };
  },
  computed: {
    /*
    beitragsintervalle: {
      get() {
        return this.$store.state.subventionbeitrag.beitragsintervalle;
      },
    },*/
    abrechnungsstati: {
      get() {
        return this.$store.state.subventionbeitrag.abrechnungsstati;
      },
    },
    antragsstati: {
      get() {
        return this.$store.state.subventionbeitrag.antragsstati;
      },
    },
    abrechnungsperioden: {
      get() {
        return this.$store.state.subventionbeitrag.abrechnungsperioden;
      },
    },
    checkFields() {
      if (
        this.abrechnung.subventionsbeitrag.id &&
        this.abrechnung.antrag &&
        this.abrechnung.abrechnung &&
        this.abrechnung.periode?.id &&
        this.abrechnung.antragsstatus?.id &&
        this.abrechnung.abrechnungsstatus?.id &&
        this.abrechnung.betrag
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {},
  async created() {},
  mounted() {
    this.$store.dispatch(
      `subventionbeitrag/${LADE_AUSWAHLWERTE_SUBVENTION_BEITRAG}`
    );
    this.getSubventionsBeitraege();
    this.abrechnung.subvention = this.subvention;
    /*
    if (this.beitragsintervalle.length == 0) {
      this.$store.dispatch(
        `subventionbeitrag/${LADE_AUSWAHLWERTE_SUBVENTION_BEITRAG}`
      );
    }*/
  },
  methods: {
    closeModal() {
      this.shown = false;
      $("#subventionsabrechnung-erstellen-modal").modal("hide");
    },

    speichern() {
      var json = Object.assign({}, this.abrechnung);

      Api.post("/subvention/abrechnung/", json).then(() => {
        this.$emit("abrechnungErstellt");
        this.closeModal();
      });
    },

    getSubventionsBeitraege() {
      Api.get("/bildung/subvention/beitrag/", {
        params: {
          subvention: this.subvention,
        },
      }).then((response) => {
        this.subventionsbeitraege = response.data;
      });
    },
    /*
    clear() {
      console.log("clear");
      this.beitragskriterium = {
        subventionsstatus: {},
        ausbildungstatus: {},
        stichtag: "",
        beitragsintervall: {},
        antragsdatum: "",
        antragsstatus: {},
        abrechnungsdatum: "",
        abrechnungsperiode: {},
        abrechnungsstatus: {},
      };
    },

    speichern() {
      var json = Object.assign({}, this.beitragskriterium);

      console.log(json);

      if (json) {
        Api.post("subvention/beitrag/", json).then((response) => {
          this.count = response.count;
          this.closeModal();
        });
      } /*else {
      }
      
    },*/
  },
};
</script>

<style>
</style>

<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.subsidycontributions')"
        :col="3"
      >
        <div class="row mt-2 d-flex justify-content-between">
          <div class="ml-4">
            <button
              class="btn btn-success mr-2"
              @click="showModal()"
              v-if="editable"
            >
              <font-awesome-icon
                icon="fa-duotone fa-dollar-sign"
                class="mr-2"
              />
              {{ $t("global.newpost") }}
            </button>
          </div>
          <div class="mr-4">
            <button
              class="btn btn-danger"
              @click="oeffneLoeschenModal"
              v-if="editable"
            >
              <font-awesome-icon class="mr-1" icon="fa-duotone fa-trash" />
              <span>{{ $t("global.delete") }}</span>
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <div>
              <b-table
                ref="selectableTable"
                tbody-tr-class="item"
                style="max-height: 300px"
                responsive
                sticky-header
                :items="subventionsbeitraege"
                :fields="fields"
                sort-icon-left
                selectable
                select-mode="range"
                @row-dblclicked="editieren"
              >
                <template v-slot:head(selected)="row">
                  <b-form-checkbox
                    v-model="row.selected"
                    @input="selectAllRows(row)"
                  ></b-form-checkbox>
                </template>
                <template v-slot:cell(selected)="row">
                  <b-form-checkbox
                    v-model="row.item.selected"
                    @input="onRowSelected(row)"
                  ></b-form-checkbox>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <subventionsbeitrag-modal
      :subvention="subvention"
      :subventionskantone="kantone"
      :beitragstati="beitragstati"
      :beitragsintervalle="beitragsintervalle"
      :beitrag="subventionsbeitrag"
      :key="beitragModalKey"
      @beitragChanged="updateBeitraege"
      id="subventionsbeitrag-modal"
    />

    <loeschen-modal
      id="subventionsbeitrag-loeschen-modal"
      @confirmed="subventionsBeitragLoeschen"
      :selectedIds="selectedIds"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import ContainerHeadline from "@/components/ContainerHeadline";
import numeral from "numeral";

import { LADE_AUSWAHLWERTE_SUBVENTION_BEITRAG } from "@/store/subventionbeitrag/actions.type";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import SubventionsbeitragModal from "@/components/Subvention/SubventionsbeitragModal";

import reiter from "@/mixins/Reiter";

export default {
  components: {
    ContainerHeadline,
    LoeschenModal,
    SubventionsbeitragModal,
  },
  mixins: [reiter],
  props: {
    subvention: {
      type: String,
      required: false,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    kantone: {
      type: Array,
    },
  },
  data() {
    return {
      page: 0,
      anzahlProPage: 99,
      subventionsbeitraege: [],
      subventionsbeitrag: {},
      selected: [],
      selectedIds: [],
      beitragModalKey: 0,
    };
  },
  watch: {
    shown(val) {
      if (val && this.subventionsbeitraege.length == 0)
        this.getSubventionsBeitraege();
    },
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
        {
          key: "subventionskanton.bezeichnung",
          sortable: true,
          label: this.$t("global.canton"),
        },
        {
          key: "subventionsbeitragstatus.bezeichnung",
          sortable: true,
          label: this.$t("global.contributionstatus"),
        },
        {
          key: "beitragsintervall.bezeichnung",
          sortable: true,
          label: this.$t("global.interval"),
        },
        {
          key: "betrag",
          sortable: true,
          label: this.$t("global.amount"),
          formatter: (value) => {
            if (value) {
              return numeral(value).format("#,##0.00");
            } else {
              return "-";
            }
          },
        },
      ];
    },
    beitragstati: {
      get() {
        return this.$store.state.subventionbeitrag.beitragstati;
      },
    },
    beitragsintervalle: {
      get() {
        return this.$store.state.subventionbeitrag.beitragsintervalle;
      },
    },
  },
  mounted() {
    if (this.shown) this.getSubventionsBeitraege();
    if (this.beitragstati.length == 0) {
      this.$store.dispatch(
        `subventionbeitrag/${LADE_AUSWAHLWERTE_SUBVENTION_BEITRAG}`
      );
    }
  },
  created() {},
  methods: {
    getSubventionsBeitraege() {
      Api.get("bildung/subvention/beitrag/", {
        params: {
          subvention: this.subvention,
        },
      }).then((response) => {
        this.subventionsbeitraege = response.data;
      });
    },

    updateBeitraege() {
      this.$emit("updateBeitraege");
    },

    details() {
      /*if (this.berechtigungen.m_anmeldungen.read)
        this.$router.push({
          name: "anmeldung",
          params: { anmeldungid: anmeldungen.id, anzeige: 0 },
        });
        */
    },

    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = true;
        });
      } else {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },

    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
        this.selectedIds = Array.from(new Set(this.selectedIds));
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },

    showModal(beitrag) {
      if (beitrag) this.subventionsbeitrag = beitrag;
      this.$bus.$emit("open-modal", "subventionsbeitrag-modal");
    },

    editieren(val) {
      this.showModal(val);
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit(
          "openLoeschenModal",
          "subventionsbeitrag-loeschen-modal"
        );
      else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nosubsidycontributionsselectedtodelete"),
        });
      }
    },

    subventionsBeitragLoeschen() {
      Api.delete("bildung/subvention/beitrag/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.updateBeitraege();
      });
    },
  },
};
</script>

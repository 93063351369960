<template>
  <div class="col-xl-12 pr-4">
    <container-headline
      :headline="$t('global.persons')"
      :col="3"
    ></container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-6">
              <div class="row">
                <div class="col-xl-12">
                  <multi-select-person
                    id="kontaktperson"
                    :personen="notiz.kontaktpersonen"
                    :label="$t('global.contactperson')"
                    :allowOpen="berechtigungen.m_geschaeftspartner.read"
                    :editable="editable"
                    :required="false"
                    @confirmed="setKontaktPerson"
                  />
                </div>
              </div>
            </div>

            <div class="col-xl-3">
              <single-select-person
                id="kontaktdurch"
                :person="notiz.kontakt_durch"
                :label="$t('global.contactby')"
                :allowOpen="berechtigungen.m_geschaeftspartner.read"
                :editable="editable"
                :required="false"
                :rollen="filterMitarbeitende"
                @confirmed="setKontaktDurch"
              />
            </div>
            <div class="col-xl-3">
              <single-select-person
                id="person"
                :person="notiz.person"
                :label="$t('global.responsible')"
                :allowOpen="berechtigungen.m_geschaeftspartner.read"
                :editable="editable"
                :required="false"
                :rollen="filterMitarbeitende"
                @confirmed="setVerantwortlich"
              />
            </div>
            <div class="col-xl-6">
              <div class="row">
                <div class="col-xl-12">
                  <multi-select-firma
                    id="firma"
                    :firmen="notiz.firmen"
                    :label="$t('global.company')"
                    :allowOpen="berechtigungen.m_geschaeftspartner.read"
                    :editable="editable"
                    :required="false"
                    @confirmed="setKontaktFirma"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";

import MultiSelectPerson from "@/components/global/MultiSelectPerson.vue";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import MultiSelectFirma from "@/components/global/MultiSelectFirma.vue";

import store from "@/store";

import page from "@/mixins/Page";

export default {
  name: "NotizPersonen",
  components: {
    ContainerHeadline,
    SingleSelectPerson,
    MultiSelectPerson,
    MultiSelectFirma,
  },
  mixins: [],
  store,
  props: {
    notiz: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    navbarTitel: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      filterMitarbeitende: this.$CONST("ROLLEN").MITARBEITER,
    };
  },
  computed: {},
  watch: {},

  created() {},

  mounted: function () {
    console.log("in notiz: " + this.notiz);
  },

  methods: {
    setKontaktPerson(person) {
      this.notiz.kontaktpersonen = person;
    },

    setKontaktFirma(firma) {
      this.notiz.firmen = firma;
    },

    setKontaktDurch(person) {
      this.notiz.kontakt_durch = person;
    },

    setVerantwortlich(person) {
      this.notiz.person = person;
    },
  },
};
</script>

<style>
</style>

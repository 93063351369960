import { SET_SPRACHTEXTE } from "./mutations.type";
import { LADE_SPRACHTEXTE } from "./actions.type";
import { loadLanguage } from "../../main.js";

import Api from "../../Api";

export default {
  namespaced: true,
  state: {
    loaded: false,
    texts: [],
  },
  mutations: {
    [SET_SPRACHTEXTE](state, val) {
      state.texts = val;
      state.loaded = true;
    },
  },
  actions: {
    async [LADE_SPRACHTEXTE](context) {
      let response = await Api.get("/language/");
      context.commit(SET_SPRACHTEXTE, response.data);

      Object.entries(response.data).forEach(([key, value]) => {
        loadLanguage(key, value);
      });
    },
  },
};

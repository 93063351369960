<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">Manuell zuordnen</h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xl-3 pb-3">
              <div class="form-group">
                <input
                  v-model="filterPerson"
                  class="form-control input"
                  type="text"
                  placeholder=" "
                />
                <label>{{ $t("global.person") }}</label>
              </div>
            </div>
            <div class="col-xl-3 pb-3">
              <div class="form-group">
                <input
                  v-model="filterFirma"
                  class="form-control input"
                  type="text"
                  placeholder=" "
                />
                <label>{{ $t("global.company") }}</label>
              </div>
            </div>
            <div class="col-xl-3 pb-3">
              <div class="form-group">
                <input
                  v-model="filterAuftragnr"
                  class="form-control input"
                  type="text"
                  placeholder=" "
                />
                <label>{{ $t("global.ordernum") }}</label>
              </div>
            </div>
            <div class="col-xl-3 pb-3">
              <div class="form-group">
                <input
                  v-model="filterReferenzNr"
                  class="form-control input"
                  type="text"
                  placeholder=" "
                />
                <label>Referenz-Nr.</label>
              </div>
            </div>
            <div class="row pb-3">
              <div class="col-12">
                <div class="col-xl-12">
                  <div class="form-group">
                    <date-picker
                      date-format="dd.mm.yy"
                      label="Rechnungsdatum"
                      :show-icon="true"
                      :initial="filterDatum"
                      @update="(val) => (filterDatum = val)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!--
            <div class="col-xl-3 pb-3">
              <div class="form-group">
                <input
                  v-model="filterBetrag"
                  class="form-control input"
                  type="text"
                  placeholder=" "
                />
                <label>Betrag</label>
              </div>
            </div>
            -->
          </div>
          <div class="row">
            <div class="col-xl-12 block modal-liste br-t-l-0">
              <div>
                <b-table
                  sticky-header
                  selectable
                  select-mode="single"
                  @row-selected="onRowSelected"
                  tbody-tr-class="item"
                  :items="zahlschulden"
                  :fields="fields"
                >
                </b-table>
                <infinite-loading
                  :identifier="infiniteId"
                  @infinite="infiniteHandler"
                >
                  <div slot="spinner">Lade weitere Daten...</div>
                  <div slot="no-more">Keine weiteren Daten</div>
                  <div slot="no-results">Keine weiteren Daten gefunden</div>
                </infinite-loading>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="abbrechen">
            Abbrechen
          </button>
          <button
            type="button"
            class="btn btn-danger mr-2"
            @click="entfernen"
            v-if="remove"
          >
            {{ $t("global.remove") }}
          </button>
          <button type="button" class="btn btn-primary" @click="uebernehmen">
            Übernehmen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import InfiniteLoading from "vue-infinite-loading";
import { debounce } from "@/utils/Debouncer";
import DatePicker from "@/components/Datepicker";
import ModalCloseButton from "@/components/global/ModalCloseButton.vue";
import { insertUrlParam, removeUrlParam } from "@/utils/Url";
import { modal } from "@/mixins/Modal";

export default {
  name: "ZahlungseingangZuweisenModal",
  components: {
    InfiniteLoading,
    DatePicker,
    ModalCloseButton
  },
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true,
    },
    betrag: {
      type: Number,
      required: true,
    },
    remove: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 0,
      shown: false,
      anzahlProPage: 20,
      infiniteId: +new Date(),

      filterBetrag: null,
      filterPerson: null,
      filterAuftragnr: null,
      filterReferenzNr: null,
      filterDatum: null,
      filterFirma: null,

      zahlschulden: [],
      vorname: null,
      name: null,
      zip: null,
      selected: null,
    };
  },
  computed: {
    fields() {
      return [
        { key: "debitor_nr", sortable: true, label: "Debitor-Nr." },
        {
          key: "auftrag.nummer",
          sortable: true,
          label: this.$t("global.ordernum"),
        },
        {
          key: "person.personName",
          sortable: true,
          label: this.$t("global.person"),
        },
        {
          key: "rechnung_an",
          sortable: true,
          label: this.$t("global.invoiceto"),
        },
        {
          key: "auftrag.rechnungsdatum",
          sortable: true,
          label: this.$t("global.invoicedate"),
        },
        { key: "bruttobetrag", sortable: true, label: "Bruttobetrag" },
        { key: "offener_saldo", sortable: true, label: "Offener Saldo" },
        {
          key: "auftrag.referenznummer",
          sortable: true,
          label: "Referenz-Nr.",
        },
      ];
    },
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
        //betrag: this.betrag,
      };

      if (this.filterBetrag) params.filterBetrag = this.filterBetrag;
      if (this.filterPerson) params.filterPerson = this.filterPerson;
      if (this.filterFirma) params.filterFirma = this.filterFirma;
      if (this.filterAuftragnr) params.filterAuftragnr = this.filterAuftragnr;
      if (this.filterReferenzNr)
        params.filterReferenzNr = this.filterReferenzNr;
      if (this.filterDatum) params.filterDatum = this.filterDatum;

      return params;
    },
  },
  watch: {
    filter: function (val) {
      if (this.showNavbar)
        val ? insertUrlParam("filter", val) : removeUrlParam("filter");
      this.resetLoading(this);
    },

    filterBetrag: function () {
      this.resetLoading(this);
    },
    filterPerson: function () {
      this.resetLoading(this);
    },
    filterFirma: function () {
      this.resetLoading(this);
    },
    filterAuftragnr: function () {
      this.resetLoading(this);
    },
    filterReferenzNr: function () {
      this.resetLoading(this);
    },
    filterDatum: function () {
      this.resetLoading(this);
    },
  },
  created() {},
  mounted() {
    this.$bus.$on("zahlungseingang-zuweisen-modal", () => {
      if (!this.shown) this.openModal();
    });
  },
  methods: {
    resetLoading: debounce((self) => {
      self.page = 0;
      self.zahlschulden = [];
      self.infiniteId += 1;
    }, 500),

    openModal() {
      this.shown = true;
      Api.get("/finanzen/zahlschuld/", {
        params: this.queryParams,
      }).then((response) => {
        //response.data.shift();
        this.zahlschulden = response.data;
      });
    },

    infiniteHandler($state) {
      Api.get("/finanzen/zahlschuld/", {
        params: this.queryParams,
      }).then((response) => {
        //response.data.shift();
        if (response.data.length === this.anzahlProPage) {
          this.page += 1;
          this.zahlschulden.push(...response.data);
          $state.loaded();
        } else {
          this.zahlschulden.push(...response.data);
          $state.complete();
        }
      });
    },

    onRowSelected(items) {
      console.log(items);
      if (items.length > 0) {
        this.selected = items[0];
      } else {
        this.selected = this.selected.filter((id) => id != items.item.id);
      }
    },
    uebernehmen() {
      if (this.selected) {
        this.$emit("confirmed", this.selected);

        $("#" + this.id).modal("hide");
        this.shown = false;
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: "Keinen Debitor ausgewählt",
        });
      }
    },
    abbrechen() {
      $("#" + this.id).modal("hide");
      this.shown = false;
    },
    entfernen() {},
  },
};
</script>

<style></style>

import JournalEintrag from "@/pages/Journal/Journaleintrag";
import JournalEintragListe from "@/pages/Dashboardlisten/Journaleintraege";

export default [
  {
    path: "/journal/journaleintraege",
    name: "journaleintrag-liste",
    component: JournalEintragListe,
  },
  {
    path: "/journal/journaleintrag/:id?/:anzeige",
    name: "journaleintrag",
    component: JournalEintrag,
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
  },
];

<template>
  <div>
    <container-headline
      :headline="$t('global.additionalinformation')"
      :col="6"
    ></container-headline>

    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-6" v-if="person.nummer">
              <div class="form-group">
                <input
                  v-model.number="person.nummer"
                  class="form-control"
                  readonly
                  placeholder=" "
                  :tabindex="100"
                />
                <label
                  >{{
                    $CONST("CONFIG").APPTYPE === "DESKA"
                      ? $t("global.pinnumber")
                      : $t("global.personnumber")
                  }}
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div
                v-if="
                  this.roles.isAlumni ||
                  this.roles.isDiplomarbeiten ||
                  this.roles.isInteressent ||
                  this.roles.isMitarbeiter ||
                  this.roles.isReferent ||
                  this.roles.isStudent ||
                  this.roles.isTeilnehmerInfoabend ||
                  this.roles.isDozent
                "
                class="form-group"
              >
                <ValidationProvider rules="ahv" immediate v-slot="{ passed }">
                  <input
                    v-model.trim="person.sozialversicherungsnummer"
                    class="form-control"
                    v-bind:class="{ 'border-info': !passed }"
                    placeholder=" "
                    :readonly="!editable"
                    :tabindex="101"
                    @blur="checkAHVFormat"
                  />
                  <span v-if="!passed" class="minusIcon">
                    <font-awesome-icon
                      icon="fa-duotone fa-exclamation-triangle"
                    />
                  </span>
                  <label>{{ $t("global.socialsecuritynumber") }}</label>
                </ValidationProvider>
              </div>
            </div>
            <div
              v-if="roles.isDozent || roles.isMitarbeiter || roles.isReferent"
              class="col-xl-6"
            >
              <div class="form-group">
                <input
                  v-model.number="person.personalnummer"
                  class="form-control"
                  placeholder=" "
                  :readonly="!editable"
                  :tabindex="102"
                />
                <label>{{ $t("global.personnelnumber") }}</label>
              </div>
            </div>
          </div>

          <div
            v-if="
              this.roles.isAlumni ||
              this.roles.isDiplomarbeiten ||
              this.roles.isInteressent ||
              this.roles.isMitarbeiter ||
              this.roles.isReferent ||
              this.roles.isStudent ||
              this.roles.isTeilnehmerInfoabend ||
              this.roles.isDozent
            "
            class="row"
          >
            <div class="col-xl-6">
              <div class="form-group">
                <date-picker
                  date-format="dd.mm.yy"
                  :initial="person.eintritt"
                  placeholer=" "
                  :disabled="!editable"
                  :show-icon="true"
                  :tabindex="103"
                  @update="(val) => (person.eintritt = val)"
                />
                <label>{{ $t("global.entry") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <date-picker
                  date-format="dd.mm.yy"
                  :initial="person.austritt"
                  placeholder=" "
                  :show-icon="true"
                  :disabled="!editable"
                  :tabindex="104"
                  @update="(val) => (person.austritt = val)"
                />
                <label>{{ $t("global.exit") }}</label>
              </div>
            </div>
          </div>
          <div
            v-if="
              this.roles.isAlumni ||
              this.roles.isDiplomarbeiten ||
              this.roles.isInteressent ||
              this.roles.isMitarbeiter ||
              this.roles.isReferent ||
              this.roles.isStudent ||
              this.roles.isTeilnehmerInfoabend ||
              this.roles.isDozent ||
              this.roles.isArbeitgeber ||
              this.roles.isJobCoach ||
              this.roles.isPraxisbildner ||
              this.roles.isIVBerater ||
              this.roles.isNotfallkontakt
            "
            class="row"
          >
            <div class="col-xl-6">
              <div class="form-group">
                <v-select
                  v-model="person.nationalitaet"
                  label="bezeichnungMitIso"
                  :options="nationalitaeten"
                  :reduce="(n) => n.id"
                  :disabled="!editable"
                  :tabindex="105"
                  :placeholder="$t('global.nationality')"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>

                  <span slot="option" slot-scope="nationalitaet">
                    {{ nationalitaet.bezeichnungMitIso }}
                  </span>
                  <!--
                  <span slot="option" slot-scope="nationalitaet">
                    {{ nationalitaet.iso_2 }} -
                    {{ nationalitaet.name }}
                  </span>
                  -->
                </v-select>
                <label>{{ $t("global.nationality") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <v-select
                  v-model="person.muttersprache"
                  label="bezeichnung"
                  :options="muttersprachen"
                  :reduce="(m) => m.id"
                  :disabled="!editable"
                  :tabindex="106"
                  :placeholder="$t('global.firstlanguage')"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.firstlanguage") }}</label>
              </div>
            </div>
          </div>
          <div
            v-if="
              this.roles.isAlumni ||
              this.roles.isDiplomarbeiten ||
              this.roles.isInteressent ||
              this.roles.isMitarbeiter ||
              this.roles.isReferent ||
              this.roles.isStudent ||
              this.roles.isTeilnehmerInfoabend ||
              this.roles.isDozent
            "
            class="row"
          >
            <div class="col-xl-6">
              <div class="form-group">
                <input
                  v-model.trim="person.heimatort"
                  class="form-control"
                  placeholder=" "
                  :tabindex="107"
                  :readonly="!editable"
                />
                <label>{{ $t("global.hometown") }}</label>
              </div>
            </div>
           <!--  <div class="col-xl-2">
              <div class="form-group">
                <input
                  v-model.trim="person.kanton"
                  class="form-control"
                  placeholder=" "
                  :tabindex="107"
                  :readonly="!editable"
                />
                <label>Kanton</label>
              </div>
            </div> -->
            <div class="col-xl-6">
              <div class="form-group">
                <input
                  v-model.trim="person.wohnort"
                  class="form-control"
                  placeholder=" "
                  :tabindex="107"
                  :readonly="!editable"
                />
                <label>{{ $t("global.residence") }}</label>
              </div>
            </div>
            <div class="col-xl-6"></div>
          </div>
          <div v-if="person.nationalitaet && !person.isSchweizer" class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <v-select
                  v-model="person.aufenthaltsbewilligung"
                  label="bezeichnung"
                  :options="aufenthaltsbewilligungen"
                  :reduce="(s) => s.id"
                  :disabled="!editable"
                  :tabindex="108"
                  :placeholder="$t('global.residencepermit')"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.residencepermit") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <date-picker
                  date-format="dd.mm.yy"
                  :initial="person.gueltig_bis"
                  placeholer=" "
                  :show-icon="true"
                  :disabled="!editable"
                  :tabindex="108"
                  @update="(val) => (person.gueltig_bis = val)"
                />
                <label>{{ $t("global.validuntil") }}</label>
              </div>
            </div>
          </div>
          <div class="row" v-if="roles.isStudent">
            <div class="col-xl-3">
              <div class="form-group">
                <date-picker
                  date-format="dd.mm.yy"
                  :initial="person.jokertag"
                  placeholer=" "
                  :show-icon="true"
                  :disabled="!editable"
                  :tabindex="109"
                  @update="(val) => (person.jokertag = val)"
                />
                <label>{{ $t("global.jokerday") }}</label>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="form-check">
                <input
                  v-model="person.jokertag_bezogen"
                  class="form-check-input"
                  type="checkbox"
                  :tabindex="109"
                  :readonly="!editable"
                />
                <label class="form-check-label">{{
                  $t("global.jokerdayobtained")
                }}</label>
              </div>
            </div>
          </div>
          <div class="row" v-if="roles.isStudent">
            <div class="col-xl-3">
              <div class="form-group">
                <date-picker
                  date-format="dd.mm.yy"
                  :initial="person.besuch_Arbeitsplatz"
                  placeholer=" "
                  :show-icon="true"
                  :disabled="!editable"
                  :tabindex="110"
                  @update="(val) => (person.besuch_Arbeitsplatz = val)"
                />
                <label>{{ $t("global.visitattheworkplace") }}</label>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="form-check">
                <input
                  v-model="person.arbeitsplatz_besucht"
                  class="form-check-input"
                  type="checkbox"
                  :tabindex="110"
                  :readonly="!editable"
                />
                <label class="form-check-label">{{
                  $t("global.visited")
                }}</label>
              </div>
            </div>
          </div>
          <div class="row" v-if="roles.isStudent">
            <div class="col-xl-4">
              <div class="form-check">
                <input
                  v-model="person.nachteilsausgleich"
                  class="form-check-input"
                  type="checkbox"
                  :tabindex="111"
                  :readonly="!editable"
                />
                <label class="form-check-label">{{
                  $t("global.disadvantagecompensation")
                }}</label>
              </div>
            </div>
            <div class="col-xl-4">
              <div class="form-check">
                <input
                  v-model="person.surdossier"
                  class="form-check-input"
                  type="checkbox"
                  :tabindex="112"
                  :readonly="!editable"
                />
                <label class="form-check-label">{{
                  $t("global.surdossier")
                }}</label>
              </div>
            </div>
            <div class="col-xl-4">
              <div class="form-check">
                <input
                  v-model="person.fotoeinverstaendniserklaerung"
                  class="form-check-input"
                  type="checkbox"
                  :tabindex="113"
                  :readonly="!editable"
                />
                <label class="form-check-label">{{
                  $t("global.photoconsentform")
                }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <v-select
                  v-model="person.versand"
                  multiple
                  label="bezeichnung"
                  :options="versandlisten"
                  :reduce="(an) => an.id"
                  :disabled="!editable"
                  :tabindex="114"
                  :placeholder="$t('global.dispatch')"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.dispatch") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div
                v-if="
                  roles.isStudent ||
                  roles.isInteressent ||
                  roles.isAlumni ||
                  roles.isTeilnehmerInfoabend ||
                  roles.isKunde
                "
                class="form-group"
              >
                <v-select
                  v-model="person.interesse"
                  multiple
                  label="bezeichnung"
                  :options="interessen"
                  :reduce="(uf) => uf.id"
                  :disabled="!editable"
                  :tabindex="115"
                  :placeholder="$t('global.interests')"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.interests") }}</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <textarea
                  v-model.trim="person.bemerkung"
                  class="form-control"
                  placeholder=" "
                  :tabindex="116"
                  :readonly="!editable"
                ></textarea>
                <label>{{ $t("global.giftexit") }}</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-4">
              <div class="form-group">
                <input
                  v-model.trim="person.name_vorname_amtsleitung"
                  class="form-control"
                  placeholder=" "
                  :tabindex="117"
                  :readonly="!editable"
                />
                <label>{{ $t("global.nameofficemanagement") }}</label>
              </div>
            </div>
            <div class="col-xl-4">
              <div class="form-group">
                <input
                  v-model.trim="person.name_vorname_praxiscoach"
                  class="form-control"
                  placeholder=" "
                  :tabindex="118"
                  :readonly="!editable"
                />
                <label>{{ $t("global.nameparcticecoach") }}</label>
              </div>
            </div>
            <div class="col-xl-4">
              <div class="form-group">
                <input
                  v-model.trim="person.fuehrungsfunktion"
                  class="form-control"
                  placeholder=" "
                  :tabindex="119"
                  :readonly="!editable"
                />
                <label>{{ $t("global.managementfunction") }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <input
                  v-model.trim="person.direkter_vorgesetzter"
                  class="form-control"
                  placeholder=" "
                  :tabindex="120"
                  :readonly="!editable"
                />
                <label>{{ $t("global.directsupervisor") }}</label>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="form-group">
                <date-picker
                  date-format="dd.mm.yy"
                  :initial="person.datum_anstellungsbeginn_justizvollzug"
                  placeholer=" "
                  :show-icon="true"
                  :disabled="!editable"
                  :tabindex="121"
                  @update="
                    (val) =>
                      (person.datum_anstellungsbeginn_justizvollzug = val)
                  "
                />
                <label>{{ $t("global.startofemployment") }}</label>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="form-group">
                <input
                  type="number"
                  v-model.number="person.anzahl_jahre_berufserfahrung"
                  class="form-control"
                  placeholder=" "
                  :tabindex="120"
                  :readonly="!editable"
                />
                <label>{{ $t("global.yearsofworkexperience") }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ENDE Zusatzangaben -->
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";
import DatePicker from "@/components/Datepicker";

import { LADE_VERSANDLISTEN } from "@/store/korrespondenz/actions.type";

import { extend } from "vee-validate";

extend("ahv", {
  validate: (value) => {
    let ahv = value.replace(/\./g, "");
    let sum = 0;
    let pruefsumme = parseInt(ahv[ahv.length - 1].toString());
    for (let i = ahv.length - 2; i >= 0; i--) {
      let n = parseInt(ahv[i].toString());
      sum += i % 2 == 0 ? n : n * 3;
    }
    return (sum + pruefsumme) % 10 == 0;
  },
});

export default {
  name: "Geschaeftspartner-Zusatzangaben",
  components: { ContainerHeadline, DatePicker },
  mixins: [],
  props: {
    person: {
      type: Object,
      default: function () {
        return {};
      },
    },
    editable: {
      type: Boolean,
      required: true,
    },
    roles: {
      type: Object,
      default: function () {},
    },
  },
  data() {
    return { nationalitaet: null };
  },
  computed: {
    nationalitaeten: {
      get() {
        return this.$store.state.geschaeftspartner.nationalitaeten;
      },
    },
    versandlisten: {
      get() {
        return this.$store.state.korrespondenz.versandlisten;
      },
    },
    muttersprachen: {
      get() {
        return this.$store.state.geschaeftspartner.sprachen;
      },
    },
    interessen: {
      get() {
        return this.$store.state.geschaeftspartner.interessen;
      },
    },
    kantone: {
      get() {
        return this.$store.state.geschaeftspartner.kantone;
      },
    },
    aufenthaltsbewilligungen: {
      get() {
        return this.$store.state.geschaeftspartner.aufenthaltsbewilligungen;
      },
    },
    postleitzahlen: {
      get() {
        return this.$store.state.geschaeftspartner.postleitzahlen;
      },
    },
  },
  watch: {},
  mounted: function () {
    if (this.versandlisten.length == 0)
      this.$store.dispatch(`korrespondenz/${LADE_VERSANDLISTEN}`);

    /*if (this.nationalitaeten.length == 0)
      this.$store.dispatch(
        `geschaeftspartner/${LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER}`
      );
*/
    console.log("Zusatzangaben mounted");
  },
  methods: {
    checkAHVFormat() {
      let ahvno = this.person.sozialversicherungsnummer;

      if (ahvno == "" || ahvno.includes(".")) return;

      let p1 = ahvno.substring(0, 3);
      let p2 = ahvno.substring(3, 7);
      let p3 = ahvno.substring(7, 11);
      let p4 = ahvno.substring(11, 13);

      this.person.sozialversicherungsnummer =
        p1 + "." + p2 + "." + p3 + "." + p4;
    },
  },
};
</script>

<style></style>

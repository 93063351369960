<template>
  <div class="row form-group">
    <zahlungseingang-zuweisen-modal
      id="zahlungseingang-zuweisen-modal"
      :betrag="betrag"
      @confirmed="onDebitorResult"
    ></zahlungseingang-zuweisen-modal>
  </div>
</template>

<script>
import ZahlungseingangZuweisenModal from "@/components/Modals/ZahlungseingangZuweisenModal";
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";

export default {
  name: "SingleSelectZahlungseingang",
  components: { ZahlungseingangZuweisenModal },
  props: {
    id: {
      type: String,
      default: null,
    },
    betrag: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      ZahlungseingangZuweisenModalId: "zahlungseingang-zuweisen-modal",
    };
  },
  methods: {
    oeffneSingleSelectModal() {
      $("#zahlungseingang-zuweisen-modal").modal("show");
    },
    onDebitorResult(selectedItem) {
      if (selectedItem) {
        var json = {
          zahlschuldId: selectedItem.id,
        };

        Api.put("finanzen/zahlungseingang/", json, { params: { id: this.id } })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .then((response) => {
            this.$emit("updateZahlungseingang", response.data.zahlschuld);
          });
      }
    },
  },
};
</script>

export default {
  verkauf_rechung: {
    read: true,
    delete: false,
    change: true,
    create: false,
    book: false,
    pay: false,
    print: false
  }
}
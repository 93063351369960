<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">{{ $t("global.massmutation") }}</h4>
        </div>

        <ValidationObserver v-slot="{ invalid }">
          <div class="modal-body">
            <div class="row ml-0 mr-0">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <container-headline
                      :headline="$t('global.fieldstobemutated')"
                      :col="6"
                    ></container-headline>
                    <div class="row mb-3">
                      <div class="col-xl-12 block br-t-l-0">
                        <div class="eingabe-panel-kopf p-3">
                          <!-- For v-select values -->
                          <div class="row">
                            <div
                              v-for="(field, index) in mutatableFields"
                              :key="field"
                              class="col-xl-6"
                            >
                              <div class="form-group">
                                <v-select
                                  v-model.trim="mutatedValues[field]"
                                  :options="mutatableValues[index][0]"
                                  :reduce="(v) => v.id"
                                  :label="mutatableLabels[index]"
                                  :placeholder="mutatableFields[index]"
                                >
                                </v-select>
                                <label>{{ mutatableFields[index] }}</label>
                              </div>
                            </div>
                          </div>

                          <!-- v-select mit multiple selection -->
                          <div class="row">
                            <div
                              v-for="(field, index) in mutatableFieldsMultiple"
                              :key="field"
                              class="col-xl-6"
                            >
                              <div class="form-group">
                                <!-- Ein Array der ID's der ausgewählten Objekte landet im json -->
                                <v-select
                                  v-model.trim="mutatedValues[field]"
                                  :options="mutatableMultipleValues[index][0]"
                                  :reduce="(v) => v.id"
                                  :label="mutatableLabelsMultiple[index]"
                                  :placeholder="mutatableFieldsMultiple[index]"
                                  multiple
                                >
                                </v-select>
                                <label>{{
                                  mutatableFieldsMultiple[index]
                                }}</label>
                              </div>
                            </div>
                          </div>

                          <!-- For Checkbox values -->
                          <div class="row">
                            <div
                              v-for="(field, index) in mutatableCheckboxes"
                              :key="field"
                              class="col-xl-12 mt-2"
                            >
                              <div class="form-check">
                                <div class="d-flex justify-content-between">
                                  <label class="mr-5"
                                    >{{ mutatableCheckboxes[index] }}:</label
                                  >

                                  <div>
                                    <input
                                      id="auswaehlen"
                                      class="form-check-input"
                                      type="radio"
                                      value="auswaehlen"
                                      v-model.trim="mutatedValues[field]"
                                    />
                                    <label for="auswaehlen">{{
                                      $t("global.select")
                                    }}</label>
                                  </div>
                                  <div>
                                    <input
                                      id="entfernen"
                                      class="form-check-input"
                                      type="radio"
                                      value="entfernen"
                                      v-model.trim="mutatedValues[field]"
                                    />
                                    <label for="entfernen">{{
                                      $t("global.remove")
                                    }}</label>
                                  </div>
                                  <!-- <div>
                                    <input
                                      id="ignorieren"
                                      checked
                                      class="form-check-input"
                                      type="radio"
                                      value="ignorieren"
                                      v-model.trim="mutatedValues[field]"
                                    />
                                    <label for="ignorieren">{{
                                      $t("global.ignore")
                                    }}</label>
                                  </div> -->
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- For date values -->
                          <div class="row">
                            <div
                              v-for="(field, index) in mutatableDateFields"
                              :key="field"
                              class="col-xl-6"
                            >
                              <div class="form-group">
                                <date-picker
                                  date-format="dd.mm.yy"
                                  placeholder=""
                                  :show-icon="true"
                                  @update="
                                    (val) =>
                                      (mutatedDateValues[
                                        mutatableDateNames[index]
                                      ] = val)
                                  "
                                  :inModal="true"
                                />
                                <label>{{ mutatableDateFields[index] }}</label>
                              </div>
                            </div>
                          </div>
                          <!-- For time values -->
                          <div class="row">
                            <div
                              v-for="(field, index) in mutatableTimeFields"
                              :key="field"
                              class="col-xl-6"
                            >
                              <time-field
                                placeholder=" "
                                :label="$t('global.Time')"
                                @update="
                                  (val) =>
                                    (mutatedTimeValues[
                                      mutatableTimeNames[index]
                                    ] = val)
                                "
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeModal">
              {{ $t("global.cancel") }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="openConfirmMessageBox"
              :disabled="invalid"
            >
              {{ $t("global.save") }}
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>
    <message-box
      id="confirm-massmutation-modal"
      ref="confirmMassmutationModal"
      :headerText="$t('global.confirmmassmutation')"
      :text="$t('global.doyoureallywanttomutatethesevalues')"
      :ok="true"
      :cancel="true"
      :okText="$t('global.ok')"
      :cancelText="$t('global.cancel')"
      @ok="speichern"
    />
  </div>
</template>

<script>
import { modal } from "@/mixins/Modal";
import Api from "@/Api";
import ContainerHeadline from "@/components/ContainerHeadline";
import DatePicker from "@/components/Datepicker";
import TimeField from "@/components/TimeField.vue";
import MessageBox from "@/components/Modals/Messagebox";
import ModalCloseButton from "@/components/global/ModalCloseButton.vue";

export default {
  name: "MassenmutationModalGeneric",
  mixins: [modal],
  components: {
    ContainerHeadline,
    DatePicker,
    TimeField,
    MessageBox,
    ModalCloseButton
  },
  props: {
    id: {
      type: String,
      default: "massenmutation-modal-generic",
    },
    objects: {
      type: Array,
    },
    mutatableFields: {
      type: Array,
    },
    mutatableFieldsMultiple: {
      type: Array,
    },
    mutatableDateFields: {
      type: Array,
    },
    mutatableDateNames: {
      type: Array,
    },
    mutatableTimeFields: {
      type: Array,
    },
    mutatableTimeNames: {
      type: Array,
    },
    mutatableValues: {
      type: Array,
    },
    mutatableMultipleValues: {
      type: Array,
    },
    mutatableLabels: {
      type: Array,
    },
    mutatableLabelsMultiple: {
      type: Array,
    },
    mutatableCheckboxes: {
      type: Array,
    },
    mutationPfad: {
      type: String,
    },
    selectedIds: {
      type: Array,
    },
  },
  data() {
    return {
      beitragId: null,
      subventionsbeitrag: {},
      mutatedValues: [],
      mutatedDateValues: [],
      mutatedTimeValues: [],
      shown: false,
    };
  },
  computed: {},
  watch: {},
  async created() {},
  mounted() {
    this.$bus.$on("open-modal", (id) => {
      if (id == this.id && !this.shown) this.openModal();
    });
  },
  methods: {
    inititalizeBeitrag(beitrag) {
      if (beitrag) {
        this.beitragId = beitrag.id;
        this.subventionsbeitrag = beitrag;
      } else this.clear();
    },
    clear() {
      this.subventionsbeitrag = {};
      this.mutatedValues = [];
    },

    beitragToJson() {
      return this.subventionsbeitrag;
    },

    openConfirmMessageBox() {
      this.$refs.confirmMassmutationModal.openModal();
    },

    speichern() {
      // this.mutatedDateValues.forEach(dateEntry => this.mutatedValues.push(dateEntry));
      if (this.mutatableDateNames) {
        this.mutatableDateNames.forEach(
          (element, index) =>
            (this.mutatedValues[element] = this.mutatedDateValues[element])
        );
      }
      if (this.mutatableTimeNames) {
        this.mutatableTimeNames.forEach(
          (element, index) =>
            (this.mutatedValues[element] = this.mutatedTimeValues[element])
        );
      }
      let json = Object.assign({}, this.mutatedValues);

      Api.put(this.mutationPfad, json, {
        params: { ids: this.selectedIds.join(",") },
      })
        .then((response) => {
          if (response.data.error && response.data.error.length !== 0) {
            this.$notify({
              type: "warn",
              title: this.$t("global.actionfailed"),
              text: this.$t("global.atleastoneelementcouldnotbemutated"),
              duration: 5000,
            });
          } else {
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.allelementsweresuccessfullymutated"),
              duration: 5000,
            });
            this.closeModal();
            this.$emit("mutated");
          }
        })
        .finally(() => {
          this.closeModal();
        });
    },

    closeModal() {
      this.shown = false;
      this.clear();
      //   this.$emit("mutated");
      $("#" + this.id).modal("hide");
    },
  },
};
</script>

<style></style>

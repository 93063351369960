<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>

    <navbar v-if="showNavbar"
            :docsName="docsName"
    />

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="$t('global.templateclasslist')"
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-shortkey.once="['ctrl', 's']"
                    v-if="editable"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>

                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="editable"
                  >
                    <font-awesome-icon icon="fa-duotone fa-trash" />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <klasse-stammdaten
            :klasse="klasse"
            :editable="editable"
            :isTemplate="true"
          />
        </div>

        <div class="col-xl-6 pl-4" :class="{ 'col-xl-12': !showNavbar }">
          <klasse-bildungsgang
            :v-if="klassenid"
            :klasse="klasse"
            :editable="editable"
            :isTemplate="true"
          />

          <klasse-teilnehmer
            :v-if="klassenid"
            :klasse="klasse"
            :editable="editable"
            :isTemplate="true"
          />
        </div>

        <!-- REITER -->

        <div v-if="klassenid" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 p-0">
              <ul class="nav nav-tabs mt-3 fs-28">
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 0 }"
                    :to="{
                      name: 'template-klasse',
                      params: { klassenid: klassenid, anzeige: 0 },
                    }"
                    >{{ $t("global.Templatessubjects") }}</router-link
                  >
                </li>
                <li
                  class="nav-item"
                  v-if="berechtigungen.b_bildung_noteneingabe.read"
                >
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 2 }"
                    :to="{
                      name: 'template-klasse',
                      params: { klassenid: klassenid, anzeige: 2 },
                    }"
                    >{{ $t("global.scoreentriestemplates") }}</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="col-xl-12 p-0">
              <div class="tab-container p-3">
                <div v-show="anzeige === 0">
                  <template-fachliste
                    :klasse="klasse"
                    :shown="anzeige == 0 ? true : false"
                    :editable="editable"
                  />
                </div>
                <div v-show="anzeige === 2">
                  <templateNoteneingabeliste
                    :veranstaltung="klassenid"
                    :shown="anzeige == 2 ? true : false"
                    :editable="editable"
                    :klasseObject="klasse"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>

    <loading-overlay v-show="loading"></loading-overlay>

    <loeschen-modal id="klasse-loeschen-modal" @confirmed="klasseloeschen" />
    <change-watcher-alert />
  </div>
</template>

<script>
import Api from "@/Api";
import page from "@/mixins/Page";
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";
import LoadingOverlay from "@/components/global/LoadingOverlay";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import KlasseStammdaten from "@/components/Bildung/Klasse/Stammdaten";
import KlasseBildungsgang from "@/components/Bildung/Klasse/Bildungsgang";
import KlasseTeilnehmer from "@/components/Bildung/Klasse/Teilnehmer";

import TemplateFachliste from "@/components/Bildung/Klasse/TemplateFachliste";
import TemplateNoteneingabeliste from "@/pages/Bildung/Template/TemplateNoteneingabeliste";

import LoeschenModal from "@/components/Modals/LoeschenModal";

import ChangeWatcher from "@/mixins/ChangeWatcher";

export default {
  name: "TemplateKlasse",
  components: {
    HeadMenu,
    Navbar,
    LoadingOverlay,
    LoeschenModal,
    KlasseStammdaten,
    KlasseBildungsgang,
    KlasseTeilnehmer,
    TemplateFachliste,
    TemplateNoteneingabeliste,
  },
  metaInfo() {},
  mixins: [page, ChangeWatcher],
  props: {
    klassenid: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      editable: false,
      loading: false,

      klasse: {
        lehrgang: {
          bezeichnung: this.$t("global.notlinked"),
          lehrgangstatus: { bezeichnung: this.$t("global.notlinked") },
        },
        verantwortlicher: { personName: this.$t("global.notlinked") },
      },
    };
  },
  computed: {},
  watch: {
    klassenid: {
      handler: function (val) {
        if (val) {
          this.ladeVeranstaltung(val);
        } else {
          this.editable = true;
        }
      },
      immediate: true,
    },
  },
  created() {},
  mounted: function () {},
  methods: {
    ladeVeranstaltung(klassenid) {
      Api.get("template/veranstaltung/", { params: { id: klassenid } }).then(
        (response) => {
          this.initializeKlasse(response.data);
        }
      );
    },

    initializeKlasse(klasse) {
      if (klasse) {
        this.klasse = klasse;

        this.klasse.minTeilnehmer = this.teilnehmeranzahl_minimal;
        this.klasse.maxTeilnehmer = this.teilnehmeranzahl_maximal;

        if (!this.klasse.verantwortlicher)
          this.klasse.verantwortlicher = {
            personName: this.$t("global.notlinked"),
          };

        if (!this.klasse.lehrgang)
          this.klasse.lehrgang = {
            bezeichnung: this.$t("global.notlinked"),
            lehrgangstatus: { bezeichnung: this.$t("global.notlinked") },
          };

        if (!this.klasse.lehrgang.lehrgangstatus)
          // Datenfail in der Datenbank Lehrgang ohne Status
          this.klasse.lehrgang.lehrgangstatus = {
            bezeichnung: this.$t("global.notlinked"),
          };

        this.editable = this.$CONST("EVENTEDITABLESTATI").includes(
          this.klasse.veranstaltungsstatus.id
        )
          ? true
          : false;
      } else {
        console.error("Klasse ist null");
      }

      this.initChangeWatcher(this.klasse);
    },

    abbrechen() {
      this.$router.push({ name: "template-klassen-liste" });
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;

      var json = Object.assign({}, this.klasse);

      json.qualifikationen = this.klasse.qualifikationen.map((qa) => {
        return qa.id;
      });

      json.themen = this.klasse.themen.map((t) => {
        return t.id;
      });

      json.lehrgang = this.klasse.lehrgang.id;
      json.veranstaltungsstatus = this.klasse.veranstaltungsstatus.id;
      json.veranstaltungstyp = this.klasse.veranstaltungstyp.id;
      json.verantwortlicher = this.klasse.verantwortlicher.id;

      Api.put("template/veranstaltung/", json, {
        params: { id: this.klasse.id },
      })
        .then((response) => {
          this.initializeKlasse(response.data);
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.classsuccessfullysaved"),
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "klasse-loeschen-modal");
    },

    klasseloeschen() {
      Api.delete("template/veranstaltung/", {
        params: { ids: this.klassenid },
      }).then(() => {
        this.$router.push({ name: "template-klassen-liste" });
      });
    },
  },
};
</script>

<style></style>

<template>
  <div class="list">
    <notifications></notifications>
    <navbar :docsName="docsName"/>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters['kurzbezeichnung']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.shortdesignation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters['bezeichnung']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters['artikeltyp']"
                        :options="artikeltypen"
                        :reduce="(t) => t.id"
                        label="bezeichnung"
                        :placeholder="$t('global.itemtype')"
                      >
                        <span slot="no-options">{{
                          $t("global.noitemtypefound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.itemtype") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button />
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.itemlist')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row mt-2 d-flex justify-content-between">
                  <div class="ml-4 mr-2">
                    <router-link
                      :to="{
                        name: 'artikel',
                        params: { anzeige: 0 },
                      }"
                      tag="button"
                      class="btn btn-success"
                      ><font-awesome-icon icon="fa-regular fa-plus" />
                      {{ $t("global.newitem") }}
                    </router-link>
                  </div>
                  <div class="mr-4">
                    <button class="btn btn-danger" @click="oeffneLoeschenModal">
                      <font-awesome-icon
                        class="mr-1"
                        icon="fa-duotone fa-trash"
                      />
                      <span>{{ $t("global.delete") }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :filter="filterBezeichnung"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="openArtikel"
                    thead-tr-class="pointer"
                  >
                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <messagebox-count
      headerText="Artikel gelöscht"
      id="count-delete-alert-msgbox"
      :ok="true"
      :cancel="false"
      :okText="$t('global.ok')"
      :cancelText="$t('global.cancel')"
    />

    <loeschen-modal
      id="artikel-loeschen-modal"
      @confirmed="loeschen"
      :multiple="true"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import { debounce } from "@/utils/Debouncer";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";

import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";
import InfiniteLoading from "vue-infinite-loading";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";

import { LADE_AUSWAHLWERTE_ARTIKEL } from "@/store/artikel/actions.type";
import { SET_LISTEN_FILTER } from "@/store/dashboard/actions.type";

export default {
  name: "Artikelliste",
  components: {
    Navbar,
    InfiniteLoading,
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
    FilterSearchResetButton,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.item");
      },
    };
  },
  mixins: [page, dashboard, filter],
  props: {},
  store,
  data() {
    return {
      page: 0,
      listData: [],
      anzahlProPage: 50,
      anzahlDaten: null,
      anzahlDatenFilter: null,

      filterBezeichnung: null,
      filterKurzbezeichnung: null,
      filterArtikeltyp: null,
      filterCount: null,

      infiniteId: +new Date(),
      sortBy: "artikelnummer",
      sortDesc: false,
      selected: [],
      selectedIds: [],
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        { key: "artikelnummer", sortable: true, label: this.$t("global.num") },
        {
          key: "kurzbezeichnung",
          sortable: true,
          label: this.$t("global.shortdesignation"),
        },
        {
          key: "artikeltyp.bezeichnung",
          sortable: true,
          label: this.$t("global.itemtype"),
        },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
        {
          key: "preise",
          sortable: false,
          label: this.$t("global.prices"),
        },
      ];
    },
    queryParams: function () {
      var params = {};

      params.filterBezeichnung = this.filters.bezeichnung;
      params.filterKurzbezeichnung = this.filters.kurzbezeichnung;
      params.filterArtikeltyp = this.filters.artikeltyp;
      if (this.filterCount != "") params.filterCount = this.filters.count;

      return params;
    },
    artikeltypen: {
      get() {
        return this.$store.state.artikel.artikeltypen;
      },
    },
  },
  watch: {},
  created() {
    if (this.artikeltypen.length == 0)
      this.$store.dispatch(`artikel/${LADE_AUSWAHLWERTE_ARTIKEL}`);

    this.initFilter("artikel-liste", "artikel/", true);
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
  },
  methods: {
    openArtikel(artikel) {
      // this.storeFilter();

      this.$router.push({
        name: "artikel",
        params: { artikelid: artikel.id, anzeige: 0 },
      });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "artikel-loeschen-modal");
      else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: "Kein Artikel ausgewählt.",
        });
      }
    },

    loeschen() {
      this.delete("artikel/", this.selectedIds);
    },
  },
};
</script>

<style></style>

<template>
  <div class="container-fluid">
    <div class="bg-white br-4 p-2">
      <h4 class="display-6">
        <font-awesome-icon icon="fa-solid fa-lock" class="mr-3" />Fehlende
        Berechtigungen
      </h4>
      <p class="lead">
        Der angeforderte Bereich ist geschützt und Sie besitzen nicht die
        Rechte, diesen zu Besuchen.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ForbiddenContent",
};
</script>

<style>
</style>

<template>
  <div
    :id="id"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{ $t("global.dundebtors") }}</h4>
          <modal-close-button @confirmed="closeModal" />
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 mt-3">
              <div class="col-4">
                <div class="form-group">
                  <datepicker
                    :initial="mahnungsdatum"
                    @update="(val) => (mahnungsdatum = val)"
                    show-icon
                    date-format="dd.mm.yy"
                  ></datepicker>
                  <label>{{ $t("global.dunningdate") }}</label>
                </div>
              </div>
            </div>
            <div class="col-12 mt-3">
              <div class="col-4">
                <div class="form-group">
                  <datepicker
                    :initial="stichtag"
                    @update="(val) => (stichtag = val)"
                    show-icon
                    date-format="dd.mm.yy"
                  ></datepicker>
                  <label>{{ $t("global.referencedate") }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            @click="closeModal"
          >
            {{ $t("global.cancel") }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="mahnlaufStarten"
          >
            {{ $t("global.createdunning") }} ({{ debitorenZuMahnen.length }})
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { modal } from "@/mixins/Modal";
import Api from "@/Api";
import Datepicker from "@/components/Datepicker.vue";
import ModalCloseButton from "@/components/global/ModalCloseButton.vue";

export default {
  components: { Datepicker, ModalCloseButton },
  name: "MahnungErstellenModal",
  mixins: [modal],
  props: {
    debitorenZuMahnen: {
      type: Array,
      requiered: true,
      default: () => [],
    },
  },
  data() {
    return {
      vorlage: null,
      vorlagen: [],
      mahnungsdatum: new Date().toGermanDateString(),
      stichtag: new Date(
        Date.now() - 1 * 24 * 60 * 60 * 1000
      ).toGermanDateString(),
      kontaktperson: null,
      kontaktpersonen: [],
      unsereReferenz: null,
      einleitungstext: null,
      schlusstext: null,
    };
  },
  watch: {
    vorlage(val) {
      this.einleitungstext = val.einleitungstext;
      this.schlusstext = val.schlusstext;
    },
  },
  created() {
    Api.get("rechnungslegung/vorlage/").then((response) => {
      this.vorlagen = response.data;
      this.vorlage = response.data.find((e) => e.standard) || null;
    });
  },
  methods: {
    mahnlaufStarten() {
      if (this.debitorenZuMahnen.length != 0) {
        Api.post("finanzen/mahnungen/", {
          params: {
            ids: this.debitorenZuMahnen,
            mahnungsdatum: this.mahnungsdatum,
            stichtag: this.stichtag,
          },
        })
          .then(() => {
            /*this.resetLoading(this);*/
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.dunningrunsuccessfullycompleted"),
            });
          })
          .then(() => {
            this.closeModal();
            this.$router.push({
              path: "/finanzen/mahnungen/",
            });
          });
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actioncanceled"),
          text: this.$t("global.notification_nodebtorselected"),
        });
      }
    },

    searchKontaktperson(sucheString, loading) {
      if (sucheString) {
        loading(true);
        Api.get("geschaeftspartner/", {
          params: {
            suche: sucheString,
          },
        })
          .then((response) => {
            this.kontaktpersonen = response.data;
          })
          .finally(() => {
            loading(false);
          });
      }
    },
    kontaktpersonName(option) {
      if (typeof option === "string") {
        let person = this.kontaktpersonen.find((p) => p.id === option);
        if (person) return person.label;
        else "";
      } else {
        return option.personName;
      }
    },

    rechnungErstellen() {
      Api.post("rechnungslegung/veranstaltung/rechnung/", {
        anmeldungen: Array.from(new Set(this.anmeldungen)),
        vorlage: this.vorlage ? this.vorlage.id : null,
        rechnungsdatum: this.rechnungsdatum,
        kontaktperson: this.kontaktperson,
        veranstaltung: this.veranstaltung,
        unsereReferenz: this.unsereReferenz,
        einleitungstext: this.einleitungstext,
        schlusstext: this.schlusstext,
      }).then(() => {
        this.closeModal();
        this.$router.push({
          path: "/finanzen/auftraege",
        });
      });
    },
  },
};
</script>

<style></style>

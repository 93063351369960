import {
  SET_LOHNANSAETZE,
  SET_LOHNARTEN,
  SET_HONORAR_STATI,
  SET_ABRECHNUNGS_PERIODEN
} from "./mutations.type";

import { LADE_AUSWAHLWERTE_LOHN } from "./actions.type";

import Api from "../../Api";

export default {
  namespaced: true,
  state: {
    lohnansaetze: [],
    lohnarten: [],
    honorarstati: [],
    abrechnungsperioden: []
  },
  mutations: {
    [SET_LOHNANSAETZE](state, val) {
      state.lohnansaetze = val;
    },
    [SET_LOHNARTEN](state, val) {
      state.lohnarten = val;
    },
    [SET_HONORAR_STATI](state, val) {
      state.honorarstati = val;
    },
    [SET_ABRECHNUNGS_PERIODEN](state, val) {
      state.abrechnungsperioden = val;
    }
  },
  actions: {
    async [LADE_AUSWAHLWERTE_LOHN]({ commit }) {
      let response = await Api.get("lohn/auswahlwerte/");
      commit(SET_LOHNANSAETZE, response.data.lohnansaetze);
      commit(SET_LOHNARTEN, response.data.lohnarten);
      commit(SET_HONORAR_STATI, response.data.honorarstati);
      commit(SET_ABRECHNUNGS_PERIODEN, response.data.abrechnungsperioden);
    }
  }
};

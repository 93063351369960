<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.presence')"
        :col="3"
      >
        <div class="row">
          <div class="ml-4 mr-2"></div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              tbody-tr-class="item"
              :items="anwesenheitsliste"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="isBusy"
              show-empty
              sort-icon-left
              selectable
              responsive
              select-mode="range"
              @row-dblclicked="details"
              style="max-height: 300px"
              thead-tr-class="pointer"
            >
              <template v-slot:head(selected)="row">
                <b-form-checkbox
                  v-model="row.selected"
                  @input="selectAllRows(row)"
                ></b-form-checkbox>
              </template>

              <template v-slot:cell(selected)="row">
                <b-form-checkbox
                  v-model="row.item.selected"
                  @input="onRowSelected(row)"
                ></b-form-checkbox>
              </template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>{{ $t("global.errorwhileloading") }}</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>{{ $t("global.nodataavailable") }}</strong>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";
import reiter from "@/mixins/Reiter";

export default {
  components: {
    ContainerHeadline,
    BSpinner,
  },
  mixins: [reiter],
  props: {
    anmeldung: {
      type: String,
      required: false,
    },
    person: {
      type: String,
      required: false,
    },
    termin: {
      type: String,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    shown: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      page: 0,
      infiniteId: +new Date(),
      anzahlProPage: 100,
      sortBy: "",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      anwesenheitsliste: [],

      selected: [],
      selectedIds: [],
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "anmeldung",
          sortable: true,
          label: this.$t("global.registration"),
          formatter: (value) => {
            if (value)
              return `${value.anmeldestatus?.bezeichnung} / ${value.person.personName}`;
            else return "-";
          },
        },
        {
          key: "termin",
          sortable: true,
          label: this.$t("global.appointment"),
          formatter: (value) => {
            if (value)
              return `${value.von}  ${value.von_uhrzeit} - ${value.bis_uhrzeit}`;
            else return "-";
          },
        },
        {
          key: "anwesenheitsrate",
          sortable: true,
          label: this.$t("global.attendance"),
        },
        {
          key: "praesenzstatus.bezeichnung",
          sortable: true,
          label: this.$t("global.statuspresence"),
        },
        {
          key: "anmeldung.veranstaltung.bezeichnungKuerzel",
          sortable: true,
          label: this.$t("global.event"),
        },
      ];
    },
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
        anmeldung: this.anmeldung,
        person: this.person,
        termin: this.termin,
      };

      if (this.suche) params.suche = this.suche;

      return params;
    },
  },
  created() {},
  watch: {
    shown(val) {
      if (val && this.anwesenheitsliste.length == 0) this.getAnwesenheiten();
    },
  },
  mounted() {
    if (this.shown && this.anwesenheitsliste.length == 0)
      this.getAnwesenheiten();
  },
  methods: {
    getAnwesenheiten() {
      this.isBusy = true;

      Api.get("/bildung/praesenz/", {
        params: this.queryParams,
      })
        .then((response) => {
          this.anwesenheitsliste = response.data;
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    details(anwesenheitsliste) {
      this.$router.push({
        path: "/bildung/praesenz/" + anwesenheitsliste.id,
      });
    },
    infiniteHandler($state) {
      console.log("init");
      Api.get("anwesenheitsliste/", {
        params: this.queryParams,
      }).then((response) => {
        console.log("final");
        if (response.data.length === this.anzahlProPage) {
          this.page += 1;
          this.anwesenheitsliste.push(...response.data);
          $state.loaded();
        } else {
          this.anwesenheitsliste.push(...response.data);
          $state.complete();
        }
      });
    },
    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = true;
          if (!this.selectedIds.includes(gp.id)) {
            this.selectedIds.push(gp.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },
    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },
  },
};
</script>

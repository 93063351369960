import {
  SET_KACHELN,
  SET_SPRACHEN,
} from "./mutations.type";

import { LADE_AUSWAHLWERTE_EINSTELLUNGEN } from "./actions.type";

import Auswahlwerte from "../../Auswahlwerte";

export default {
  namespaced: true,
  state: {
    kacheln: [],
    sprachen: []
  },
  mutations: {
    [SET_KACHELN](state, val) {
      state.kacheln = val;
    },
    [SET_SPRACHEN](state, val) {
      state.sprachen = val;
    },
  },
  actions: {
    async [LADE_AUSWAHLWERTE_EINSTELLUNGEN](context) {
      let response = await Auswahlwerte.get("dashboard-selection-values");
      context.commit(SET_KACHELN, response.data.kacheln);
      context.commit(SET_SPRACHEN, response.data.sprachen);
    },
  },
};

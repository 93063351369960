import { library } from "@fortawesome/fontawesome-svg-core";
import { faFileSpreadsheet } from "@fortawesome/pro-regular-svg-icons";

library.add(faFileSpreadsheet);

import FileDownload from "js-file-download";
import Api from "@/Api";
import ExportApi from "@/ExportApi";

export default {
  methods: {
    exportToExcel(data, title) {
      let json = {
        data: data,
        title: title,
        type: "json",
      };

      Api.post(
        "/export/",
        json,
        { type: "json", title: title, responseType: "blob" } //SWE: notwendig damit OutputExcel nicht beschädigt wird
      )
        .then((response) => {
          this.excelFile = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          FileDownload(
            this.excelFile,
            title + `-${new Date().toLocaleDateString()}.xlsx`
          );
          this.loading = false;
        })
        .catch(() => {
          setTimeout(() => {
            this.$notify({
              type: "error",
              title: "Aktion fehlgeschlagen",
              text: "Fehler beim Erstellen der Excel-Datei",
              duration: 7000,
            });
          }, 2000);
          this.loading = false;
        });
    },

    // Excel Export über Export-Plugin
    exportToExcelWithPlugin(data, endpoint, title) {
      ExportApi.post("/excel/" + endpoint, data, {
        type: "json",
        title: title,
        responseType: "blob",
      })
        .then((response) => {
          this.excelFile = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          FileDownload(
            this.excelFile,
            title + `-${new Date().toLocaleDateString()}.xlsx`
          );
          this.loading = false;
        })
        .catch(() => {
          setTimeout(() => {
            this.$notify({
              type: "error",
              title: "Aktion fehlgeschlagen",
              text: "Fehler beim Erstellen der Excel-Datei",
              duration: 7000,
            });
          }, 2000);
          this.loading = false;
        });
    },
  },
};

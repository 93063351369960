<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>

    <navbar v-if="showNavbar"
            :docsName="docsName"
    />

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="$t('global.fee')"
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    v-if="editable || isAdmin"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>

                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="berechtigungen.b_finanzen_honorar.delete"
                  >
                    <font-awesome-icon
                      class="mr-1"
                      icon="fa-duotone fa-trash"
                    />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- START Linke Seite -->
        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Verknüpfung -->
          <container-headline
            :headline="$t('global.masterdata')"
            :col="4"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <v-select
                        v-model="honorar.honorarstatus"
                        label="bezeichnung"
                        :options="honorarstati"
                        :reduce="(hs) => hs.id"
                        :disabled="!editable && !isAdmin"
                        :placeholder="$t('global.statusfee')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.statusfee") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :initial="honorar.honorardatum"
                        placeholer=" "
                        :show-icon="true"
                        :disabled="!editable"
                        @update="(val) => (honorar.honorardatum = val)"
                      />
                      <label>{{ $t("global.date") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <input
                        v-model="honorar.bezeichnung"
                        class="form-control"
                        placeholder=" "
                        :readonly="!editable"
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <input
                        v-model="honorar.honorarnummer"
                        class="form-control"
                        placeholder=" "
                        readonly
                      />
                      <label>{{ $t("global.number") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <textarea
                        v-model.trim="honorar.bemerkung"
                        class="form-control"
                        placeholder=" "
                        :readonly="!editable"
                        rows="4"
                      ></textarea>
                      <label>{{ $t("global.comment") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- START Verknüpfung -->
          <container-headline
            :headline="$t('global.wagedata')"
            :col="4"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-6">
                    <single-select-person
                      id="honorar-empfaenger"
                      :person="honorar.empfaenger"
                      :label="$t('global.receiver')"
                      :allowOpen="berechtigungen.m_geschaeftspartner.read"
                      :editable="editable"
                      :required="true"
                      :rollen="empfaengerRollen"
                      @confirmed="setEmpfaenger"
                    />
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <v-select
                        v-model="honorar.empfaenger.anstellung"
                        label="bezeichnung"
                        :options="anstellungen"
                        :reduce="(aa) => aa.id"
                        :disabled="!editable"
                        :placeholder="$t('global.typeofemployment')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.typeofemployment") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <v-select
                        v-model="honorar.lohnart"
                        label="bezeichnung"
                        :options="lohnarten"
                        :reduce="(la) => la.id"
                        :disabled="!editable"
                        :placeholder="$t('global.wagetype')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.wagetype") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-2">
                    <div class="form-group">
                      <input
                        v-model.number="anzahl"
                        class="form-control"
                        placeholder=" "
                        :readonly="!editable"
                      />
                      <label>{{ $t("global.Number") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <div class="form-group">
                      <input
                        v-model.number="ansatz"
                        class="form-control"
                        placeholder=" "
                        :readonly="!editable"
                      />
                      <label> {{ $t("global.rate") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <div class="form-group">
                      <input
                        v-model.number="honorar.betrag"
                        class="form-control"
                        placeholder=" "
                        :readonly="!editable"
                      />
                      <label>{{ $t("global.amount") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <v-select
                        v-model="honorar.abrechnungsperiode"
                        label="bezeichnung"
                        :options="abrechnungsperioden"
                        :reduce="(ap) => ap.id"
                        :disabled="!editable"
                        :placeholder="$t('global.billingperiod')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.billingperiod") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- ENDE linke Seite -->

        <!-- START Rechte Seite -->
        <div class="col-xl-6 pl-4" :class="{ 'col-xl-12': !showNavbar }">
          <container-headline
            :headline="$t('global.event')"
            :col="4"
            v-if="honorar.dozenteneinsatz.id"
          ></container-headline>
          <div class="row mb-3" v-if="honorar.dozenteneinsatz.id">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-6">
                    <single-select-veranstaltung
                      id="honorar-veranstaltung"
                      :veranstaltung="
                        honorar.dozenteneinsatz.termin.veranstaltung
                      "
                      :label="$t('global.event')"
                      :allowOpen="berechtigungen.b_bildung_fach.read"
                      :editable="false"
                    />
                  </div>
                  <div class="col-xl-6">
                    <span class="small">{{ $t("global.appointment") }}</span>
                    <div v-on:click="openTermin" style="cursor: pointer">
                      {{ honorar.dozenteneinsatz.termin.von }}
                      {{ honorar.dozenteneinsatz.termin.von_uhrzeit }} -
                      {{ honorar.dozenteneinsatz.termin.bis }}
                      {{ honorar.dozenteneinsatz.termin.bis_uhrzeit }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="row">
                      <div class="col-12">
                        <span class="small">{{
                          $t("global.lecturerbooking")
                        }}</span>
                        <div
                          v-on:click="openDozentenbuchung"
                          style="cursor: pointer"
                        >
                          {{
                            honorar.dozentenbuchung
                              ? honorar.dozentenbuchung.person
                                ? honorar.dozentenbuchung.person.personName
                                : ""
                              : ""
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <single-select-dozenteneinsatz
                      id="dozenteneinsatz-suchen"
                      :dozenteneinsatz="honorar.dozenteneinsatz"
                      :label="$t('global.lecturerdeployment')"
                      :allowOpen="berechtigungen.m_geschaeftspartner.read"
                      :editable="editable"
                      :required="false"
                      :rollen="empfaengerRollen"
                      @confirmed="setDozenteneinsatz"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- START Verlauf -->
          <container-headline
            :headline="$t('global.process')"
            :col="4"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-4">
                    <single-select-person
                      id="honorar-zustaendiger"
                      :person="honorar.zustaendig"
                      :label="$t('global.REsponsible')"
                      :allowOpen="berechtigungen.m_geschaeftspartner.read"
                      :editable="editable"
                      :required="true"
                      :rollen="zustaendigRollen"
                      @confirmed="setZustaendiger"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4">
                    <span class="small">{{ $t("global.open") }}</span>
                    <div>{{ honorar.offen }}</div>
                  </div>
                  <div class="col-xl-4">
                    <span class="small">{{ $t("global.canceled") }}</span>
                    <div>{{ honorar.storniert }}</div>
                  </div>

                  <div class="col-xl-4">
                    <span class="small">{{ $t("global.Settled") }}</span>
                    <div>{{ honorar.abgerechnet }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ENDE Verlauf -->
        </div>
        <!-- ENDE Rechte Seite -->
      </div>
    </ValidationObserver>

    <!-- MODALS -->

    <loeschen-modal id="honorar-loeschen-modal" @confirmed="honorarLoeschen" />

    <loading-overlay v-show="loading"></loading-overlay>

    <change-watcher-alert />
  </div>
</template>

<script>
import Api from "@/Api";
import page from "@/mixins/Page";
import HeadMenu from "@/components/HeadMenu";
import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import LoadingOverlay from "@/components/global/LoadingOverlay";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import SingleSelectVeranstaltung from "@/components/global/SingleSelectVeranstaltung.vue";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import SingleSelectDozenteneinsatz from "@/components/global/SingleSelectDozenteneinsatz.vue";
import DatePicker from "@/components/Datepicker";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import store from "@/store";
import { LADE_AUSWAHLWERTE_LOHN } from "@/store/lohn/actions.type";
import { LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER } from "@/store/geschaeftspartner/actions.type";

import ChangeWatcher from "@/mixins/ChangeWatcher";
import ChangeWatcherAlert from "@/components/global/ChangeWatcherAlert.vue";

export default {
  name: "Honorar",
  components: {
    HeadMenu,
    Navbar,
    ContainerHeadline,
    LoeschenModal,
    LoadingOverlay,
    SingleSelectVeranstaltung,
    SingleSelectPerson,
    SingleSelectDozenteneinsatz,
    DatePicker,
    ChangeWatcherAlert,
  },
  metaInfo() {},
  mixins: [page, ChangeWatcher],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      honorar: {
        empfaenger: { personName: this.$t("global.notlinked") },
        zustaendig: { personName: this.$t("global.notlinked") },
        dozentenbuchung: {},
        dozenteneinsatz: { termin: { veranstaltung: {} } },
        lohnart: {},
        honorarstatus: {
          id: "1763D22FD2F",
          bezeichnung: this.$t("global.open"),
          kuerzel: "open",
        },
        honorardatum: new Date().toGermanDateString(),
      },
      isAdmin: false,
      ansatz: 0,
      anzahl: 0,
      loading: false,
      editable: false,
      zustaendigRollen: this.$CONST("ROLLEN").MITARBEITER,
      empfaengerRollen: this.$CONST("ROLLEN").DOZENTEN,
    };
  },
  computed: {
    anstellungen: {
      get() {
        return this.$store.state.geschaeftspartner.anstellungsarten;
      },
    },
    honorarstati: {
      get() {
        return this.$store.state.lohn.honorarstati;
      },
    },
    lohnarten: {
      get() {
        return this.$store.state.lohn.lohnarten;
      },
    },
    abrechnungsperioden: {
      get() {
        return this.$store.state.lohn.abrechnungsperioden;
      },
    },
  },
  watch: {
    ansatz: function (val) {
      this.honorar.betrag = val * this.anzahl;
    },
    anzahl: function (val) {
      this.honorar.betrag = val * this.ansatz;
    },
  },
  created() {
    if (this.id) {
      Api.get("finanzen/honorar/", { params: { id: this.id } }).then(
        (response) => {
          this.initializeHonorar(response.data);
        }
      );
    } else {
      this.editable = true;
      if (!this.honorar.zustaendig.id) {
        this.honorar.zustaendig = this.angemeldeterMitarbeiter.person;
      }
    }
  },
  mounted: function () {
    if (this.anstellungen.length == 0)
      this.$store.dispatch(
        `geschaeftspartner/${LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER}`
      );

    if (this.honorarstati.length == 0)
      this.$store.dispatch(`lohn/${LADE_AUSWAHLWERTE_LOHN}`);

    this.isAdmin =
      this.gruppen.findIndex(
        (gruppe) => gruppe.id == this.$CONST("GRUPPEN").ADMIN
      ) == -1
        ? false
        : true;
  },
  methods: {
    abbrechen() {
      this.$router.push({ name: "honorar-liste" });
    },

    openTermin() {
      this.$router.replace({
        name: "termin",
        params: {
          id: this.honorar.termin ? this.honorar.termin.id : null,
          anzeige: 0,
        },
      });
    },

    openDozentenbuchung() {
      this.$router.replace({
        name: "dozentenbuchung",
        params: {
          id: this.honorar.dozentenbuchung
            ? this.honorar.dozentenbuchung.id
            : null,
          anzeige: 0,
        },
      });
    },

    initializeHonorar(honorar) {
      if (honorar) {
        this.honorar = honorar;

        //Watcher auf diese Werte um Betrag zu berechnen
        this.ansatz = honorar.ansatz;
        this.anzahl = honorar.anzahl;

        this.editable =
          this.honorar.honorarstatus.id == this.$CONST("HONORARSTATI").OPEN
            ? true
            : false;

        if (!honorar.dozenteneinsatz) {
          this.honorar.dozenteneinsatz = { termin: { veranstaltung: {} } };
        }

        if (!this.honorar.zustaendig.id) {
          this.honorar.zustaendig = this.angemeldeterMitarbeiter.person;
        }

        if (!this.berechtigungen.b_finanzen_honorar.update)
          this.editable = false;

        this.initChangeWatcher(this.honorar);
      } else {
        console.error("honorar ist null");
      }
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;
      var json = Object.assign({}, this.honorar);

      json.ansatz = this.ansatz;
      json.anzahl = this.anzahl;

      if (!this.honorar.id) {
        Api.post("finanzen/honorar/", json, {
          params: { id: json.dozenteneinsatz.id },
        })
          .then((response) => {
            this.$router.replace({
              name: "honorar",
              params: { id: response.data.id, anzeige: 0 },
            });
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.feesuccessfullysaved"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("finanzen/honorar/", json, {
          params: { id: this.honorar.id },
        })
          .then((response) => {
            this.initializeHonorar(response.data);

            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.feesuccessfullysaved"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    setZustaendiger(person) {
      this.honorar.zustaendig = person;
    },

    setDozenteneinsatz(dozenteneinsatz) {
      this.honorar.dozenteneinsatz = dozenteneinsatz;
      this.honorar.dozenteneinsatz.termin.veranstaltung =
        dozenteneinsatz.dozentenbuchung.veranstaltung;
    },

    setEmpfaenger(person) {
      this.honorar.empfaenger = person;
    },

    honorarLoeschen() {
      Api.delete("finanzen/honorar/", { data: [this.id] }).then(() => {
        this.$router.push({ name: "honorar-liste" });
      });
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "honorar-loeschen-modal");
    },
  },
};
</script>

<style></style>

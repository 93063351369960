var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'mt-30-px': !_vm.showNavbar }},[_c('notifications'),(_vm.showNavbar)?_c('navbar',{attrs:{"docsName":_vm.docsName}}):_vm._e(),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"container-fluid row"},[(_vm.showNavbar)?_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 block br-t-l-0 mb-3"},[_c('head-menu',{staticClass:"d-xl-flex",attrs:{"col":12,"headline":_vm.$t('global.informationevent') +
                (_vm.infoanlass.bezeichnung ? ': ' + _vm.infoanlass.bezeichnung : '')}}),_c('div',{staticClass:"row mt-2 d-flex justify-content-between"},[_c('div',{staticClass:"ml-4"},[(_vm.editable)?_c('button',{directives:[{name:"shortkey",rawName:"v-shortkey.once",value:(['ctrl', 's']),expression:"['ctrl', 's']",modifiers:{"once":true}}],staticClass:"btn btn-success mr-2",attrs:{"disabled":invalid},on:{"click":_vm.speichern,"shortkey":_vm.speichern}},[_vm._v(" "+_vm._s(_vm.$t("global.save"))+" ")]):_vm._e(),_c('button',{staticClass:"btn btn-primary mr-2",on:{"click":_vm.abbrechen}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"fa-solid fa-list"}}),_vm._v(_vm._s(_vm.$t("global.tolist"))+" ")],1)]),_c('div',{staticClass:"mr-4"},[(_vm.berechtigungen.m_bildung.delete)?_c('button',{staticClass:"btn btn-danger",on:{"click":_vm.oeffneLoeschenModal}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":"fa-duotone fa-trash"}}),_c('span',[_vm._v(_vm._s(_vm.$t("global.delete")))])],1):_vm._e()])])],1)])]):_vm._e(),_c('div',{staticClass:"col-xl-6 pr-4",class:{ 'col-xl-12': !_vm.showNavbar }},[_c('generisch-stammdaten',{attrs:{"v-if":_vm.infoanlassid,"veranstaltung":_vm.infoanlass,"editable":_vm.editable,"isTemplate":false,"veranstaltungstypen":_vm.infoanlasstypen}}),_c('generisch-teilnehmer',{attrs:{"v-if":_vm.infoanlassid,"veranstaltung":_vm.infoanlass,"editable":_vm.editable,"isTemplate":false}})],1),_c('div',{staticClass:"col-xl-6 pl-4",class:{ 'col-xl-12': !_vm.showNavbar }},[_c('infoanlass-details',{attrs:{"v-if":_vm.infoanlassid,"infoanlass":_vm.infoanlass,"editable":_vm.editable,"isTemplate":false}})],1),(_vm.infoanlassid)?_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 p-0"},[_c('ul',{staticClass:"nav nav-tabs mt-3 fs-28"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"primary-headline-text nav-link",class:{ active: _vm.anzeige === 0 },attrs:{"to":{
                    name: 'infoanlass',
                    params: { infoanlassid: _vm.infoanlassid, anzeige: 0 },
                  }}},[_vm._v(_vm._s(_vm.$t("global.registrations")))])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"primary-headline-text nav-link",class:{ active: _vm.anzeige === 1 },attrs:{"to":{
                    name: 'infoanlass',
                    params: { infoanlassid: _vm.infoanlassid, anzeige: 1 },
                  }}},[_vm._v(_vm._s(_vm.$t("global.lecturerbooking")))])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"primary-headline-text nav-link",class:{ active: _vm.anzeige === 2 },attrs:{"to":{
                    name: 'infoanlass',
                    params: { infoanlassid: _vm.infoanlassid, anzeige: 2 },
                  }}},[_vm._v(_vm._s(_vm.$t("global.appointment")))])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"primary-headline-text nav-link",class:{ active: _vm.anzeige === 3 },attrs:{"to":{
                    name: 'infoanlass',
                    params: { infoanlassid: _vm.infoanlassid, anzeige: 3 },
                  }}},[_vm._v(_vm._s(_vm.$t("global.clearingitems")))])],1)])]),_c('div',{staticClass:"col-xl-12 p-0"},[_c('div',{staticClass:"tab-container p-3"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.anzeige === 0),expression:"anzeige === 0"}]},[_c('anmeldungsliste',{key:_vm.anmeldungslisteKey,attrs:{"veranstaltung":_vm.infoanlassid,"shown":_vm.anzeige == 0 ? true : false,"editable":_vm.editable,"showUebertragen":false}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.anzeige === 1),expression:"anzeige === 1"}]},[_c('dozentenbuchungsliste',{attrs:{"veranstaltung":_vm.infoanlassid,"shown":_vm.anzeige == 1 ? true : false,"editable":_vm.editable}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.anzeige === 2),expression:"anzeige === 2"}]},[_c('terminliste',{attrs:{"veranstaltung":_vm.infoanlassid,"shown":_vm.anzeige == 2 ? true : false,"editable":_vm.editable}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.anzeige === 3),expression:"anzeige === 3"}]},[_c('verrechnungspositionen',{attrs:{"veranstaltung":_vm.infoanlassid,"shown":_vm.anzeige == 3 ? true : false,"editable":_vm.editable},on:{"positionChanged":_vm.anmeldungslisteUpdate}})],1)])])])]):_vm._e()])]}}])}),_c('loading-overlay',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}]}),_c('change-watcher-alert'),_c('messagebox-enhanced',{attrs:{"headerText":_vm.$t('global.startpromotion'),"ok":true},on:{"ok":_vm.promotionStart}}),_c('loeschen-modal',{attrs:{"id":"infoanlass-loeschen-modal"},on:{"confirmed":_vm.infoanlassloeschen}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="modal" tabindex="-1" :id="id" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content bg-ice">
        <div class="modal-header bg-cosmos p-3">
          <span>{{ headerText }}</span>
          <modal-close-button @confirmed="closeModal()" />
        </div>
        <div class="modal-body fs-14">
          <!-- DATUM -->
          <div class="row mb-3">
            <div class="col-12 mb-2">
              <span class="text-bold">{{
                $t("global.pleaseselectpdfdate")
              }}</span>
            </div>
            <div class="col-6">
              <div class="form-group p-0">
                <date-picker
                  date-format="dd.mm.yy"
                  placeholder=" "
                  :show-icon="true"
                  :inModal="true"
                  @update="(val) => setDate(val)"
                />
              </div>
            </div>
          </div>

          <!-- SPRACHE -->
          <div class="row mb-3" v-if="languages.length > 1">
            <div class="col-12 mb-2">
              <span class="text-bold">{{
                $t("global.pleaseselectpdflanguage")
              }}</span>
            </div>
            <div class="col-12 d-flex flex-wrap">
              <div class="mr-3" v-if="languages.includes('DE')">
                <input id="DE" v-model="language" type="radio" value="DE" />
                <label class="ml-2" for="DE">{{ $t("global.german") }}</label>
              </div>

              <div class="mr-3" v-if="languages.includes('EN')">
                <input id="EN" v-model="language" type="radio" value="EN" />
                <label class="ml-2 mr-3" for="EN">{{
                  $t("global.english")
                }}</label>
              </div>

              <div class="mr-3" v-if="languages.includes('FR')">
                <input id="FR" v-model="language" type="radio" value="FR" />
                <label class="ml-2 mr-3" for="FR">{{
                  $t("global.french")
                }}</label>
              </div>

              <div class="mr-3" v-if="languages.includes('IT')">
                <input id="IT" v-model="language" type="radio" value="IT" />
                <label class="ml-2 mr-3" for="IT">{{
                  $t("global.italian")
                }}</label>
              </div>
            </div>
          </div>
          <div class="row mb-3" v-if="languages.length == 1">
            <div class="col-12 mb-2 d-flex">
              <span class="mr-2 text-bold"
                >{{ $t("global.availablelanguage") }}:</span
              >
              <div v-if="languages.includes('DE')">
                {{ $t("global.german") }}
              </div>
              <div v-if="languages.includes('EN')">
                {{ $t("global.english") }}
              </div>
              <div v-if="languages.includes('FR')">
                {{ $t("global.french") }}
              </div>
              <div v-if="languages.includes('IT')">
                {{ $t("global.italian") }}
              </div>
            </div>
          </div>

          <!-- ZERTIFIKAT TYP -->
          <div class="row mb-3" v-if="certificateTypes.length > 0">
            <div class="col-12 mb-2">
              <span class="text-bold">{{ certificateTypesText }}</span>
            </div>

            <div class="col-12 d-flex flex-wrap flex-column">
              <div
                v-for="(certType, index) in certificateTypes"
                :key="'certType-' + index"
                class="mr-3"
              >
                <input
                  :id="certType.id"
                  v-model="certificateType"
                  type="radio"
                  :value="certType"
                />
                <label class="ml-2" :for="certType.id">{{
                  certType.bezeichnung
                }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row w-100">
            <div class="col-12 p-0 d-flex justify-content-between text-center">
              <button
                type="button"
                class="btn btn-danger mr-3"
                data-dismiss="modal"
                @click="onCancel"
              >
                <span class="font-khand font-weight-bold">{{
                  $t("global.cancel")
                }}</span>
              </button>
              <button
                type="button"
                class="btn btn-success"
                @click="onOk"
                :disabled="!formIsValid"
              >
                <span class="font-khand font-weight-bold">{{ okText }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { modal } from "@/mixins/Modal";
import ModalCloseButton from "@/components/global/ModalCloseButton";
import DatePicker from "@/components/Datepicker";

export default {
  name: "ZertifikatAuswahlModal",
  components: {
    ModalCloseButton,
    DatePicker,
  },
  mixins: [modal],
  props: {
    id: {
      type: String,
      default: "zertifikat-auswahl-modal",
    },
    headerText: {
      type: String,
      default: "Zertifikat konfigurieren",
    },
    okText: {
      type: String,
      default: "OK",
    },
    languages: {
      type: Array,
      default: () => [],
    },
    certificateTypesText: {
      type: String,
      default: "",
    },
    certificateTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headerTitel: null,
      date: null,
      type: 0,
      language: null,
      certificateType: null,
    };
  },
  computed: {
    formIsValid() {
      if (!this.date) return false;
      if (this.languages.length > 0 && !this.language) return false;
      if (this.certificateTypes.length > 0 && !this.certificateType)
        return false;

      return true;
    },
  },
  mounted() {
    if (this.languages.length == 1) this.language = this.languages[0];
  },
  methods: {
    onCancel() {
      this.closeModal();
    },
    onOk() {
      this.closeModal();

      let json = {
        date: this.date,
        language: this.language,
        certificateType: this.certificateType,
      };
      this.$emit("ok", json);
    },
    setDate(val) {
      this.date = val;
    },
  },
};
</script>
  
import Subventionsliste from "@/pages/Dashboardlisten/Subventionsliste";
import Subventionsbeitragliste from "@/pages/Dashboardlisten/Subventionsbeitragliste";
import Subventionsabrechnungliste from "@/pages/Dashboardlisten/Subventionsabrechnungliste";
import Subvention from "@/pages/Bildung/Subvention/Subvention";
import Subventionsbeitrag from "@/pages/Bildung/Subvention/Subventionsbeitrag";
import Subventionsabrechnung from "@/pages/Bildung/Subvention/Subventionsabrechnung";


export default [
  {
    path: "/Bildung/Subventionsliste",
    name: "subventionsliste",
    component: Subventionsliste
  },
  {
    path: "/Bildung/Subventionsbeitraege",
    name: "subventionsbeitragliste",
    component: Subventionsbeitragliste
  },
  {
    path: "/Bildung/Subventionsabrechnungliste",
    name: "subventionsabrechnungliste",
    component: Subventionsabrechnungliste
  },
  {
    path: "/Bildung/Subventionsbeitrag",
    name: "neuer-subventionsbeitrag",
    component: Subvention
  },
  {
    path: "/Bildung/Subventionsbeitrag/:subventionsbeitragid/:anzeige",
    name: "subventionsbeitrag",
    component: Subventionsbeitrag,
    props: route => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        subventionsbeitragid: route.params.subventionsbeitragid,
        anzeige: isNaN(a) ? 0 : a
      };
    },
  },
  {
    path: "/Bildung/Subvention",
    name: "neue-subvention",
    component: Subvention
  },
  {
    path: "/Bildung/Subvention/:subventionid/:anzeige",
    name: "subvention",
    component: Subvention,
    props: route => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        subventionid: route.params.subventionid,
        anzeige: isNaN(a) ? 0 : a
      };
    }
  },
  {
    path: "/Bildung/Subventionsabrechnung/:subventionsabrechnungid",
    name: "subventionsabrechnung",
    component: Subventionsabrechnung,
    props: route => {
      return {
        subventionsabrechnungid: route.params.subventionsabrechnungid,
      };
    }
  },
];

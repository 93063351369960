<template>
  <div class="modal" tabindex="-1" :id="id" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content bg-ice">
        <div class="modal-header bg-cosmos p-3">
          <span>{{ headerTitel }}</span>
          <modal-close-button />
        </div>
        <div class="modal-body">
          <p v-if="text !== null">{{ text }}</p>
          <span v-if="successText && successCount > 0 && successCount != null" class="text-success"
            >{{ successText }}{{ successCount }}</span
          >
          <span v-if="errorText && errorsCount > 0 && errorsCount != null" class="text-danger"
            >{{ errorText }}{{ errorsCount }}</span
          >
          <span> </span>
          <slot></slot>
        </div>
        <div class="modal-footer">
          <div class="row w-100">
            <div class="col-12 text-center">
              <button
                v-show="repeat"
                type="button"
                class="btn btn-primary mr-3"
                data-dismiss="modal"
                @click="onRepeat"
              >
                <span class="font-khand font-weight-bold">{{
                  repeatText
                }}</span>
              </button>
              <button
                v-show="ok"
                type="button"
                class="btn btn-success mr-3"
                @click="onOk"
              >
                <span class="font-khand font-weight-bold">{{ okText }}</span>
              </button>
              <button
                v-show="cancel"
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                @click="onCancel"
              >
                <span class="font-khand font-weight-bold">{{
                  cancelText
                }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { modal } from "@/mixins/Modal";
import ModalCloseButton from "@/components/global/ModalCloseButton";

export default {
  name: "Messagebox",
  components: {
    ModalCloseButton,
  },
  props: {
    headerText: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      default: "count-enhanced-modal",
    },
    ok: {
      type: Boolean,
      default: false,
    },
    cancel: {
      type: Boolean,
      default: true,
    },
    repeat: {
      type: Boolean,
      default: false,
    },
    okText: {
      type: String,
      default: "OK",
    },
    cancelText: {
      type: String,
      default: "Abbrechen",
    },
    repeatText: {
      type: String,
      default: "Wiederholen",
    },
  },
  data() {
    return {
      text: null,
      successText: null,
      errorText: null,
      headerTitel: null,
      successCount: null,
      errorsCount: null,
      
    };
  },
  mixins: [modal],
  mounted() {
    this.$bus.$on("openMessageBoxCount", (data) => {
      this.successCount = null;
      this.errorsCount = null;
      if (data.id != null && data.id != this.id) return;
    
      this.text = data.text ? data.text : this.text;
      this.headerTitel = data.headerText ? data.headerText : this.headerText;

      this.successText = data.successText ? data.successText : this.successText;
      this.errorText = data.errorText ? data.errorText : this.errorText;

      this.successCount = data.successCount
        ? data.successCount
        : this.successCount;
        
      this.errorsCount = data.errorsCount ? data.errorsCount : this.errorsCount;
   

      $("#" + this.id).modal({
        backdrop: "static",
        keyboard: false,
      });
    });
  },
  methods: {
    closeModal() {
      $("#" + this.id).modal("hide");
    },
    onCancel() {
      this.closeModal();
      this.$emit("cancel");
    },
    onOk() {
      this.closeModal();
      this.$emit("ok");
    },
    onRepeat() {
      this.closeModal();
      this.$emit("repeat");
    },
  },
};
</script>

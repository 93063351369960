<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="'Einträge (' + count + ')'"
        :col="3"
      >
        <div class="row mt-2 d-flex justify-content-between">
          <div class="ml-4">
            <button
              class="btn btn-primary mr-2"
              @click="massmail(true)"
              v-if="hasActive"
            >
              <span class="mr-2">
                <font-awesome-icon icon="fa-duotone fa-envelope" /></span
              >{{ $t("global.massemailactive") }}
            </button>
            <button class="btn btn-primary mr-2" @click="massmail(false)">
              <span class="mr-2">
                <font-awesome-icon icon="fa-duotone fa-envelope" /></span
              >{{ $t("global.massemailall") }}
            </button>
            <button
              class="btn btn-primary mr-2"
              @click="serienbrief(true)"
              v-if="hasActive"
            >
              <span class="mr-2">
                <font-awesome-icon icon="fa-duotone fa-envelope" /></span
              >{{ $t("global.serialletteractive") }}
            </button>
            <button class="btn btn-primary mr-2" @click="serienbrief(false)">
              <span class="mr-2">
                <font-awesome-icon icon="fa-duotone fa-envelope" /></span
              >{{ $t("global.serialletterall") }}
            </button>
          </div>
          <div class="mr-4">
            <button
              class="btn btn-danger"
              @click="oeffneLoeschenModal"
              v-if="berechtigungen.m_kampagne.delete"
            >
              <font-awesome-icon class="mr-2" icon="fa-duotone fa-trash" />
              <span>{{ $t("global.delete") }}</span>
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              tbody-tr-class="item"
              :items="eintraege"
              :fields="fields"
              :busy="isBusy"
              show-empty
              sort-icon-left
              fixed
              selectable
              select-mode="range"
              @row-dblclicked="details"
            >
              <template v-slot:head(selected)="row">
                <b-form-checkbox
                  v-model="row.selected"
                  @input="selectAllRows(row)"
                ></b-form-checkbox>
              </template>
              <template v-slot:cell(selected)="row">
                <b-form-checkbox
                  v-model="row.item.selected"
                  @input="onRowSelected(row)"
                ></b-form-checkbox>
              </template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>{{ $t("global.errorwhileloading") }}</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>{{ $t("global.nodataavailable") }}</strong>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="eintrag-loeschen-modal"
      @confirmed="eintragloeschen"
      :multiple="true"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import ContainerHeadline from "@/components/ContainerHeadline";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import reiter from "@/mixins/Reiter";
import { BSpinner } from "bootstrap-vue";

import { library } from "@fortawesome/fontawesome-svg-core";

import { faEnvelope } from "@fortawesome/pro-duotone-svg-icons";

library.add(faEnvelope);

export default {
  components: {
    ContainerHeadline,
    LoeschenModal,
    BSpinner,
  },
  mixins: [reiter],

  props: {
    kampagne: {
      type: String,
      required: false,
    },
    shown: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      count: 0,
      isBusy: false,
      ladeFehler: false,
      eintraege: [],
      teilnehmer: null,
      hasActive: false,
    };
  },
  watch: {
    shown(val) {
      if (val && this.eintraege.length == 0) this.getEintrage();
    },
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "erstelltdatum",
          sortable: true,
          label: this.$t("global.created"),
        },
        {
          key: "foreigntable",
          sortable: true,
          label: this.$t("global.typeofaction"),
          formatter: (value) => {
            if (value) {
              switch (value) {
                case "masskorrespondenz":
                  return this.$t("global.serialletter");

                case "massmail":
                  return this.$t("global.massemail");
                default:
                  return "-";
              }
            } else {
              return "-";
            }
          },
        },
        {
          key: "status.bezeichnung",
          sortable: true,
          label: this.$t("global.status"),
        },
        {
          key: "verantwortlicher.personName",
          sortable: true,
          label: this.$t("global.Responsible"),
        },
      ];
    },
  },
  mounted() {
    if (this.shown) {
      this.getEintrage();
      this.getTeilnehmer();
    }

    this.$bus.$on("kampagne-teilnehmer-update", (data) => {
      this.updatedTeilnehmer(data);
    });
  },
  created() {},

  methods: {
    getEintrage() {
      this.isBusy = true;
      Api.get("crm/kampagne/eintrag/", {
        params: {
          kampagne: this.kampagne,
        },
      })
        .then((response) => {
          this.eintraege = response.data;
          this.count = this.eintraege.length;
          this.ladeFehler = false;
        })
        .catch((e) => {
          console.log(e);
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    getTeilnehmer() {
      this.isBusy = true;
      Api.get("crm/kampagne/teilnehmer/", {
        params: {
          kampagne: this.kampagne,
        },
      })
        .then((response) => {
          this.updatedTeilnehmer(response.data);
          this.ladeFehler = false;
        })
        .catch((e) => {
          console.log(e);
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    details(eintrag) {
      switch (eintrag.foreigntable) {
        case "masskorrespondenz":
          this.$router.push({
            name: "korrespondenz",
            params: {
              id: eintrag.foreignkey,
              anzeige: 0,
            },
          });
          break;
        case "massmail":
          this.$router.push({
            name: "mail",
            params: {
              id: eintrag.foreignkey,
              anzeige: 0,
            },
          });
          break;
      }
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "eintrag-loeschen-modal");
    },

    eintragloeschen() {
      Api.delete("crm/kampagne/eintrag/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.getEintrage();
      });
    },

    updatedTeilnehmer(teilnehmer) {
      this.teilnehmer = teilnehmer;

      this.hasActive =
        teilnehmer.filter((teilnehmer) => {
          return teilnehmer.status && teilnehmer.status.appkey == "active";
        }).length > 0;
    },

    serienbrief(activeOnly) {
      this.isBusy = true;
      let tempList = activeOnly
        ? this.teilnehmer
            .filter((item) => item.status?.appkey == "active")
            .map((item) => item.person.id)
        : this.teilnehmer.map((item) => item.person.id);

      let json = {
        empfaenger: [],
        person: [], // ist de facto person. Noch unsauber im Backend
        isMassKorrespondenz: true,
        versand: {
          erstelltvon: this.angemeldeterMitarbeiter.person,
          datum: new Intl.DateTimeFormat("ch").format(new Date()),
          ort: this.$CONST("CONFIG").MANDANTSTADT,
          betreff: "Kampagne",
        },
        inhalt: "",
        zeitpunkt: new Intl.DateTimeFormat("ch").format(new Date()),
      };

      json.empfaenger = tempList;
      json.person = tempList;

      Api.post("korrespondenz/", json, { params: {} })
        .then((response) => {
          let eintryjson = {
            foreigntable: "masskorrespondenz",
            foreignkey: response.data.id,
            erstelltdatum: new Intl.DateTimeFormat("ch").format(new Date()),
            kampagne: this.kampagne,
            status: null, // TODO: Setzen
            verantwortlicher: this.angemeldeterMitarbeiter.person,
          };

          Api.post("crm/kampagne/eintrag/", eintryjson, { params: {} })
            .then(() => {})
            .finally(() => {
              this.getEintrage();
            });
        })
        .catch((e) => {
          console.log(e);
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    massmail(activeOnly) {
      this.loading = true;
      let tempList = activeOnly
        ? this.teilnehmer
            .filter((item) => item.status?.appkey == "active")
            .map((item) => item.person.id)
        : this.teilnehmer.map((item) => item.person.id);

      let json = {
        empfaenger: tempList,
        isMassMail: true,
        inhalt: "",
        betreff: "Kampagne",
        zeitpunkt: new Intl.DateTimeFormat("ch").format(new Date()),
      };

      Api.post("mail/", json, { params: {} })
        .then((response) => {
          let eintryjson = {
            foreigntable: "massmail",
            foreignkey: response.data.id,
            erstelltdatum: new Intl.DateTimeFormat("ch").format(new Date()),
            kampagne: this.kampagne,
            status: null, // TODO: Setzen
            verantwortlicher: this.angemeldeterMitarbeiter.person,
          };

          Api.post("crm/kampagne/eintrag/", eintryjson, { params: {} })
            .then(() => {})
            .finally(() => {
              this.getEintrage();
            });
        })
        .catch((e) => {
          console.log(e);
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
  },
};
</script>

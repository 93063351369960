<template>
  <div id="bankverbindung-modal" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">
            {{
              bankverbindung.id
                ? $t("global.editbankdetails")
                : $t("global.addbankdetails")
            }}
          </h4>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <div class="col-xl-12">
              <div class="form-group">
                <input
                  v-model.trim="bankverbindung.bankverbindung"
                  type="text"
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.bankdetails") }}</label>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-xl-12">
              <div class="form-group">
                <input
                  v-model.trim="bankverbindung.kontonummer"
                  type="text"
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.accountnumber") }}</label>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-xl-12">
              <div class="form-group">
                <input
                  v-model.trim="bankverbindung.bc_nr"
                  type="text"
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.bcnumber") }}</label>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-xl-12">
              <div class="form-group">
                <input
                  v-model.trim="bankverbindung.iban"
                  type="text"
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.iban") }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="abbrechen">
            {{ $t("global.cancel") }}
          </button>
          <button type="button" class="btn btn-primary" @click="uebernehmen">
            {{ $t("global.apply") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalCloseButton from "@/components/global/ModalCloseButton.vue";
import { modal } from "@/mixins/Modal";

export default {
  name: "BankverbindungModal",
  components: {ModalCloseButton},
  mixins: [modal],
  props: {},
  data() {
    return {
      shown: false,
      bankverbindung: {},
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.$bus.$on("bankverbindung-modal-show", (data) => {
      this.bankverbindung = Object.assign({}, data);
      if (!this.shown) this.openModal();
    });
  },
  methods: {
    openModal() {
      this.shown = true;

      $("#bankverbindung-modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    uebernehmen() {
      if (this.bankverbindung.neueBankverbindung) {
        this.$emit("bankverbindungCreated", this.bankverbindung);
      } else {
        this.$emit("bankverbindungChanged", this.bankverbindung);
      }
      /*
      if (this.bankverbindung.id) {
        this.$emit("bankverbindungChanged", this.bankverbindung);
      } else {
        this.$emit("bankverbindungCreated", this.bankverbindung);
      }*/
      this.shown = false;
      $("#bankverbindung-modal").modal("hide");
    },

    abbrechen() {
      this.shown = false;
      $("#bankverbindung-modal").modal("hide");
    },
  },
};
</script>

<style>
</style>

<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.subsidies')"
        :col="3"
      >
        <div class="row mt-2 d-flex justify-content-between">
          <div class="ml-4 mr-2">
            <button
              class="btn btn-success"
              @click="neueSubvention"
              v-if="editable"
            >
              <font-awesome-icon icon="fa-regular fa-plus" />
              <span>{{ $t("global.new") }}</span>
            </button>
          </div>
          <div class="mr-4">
            <button
              class="btn btn-danger"
              @click="oeffneLoeschenModal"
              v-if="editable"
            >
              <font-awesome-icon class="mr-1" icon="fa-duotone fa-trash" />
              <span>{{ $t("global.delete") }}</span>
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              tbody-tr-class="item"
              :items="subventionen"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              sort-icon-left
              :busy="isBusy"
              show-empty
              fixed
              selectable
              select-mode="range"
              @row-dblclicked="details"
            >
              <template v-slot:head(selected)="row">
                <b-form-checkbox
                  v-model="row.selected"
                  @input="selectAllRows(row)"
                ></b-form-checkbox>
              </template>
              <template v-slot:cell(selected)="row">
                <b-form-checkbox
                  v-model="row.item.selected"
                  @input="onRowSelected(row)"
                ></b-form-checkbox>
              </template>
              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>{{ $t("global.errorwhileloading") }}</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>{{ $t("global.nodataavailable") }}</strong>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <loeschen-modal
      id="subvention-loeschen-modal"
      @confirmed="subventionLoeschen"
      :selectedIds="selectedIds"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import ContainerHeadline from "@/components/ContainerHeadline";
import { apiErrorToAlert } from "@/utils/Errorhandler";

import LoeschenModal from "@/components/Modals/LoeschenModal";

export default {
  components: {
    ContainerHeadline,
    LoeschenModal,
  },
  props: {
    person: {
      type: String,
      required: false,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      page: 0,
      infiniteId: +new Date(),
      anzahlProPage: 60,
      sortBy: "",
      ladeFehler: false,
      isBusy: false,
      sortDesc: false,
      subventionen: [],
      selected: [],
      selectedIds: [],
    };
  },
  watch: {
    shown(val) {
      if (val && this.subventionen.length == 0) this.getSubventionen();
    },
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "ausbildung.bezeichnung",
          sortable: true,
          label: this.$t("global.educationcourse"),
        },
        {
          key: "subventionskanton.bezeichnung",
          sortable: true,
          label: this.$t("global.canton"),
        },
        {
          key: "kostengutsprache",
          sortable: true,
          label: this.$t("global.costapproval"),
        },
        {
          key: "subventionsstatus.bezeichnung",
          sortable: true,
          label: this.$t("global.status"),
        },
      ];
    },
    queryParams: function () {
      var params = {
        person: this.person,
        page: this.page,
        count: this.anzahlProPage,
      };

      return params;
    },
  },
  mounted() {
    if (this.shown) this.getSubventionen();
  },
  created() {},
  methods: {
    getSubventionen() {
      this.isBusy = true;
      Api.get("bildung/subvention/", {
        params: {
          person: this.person,
          page: this.page,
          count: this.anzahlProPage,
        },
      })
        .then((response) => {
          this.subventionen = response.data;
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    details(subvention) {
      this.$router.push({
        name: "subvention",
        params: { subventionid: subvention.id, anzeige: 0 },
      });
    },
    infiniteHandler($state) {
      if (this.shown) {
        Api.get("bildung/subvention/", {
          params: this.queryParams,
        }).then((response) => {
          if (response.data.length === this.anzahlProPage) {
            this.page += 1;
            this.subventionen.push(...response.data);
            $state.loaded();
          } else {
            this.subventionen.push(...response.data);
            $state.complete();
          }
        });
      }
    },
    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((s) => {
          s.selected = true;
          if (!this.selectedIds.includes(s.id)) {
            this.selectedIds.push(s.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((s) => {
          s.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },
    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },

    neueSubvention() {
      Api.post("bildung/subvention/", {
        person: { id: this.person },
        ausbildung: {},
      }).then((response) => {
        this.$router.push({
          name: "subvention",
          params: {
            subventionid: response.data.id,
            anzeige: 0,
          },
        });
      });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "subvention-loeschen-modal");
      else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noregistrationselecteddeletion"),
        });
      }
    },

    subventionLoeschen() {
      this.isBusy = true;

      Api.delete("bildung/subvention", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.getSubventionen();
      });
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('container-headline',{attrs:{"headline":_vm.$t('global.personaldata'),"col":6}}),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-xl-12 block br-t-l-0"},[_c('div',{staticClass:"eingabe-panel-kopf p-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-10"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.person.name),expression:"person.name",modifiers:{"trim":true}}],staticClass:"form-control",class:{ 'border-danger': !passed },attrs:{"placeholder":" ","readonly":!_vm.editable,"tabindex":1},domProps:{"value":(_vm.person.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.person, "name", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{class:{ 'text-danger': !passed }},[_vm._v(_vm._s(_vm.$t("global.surname")))])]}}])})],1)]),_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.person.vorname),expression:"person.vorname",modifiers:{"trim":true}}],staticClass:"form-control",class:{ 'border-danger': !passed },attrs:{"placeholder":" ","readonly":!_vm.editable,"tabindex":2},domProps:{"value":(_vm.person.vorname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.person, "vorname", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{class:{ 'text-danger': !passed }},[_vm._v(_vm._s(_vm.$t("global.firstname")))])]}}])})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
return [(_vm.partnerrollen)?_c('v-select',{class:{ 'border-info': !passed },attrs:{"label":"bezeichnung","options":_vm.partnerrollen,"disabled":!_vm.editable,"placeholder":_vm.$t('global.type'),"reduce":function (r) { return r.id; },"multiple":"","tabindex":3},model:{value:(_vm.person.rolle),callback:function ($$v) {_vm.$set(_vm.person, "rolle", $$v)},expression:"person.rolle"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t("global.taptosearch")))])]):_vm._e(),_c('label',{class:{ 'text-danger': !passed }},[_vm._v(" "+_vm._s(_vm.$t("global.type")))])]}}])})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-4"},[_c('div',{staticClass:"form-group"},[_c('v-select',{attrs:{"label":"bezeichnung","options":_vm.akademischetitel,"disabled":!_vm.editable,"reduce":function (a) { return a.id; },"placeholder":_vm.$t('global.academictitle'),"tabindex":4},model:{value:(_vm.person.akademischertitel),callback:function ($$v) {_vm.$set(_vm.person, "akademischertitel", $$v)},expression:"person.akademischertitel"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t("global.taptosearch")))])]),_c('label',[_vm._v(_vm._s(_vm.$t("global.academictitle")))])],1)]),(
                      this.roles.isKunde ||
                      this.roles.isLieferant ||
                      this.roles.isTeilnehmerInfoabend ||
                      this.roles.isTraegerschaft ||
                      this.roles.isArbeitgeber
                    )?_c('div',{staticClass:"col-xl-8"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.person.funktion),expression:"person.funktion",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"readonly":!_vm.editable,"placeholder":" ","tabindex":5},domProps:{"value":(_vm.person.funktion)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.person, "funktion", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',[_vm._v(_vm._s(_vm.$t("global.function")))])])]):_vm._e()]),(_vm.$CONST('CONFIG').APPTYPE != 'DESKA')?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-4"},[_c('div',{staticClass:"form-group"},[_c('date-picker',{attrs:{"date-format":"dd.mm.yy","initial":_vm.person.geburtsdatum,"placeholder":" ","show-icon":true,"disabled":!_vm.editable,"tabindex":6},on:{"update":function (val) { return (_vm.person.geburtsdatum = val); }}}),_c('label',[_vm._v(_vm._s(_vm.$t("global.dateofbirth")))])],1)]),_c('div',{staticClass:"col-xl-4"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var passed = ref.passed;
return [_c('v-select',{class:!passed ? 'border-danger' : '',attrs:{"label":"bezeichnung","options":_vm.geschlechter,"reduce":function (g) { return g.id; },"disabled":!_vm.editable,"placeholder":_vm.$t('global.gender'),"tabindex":7},model:{value:(_vm.person.geschlecht),callback:function ($$v) {_vm.$set(_vm.person, "geschlecht", $$v)},expression:"person.geschlecht"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t("global.taptosearch")))])]),_c('label',{class:{ 'text-danger': !passed }},[_vm._v(_vm._s(_vm.$t("global.gender")))])]}}],null,false,1798848061)})],1)]),_c('div',{staticClass:"col-xl-4"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var passed = ref.passed;
return [_c('v-select',{class:!passed ? 'border-danger' : '',attrs:{"label":"bezeichnung","options":_vm.anreden,"disabled":!_vm.editable,"reduce":function (a) { return a.id; },"placeholder":_vm.$t('global.salutation'),"tabindex":8},model:{value:(_vm.person.anrede),callback:function ($$v) {_vm.$set(_vm.person, "anrede", $$v)},expression:"person.anrede"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t("global.taptosearch")))])]),_c('label',{class:{ 'text-danger': !passed }},[_vm._v(_vm._s(_vm.$t("global.salutation")))])]}}],null,false,2056495211)})],1)])])]):_vm._e(),_c('div',{staticClass:"row"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-4"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var passed = ref.passed;
return [_c('v-select',{class:!passed ? 'border-danger' : '',attrs:{"label":"bezeichnung","options":_vm.aktivitaetsstati,"reduce":function (a) { return a.id; },"disabled":!_vm.editable && !_vm.isAdmin,"placeholder":_vm.$t('global.status'),"tabindex":9},model:{value:(_vm.person.aktivitaetsstatus),callback:function ($$v) {_vm.$set(_vm.person, "aktivitaetsstatus", $$v)},expression:"person.aktivitaetsstatus"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t("global.taptosearch")))])]),_c('label',{class:{ 'text-danger': !passed }},[_vm._v(_vm._s(_vm.$t("global.status")))])]}}])})],1)]),_c('div',{staticClass:"col-xl-4"},[_c('div',{staticClass:"form-group"},[_c('v-select',{attrs:{"getOptionLabel":function (lang) { return lang.bezeichnung; },"options":_vm.langs,"disabled":!_vm.editable && !_vm.isAdmin,"placeholder":_vm.$t('global.correspondencelanguage'),"tabindex":10},model:{value:(_vm.person.korrespondenzsprache),callback:function ($$v) {_vm.$set(_vm.person, "korrespondenzsprache", $$v)},expression:"person.korrespondenzsprache"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t("global.taptosearch")))])]),_c('label',[_vm._v(_vm._s(_vm.$t("global.correspondencelanguage")))])],1)]),(_vm.$CONST('CONFIG').APPTYPE === 'Education')?_c('div',{staticClass:"col-xl-4"},[(
                        _vm.roles.isDozent ||
                        _vm.roles.isMitarbeiter ||
                        _vm.roles.isDiplomarbeiten ||
                        _vm.roles.isReferent
                      )?_c('div',{staticClass:"form-group"},[_c('v-select',{attrs:{"label":"bezeichnung","options":_vm.zivilstaende,"reduce":function (z) { return z.id; },"disabled":!_vm.editable,"placeholder":_vm.$t('global.civilstatus'),"tabindex":10},model:{value:(_vm.person.zivilstand),callback:function ($$v) {_vm.$set(_vm.person, "zivilstand", $$v)},expression:"person.zivilstand"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t("global.taptosearch")))])]),_c('label',[_vm._v(_vm._s(_vm.$t("global.civilstatus")))])],1):_vm._e()]):_vm._e(),(_vm.$CONST('CONFIG').APPTYPE === 'Education')?_c('div',{staticClass:"col-xl-4"},[(
                        _vm.roles.isDozent ||
                        _vm.roles.isMitarbeiter ||
                        _vm.roles.isDiplomarbeiten ||
                        _vm.roles.isReferent
                      )?_c('div',{staticClass:"form-group"},[_c('date-picker',{attrs:{"date-format":"dd.mm.yy","initial":_vm.person.zivilstand_datum,"disabled":!_vm.editable,"show-icon":true,"tabindex":11},on:{"update":function (val) { return (_vm.person.zivilstand_datum = val); }}}),_c('label',[_vm._v(_vm._s(_vm.$t("global.dateofcivilstatus")))])],1):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-xl-2"},[_c('div',{staticClass:"form-group d-flex",staticStyle:{"flex-wrap":"wrap"}},[_c('label',[_vm._v(_vm._s(_vm.$t("global.profilepicture")))]),(!_vm.person.bildUrl && _vm.editable)?_c('button',{staticClass:"btn btn-success btn-sm",staticStyle:{"width":"60px","height":"30px","padding":"0 4px 0 0"},on:{"click":_vm.oeffneDokumentModal}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":"fa-regular fa-plus"}}),_c('span',[_vm._v(_vm._s(_vm.$t("global.image")))])],1):_vm._e(),(_vm.person.bild)?_c('button',{staticClass:"btn btn-danger btn-sm",staticStyle:{"width":"30px","height":"30px","margin-left":"auto"},on:{"click":_vm.fotoLoeschen}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-ban","size":"sm"}})],1):_vm._e(),(_vm.person.bild && _vm.person.bildUrl)?_c('img',{staticClass:"profilbild mt-2",staticStyle:{"width":"auto","height":"auto","max-width":"100%","object-fit":"contain","object-position":"center"},attrs:{"src":_vm.person.bildUrl}}):_vm._e()]),_c('bild-modal',{attrs:{"gid":this.id,"id":"bild-modal"},on:{"bildUploaded":_vm.ladeGeschaeftspartner}})],1)])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
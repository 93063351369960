<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar"
            :docsName="docsName"
    />
    <div class="container-fluid row">
      <div v-if="showNavbar" class="col-xl-12">
        <div class="row">
          <div class="col-xl-12 block br-t-l-0 mb-3">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.dunningletter')"
            ></head-menu>
            <div class="row mt-2 d-flex justify-content-between">
              <div class="ml-4 mr-2">
                <button class="btn btn-primary ml-2" @click="abbrechen">
                  <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                    $t("global.tolist")
                  }}
                </button>
              </div>

              <div class="mr-4">
                <button
                  v-if="berechtigungen.m_finanzen.delete"
                  class="btn btn-danger"
                  @click="oeffneLoeschenModal"
                >
                  <font-awesome-icon class="mr-1" icon="fa-duotone fa-trash" />
                  <span>{{ $t("global.delete") }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- START Linke Seite -->
      <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
        <!-- START Stammdaten -->
        <container-headline
          :headline="$t('global.masterdata')"
          :col="6"
        ></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="mahnung.mahndatum"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label class="t-8">{{ $t("global.dunningdate") }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ENDE Stammdaten -->
        <!-- START Finanzbuchhaltung -->
        <container-headline
          :headline="$t('global.debtor')"
          :col="6"
        ></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-6">
                  <single-select-debitor
                    id="person-debitor"
                    :debitor="mahnung.zahlschuld"
                    :label="$t('global.debtor')"
                    :allowOpen="true"
                    :editable="editable"
                  />
                  <!--
                  <div class="form-group">
                    <input
                      v-model="mahnung.zahlschuld.debitornummer"
                      class="form-control mt-2 mb-3"
                      placeholder=" "
                      readonly
                    />
                    <label class="t-8">Debitor</label>
                  </div>
                  -->
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="mahnung.zahlschuld.auftrag.nummer"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.order") }}</label>
                    <span class="openIcon" v-on:click="openAuftrag"
                      ><font-awesome-icon
                        icon="fa-duotone fa-arrow-circle-right"
                      />
                    </span>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="mahnung.mahnstufestatus.bezeichnung"
                      class="form-control"
                      readonly
                    />
                    <label>{{ $t("global.dunninglevelstatus") }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ENDE Finanzbuchhaltung -->
      </div>
      <!-- ENDE linke Seite -->
      <!-- START Rechte Seite -->
      <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
        <!-- START Kontierung -->
        <container-headline
          :headline="$t('global.text')"
          :col="6"
        ></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="mahnung.betreff"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.subject") }}</label>
                  </div>
                </div>

                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="mahnung.einleitung"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.introduction") }}</label>
                  </div>
                </div>

                <div class="col-xl-12">
                  <div class="form-group">
                    <textarea
                      v-model="mahnung.schluss"
                      class="form-control"
                      placeholder=" "
                      rows="5"
                      readonly
                    />
                    <label>{{ $t("global.ending") }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ENDE Kontierung -->
        <!-- START Betrag -->
        <container-headline
          :headline="$t('global.values')"
          :col="6"
        ></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="mahnung.waehrung.bezeichnung"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.currency") }}</label>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="mahnspesen2Decimal"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.reminderfees") }}</label>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="mahnung.rechnungsdatum"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.invoicedate") }}</label>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="offenerSaldo"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.openbalance") }}</label>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="bruttoBetrag"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.grossamount") }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ENDE Betrag -->
        <!-- START Zusatzangaben -->
        <container-headline
          :headline="$t('global.additionalinformation')"
          :col="6"
        ></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <textarea
                      v-model="mahnung.mahnadresse"
                      class="form-control"
                      placeholder=" "
                      rows="5"
                      readonly
                    />
                    <label>{{ $t("global.dunningaddress") }}</label>
                  </div>
                </div>

                <div class="col-xl-6">
                  <div class="form-group">
                    <textarea
                      v-model="mahnung.bemerkung"
                      class="form-control"
                      placeholder=" "
                      readonly
                      rows="5"
                    />
                    <label>{{ $t("global.comment") }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ENDE Zusatzangaben -->
      </div>
      <!-- ENDE rechte Seite -->
    </div>
    <div id="modal-loeschen" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4>
              <font-awesome-icon
                icon="fa-regular fa-warning"
                class="mr-2 text-warning"
              />{{ $t("global.deletereceipt") }}
            </h4>
          </div>
          <div class="modal-body">
            {{ $t("global.doyouwantdeletereceipt") }}
          </div>
          <div class="modal-footer">
            <button class="btn btn-default" @click="schliesseLoeschenModal">
              {{ $t("global.cancel") }}
            </button>
            <button class="btn btn-primary" @click="loeschen">
              {{ $t("global.delete") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <change-watcher-alert />
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";

import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import Api from "@/Api";
import store from "@/store";
import page from "@/mixins/Page";
import SingleSelectDebitor from "@/components/global/SingleSelectDebitor.vue";

import ChangeWatcher from "@/mixins/ChangeWatcher";
import ChangeWatcherAlert from "@/components/global/ChangeWatcherAlert.vue";

export default {
  name: "Mahnung",
  components: {
    HeadMenu,
    Navbar,
    ContainerHeadline,
    SingleSelectDebitor,
    ChangeWatcherAlert,
  },
  metaInfo() {
    return {};
  },
  mixins: [page, ChangeWatcher],
  store,
  props: {
    mahnungid: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
    /* kategorieProp: {
      type: String,
      default: null,
    },*/
  },
  data() {
    return {
      editable: false,
      mahnungen: [],
      loading: false,
      mahnung: {
        id: "",
        mahnadresse: "",
        bemerkung: "",
        betreff: "",
        einleitung: "",
        schluss: "",
        druckdatum: "",
        rechnungsdatum: "",
        mahndatum: "",
        bruttobetrag: 0.0,
        offenersaldo: 0.0,
        faelligersaldo: 0.0,
        zahlungseingaenge: 0.0,
        minderungen: 0.0,
        mahnspesen: 0.0,
        adressegeaendert: false,
        mahnlauf: {},
        waehrung: {},
        zahlschuld: { auftrag: {} },
        mahnstufestatus: {},
      },
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.mahnung) {
        /*if (this.kategorieObj)
          return `${this.kategorieObj.bezeichnung} ${this.mahnung.nummer}`; */
        return this.$t("global.dunningletter");
      } else {
        /*if (this.kategorieObj) return `Neue ${this.kategorieObj.bezeichnung}`;*/
        return this.$t("global.newdunning");
      }
    },
    offenerSaldo: function () {
      return this.mahnung.offenersaldo.toFixed(2);
    },
    bruttoBetrag: function () {
      return this.mahnung.bruttobetrag.toFixed(2);
    },
    mahnspesen2Decimal: function () {
      return this.mahnung.mahnspesen.toFixed(2);
    },
  },

  created() {
    Api.get("finanzen/mahnungen/", { params: { id: this.mahnungid } }).then(
      (response) => {
        if (response) this.initializeMahnung(response.data);
      }
    );
  },
  mounted: function () {},
  methods: {
    openAuftrag() {
      if (this.mahnung.zahlschuld.auftrag.id) {
        this.$router.push({
          name: "auftrag.id.anzeige",
          params: { id: this.mahnung.zahlschuld.auftrag.id, anzeige: 0 },
        });
      }
    },
    abbrechen() {
      this.$router.push({ name: "mahnungen" });
    },
    initializeMahnung(mahnung) {
      if (mahnung) {
        this.mahnung = mahnung;
        if (!mahnung.mahnlauf)
          this.mahnung.mahnlauf = { bezeichnung: this.$t("global.notlinked") };
        if (!mahnung.waehrung)
          this.mahnung.waehrung = { bezeichnung: this.$t("global.notlinked") };
        if (!mahnung.zahlschuld)
          this.mahnung.zahlschuld = {
            bezeichnung: this.$t("global.notlinked"),
          };
        if (!mahnung.mahnstufestatus)
          this.mahnung.mahnstufestatus = {
            bezeichnung: this.$t("global.notlinked"),
          };

        this.initChangeWatcher(this.mahnung);
      } else {
        console.error("mahnung ist null");
      }
    },

    speichern() {
      /* if (this.loading) return;

      this.loading = true;
      var json = {};

      if (!this.korrespondenz) {
        Api.post("finanzen/beleg/", json)
          .then((response) => {
            this.initializeBeleg(response.data);
            this.$notify({
              type: "success",
              title: "Aktion erfolgreich",
              text: "Mahnung erfolgreich gespeichert.",
            });

            this.$router.replace({
              name: "MahnungMitId",
              params: { id: response.data.id },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("finanzen/mahnungen/", json, { params: { id: this.id } })
          .then((response) => {
            this.initializeBeleg(response.data);
            this.$notify({
              type: "success",
              title: "Aktion erfolgreich",
              text: "Mahnung erfolgreich gespeichert.",
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }*/
    },
    oeffneLoeschenModal() {
      $("#modal-loeschen").modal("show");
    },
    schliesseLoeschenModal() {
      $("#modal-loeschen").modal("hide");
    },
    loeschen() {
      Api.delete("finanzen/mahnungen/", {
        params: { id: this.id },
      }).then(() => {
        this.schliesseLoeschenModal();
        this.$router.push({ name: "Mahnungen" });
      });
    },
  },
};
</script>

<style></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xl-12 pr-4"},[(_vm.mail.empfaenger.length > 0)?_c('container-headline',{attrs:{"headline":_vm.$t('global.receiver') + ' (' + _vm.mail.empfaenger.length + ')',"col":3}},[_c('div',{staticClass:"row d-flex"},[_c('div',{staticClass:"ml-4 mt-3"},[(_vm.editable)?_c('button',{staticClass:"btn btn-success mr-2",on:{"click":_vm.oeffneGPSucheModal}},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-plus"}})],1):_vm._e()]),_c('div',{staticClass:"mr-2 mt-3"},[(_vm.editable)?_c('button',{staticClass:"btn btn-danger",on:{"click":_vm.oeffneLoeschenModal}},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-minus"}})],1):_vm._e()]),_c('div',{staticClass:"col-xl-2"},[_c('div',{staticClass:"form-group"},[_c('date-picker',{attrs:{"disabled":!_vm.editable,"initial":_vm.mail.versanddatum,"date-format":"dd.mm.yy","placeholer":" ","show-icon":true},on:{"update":function (val) { return _vm.setMailVersanddatum(val); }}}),_c('label',[_vm._v(_vm._s(_vm.$t("global.shippingdate")))])],1)]),_c('div',{staticClass:"col-xl-2"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.mail.versandzeit),expression:"mail.versandzeit",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"readonly":!_vm.editable,"placeholder":"HH:MM"},domProps:{"value":(_vm.mail.versandzeit)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.mail, "versandzeit", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',[_vm._v(_vm._s(_vm.$t("global.shippingtime")))])])]),(_vm.mail.isVorlage)?_c('div',{staticClass:"col-xl-2"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
return [_c('v-select',{class:{ 'border-danger': !passed },attrs:{"label":"kuerzel","options":_vm.langs,"disabled":!_vm.editable,"placeholder":_vm.$t('global.defaultlanguage'),"selectable":function (lang) { return _vm.mail.translation[lang.kuerzel].text &&
                  _vm.mail.translation[lang.kuerzel].betreff; },"reduce":function (lang) { return lang.id; }},model:{value:(_vm.mail.defaultSprache),callback:function ($$v) {_vm.$set(_vm.mail, "defaultSprache", $$v)},expression:"mail.defaultSprache"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t("global.taptosearch")))])]),_c('label',{class:{ 'text-danger': !passed }},[_vm._v(_vm._s(_vm.$t("global.defaultlanguage")))])]}}],null,false,275351164)})],1)]):_vm._e()])]):_vm._e(),(_vm.mail.empfaenger.length > 0)?_c('div',{key:_vm.receiverKey,staticClass:"row block br-t-l-0 mb-3 p-2",staticStyle:{"height":"200px","overflow-y":"auto"}},_vm._l((_vm.mail.empfaenger),function(item,index){return _c('div',{key:("empfaenger-" + index),staticClass:"col-xl-2 p-1"},[_c('div',{staticClass:"p-1 d-flex align-items-center",style:('background-color: ' +
          (item.selected
            ? 'lightgreen; color:white; font-weight: bold'
            : 'white') +
          ';cursor: pointer; font-size: 10pt'),on:{"contextmenu":function($event){$event.preventDefault();return _vm.openReceiver(item)},"click":function($event){return _vm.selectReceiver(item)}}},[_c('span',{staticClass:"mr-auto"},[_vm._v(_vm._s(item.vorname + " " + item.name))]),(!_vm.mailadresseVorhanden(item))?_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 text-danger fs-17 pr-2",attrs:{"title":_vm.$t('global.nomainemailadressfound'),"icon":"fa-duotone fa-exclamation-triangle"}}):_vm._e()],1)])}),0):_vm._e(),(_vm.mail.firma.length > 0)?_c('container-headline',{attrs:{"headline":_vm.$t('global.receiver') + ' (' + _vm.mail.firma.length + ')',"col":3}},[_c('div',{staticClass:"row mt-2 d-flex"},[_c('div',{staticClass:"ml-4"},[(_vm.editable)?_c('button',{staticClass:"btn btn-success mr-2",on:{"click":_vm.oeffneFirmaSucheModal}},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-plus"}})],1):_vm._e()]),_c('div',{staticClass:"mr-2"},[(_vm.editable)?_c('button',{staticClass:"btn btn-danger",on:{"click":_vm.oeffneLoeschenModal}},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-minus"}})],1):_vm._e()])])]):_vm._e(),(_vm.mail.firma.length > 0)?_c('div',{key:_vm.receiverKey,staticClass:"row block br-t-l-0 mb-3 p-2",staticStyle:{"height":"200px","overflow-y":"auto"}},_vm._l((_vm.mail.firma),function(item,index){return _c('div',{key:("firma-" + index),staticClass:"col-xl-2 p-1"},[_c('div',{staticClass:"p-1 d-flex align-items-center",style:('background-color: ' +
          (item.selected
            ? 'lightgreen; color:white; font-weight: bold'
            : 'white') +
          ';cursor: pointer; font-size: 10pt'),on:{"click":function($event){return _vm.selectReceiverFirma(item)},"contextmenu":function($event){$event.preventDefault();return _vm.openReceiverFirma(item)}}},[_c('span',[_vm._v(_vm._s(item.name))]),(!_vm.mailadresseVorhanden(item))?_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.hover",modifiers:{"hover":true}}],staticClass:"ml-auto text-danger fs-17 pr-2",attrs:{"title":"Keine Hauptmailadresse gefunden","icon":"fa-duotone fa-exclamation-triangle"}}):_vm._e()],1)])}),0):_vm._e(),(_vm.mail.empfaenger.length > 0)?_c('gpSucheModal',{attrs:{"id":_vm.modalId},on:{"confirmed":_vm.onGPResult}}):_vm._e(),(_vm.mail.empfaenger.length > 0)?_c('loeschen-modal',{attrs:{"id":"empfaenger-loeschen-modal","multiple":true},on:{"confirmed":_vm.empfaengerLoeschen}}):_vm._e(),(_vm.mail.firma.length > 0)?_c('firmaSucheModal',{attrs:{"id":_vm.modalId},on:{"confirmed":_vm.onFirmaResult}}):_vm._e(),(_vm.mail.firma.length > 0)?_c('loeschen-modal',{attrs:{"id":"empfaenger-loeschen-modal","multiple":true},on:{"confirmed":_vm.firmaLoeschen}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
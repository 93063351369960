<template>
  <div id="journaleintrag-template-modal" class="modal fade">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">Journaleintrag Vorlage wählen</h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xl-12">
              <ul class="list-group">
                <li
                  v-for="item in templates"
                  :key="item.id"
                  v-bind:class="{ active: item.id == template.id }"
                  class="list-group-item"
                  @click="selectTemplate(item)"
                >
                  {{ item.bezeichnung }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="closeModal">
            Abbrechen
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="uebernehmen"
            :disabled="!hasSelected"
          >
            Übernehmen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalCloseButton from "@/components/global/ModalCloseButton";

export default {
  name: "JournalEintragTemplateModal",
  components: { ModalCloseButton },
  props: {},
  data() {
    return {
      shown: false,
      template: {},
      hasSelected: false,
    };
  },
  computed: {
    templates() {
      if (this.$store) return this.$store.state.journal.journaleintragtemplates;
      return [];
    },
  },
  watch: {},
  mounted() {
    this.$bus.$on("journaleintrag-template-modal-show", () => {
      this.template = {};

      if (!this.shown) this.openModal();
    });
  },
  methods: {
    selectTemplate(item) {
      this.template = this.template.id == item.id ? {} : item;
      this.hasSelected = this.template.id ? true : false;
    },
    openModal() {
      this.shown = true;

      $("#journaleintrag-template-modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    closeModal() {
      this.shown = false;
      $("#journaleintrag-template-modal").modal("hide");
    },

    uebernehmen() {
      this.$emit("journalEintragTemplateSelected", this.template);

      this.closeModal();
    },
  },
};
</script>

<style>
</style>

<template>
  <div class="modal" tabindex="-1" :id="id" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content bg-ice">
        <div class="modal-header bg-cosmos p-3">
          <span>{{ $t("global.cancelinvoices") }}</span>
          <modal-close-button @confirmed="closeModal" />
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xl-12">
              <p>
                {{ $t("global.pleaseprovideacancellationreason") }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12 d-flex">
              <div class="form-group col-xl-8">
                <textarea
                  v-model="stornierungsgrund"
                  placeholder=""
                  rows="3"
                  class="form-control"
                ></textarea>
                <label>{{ $t("global.reasonforcancellation") }}</label>
              </div>
              <div class="col-xl-4">
                <div class="form-group">
                  <date-picker
                    showIcon
                    :initial="stornierungsdatum"
                    @update="(val) => (stornierungsdatum = val)"
                    dateFormat="dd.mm.yy"
                    :inModal="true"
                  ></date-picker>
                  <label>Stornierungsdatum</label>
                  <!-- global variabel erstellen -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row w-100">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-danger mr-3"
                data-dismiss="modal"
                @click="onCancel"
              >
                <span class="font-khand font-weight-bold">{{
                  $t("global.abort")
                }}</span>
              </button>
              <button type="button" class="btn btn-success" @click="stornieren">
                <span class="font-khand font-weight-bold">{{
                  $t("global.Cancel")
                }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { modal } from "@/mixins/Modal";
import ModalCloseButton from "@/components/global/ModalCloseButton";
import DatePicker from "@/components/Datepicker";

export default {
  name: "AuftragStornierenModal",
  components: {
    ModalCloseButton,
    DatePicker,
  },
  props: {
    id: {
      type: String,
      default: "auftrag-stornieren-modal",
    },
  },
  data() {
    return {
      stornierungsgrund: "",
      stornierungsdatum: new Date().toGermanDateString(),
    };
  },
  mixins: [modal],
  mounted() {
    this.$bus.$on("oeffneAuftragStornierenModal", (data) => {
      if (data.id != null && data.id != this.id) return;

      $("#" + this.id).modal({
        backdrop: "static",
        keyboard: false,
      });
    });
  },
  methods: {
    closeModal() {
      this.shown = false;
      $("#" + this.id).modal("hide");
    },
    onCancel() {
      this.closeModal();
      this.$emit("cancel");
    },
    stornieren() {
      this.closeModal();
      this.$emit("stornieren", this.stornierungsgrund, this.stornierungsdatum);
    },
  },
};
</script>

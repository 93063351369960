<template>
  <div>
    <container-headline
      :headline="$t('global.personaldata')"
      :col="6"
    ></container-headline>

    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-12">
              <div class="row">
                <!-- Felder für Gesch#ftspartner vom Typ Person -->
                <div class="col-xl-10">
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <ValidationProvider
                          rules="required"
                          immediate
                          v-slot="{ passed }"
                        >
                          <input
                            v-model.trim="person.name"
                            class="form-control"
                            v-bind:class="{ 'border-danger': !passed }"
                            placeholder=" "
                            :readonly="!editable"
                            :tabindex="1"
                          />
                          <label v-bind:class="{ 'text-danger': !passed }">{{
                            $t("global.surname")
                          }}</label>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <ValidationProvider
                          rules="required"
                          immediate
                          v-slot="{ passed }"
                        >
                          <input
                            v-model.trim="person.vorname"
                            class="form-control"
                            v-bind:class="{ 'border-danger': !passed }"
                            placeholder=" "
                            :readonly="!editable"
                            :tabindex="2"
                          />
                          <label v-bind:class="{ 'text-danger': !passed }">{{
                            $t("global.firstname")
                          }}</label>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <ValidationProvider
                          rules="required"
                          immediate
                          v-slot="{ passed }"
                        >
                          <v-select
                            v-if="partnerrollen"
                            v-model="person.rolle"
                            label="bezeichnung"
                            v-bind:class="{ 'border-info': !passed }"
                            :options="partnerrollen"
                            :disabled="!editable"
                            :placeholder="$t('global.type')"
                            :reduce="(r) => r.id"
                            multiple
                            :tabindex="3"
                          >
                            <span slot="no-options">{{
                              $t("global.taptosearch")
                            }}</span>
                          </v-select>
                          <label v-bind:class="{ 'text-danger': !passed }">
                            {{ $t("global.type") }}</label
                          >
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4">
                      <div class="form-group">
                        <v-select
                          v-model="person.akademischertitel"
                          label="bezeichnung"
                          :options="akademischetitel"
                          :disabled="!editable"
                          :reduce="(a) => a.id"
                          :placeholder="$t('global.academictitle')"
                          :tabindex="4"
                        >
                          <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                        </v-select>
                        <label>{{ $t("global.academictitle") }}</label>
                      </div>
                    </div>
                    <!--<div class="col-xl-4" v-if="this.roles.isStudent">
                      <div class="form-group">
                        <v-select
                          v-model="person.niveau"
                          label="bezeichnung"
                          :options="niveaus"
                          :disabled="!editable"
                          :reduce="(a) => a.id"
                          :placeholder="$t('global.niveau')"
                          :tabindex="2"
                        >
                          <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                        </v-select>
                        <label>{{ $t("global.niveau") }}</label>
                      </div>
                    </div>-->
                    <div
                      class="col-xl-8"
                      v-if="
                        this.roles.isKunde ||
                        this.roles.isLieferant ||
                        this.roles.isTeilnehmerInfoabend ||
                        this.roles.isTraegerschaft ||
                        this.roles.isArbeitgeber
                      "
                    >
                      <div class="form-group">
                        <input
                          v-model.trim="person.funktion"
                          class="form-control"
                          :readonly="!editable"
                          placeholder=" "
                          :tabindex="5"
                        />
                        <label>{{ $t("global.function") }}</label>
                      </div>
                    </div>
                  </div>

                  <div v-if="$CONST('CONFIG').APPTYPE != 'DESKA'">
                    <div class="row">
                      <div class="col-xl-4">
                        <div class="form-group">
                          <date-picker
                            date-format="dd.mm.yy"
                            :initial="person.geburtsdatum"
                            placeholder=" "
                            :show-icon="true"
                            :disabled="!editable"
                            :tabindex="6"
                            @update="(val) => (person.geburtsdatum = val)"
                          />
                          <label>{{ $t("global.dateofbirth") }}</label>
                        </div>
                      </div>
                      <div class="col-xl-4">
                        <div class="form-group">
                          <ValidationProvider
                            rules="required"
                            immediate
                            v-slot="{ passed }"
                          >
                            <v-select
                              v-model="person.geschlecht"
                              label="bezeichnung"
                              v-bind:class="!passed ? 'border-danger' : ''"
                              :options="geschlechter"
                              :reduce="(g) => g.id"
                              :disabled="!editable"
                              :placeholder="$t('global.gender')"
                              :tabindex="7"
                            >
                              <span slot="no-options">{{
                                $t("global.taptosearch")
                              }}</span>
                            </v-select>
                            <label v-bind:class="{ 'text-danger': !passed }">{{
                              $t("global.gender")
                            }}</label>
                          </ValidationProvider>
                        </div>
                      </div>
                      <div class="col-xl-4">
                        <div class="form-group">
                          <ValidationProvider
                            rules="required"
                            immediate
                            v-slot="{ passed }"
                          >
                            <v-select
                              v-model="person.anrede"
                              label="bezeichnung"
                              v-bind:class="!passed ? 'border-danger' : ''"
                              :options="anreden"
                              :disabled="!editable"
                              :reduce="(a) => a.id"
                              :placeholder="$t('global.salutation')"
                              :tabindex="8"
                            >
                              <span slot="no-options">{{
                                $t("global.taptosearch")
                              }}</span>
                            </v-select>
                            <label v-bind:class="{ 'text-danger': !passed }">{{
                              $t("global.salutation")
                            }}</label>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row"></div>
                  <div class="row">
                    <div class="col-xl-4">
                      <div class="form-group">
                        <ValidationProvider
                          rules="required"
                          immediate
                          v-slot="{ passed }"
                        >
                          <v-select
                            v-model="person.aktivitaetsstatus"
                            v-bind:class="!passed ? 'border-danger' : ''"
                            label="bezeichnung"
                            :options="aktivitaetsstati"
                            :reduce="(a) => a.id"
                            :disabled="!editable && !isAdmin"
                            :placeholder="$t('global.status')"
                            :tabindex="9"
                          >
                            <span slot="no-options">{{
                              $t("global.taptosearch")
                            }}</span>
                          </v-select>
                          <label v-bind:class="{ 'text-danger': !passed }">{{
                            $t("global.status")
                          }}</label>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-xl-4">
                      <div class="form-group">
                        <v-select
                          v-model="person.korrespondenzsprache"
                          :getOptionLabel="(lang) => lang.bezeichnung"
                          :options="langs"
                          :disabled="!editable && !isAdmin"
                          :placeholder="$t('global.correspondencelanguage')"
                          :tabindex="10"
                        >
                          <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                        </v-select>
                        <label>{{ $t("global.correspondencelanguage") }}</label>
                      </div>
                    </div>
                    <div
                      class="col-xl-4"
                      v-if="$CONST('CONFIG').APPTYPE === 'Education'"
                    >
                      <div
                        v-if="
                          roles.isDozent ||
                          roles.isMitarbeiter ||
                          roles.isDiplomarbeiten ||
                          roles.isReferent
                        "
                        class="form-group"
                      >
                        <v-select
                          v-model="person.zivilstand"
                          label="bezeichnung"
                          :options="zivilstaende"
                          :reduce="(z) => z.id"
                          :disabled="!editable"
                          :placeholder="$t('global.civilstatus')"
                          :tabindex="10"
                        >
                          <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                        </v-select>
                        <label>{{ $t("global.civilstatus") }}</label>
                      </div>
                    </div>
                    <div
                      class="col-xl-4"
                      v-if="$CONST('CONFIG').APPTYPE === 'Education'"
                    >
                      <div
                        v-if="
                          roles.isDozent ||
                          roles.isMitarbeiter ||
                          roles.isDiplomarbeiten ||
                          roles.isReferent
                        "
                        class="form-group"
                      >
                        <date-picker
                          date-format="dd.mm.yy"
                          :initial="person.zivilstand_datum"
                          :disabled="!editable"
                          :show-icon="true"
                          :tabindex="11"
                          @update="(val) => (person.zivilstand_datum = val)"
                        />
                        <label>{{ $t("global.dateofcivilstatus") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Ende Felder für Gesch#ftspartner vom Typ Person -->

                <!-- Felder für Gesch#ftspartner vom Typ Firma 
                <div v-if="person.typ.bezeichnung == 'Firma'" class="col-xl-10">
                  <div class="row">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <ValidationProvider
                          rules="required"
                          immediate
                          v-slot="{ passed }"
                        >
                          <v-select
                            v-if="partnerrollen"
                            v-model="person.rolle"
                            label="bezeichnung"
                            v-bind:class="{ 'border-info': !passed }"
                            :options="partnerrollen"
                            :disabled="!editable"
                            placeholder="Typ"
                            :reduce="(r) => r.id"
                            multiple
                            :tabindex="3"
                          >
                            <span slot="no-options">Zum Suchen Tippen</span>
                          </v-select>
                          <label v-bind:class="{ 'text-danger': !passed }"
                            >Typ</label
                          >
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <ValidationProvider
                          rules="required"
                          immediate
                          v-slot="{ passed }"
                        >
                          <input
                            v-model.trim="person.firma.name"
                            class="form-control"
                            v-bind:class="{ 'border-danger': !passed }"
                            placeholder=" "
                            :readonly="!editable"
                            :tabindex="5"
                          />
                          <label v-bind:class="{ 'text-danger': !passed }"
                            >Name</label
                          >
                        </ValidationProvider>
                      </div>
                    </div>
                    <div
                      class="col-xl-8"
                      v-if="this.roles.isKunde || this.roles.isMitarbeiter"
                    ></div>
                  </div>

                  <div class="row">
                    <div class="col-xl-4">
                      <div class="form-group">
                        <v-select
                          v-model="person.aktivitaetsstatus"
                          label="bezeichnung"
                          :options="aktivitaetsstati"
                          :reduce="(s) => s.id"
                          :disabled="!editable && !isAdmin"
                          placeholder="Status"
                          :tabindex="9"
                        >
                          <span slot="no-options">Zum Suchen Tippen</span>
                        </v-select>
                        <label>Status</label>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row"
                    v-if="
                      roles.isDozent ||
                      roles.isMitarbeiter ||
                      roles.isDiplomarbeiten ||
                      roles.isReferent
                    "
                  >
                    <div class="col-xl-6">
                      <div class="form-group">
                        <v-select
                          v-model="person.firma.relationstyp"
                          label="bezeichnung"
                          :options="relationstypen"
                          :reduce="(an) => an.id"
                          :disabled="!editable"
                          :tabindex="111"
                          placeholder="Firma"
                        >
                          <span slot="no-options">Zum Suchen Tippen</span>
                        </v-select>
                        <label>Funktion</label>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <v-select
                          v-model="person.firma.relation_mit"
                          label="name"
                          :options="firmenListe"
                          :disabled="!editable"
                          :reduce="(t) => t.id"
                          placeholder="Firma"
                          :tabindex="2"
                        >
                          <span slot="no-options">Zum Suchen Tippen</span>
                        </v-select>
                        <label>Firma</label>
                      </div>
                    </div>
                  </div>

                  <div
                    v-for="items in person.firma.relationen"
                    :key="items.id"
                    class="row"
                  >
                    <div class="col-xl-5">
                      <div class="form-group">
                        <input
                          v-model.trim="items.relationstyp.bezeichnung"
                          class="form-control"
                          :readonly="!editable"
                          placeholder=" "
                          :tabindex="202"
                        />
                        <label>Funktion</label>
                      </div>
                    </div>
                    <div class="col-xl-5">
                      <div class="form-group">
                        <input
                          v-model.trim="items.geschaeftspartner.firma.name"
                          class="form-control"
                          :readonly="!editable"
                          placeholder=" "
                          :tabindex="203"
                        />
                        <label>Firma</label>
                      </div>
                    </div>
                    <div class="col-xl-2">
                      <button
                        class="btn btn-danger btn-sm"
                        @click="relationLoeschen(items.id)"
                        style="
                          width: 70pxpx;
                          height: 30px;
                          margin-left: auto;
                          margin-top: 17px;
                        "
                      >
                        Relation löschen
                      </button>
                    </div>
                  </div>
                </div>
                 Ende Felder für Gesch#ftspartner vom Typ Firma -->

                <!--
                <div class="col-xl-2">
                  <div :key="key">
                    <div
                      v-if="!person.bild && editable"
                      class="form-group text-center"
                    >
                      <vue-2-dropzone
                        id="dropzone"
                        ref="dokumenteUpload"
                        class="dropzone-white dropzone-small"
                        :options="dropzoneOptions"
                        :disabled="!editable"
                        @vdropzone-success="fileUploaded"
                      ></vue-2-dropzone>
                      <button
                        v-if="this.id"
                        class="btn btn-primary"
                        @click="uploadFiles"
                      >
                        Upload
                      </button>
                    </div>

                    <div class="row">
                      <div class="col-6">
                        <div v-if="person.bild">
                          <button class="btn btn-primary btn-sm">
                            <span
                              class="editIcon"
                              @click="setVisibility(isHidden)"
                            >
                              <font-awesome-icon icon="fa-duotone fa-pencil-alt" size="lg"/>
                            ></span>
                          </button>
                        </div>
                      </div>
                      <div class="col-6">
                        <div v-if="!isHidden">
                          <button
                            class="btn btn-danger btn-sm"
                            @click="fotoLoeschen"
                          >
                            <span class="editIcon">
                             <font-awesome-icon icon="fa-duotone fa-trash" size="lg"/></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <img
                      v-if="person.bild"
                      class="profilbild"
                      style="width: 100%; height: auto"
                      :src="person.bildUrl"
                    />
                    <div v-if="!isHidden" class="form-group text-center">
                      <vue-2-dropzone
                        id="dropzone"
                        ref="dokumenteUpload"
                        class="dropzone-white dropzone-small"
                        :options="dropzoneOptions"
                        @vdropzone-success="fileUploaded"
                      ></vue-2-dropzone>
                      <button class="btn btn-primary" @click="uploadFiles">
                        Upload
                      </button>
                    </div>
                  </div>
                </div>
                -->
                <div class="col-xl-2">
                  <div class="form-group d-flex" style="flex-wrap: wrap">
                    <label>{{ $t("global.profilepicture") }}</label>
                    <button
                      v-if="!person.bildUrl && editable"
                      class="btn btn-success btn-sm"
                      @click="oeffneDokumentModal"
                      style="width: 60px; height: 30px; padding: 0 4px 0 0"
                    >
                      <font-awesome-icon
                        icon="fa-regular fa-plus"
                        class="mr-1"
                      />
                      <span>{{ $t("global.image") }}</span>
                    </button>

                    <button
                      v-if="person.bild"
                      class="btn btn-danger btn-sm"
                      @click="fotoLoeschen"
                      style="width: 30px; height: 30px; margin-left: auto"
                    >
                      <font-awesome-icon icon="fa-solid fa-ban" size="sm" />
                    </button>

                    <img
                      v-if="person.bild && person.bildUrl"
                      class="profilbild mt-2"
                      style="
                        width: auto;
                        height: auto;
                        max-width: 100%;
                        object-fit: contain;
                        object-position: center;
                      "
                      :src="person.bildUrl"
                    />
                  </div>

                  <bild-modal
                    :gid="this.id"
                    id="bild-modal"
                    @bildUploaded="ladeGeschaeftspartner"
                  ></bild-modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import vue2Dropzone from "vue2-dropzone";
import { dropzoneOptionsBild } from "@/config/Dropzone";
import ContainerHeadline from "@/components/ContainerHeadline";
import BildModal from "@/components/Geschaeftspartner/BildModal";

import DatePicker from "@/components/Datepicker";

import Api from "@/Api";
import { LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER } from "@/store/geschaeftspartner/actions.type";
import { LADE_AUSWAHLWERTE_EINSTELLUNGEN } from "@/store/einstellungendashboard/actions.type";

export default {
  name: "Geschaeftspartner-Personalangaben",
  components: {
    //vue2Dropzone,
    ContainerHeadline,
    BildModal,
    DatePicker,
  },
  mixins: [],
  props: {
    id: {
      type: String,
      required: false,
    },
    person: {
      type: Object,
      default: function () {
        return {};
      },
    },
    editable: {
      type: Boolean,
      required: true,
    },
    roles: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      dropzoneOptions: dropzoneOptionsBild(Api.defaults.baseURL, this.id),
      isHidden: true,
      key: 0,
      bildKey: 0,
      isAdmin: false,
      firmenListe: [],
      relationstypen: [],
    };
  },
  computed: {
    langs: {
      get() {
        return this.$store.state.einstellungendashboard.sprachen;
      },
    },
    partnerrollen: {
      get() {
        return this.$store.state.geschaeftspartner.partnerrollen;
      },
    },
    geschlechter: {
      get() {
        return this.$store.state.geschaeftspartner.geschlechter;
      },
    },
    anreden: {
      get() {
        return this.$store.state.geschaeftspartner.anreden;
      },
    },
    akademischetitel: {
      get() {
        return this.$store.state.geschaeftspartner.akademischetitel;
      },
    },
    aktivitaetsstati: {
      get() {
        return this.$store.state.geschaeftspartner.aktivitaetsstati;
      },
    },
    zivilstaende: {
      get() {
        return this.$store.state.geschaeftspartner.zivilstaende;
      },
    },
    /*niveaus: {
      get() {
        return this.$store.state.geschaeftspartner.kompetenzstufen;
      },
    },*/
  },
  created() {
    if (this.firmenListe.length == 0) this.ladeFirmen();
    if (this.relationstypen.length == 0) this.ladeRelationstyp();
  },
  watch: {
    ["person.rolle"]: {
      handler: function (val) {
        if (val && this.partnerrollen.length > 0) {
          this.$emit("rolesChanged", val);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.langs.length == 0) {
      this.$store.dispatch(
        `einstellungendashboard/${LADE_AUSWAHLWERTE_EINSTELLUNGEN}`
      );
    }
    /*if (this.partnerrollen.length == 0)
      this.$store.dispatch(
        `geschaeftspartner/${LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER}`
      );
*/
    this.isAdmin =
      this.gruppen.findIndex(
        (gruppe) => gruppe.id == this.$CONST("GRUPPEN").ADMIN
      ) == -1
        ? false
        : true;
  },
  methods: {
    ladeGeschaeftspartner() {
      this.$emit("bildUpdated", this.id);
    },

    oeffneDokumentModal() {
      $("#bild-modal").modal("show");
    },

    fotoLoeschen() {
      Api.delete("bildupload/", { params: { id: this.person.id } }).then(() => {
        this.$emit("bildUpdated");
      });
    },
    ladeFirmen() {
      /*this.loading = true;

      //var params = null;

      Api.get("/geschaeftspartner/", {
        params: { typ: "17EF88469AE" },
      })
        .then((response) => {
          this.firmenListe = [];
          response.data.forEach((geschaeftspartner) => {
            this.firmenListe.push({
              id: geschaeftspartner.id,
              firmaid: geschaeftspartner.firma.id,
              name: geschaeftspartner.firma.name,
              style: "font-size: 0.8em; cursor: pointer",
            });
          });
        })
        .finally(() => {
          this.loading = false;
        });*/
    },
    ladeRelationstyp() {
      /*this.loading = true;

      var params = null;

      Api.get("/geschaeftspartner/relationstyp/", {
        params: params,
      })
        .then((response) => {
          this.relationstypen = [];
          response.data.forEach((relationstyp) => {
            this.relationstypen.push({
              id: relationstyp.id,
              bezeichnung: relationstyp.bezeichnung,
              style: "font-size: 0.8em; cursor: pointer",
            });
          });
        })
        .finally(() => {
          this.loading = false;
        });*/
    },
    relationLoeschen(relation) {
      Api.delete("geschaeftspartner/relation/", {
        data: [this.id],
        params: { id: relation },
      }).then(() => {
        this.$router.push({ name: "GeschaeftspartnerListe" });
      });
    },
  },
};
</script>

<style></style>

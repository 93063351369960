export { apiErrorToAlert };

function apiErrorToAlert(e) {
    if (e.response) {
        return {
            type: "error",
            title: "Aktion fehlerhaft",
            text: `Status ${e.response.status} - Fehler: ${e.response.data}`
        };
    } else {
        return {
            type: "error",
            title: "Aktion fehlerhaft",
            text: e
        }
    }
}
import Korrespondenz from "@/pages/Kommunikation/Korrespondenz";
import KorrespondenzListe from "@/pages/Dashboardlisten/Korrespondenzliste";
import Mail from "@/pages/Kommunikation/Mail";
import MailListe from "@/pages/Dashboardlisten/Mailliste";
import Notiz from "@/pages/Kommunikation/Notiz";
import NotizenListe from "@/pages/Dashboardlisten/Notizenliste";

import KorrespondenzVorlagenListe from "@/pages/Dashboardlisten/TemplateKorrespondenzliste";
import KorrespondenzVorlage from "@/pages/Kommunikation/KorrespondenzTemplate";
import MailVorlagenListe from "@/pages/Dashboardlisten/TemplateMailliste";
import MailVorlage from "@/pages/Kommunikation/MailTemplate";

export default [
  {
    path: "/kommunikation/korrespondenzliste",
    name: "korrespondenz-liste",
    component: KorrespondenzListe
  },
  {
    path: "/kommunikation/korrespondenz/:id?/:anzeige",
    name: "korrespondenz",
    component: Korrespondenz,
    props: route => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a
      };
    }
  },
  {
    path: "/kommunikation/korrespondenzvorlage",
    name: "Neue Korrespondenzvorlage",
    component: KorrespondenzVorlage,
    props: route => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a
      };
    }
  },
  {
    path: "/kommunikation/korrespondenzvorlagen",
    name: "korrespondenz-vorlagen-liste",
    component: KorrespondenzVorlagenListe
  },

  {
    path: "/kommunikation/korrespondenzvorlage/:id?/:anzeige",
    name: "korrespondenz-vorlage",
    component: KorrespondenzVorlage,
    props: route => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a
      };
    }
  },

  /*** E-MAIL ***/

  {
    path: "/kommunikation/mailliste",
    name: "mail-liste",
    component: MailListe
  },
  {
    path: "/kommunikation/mail/:id?/:anzeige",
    name: "mail",
    component: Mail,
    props: route => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a,
      };
    }
  },
  {
    path: "/kommunikation/mailvorlagen",
    name: "mail-vorlagen-liste",
    component: MailVorlagenListe
  },
  {
    path: "/kommunikation/mailvorlage/:id?/:anzeige",
    name: "mail-vorlage",
    component: MailVorlage,
    props: route => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a
      };
    }
  },

  /*** NOTIZ ***/
  {
    path: "/kommunikation/notizen",
    name: "notizen-liste",
    component: NotizenListe
  },
  {
    path: "/kommunikation/notiz/:id?/:anzeige",
    name: "notiz",
    component: Notiz,
    props: route => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a
      };
    }
  }
];

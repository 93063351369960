<template>
  <div class="list">
    <navbar :docsName="docsName"/>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <single-select-veranstaltung
                      id="noteneingabe-veranstaltung"
                      :veranstaltung="filters.veranstaltung"
                      :label="$t('global.event')"
                      :allowOpen="false"
                      :editable="true"
                      @confirmed="setVeranstaltung"
                    />
                  </div>
                </div>
                <!--
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="
                          filters['praesenzveranstaltung.periode.bezeichnung']
                        "
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>Periode Alt</label>
                    </div>
                  </div>
                </div>
-->
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.periode"
                        :options="perioden"
                        :reduce="(nes) => nes.id"
                        label="bezeichnung"
                        :placeholder="$t('global.period')"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.noperiodsfound")
                        }}</span>
                        <template v-slot:option="option">
                          {{ option.bezeichnung }} ({{ option.startdatum }}-{{
                            option.enddatum
                          }})
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          {{ option.bezeichnung }} ({{ option.startdatum }}-{{
                            option.enddatum
                          }})
                        </template>
                      </v-select>
                      <label>{{ $t("global.period") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="
                          filters['praesenzveranstaltung.periodennummer']
                        "
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.semester") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.verantwortliche"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.responsible") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :show-icon="true"
                        :initial="filters.datum"
                        field="datum"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.date") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :show-icon="true"
                        :initial="filters.sichtbarVon"
                        field="sichtbarVon"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.visiblefrom") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :show-icon="true"
                        :initial="filters.sichtbarBis"
                        field="sichtbarBis"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.visibleuntil") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.noteneingabe_status"
                        :options="noteneingabestati"
                        :reduce="(nes) => nes.id"
                        label="bezeichnung"
                        :placeholder="$t('global.Scoreinputstatus')"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.noscoreinputstatusfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.status") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button />
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.scoreinputlist')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between">
                  <div class="row ml-4">
                    <massenmutation-button
                      :mutatableFields="this.mutatableFields"
                      :mutatableDateFields="this.mutatableDateFields"
                      :mutatableTimeFields="this.mutatableTimeFields"
                      :mutatableValues="this.mutatableValues"
                      :mutatableLabels="this.mutatableLabels"
                      :mutatableDateNames="this.mutatableDateNames"
                      :mutatableTimeNames="this.mutatableTimeNames"
                      :mutationPfad="this.mutationPfad"
                      :selectedIds="this.selectedIds"
                      @mutated="refresh"
                    />
                    <button
                      class="ml-2 btn btn-primary"
                      @click="verantwortlicheAktualisieren"
                      v-if="berechtigungen.b_bildung_noteneingabe.update"
                    >
                      <font-awesome-icon icon="fa-regular fa-sync" /><span
                        class="ml-2"
                        >{{ $t("global.updateresponsibleperson") }}</span
                      >
                    </button>
                  </div>

                  <div class="mr-4 float-right">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.b_bildung_noteneingabe.delete"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />{{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                    thead-tr-class="pointer"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td class="no-pointer" v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                    <template v-slot:cell(praesenzveranstaltung)="data">
                      <div v-if="data.value.periode">
                        {{ data.value.periode.bezeichnung }} ({{
                          data.value.periode.startdatum
                        }}-{{ data.value.periode.enddatum }})
                      </div>
                    </template>
                  </b-table>

                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="noteneingabe-loeschen-modal"
      :selectedIds="selectedIds"
      @confirmed="noteneingabeLoeschen"
      :multiple="true"
    />

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";

import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import DatePicker from "@/components/Datepicker";

import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import SingleSelectVeranstaltung from "@/components/global/SingleSelectVeranstaltung.vue";
import MassenmutationButton from "@/components/Buttons/MassenmutationButton";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";

import { LADE_AUSWAHLWERTE_NOTENEINGABE } from "@/store/noteneingabe/actions.type";
import { LADE_AUSWAHLWERTE_VERANSTALTUNG } from "@/store/veranstaltung/actions.type";
import { LADE_AUSWAHLWERTE_NOTEN } from "@/store/noten/actions.type";

import store from "@/store";

export default {
  name: "Noteneingabeliste",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    DatePicker,
    LoeschenModal,
    SingleSelectVeranstaltung,
    MassenmutationButton,
    FilterSearchResetButton,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.scoreentries");
      },
    };
  },
  store,
  mixins: [page, dashboard, filter],
  props: {},
  data() {
    return {
      filterVeranstaltung: {},
      mutatableFields: ["Status", "Notentyp", "Noteneingabetyp"],
      mutatableDateFields: ["Sichtbar von", "Sichtbar bis"],
      mutatableDateNames: ["Sichtbar_von", "Sichtbar_bis"],
      mutatableTimeFields: ["Uhrzeit", "Uhrzeit2"],
      mutatableTimeNames: ["Sichtbar_von_uhrzeit", "Sichtbar_bis_uhrzeit"],
      mutatableValues: [[], [], []],
      mutatableLabels: ["bezeichnung", "bezeichnung", "bezeichnung"],
      mutationPfad: "noteneingabe/",
      sortBy: "praesenzveranstaltung.bezeichnung",
      sortDesc: false,
      notenstrukturModalId: "notenstruktur-verbinden-modal",
      notenstrukturAendernNotenstruktur: null,
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "praesenzveranstaltung.kuerzel",
          sortable: true,
          label: this.$t("global.shortdesignation"),
        },
        {
          key: "praesenzveranstaltung.bezeichnung",
          sortable: true,
          label: this.$t("global.eventclass"),
        },
        {
          key: "notentyp.bezeichnung",
          sortable: true,
          searchable: false,
          label: this.$t("global.scoretype"),
        },
        {
          key: "praesenzveranstaltung",
          sortable: true,
          searchable: false,
          label: this.$t("global.period"),
        },
        {
          key: "praesenzveranstaltung.periodennummer",
          sortable: true,
          label: this.$t("global.semester"),
        },
        {
          key: "verantwortliche",
          sortable: true,
          label: this.$t("global.responsible"),
          formatter: (value) => {
            return value.map((v) => v.personName).join(", ");
          },
        },
        {
          key: "datum",
          searchable: false,
          sortable: true,
          label: this.$t("global.date"),
        },
        {
          key: "sichtbar_von",
          searchable: false,
          sortable: true,
          label: this.$t("global.visiblefrom"),
        },
        {
          key: "sichtbar_bis",
          searchable: false,
          sortable: true,
          label: this.$t("global.visibleuntil"),
        },
        {
          key: "noteneingabe_status.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.status"),
        },
      ];
    },
    queryParams: function () {
      var params = {};

      params.filterBezeichnung = this.setQueryParam(
        this.filters["praesenzveranstaltung.bezeichnung"]
      );
      params.filterKuerzel = this.setQueryParam(
        this.filters["praesenzveranstaltung.kuerzel"]
      );
      params.filterNoteneingabeStatus = this.setQueryParam(
        this.filters["noteneingabe_status.bezeichnung"]
      );
      params.filterSemester = this.setQueryParam(
        this.filters["praesenzveranstaltung.periodennummer"]
      );
      params.filterPeriode = this.setQueryParam(
        this.filters["praesenzveranstaltung.periode.bezeichnung"]
      );
      params.filterVeranstaltungID = this.setQueryParam(
        this.filters.veranstaltung?.id
      );
      params.fach = this.setQueryParam(this.filters.fach);
      params.noteneingabetyp = this.setQueryParam(this.filters.noteneingabeTyp);
      params.filterDatum = this.setQueryParam(this.filters.datum);
      params.sichtbarVonFilter = this.setQueryParam(this.filters.sichtbarVon);
      params.sichtbarBisFilter = this.setQueryParam(this.filters.sichtbarBis);
      params.filterVerantwortliche = this.setQueryParam(
        this.filters.verantwortliche
      );

      if (this.noteneingabe_status)
        params.noteneingabeStatus = this.noteneingabe_status;
      else
        params.noteneingabeStatus = this.filters.noteneingabe_status
          ? this.filters.noteneingabe_status.join(",")
          : null;

      if (this.periode) params.filterPeriode = this.periode;
      else
        params.filterPeriode = this.filters.periode
          ? this.filters.periode.join(",")
          : null;

      // TODO

      // params.filterVKuerzel = this.filters['praesenzveranstaltung.kuerzel']
      // params.filterNEBez = this.filters['noteneingabe_status.bezeichnung']

      if (this.filters.count != "") params.filterCount = this.filters.count;

      return params;
    },

    noteneingabestati: {
      get() {
        return this.$store.state.noteneingabe.noteneingabestati;
      },
    },
    noteneingabetypen: {
      get() {
        return this.$store.state.noteneingabe.noteneingabetypen;
      },
    },
    perioden: {
      get() {
        return this.$store.state.veranstaltung.perioden;
      },
    },
    notentypen: {
      get() {
        return this.$store.state.noten.notentypen;
      },
    },
  },

  created() {
    this.initFilter("noteneingabe-liste", "noteneingabe/", true);

    this.$store.dispatch(`noteneingabe/${LADE_AUSWAHLWERTE_NOTENEINGABE}`);
    this.$store.dispatch(`noten/${LADE_AUSWAHLWERTE_NOTEN}`);

    this.$store
      .dispatch(`veranstaltung/${LADE_AUSWAHLWERTE_VERANSTALTUNG}`)
      .then(() => {
        this.mutatableValues[0].push(this.noteneingabestati);
        this.mutatableValues[1].push(this.notentypen);
        this.mutatableValues[2].push(this.noteneingabetypen);
      });
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
  },
  methods: {
    verantwortlicheAktualisieren() {
      if (this.selectedIds.length > 0) {
        Api.put("/noteneingabe/verantwortlich-aktualisieren/", this.selectedIds)
          .then(this.resetLoading(this))
          .finally(
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: "Verantwortliche aktualisiert",
            })
          );
      } else {
        this.$notify({
          type: "warn",
          title: "Keine Einträge ausgewählt.",
          text: "Es wurden keine Noteneingaben ausgewählt.",
        });
      }
    },
    refresh() {
      console.log("in refresh");
      this.resetLoading(this);
    },
    details(noteneingaben) {
      if (noteneingaben.noteneingabe_typ) {
        if (noteneingaben.noteneingabe_typ.id == "174E97933ED") {
          this.$router.push({
            name: "noteneingabe",
            params: { id: noteneingaben.id, anzeige: 0 },
          });
        } else {
          this.$router.push({
            name: "noteneingabe",
            params: { id: noteneingaben.id, anzeige: 2 },
          });
        }
      } else {
        this.$router.push({
          name: "noteneingabe",
          params: { id: noteneingaben.id, anzeige: 0 },
        });
      }
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "noteneingabe-loeschen-modal");
      else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nosubjectselected"),
        });
      }
    },

    noteneingabeLoeschen() {
      Api.delete("noteneingabe/", {
        params: { ids: [this.selectedIds].join(",") },
      }).then(() => {
        this.resetLoading(this);
      });
    },

    setVeranstaltung(veranstaltung) {
      this.$set(this.filters, "veranstaltung", veranstaltung);
    },
  },
};
</script>

<style></style>

import ChangeWatcherAlert from "@/components/global/ChangeWatcherAlert.vue";

export default {
  beforeRouteLeave(to, from, next) {
    if (this.hasChanged) {
      this.$bus.$emit("saved-needed", next);
    } else next();
  },
  components: {
    ChangeWatcherAlert,
  },
  props: {},
  data() {
    return {
      hasChanged: false,
      changeWatcherStatus: false,
      watchedValue: null,
      previousValue: null,
    };
  },
  watch: {
    watchedValue: {
      deep: true,
      handler() {
        if (this.changeWatcherStatus && this.previousValue != null) {
          this.hasChanged = true;
        }

        this.previousValue = this.watchedValue;
      },
    },
  },
  methods: {
    initChangeWatcher(value) {
      let that = this;

      setTimeout(function () {
        that.watchedValue = value;
        that.changeWatcherStatus = true;
        that.hasChanged = false;
        that.previousValue = null;
      }, 500);
    },

    disableChangeWatcher() {
      this.changeWatcherStatus = false;
      this.hasChanged = false;
    },

    flagChanged() {
      this.hasChanged = true;
    },
  },
};

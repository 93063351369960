<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline class="col-xl-12" headline="Produkte" :col="3">
        <!--
<div class="row mt-2 d-flex justify-content-between">
			<div class="ml-4">
			<button
				class="btn btn-success"
				@click="erstelleFach"
				v-if="berechtigungen.b_bildung_fach.create"
 >
				<font-awesome-icon icon="fa-regular fa-plus" />
				{{ $t("global.new") }}
	  </button>	</div>
		  </div>
		  -->
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              tbody-tr-class="item"
              :items="produkte"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="isBusy"
              sort-icon-left
              fixed
              selectable
              select-mode="range"
              show-empty
              @row-dblclicked="oeffneProdukt"
              thead-tr-class="pointer"
            >
              <template v-slot:head(selected)="row">
                <b-form-checkbox
                  v-model="row.selected"
                  @input="selectAllRows(row)"
                ></b-form-checkbox>
              </template>

              <template v-slot:cell(selected)="row">
                <b-form-checkbox
                  v-model="row.item.selected"
                  @input="onRowSelected(row)"
                ></b-form-checkbox>
              </template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>{{ $t("global.errorwhileloading") }}</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>{{ $t("global.nodataavailable") }}</strong>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <!--
<loeschen-modal
		id="fach-loeschen-modal"
		@confirmed="fachloeschen"
		:selectedIds="Array.from(new Set(selectedIds))"
		:multiple="true"
/>
-->
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import reiter from "@/mixins/Reiter";

export default {
  name: "Produktreiter",
  components: {
    ContainerHeadline,
    //LoeschenModal,
    //BSpinner,
  },
  mixins: [reiter],
  props: {
    shown: {
      type: Boolean,
      required: false,
    },
    produktgruppeid: {
      type: String,
      required: true,
    },
    /*editable: {
      type: Boolean,
      required: true,
    },*/
  },
  data() {
    return {
      page: 0,
      anzahlProPage: 50,
      sortBy: "",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      produkte: [
        {
          kurzbezeichnung_de: "Kurzezeichnung DE 01",
          bezeichnung_de: "Bezeichnung DE 01",
          internationale_pid: "4049505307395",
          modellnummer: "100806",
        },
        {
          kurzbezeichnung_de: "Kurzezeichnung DE 02",
          bezeichnung_de: "Bezeichnung DE 02",
          internationale_pid: "4049505307396",
          modellnummer: "100807",
        },
      ],
      selected: [],
      selectedIds: [],
    };
  },
  watch: {
    shown(val) {
      //if (val && this.produkte.length == 0) this.getProdukte();
    },
  },
  mounted() {
    //if (this.shown && this.produkte.length == 0) this.getProdukte();
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "kurzbezeichnung_de",
          sortable: true,
          label: "Kurzbezeichnung",
        },
        {
          key: "bezeichnung_de",
          sortable: true,
          label: "Bezeichnung",
        },
        {
          key: "internationale_pid",
          sortable: true,
          label: "Internationale Produkt-ID",
        },
        {
          key: "modellnummer",
          sortable: true,
          label: "Modellnummer",
        },
      ];
    },
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      return params;
    },
  },
  created() {},
  methods: {
    getProdukte() {
      /*
		this.isBusy = true;
  
		Api.get("veranstaltungfach/", {
		  params: {
			veranstaltung: this.veranstaltung,
			page: this.page,
			count: this.anzahlProPage,
			veranstaltungstyp: this.$CONST("VERANSTALTUNGSTYPEN").MODULE,
		  },
		})
		  .then((response) => {
			this.faecher = response.data;
			this.ladeFehler = false;
		  })
		  .catch((e) => {
			this.$notify(apiErrorToAlert(e));
			this.ladeFehler = true;
		  })
		  .finally(() => {
			this.isBusy = false;
		  });
		  */
    },

    oeffneProdukt(produkt) {
      this.$router.push({
        name: "produkt",
        params: { produktid: produkt.id, anzeige: 0 },
      });
    },

    /*oeffneLoeschenModal() {
		if (this.selectedIds.length > 0)
		  this.$bus.$emit("openLoeschenModal", "fach-loeschen-modal");
	  },*/

    /*fachloeschen() {
		Api.delete("veranstaltungfach/", {
		  params: { ids: this.selectedIds.join(",") },
		}).then(() => {
		  this.getFaecher();
		});
	  },*/

    infiniteHandler($state) {
      /*
		if (this.shown) {
		  Api.get("faecher/", {
			params: this.queryParams,
		  }).then((response) => {
			console.log("final");
			if (response.data.length === this.anzahlProPage) {
			  this.page += 1;
			  this.faecher.push(...response.data);
			  $state.loaded();
			} else {
			  this.faecher.push(...response.data);
			  $state.complete();
			}
		  });
		}*/
    },
    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((p) => {
          p.selected = true;
          if (!this.selectedIds.includes(p.id)) {
            this.selectedIds.push(p.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((p) => {
          p.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },
    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },
    /*erstelleFach() {
		if (this.isBusy) return;
		this.isBusy = true;
		Api.post("veranstaltungen/?parent=" + this.veranstaltung, {
		  veranstaltungstyp: this.$CONST("VERANSTALTUNGSTYPEN").MODULE,
		})
		  .then((response) => {
			this.$router.push({
			  name: "fach",
			  params: { fachid: response.data.id, anzeige: 0 },
			});
			this.$notify({
			  type: "success",
			  title: this.$t("global.actionsuccessful"),
			  text: this.$t("global.subjectsuccessfullysaved"),
			});
		  })
		  .catch((e) => {
			this.$notify(apiErrorToAlert(e));
		  })
		  .finally(() => {
			this.isBusy = false;
		  });
	  },*/
  },
};
</script>

<template>
  <div>
    <button
      class="ml-2 btn btn-light-grey mt-2 mb-2"
      v-shortkey.once="this.modalOpen ? null : ['enter']"
      @click="search"
      @shortkey="search"
    >
      {{ $t("global.search") }}
    </button>
    <button
      class="ml-2 btn btn-light-grey mt-2 mb-2"
      @click="reset"
    >
    {{ $t("global.reset") }}
    </button>
  </div>
</template>

<script>
export default {
  name: "FilterSuchenButton",
  data() {
    return {
      modalOpen: false
    }
  },
  mounted() {
    this.$bus.$on("sucheModalOpen", () => {
      this.modalOpen = true;
    })
    this.$bus.$on("sucheModalClose", () => {
      this.modalOpen = false;
    })
    },
  methods: {
    search() {
      this.$bus.$emit("searchWithFilter");
    },
    reset() {
      this.$bus.$emit("resetFilter");
    }
  },
};
</script>
<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.gradepromotion')"
        :col="3"
      >
        <div class="row">
          <div
            class="ml-4 mr-2"
            v-if="promotionDone == false && editable == false"
          >
            <button class="btn btn-primary" @click="runNotenPromotion">
              <font-awesome-icon icon="fa-regular fa-plus" />
              {{ $t("global.creategradepromotion") }}
            </button>
          </div>
          <div
            class="ml-4 mr-2"
            v-if="promotionDone == true && editable == false"
          >
            <button
              class="btn btn-success"
              @click="showNotenPromotion"
              v-if="promotionDone == true"
            >
              <font-awesome-icon
                icon="fa-duotone fa-external-link-square-alt"
              />
              {{ $t("global.opengradepromotion") }}
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              tbody-tr-class="item"
              :items="notenpromotionliste"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="isBusy"
              show-empty
              sort-icon-left
              fixed
              thead-tr-class="pointer"
            >
              <template v-slot:cell(promotionstatus)="row">
                <span v-if="!editable">{{
                  row.item.promotionstatus.bezeichnung
                }}</span>
                <v-select
                  v-if="editable"
                  v-model.trim="row.item.promotionstatus"
                  :options="promotionstati"
                  :reduce="(ps) => ps.id"
                  label="bezeichnung"
                  :placeholder="$t('global.promotionstatus')"
                >
                  <span slot="no-options">{{
                    $t("global.nostatusofpromotionsfound")
                  }}</span>
                </v-select>
              </template>

              <template v-slot:cell(grenzfall)="row">
                <span v-if="row.item.grenzfall == true">
                  <span v-if="row.item.dispensiert == true">
                    <font-awesome-icon
                      icon="fa-duotone fa-check-square"
                      size="2x"
                      class="text-success"
                    />
                  </span>
                </span>
              </template>

              <template v-slot:cell(dispensiert)="row">
                <span v-if="row.item.dispensiert == true">
                  <font-awesome-icon
                    icon="fa-duotone fa-check-square"
                    size="2x"
                    class="text-success"
                  />
                </span>
              </template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>{{ $t("global.errorwhileloading") }}</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>{{ $t("global.nodataavailable") }}</strong>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";

import { LADE_AUSWAHLWERTE_NOTEN } from "@/store/noten/actions.type";
import reiter from "@/mixins/Reiter";

export default {
  components: {
    ContainerHeadline,
    BSpinner,
  },
  mixins: [reiter],
  props: {
    veranstaltung: {
      type: String,
      required: true,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 0,
      anzahlProPage: 99,
      sortBy: "",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      promotionDone: false,
      notenpromotionliste: [],
    };
  },
  watch: {
    shown(val) {
      if (val && this.notenpromotionliste.length == 0) this.getNotenPromotion();
    },
  },
  computed: {
    fields() {
      return [
        {
          key: "person.personName",
          sortable: true,
          label: this.$t("global.person"),
        },
        {
          key: "note",
          sortable: true,
          label: this.$t("global.grade"),
          class: "text-center",
        },
        {
          key: "promotionstatus",
          sortable: true,
          label: this.$t("global.status"),
          class: "text-center",
        },
        {
          key: "grenzfall",
          sortable: true,
          label: this.$t("global.borderlinecase"),
          class: "text-center",
        },
        {
          key: "dispensiert",
          sortable: true,
          label: this.$t("global.dispensed"),
          class: "text-center",
        },
      ];
    },
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      return params;
    },

    promotionstati: {
      get() {
        return this.$store.state.noten.promotionstati;
      },
    },
  },
  mounted() {
    if (this.shown) {
      if (this.notenpromotionliste.length == 0) this.getNotenPromotion();
      if (this.promotionstati.length == 0)
        this.$store.dispatch(`noten/${LADE_AUSWAHLWERTE_NOTEN}`);
    }
  },
  created() {},
  methods: {
    getNotenPromotion() {
      this.isBusy = true;

      Api.get("noten/promotion/", {
        params: {
          veranstaltung: this.veranstaltung,
          page: this.page,
          count: this.anzahlProPage,
        },
      })
        .then((response) => {
          //response.data.shift();
          this.promotionDone = true;
          this.notenpromotionliste = response.data;
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    runNotenPromotion() {},

    showNotenPromotion() {
      if (this.notenpromotionliste.length == 0) return;

      this.$router.push({
        name: "noten-berechnung",
        params: {
          id: this.notenpromotionliste[0].notenberechnung.id,
          anzeige: 0,
        },
      });
    },
  },
};
</script>

<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar"
            :docsName="docsName"
    />

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel"
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4 mr-2">
                  <button
                    v-if="editable"
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>
                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="
                      berechtigungen.m_anmeldungen.delete && produktgruppeid
                    "
                  >
                    <font-awesome-icon
                      class="mr-1"
                      icon="fa-duotone fa-trash"
                    />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- START Linke Seite -->
        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Stammdaten -->
          <div class="row">
            <div
              class="block block-kopf clickable"
              @click="setStammdatenSprache('DE')"
              :style="
                stammdatenSprache == 'DE'
                  ? styleHeadlineSelected
                  : styleHeadline
              "
            >
              <span class="col primary-headline-text p-0"
                >{{ $t("global.masterdata") }} - DE</span
              >
            </div>
            <div
              class="block block-kopf clickable"
              @click="setStammdatenSprache('EN')"
              :style="
                stammdatenSprache == 'EN'
                  ? styleHeadlineSelected
                  : styleHeadline
              "
            >
              <span class="col primary-headline-text p-0">EN</span>
            </div>
            <div
              class="block block-kopf clickable"
              @click="setStammdatenSprache('FR')"
              :style="
                stammdatenSprache == 'FR'
                  ? styleHeadlineSelected
                  : styleHeadline
              "
            >
              <span class="col primary-headline-text p-0">FR</span>
            </div>
          </div>
          <!--
          <div class="row d-flex">
            <container-headline
              :headline="$t('global.masterdata') + ' - DE'"
              :col="6"
            ></container-headline>
            <container-headline
              :headline="$t('global.masterdata') + ' - EN'"
              :col="3"
            ></container-headline>
          </div>-->
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-if="stammdatenSprache == 'DE'"
                        v-model="produktgruppe.bezeichnungDE"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <input
                        v-if="stammdatenSprache == 'EN'"
                        v-model="produktgruppe.bezeichnungEN"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <input
                        v-if="stammdatenSprache == 'FR'"
                        v-model="produktgruppe.bezeichnungFR"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <label>Bezeichnung/Titel</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-if="stammdatenSprache == 'DE'"
                        v-model="produktgruppe.beschreibungDE"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <input
                        v-if="stammdatenSprache == 'EN'"
                        v-model="produktgruppe.beschreibungEN"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <input
                        v-if="stammdatenSprache == 'FR'"
                        v-model="produktgruppe.beschreibungFR"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <label>Beschreibung/Untertitel</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <textarea
                        v-if="stammdatenSprache == 'DE'"
                        v-model="produktgruppe.beschreibung_lang_DE"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <textarea
                        v-if="stammdatenSprache == 'EN'"
                        v-model="produktgruppe.beschreibung_lang_EN"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <textarea
                        v-if="stammdatenSprache == 'FR'"
                        v-model="produktgruppe.beschreibung_lang_FR"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <label>Beschreibung lang</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="produktgruppe.pfad"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <label>Pfad</label>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-4">
                    <img
                      class="w-100 h-100"
                      :src="produktgruppe.bild.bildUrl"
                      style="object-fit: cover; object-position: center center"
                    />
                  </div>
                  <div class="col-8">
                    <div class="col-12">
                      <div class="form-group">
                        <input
                          v-if="stammdatenSprache == 'DE'"
                          v-model="produktgruppe.bild.bild_bezeichnung_de"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <input
                          v-if="stammdatenSprache == 'EN'"
                          v-model="produktgruppe.bild.bild_bezeichnung_en"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <input
                          v-if="stammdatenSprache == 'FR'"
                          v-model="produktgruppe.bild.bild_bezeichnung_fr"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <label>Bezeichnung</label>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <input
                          v-if="stammdatenSprache == 'DE'"
                          v-model="produktgruppe.bild.title_tag_de"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <input
                          v-if="stammdatenSprache == 'EN'"
                          v-model="produktgruppe.bild.title_tag_en"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <input
                          v-if="stammdatenSprache == 'FR'"
                          v-model="produktgruppe.bild.title_tag_fr"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <label>Titel Tag</label>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <input
                          v-if="stammdatenSprache == 'DE'"
                          v-model="produktgruppe.bild.alt_tag_de"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <input
                          v-if="stammdatenSprache == 'EN'"
                          v-model="produktgruppe.bild.alt_tag_en"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <input
                          v-if="stammdatenSprache == 'FR'"
                          v-model="produktgruppe.bild.alt_tag_fr"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <label>Alt Tag</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">dokumente</div>
              </div>
            </div>
          </div>
          <!-- ENDE Stammdaten -->
        </div>
        <!-- ENDE linke Seite -->
        <!-- START Rechte Seite -->
        <!-- START Technische Daten -->
        <div class="col-xl-6 pl-4" :class="{ 'col-xl-12': !showNavbar }">
          <div class="row">
            <div
              class="block block-kopf clickable"
              @click="setTechnischeDatenSprache('DE')"
              :style="
                technischeDatenSprache == 'DE'
                  ? { filter: 'none' }
                  : { filter: 'brightness(0.9)' }
              "
            >
              <span class="col primary-headline-text p-0"
                >Technische Daten - DE</span
              >
            </div>
            <div
              class="block block-kopf clickable"
              @click="setTechnischeDatenSprache('EN')"
              :style="
                technischeDatenSprache == 'EN'
                  ? { filter: 'none' }
                  : { filter: 'brightness(0.9)' }
              "
            >
              <span class="col primary-headline-text p-0">EN</span>
            </div>
            <div
              class="block block-kopf clickable"
              @click="setTechnischeDatenSprache('FR')"
              :style="
                technischeDatenSprache == 'FR'
                  ? { filter: 'none' }
                  : { filter: 'brightness(0.9)' }
              "
            >
              <span class="col primary-headline-text p-0">FR</span>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div
                class="eingabe-panel-kopf p-3 technischeDatenPanel"
                ref="technischeDatenPanel"
                :style="
                  produktgruppe.technischeDaten.length > 2
                    ? panelStyle
                    : 'height: 420px;'
                "
              >
                <div class="row mb-2">
                  <div class="col-xl-6">
                    <div class="btn btn-primary" @click="addTechnischeDaten()">
                      <font-awesome-icon
                        class="px-2 mr-1"
                        icon="fa-regular fa-plus"
                      /><span>Technische Daten hinzufügen</span>
                    </div>
                  </div>
                </div>
                <div
                  class="py-2 border-bottom"
                  v-for="(tDaten, index) in produktgruppe.technischeDaten"
                  :key="'tDaten' + index"
                >
                  <div class="row">
                    <div class="col-xl-8">
                      <div class="form-group d-flex">
                        <input
                          v-if="technischeDatenSprache == 'DE'"
                          v-model="tDaten.titelDE"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <input
                          v-if="technischeDatenSprache == 'EN'"
                          v-model="tDaten.titelEN"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <input
                          v-if="technischeDatenSprache == 'FR'"
                          v-model="tDaten.titelFR"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <label>Angabe</label>
                      </div>
                    </div>
                    <div class="col-xl-3">
                      <div class="form-group d-flex">
                        <input
                          v-model="tDaten.sortierung"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <label>Sortierung</label>
                      </div>
                    </div>
                    <div class="">
                      <div class="form-group d-flex">
                        <div
                          class="btn btn-sm btn-danger ml-1"
                          @click="loescheTechnischeDaten(index)"
                        >
                          <font-awesome-icon
                            class="px-2"
                            icon="fa-duotone fa-trash"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <textarea
                          v-if="technischeDatenSprache == 'DE'"
                          v-model="tDaten.beschreibungDE"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <textarea
                          v-if="technischeDatenSprache == 'EN'"
                          v-model="tDaten.beschreibungEN"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <textarea
                          v-if="technischeDatenSprache == 'FR'"
                          v-model="tDaten.beschreibungFR"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <label>Wert</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--ENDE Technische Daten -->
          <!-- START Bulletpoints -->
          <div class="row">
            <div
              class="block block-kopf clickable"
              @click="setBulletpointsSprache('DE')"
              :style="
                bulletpointsSprache == 'DE'
                  ? { filter: 'none' }
                  : { filter: 'brightness(0.9)' }
              "
            >
              <span class="col primary-headline-text p-0"
                >Bulletpoints - DE</span
              >
            </div>
            <div
              class="block block-kopf clickable"
              @click="setBulletpointsSprache('EN')"
              :style="
                bulletpointsSprache == 'EN'
                  ? { filter: 'none' }
                  : { filter: 'brightness(0.9)' }
              "
            >
              <span class="col primary-headline-text p-0">EN</span>
            </div>
            <div
              class="block block-kopf clickable"
              @click="setBulletpointsSprache('FR')"
              :style="
                bulletpointsSprache == 'FR'
                  ? { filter: 'none' }
                  : { filter: 'brightness(0.9)' }
              "
            >
              <span class="col primary-headline-text p-0">FR</span>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div
                class="eingabe-panel-kopf p-3 bulletpointsPanel"
                ref="bulletpointsPanel"
                :style="
                  produktgruppe.bulletpoints.length > 2
                    ? panelStyle
                    : 'height: 420px;'
                "
              >
                <div class="row mb-2">
                  <div class="col-xl-6">
                    <div class="btn btn-primary" @click="addBulletpoint()">
                      <font-awesome-icon
                        class="px-2 mr-1"
                        icon="fa-regular fa-plus"
                      /><span>Bulletpoint hinzufügen</span>
                    </div>
                  </div>
                </div>
                <div
                  class="py-2 border-bottom"
                  v-for="(bulletpoint, index) in produktgruppe.bulletpoints"
                  :key="'bulletpoint' + index"
                >
                  <div class="row">
                    <div class="col-xl-8">
                      <div class="form-group d-flex">
                        <input
                          v-if="bulletpointsSprache == 'DE'"
                          v-model="bulletpoint.titelDE"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <input
                          v-if="bulletpointsSprache == 'EN'"
                          v-model="bulletpoint.titelEN"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <input
                          v-if="bulletpointsSprache == 'FR'"
                          v-model="bulletpoint.titelFR"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <label>Angabe</label>
                      </div>
                    </div>
                    <div class="col-xl-3">
                      <div class="form-group d-flex">
                        <input
                          v-model="bulletpoint.sortierung"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <label>Sortierung</label>
                      </div>
                    </div>
                    <div class="">
                      <div class="form-group d-flex">
                        <div
                          class="btn btn-sm btn-danger ml-1"
                          @click="loescheBulletpoint(index)"
                        >
                          <font-awesome-icon
                            class="px-2"
                            icon="fa-duotone fa-trash"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <textarea
                          v-if="bulletpointsSprache == 'DE'"
                          v-model="bulletpoint.beschreibungDE"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <textarea
                          v-if="bulletpointsSprache == 'EN'"
                          v-model="bulletpoint.beschreibungEN"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <textarea
                          v-if="bulletpointsSprache == 'FR'"
                          v-model="bulletpoint.beschreibungFR"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <label>Wert</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--ENDE Bulletpoints -->
        </div>
        <!-- ENDE Rechte Seite -->
        <!-- REITER -->

        <!-- HEADER REITER -->

        <div class="col-12">
          <div class="row">
            <div class="col-12 p-0">
              <ul class="nav nav-tabs mt-3 fs-28">
                <li class="nav item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige == 0 }"
                    :to="{
                      name: 'produktgruppe',
                      params: { produktgruppeid: produktgruppeid, anzeige: 0 },
                    }"
                    >Produkte</router-link
                  >
                </li>
              </ul>
            </div>

            <!-- ENDE HEADER REITER -->

            <!-- REITER CONTENT -->
            <div class="col-12 p-0">
              <div class="tab-container p-3">
                <div v-show="anzeige === 0">
                  <produkt-reiter :produktgruppeid="produktgruppeid" />
                </div>
              </div>
            </div>
            <!-- REITER CONTENT ENDE -->
          </div>
        </div>
        <!-- REITER ENDE -->
      </div>
    </ValidationObserver>

    <loading-overlay v-show="loading"></loading-overlay>

    <loeschen-modal
      id="produktgruppe-loeschen-modal"
      @confirmed="produktgruppeLoeschen"
    />

    <change-watcher-alert />
  </div>
</template>

<script>
import Api from "@/Api";
import page from "@/mixins/Page";
import ChangeWatcher from "@/mixins/ChangeWatcher";
import server from "@/server";

import HeadMenu from "@/components/HeadMenu";
import ContainerHeadline from "@/components/ContainerHeadline";
import DatePicker from "@/components/Datepicker";
import Navbar from "@/components/Navbar";

import LoeschenModal from "@/components/Modals/LoeschenModal.vue";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import store from "@/store";

import ProduktReiter from "@/components/Reiter/Produktreiter.vue";

export default {
  name: "Produktgruppe",
  components: {
    HeadMenu,
    Navbar,
    //ContainerHeadline,
    LoeschenModal,
    ProduktReiter,
  },
  mixins: [page, ChangeWatcher],
  store,
  props: {
    produktgruppeid: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      stammdatenSprache: "DE",
      technischeDatenSprache: "DE",
      bulletpointsSprache: "DE",
      styleHeadlineSelected: { filter: "none" },
      styleHeadline: { filter: "brightness(0.9)" },
      panelStyle: {
        height: "420px",
        "overflow-y": "scroll",
      },
      produktgruppe: {
        id: "123",
        bezeichnungDE: "Produktgruppe 1",
        bezeichnungEN: "Product group 1",
        bezeichnungFR: "Groupe de product 1",
        beschreibungDE: "Produktgruppenbeschreibung 1",
        beschreibungEN: "Product group descripion 1",
        beschreibungFR: "Description du Groupe de product 1",
        beschreibung_lang_DE: "Produktgruppenbeschreibung lang 1",
        beschreibung_lang_EN: "Product group descripion long 1",
        beschreibung_lang_FR: "Description du Groupe de product longue 1",
        bild: {
          bildUrl: "https://education-dev.batix.ch/pic/17E6E142713.jpg",
          bild_bezeichnung_de: "Bezeichnung DE",
          title_tag_de: "Titel Tag DE",
          alt_tag_de: "Alt Tag DE",
          bild_bezeichnung_en: "Bezeichnung EN",
          title_tag_en: "Titel Tag EN",
          alt_tag_en: "Alt Tag EN",
          bild_bezeichnung_fr: "Bezeichnung FR",
          title_tag_fr: "Titel Tag FR",
          alt_tag_fr: "Alt Tag FR",
        },
        downloads: [],
        bulletpoints: [
          {
            titelDE: "Titel DE",
            titelEN: "Titel EN",
            titelFR: "Titel FR",
            sortierung: 10,
            beschreibungDE: "Beschreibung DE",
            beschreibungEN: "Beschreibung EN",
            beschreibungFR: "Beschreibung FR",
          },
        ],
        technischeDaten: [
          {
            titelDE: "Titel DE",
            titelEN: "Titel EN",
            titelFR: "Titel FR",
            sortierung: 10,
            beschreibungDE: "Beschreibung DE",
            beschreibungEN: "Beschreibung EN",
            beschreibungFR: "Beschreibung FR",
          },
        ],
        pfad: "url",
        produktliste: [],
        produktkategorie: {},
        produktgruppeParent: {},
        produktgruppeChilds: [],
      },
      editable: true,
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.id || this.produktgruppeid) {
        return "Produktgruppe";
      } else {
        return "Neue Produktgruppe";
      }
    },
  },
  watch: {},

  created() {},

  mounted() {},

  methods: {
    abbrechen() {
      this.$router.push({ name: "produktgruppen-liste" });
    },

    speichern() {},

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "produktgruppe-loeschen-modal");
    },

    produktgruppeLoeschen() {},
    setStammdatenSprache(sprache) {
      this.stammdatenSprache = sprache;
    },
    setTechnischeDatenSprache(sprache) {
      this.technischeDatenSprache = sprache;
    },
    setBulletpointsSprache(sprache) {
      this.bulletpointsSprache = sprache;
    },
    addTechnischeDaten() {
      let sortierung =
        this.produktgruppe.technischeDaten[
          this.produktgruppe.technischeDaten.length - 1
        ].sortierung;

      this.produktgruppe.technischeDaten.push({
        titelDE: "",
        titelEN: "",
        titelFR: "",
        sortierung: parseInt(sortierung) + 10,
        beschreibungDE: "",
        beschreibungEN: "",
        beschreibungFR: "",
      });
    },
    addBulletpoint() {
      let sortierung =
        this.produktgruppe.bulletpoints[
          this.produktgruppe.bulletpoints.length - 1
        ].sortierung;

      this.produktgruppe.bulletpoints.push({
        titelDE: "",
        titelEN: "",
        titelFR: "",
        sortierung: parseInt(sortierung) + 10,
        beschreibungDE: "",
        beschreibungEN: "",
        beschreibungFR: "",
      });
    },
    loescheTechnischeDaten(index) {
      this.produktgruppe.technischeDaten.splice(index, 1);
    },
    loescheBulletpoint(index) {
      this.produktgruppe.bulletpoints.splice(index, 1);
    },
  },
};
</script>

<style>
.technischeDatenPanel,
.bulletpointsPanel {
  scrollbar-width: thin;
  scrollbar-color: #deedf6 #f5fbff;
}
.technischeDatenPanel::-webkit-scrollbar,
.bulletpointsPanel::-webkit-scrollbar {
  width: 12px;
}

*.technischeDatenPanel::-webkit-scrollbar-track,
.bulletpointsPanel::-webkit-scrollbar-track {
  background: #f5fbff;
  border-radius: 0 0 6px 0;
}

*.technischeDatenPanel::-webkit-scrollbar-thumb,
.bulletpointsPanel::-webkit-scrollbar-thumb {
  background-color: #deedf6;
  border-radius: 20px;
  border: 3px solid #f5fbff;
}
</style>

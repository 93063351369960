<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.workplaces') + ' (' + count + ')'"
        :col="3"
      >
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              tbody-tr-class="item"
              :items="arbeitsplaetze"
              :fields="fieldsDESKA"
              :busy="isBusy"
              show-empty
              sort-icon-left
              fixed
              selectable
              select-mode="range"
              @row-dblclicked="details"
              :sort-compare="sortCompare"
              thead-tr-class="pointer"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>{{ $t("global.errorwhileloading") }}</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>{{ $t("global.nodataavailable") }}</strong>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="arbeitsplatz-loeschen-modal"
      @confirmed="arbeitsplatzloeschen"
      :multiple="true"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";
import { LADE_AUSWAHLWERTE_DOZENT } from "@/store/dozent/actions.type";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import reiter from "@/mixins/Reiter";

export default {
  components: {
    ContainerHeadline,
    LoeschenModal,
    BSpinner,
  },
  mixins: [reiter],
  props: {
    veranstaltung: {
      type: String,
      required: false,
    },
    raum: {
      type: String,
      required: false,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    arbeitsplatzlisteKey: {
      type: Number,
    },
  },
  data() {
    return {
      page: 0,
      count: 0,
      infiniteId: +new Date(),
      anzahlProPage: 25,
      isBusy: false,
      ladeFehler: false,
      arbeitsplaetze: [],
      dozentenbuchungen: [],
      fieldsDESKA: [
        { key: "selected", label: "" },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
        {
          key: "displayNr",
          sortable: true,
          label: this.$t("global.displaynumber"),
        },
      ],
      fieldsEducation: [
        { key: "selected", label: "" },
        {
          key: "von",
          sortable: true,
          label: this.$t("global.from"),
        },
        { key: "von_uhrzeit", sortable: true, label: this.$t("global.TIme") },
        { key: "bis", sortable: true, label: this.$t("global.till") },
        { key: "bis_uhrzeit", sortable: true, label: this.$t("global.TIme") },
        {
          key: "lektionen",
          sortable: true,
          label: this.$t("global.lessons"),
        },
        {
          key: "raeumeleitsystem",
          sortable: true,
        },
        {
          key: "dozenteneinsatzliste",
          sortable: true,
          label: this.$t("global.lecturer"),
        },
        {
          key: "anzahl_anmeldungen",
          sortable: true,
          label: this.$t("global.participants"),
        },
        {
          key: "anwesenheit",
          sortable: true,
          label: this.$t("global.presence"),
        },
      ],
      selected: [],
      selectedIds: [],
      NeuerarbeitsplatzModalId: "neuer-arbeitsplatz-modal",
    };
  },
  watch: {
    shown(val) {
      if (val && this.arbeitsplaetze.length == 0) this.getarbeitsplaetze();
    },

    lektionenTotal: function (value) {
      this.$bus.$emit("lektionenTotal", value);
    },
    arbeitsplatzlisteKey: function () {},
  },
  computed: {
    computedFields: function () {
      return this.$CONST("CONFIG").APPTYPE == "DESKA"
        ? this.fieldsDESKA
        : this.fieldsEducation;
    },
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
        raumID: this.id,
      };

      if (this.suche) params.suche = this.suche;

      return params;
    },
    durchfuehrungsstati: {
      get() {
        return this.$store.state.dozent.durchfuehrungsstati;
      },
    },
    planungsstati: {
      get() {
        return this.$store.state.dozent.planungsstati;
      },
    },

    lektionenTotal: function () {
      let summeLektionen = 0;
      this.arbeitsplaetze.forEach((el) => {
        summeLektionen += el.lektionen;
      });
      return summeLektionen;
    },
  },
  mounted() {
    if (this.shown) {
      this.getarbeitsplaetze();
      if (
        this.durchfuehrungsstati.length == 0 ||
        this.planungsstati.length == 0
      )
        this.$store.dispatch(`dozent/${LADE_AUSWAHLWERTE_DOZENT}`);
    }

    this.$bus.$emit("lektionenTotal", this.lektionenTotal);

    this.$bus.$on("neuerarbeitsplatzGespeichert", () => {
      this.getarbeitsplaetze();
    });
  },
  created() {
    this.$bus.$on("getDozentenbuchungen", (data) => {
      this.dozentenbuchungen = data;
    });
  },

  methods: {
    sortCompare(itemA, itemB, key) {
      if (key !== "von" && key !== "bis") {
        return false;
      } else {
        let a = itemA[key];
        let b = itemB[key];

        a = a.split(".");
        b = b.split(".");

        a =
          parseInt(a[2], 10) * 10000 +
          parseInt(a[1], 10) * 100 +
          parseInt(a[0]);
        b =
          parseInt(b[2], 10) * 10000 +
          parseInt(b[1], 10) * 100 +
          parseInt(b[0]);

        return a - b;
      }
    },
    raeumeGeaendert() {
      this.getarbeitsplaetze();
    },

    deUpdated() {
      this.$emit("updated");
    },

    raumUpdaten() {
      this.$bus.$emit("openarbeitsplatzRaumModal");
    },

    dozenteneinsatzErstellen() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openarbeitsplatzDozenteneinsatzModal");
      else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: "keine arbeitsplaetze gewählt",
        });
      }
    },

    getarbeitsplaetze() {
      this.isBusy = true;

      Api.get("arbeitsplatz/", {
        params: {
          raumID: this.raum,
          page: this.page,
          count: this.anzahlProPage,
        },
      })
        .then((response) => {
          //response.data.shift();
          this.arbeitsplaetze = response.data;
          this.count = this.arbeitsplaetze.length;
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    details(arbeitsplatz) {
      this.$router.push({
        name: "Arbeitsplatz",
        params: { id: arbeitsplatz.id, anzeige: 0 },
      });
    },

    infiniteHandler($state) {
      console.log("init");

      if (this.shown) {
        this.loading = true;
        Api.get("arbeitsplatz/", {
          params: this.queryParams,
        }).then((response) => {
          console.log("final");
          if (response.data.length === this.anzahlProPage) {
            this.page += 1;
            this.arbeitsplatz.push(...response.data);
            $state.loaded();
          } else {
            this.arbeitsplatz.push(...response.data);
            $state.complete();
          }
        });
        this.loading = false;
      }
    },

    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = true;
          if (!this.selectedIds.includes(gp.id)) {
            this.selectedIds.push(gp.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },

    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "arbeitsplatz-loeschen-modal");
    },

    arbeitsplatzloeschen() {
      Api.delete("arbeitsplatz/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.getarbeitsplaetze();
      });
    },
  },
};
</script>

<template>
  <div
    :id="id"
    class="modal fade"
    tabindex="-1"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div
      class="modal-dialog modal-fluid modal-lernzielvereinbarungBewertungModal"
    >
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">
            {{ $t("global.learningobjectivesagreement") }}
          </h4>
        </div>
        <div class="modal-body">
          <div class="col-xl-12">
            <!-- PRUEFE DATEN -->
            <div
              class="row"
              v-if="
                isBusy || ladeFehler || !this.lernzielvereinbarungBewertung.id
              "
            >
              <div class="text-center text-primary m-auto my-2" v-if="isBusy">
                <b-spinner class="align-middle"></b-spinner>
              </div>
              <div
                class="text-center text-danger m-auto my-2"
                v-if="ladeFehler && !isBusy"
              >
                <strong>{{ $t("global.errorwhileloading") }}</strong>
              </div>
              <div
                class="text-center text-primary m-auto my-2"
                v-if="!this.lernzielvereinbarungBewertung.id && !isBusy"
              >
                <strong>{{ $t("global.nodataavailable") }}</strong>
              </div>
            </div>

            <!-- TABELLE -->
            <div
              class="row px-4 mt-4"
              v-if="
                !isBusy && !ladeFehler && this.lernzielvereinbarungBewertung.id
              "
            >
              <!-- INFO -->
              <div class="col-xl-12">
                <h4 class="mb-4">
                  {{ $t("global.pralearningobjectivesagreementprotocol") }}
                </h4>
                <div class="row mt-2 bg-primary text-white font-weight-bold">
                  <div class="col-4 p-3 border border-dark">
                    {{ $t("global.student") }}
                  </div>
                  <div class="col-8 p-3 border border-dark">
                    {{ lernzielvereinbarungBewertung.info.name }}
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-4 p-3 border border-dark border-bottom-0 border-right-0"
                  >
                    {{ $t("global.date") }}
                  </div>
                  <div class="col-8 p-3 border border-bottom-0 border-dark">
                    <date-picker
                      :disabled="!editable"
                      class="w-50"
                      date-format="dd.mm.yy"
                      :initial="
                        lernzielvereinbarungBewertung.lernziele[0].bewertung
                          .datum
                      "
                      placeholder=" "
                      :show-icon="true"
                      :in-modal="true"
                      @update="(val) => setDate(val)"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-4 p-3 border border-dark border-right-0">
                    {{ $t("global.teachers") }}
                  </div>
                  <div
                    class="lernzielbewertungFaecher col-4 p-0 border border-dark border-right-0"
                  >
                    <div
                      class="row px-3"
                      v-for="(fachbez, index) in lernzielvereinbarungBewertung
                        .info.faecher"
                      :key="index"
                    >
                      <div
                        class="col-12 py-3 border-bottom border-dark"
                        style="height: 70px"
                      >
                        {{ fachbez.bezeichnung }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="lernzielbewertungFaecher col-4 p-0 border border-dark"
                  >
                    <div
                      class="row px-3"
                      v-for="(fachver, index) in lernzielvereinbarungBewertung
                        .info.faecher"
                      :key="index"
                    >
                      <div
                        class="col-12 py-2 border-bottom border-dark"
                        style="height: 70px"
                      >
                        <v-select
                          :disabled="!editable"
                          v-model="fachver.verantwortlicher"
                          :options="fachver.verantwortliche"
                          label="name"
                          :placeholder="$t('global.noteacherlinked')"
                          :clearable="false"
                        >
                        </v-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- KOMPETENZEN FORTSCHRITTE -->
              <div class="col-xl-12 mt-5 px-0">
                <b-table
                  class="bg-transparent text-primary"
                  bordered
                  ref="seletableTable"
                  :items="lernzielvereinbarungBewertung.fortschritte"
                  :fields="fortschritteFields"
                  :table-variant="'dark'"
                >
                  <template v-slot:cell(bezeichnung)="row">
                    <div>{{ row.item.bezeichnung }}</div>
                  </template>

                  <template v-slot:cell(bewertung.lernende)="row">
                    <div class="m-0 p-0">
                      <textarea
                        :disabled="!editable"
                        style="min-width: 100%"
                        v-model="row.item.bewertung.lernende"
                        :placeholder="$t('global.skillsprogressstudent')"
                        max-rows="2"
                        rows="2"
                        type="text"
                      />
                    </div>
                  </template>

                  <template v-slot:cell(bewertung.lehrperson)="row">
                    <div class="m-0 p-0">
                      <textarea
                        :disabled="!editable"
                        style="min-width: 100%"
                        v-model="row.item.bewertung.lehrperson"
                        :placeholder="$t('global.skillsprogressteacher')"
                        max-rows="2"
                        rows="2"
                        type="text"
                      />
                    </div>
                  </template>

                  <template v-slot:cell(bewertung.kuerzel)="row">
                    <div class="m-0 p-0">
                      <input
                        :disabled="!editable"
                        style="min-width: 100%"
                        v-model="row.item.bewertung.kuerzel"
                        :placeholder="$t('global.teacherabbreviation')"
                        type="text"
                      />
                    </div>
                  </template>
                </b-table>
              </div>
              <!-- LERNZIELE -->
              <div class="col-xl-12 mt-5 px-0">
                <b-table
                  class="bg-transparent text-primary"
                  bordered
                  ref="seletableTable"
                  :items="lernzielvereinbarungBewertung.lernziele"
                  :fields="lernzieleFields"
                  :table-variant="'dark'"
                >
                  <template v-slot:cell(bezeichnung)="row">
                    <div>{{ row.item.bezeichnung }}</div>
                  </template>

                  <template v-slot:cell(bewertung.lernende)="row">
                    <div class="m-0 p-0">
                      <textarea
                        :disabled="!editable"
                        style="min-width: 100%"
                        v-model="row.item.bewertung.lernende"
                        :placeholder="$t('global.learningobjectivesstudent')"
                        max-rows="2"
                        rows="2"
                        type="text"
                      />
                    </div>
                  </template>

                  <template v-slot:cell(bewertung.lehrperson)="row">
                    <div class="m-0 p-0">
                      <textarea
                        :disabled="!editable"
                        style="min-width: 100%"
                        v-model="row.item.bewertung.lehrperson"
                        :placeholder="$t('global.learningobjectivesteacher')"
                        max-rows="2"
                        rows="2"
                        type="text"
                      />
                    </div>
                  </template>

                  <template v-slot:cell(bewertung.kuerzel)="row">
                    <div class="m-0 p-0">
                      <input
                        :disabled="!editable"
                        style="min-width: 100%"
                        v-model="row.item.bewertung.kuerzel"
                        :placeholder="$t('global.teacherabbreviation')"
                        type="text"
                      />
                    </div>
                  </template>
                </b-table>
              </div>
              <!-- PROGNOSE -->
              <div class="col-xl-12">
                <div class="row mt-2 bg-primary text-white font-weight-bold">
                  <div class="col-2 p-3 border border-dark">
                    {{ $t("global.date") }}
                  </div>
                  <div class="col-1 p-3 border border-dark">
                    {{ $t("global.probabilityoftransfertonextly") }}
                  </div>
                  <div class="col-1 p-3 border border-dark">
                    {{ $t("global.probabilityoftransfertoeba") }}
                  </div>
                  <div class="col-3 p-3 border border-dark">
                    {{ $t("global.remarksontransition") }}
                  </div>
                  <div class="col-3 p-3 border border-dark">
                    {{ $t("global.other") }}
                  </div>
                  <div class="col-2 p-3 border border-dark">
                    {{ $t("global.teacherabbreviation") }}
                  </div>
                </div>

                <div class="row">
                  <div
                    class="col-2 p-3 border border-dark border-right-0 d-flex justify-content-center align-items-center"
                  >
                    <date-picker
                      :disabled="!editable"
                      class="w-100"
                      date-format="dd.mm.yy"
                      :initial="lernzielvereinbarungBewertung.prognose.datum"
                      placeholder=" "
                      :show-icon="true"
                      :in-modal="true"
                      @update="(val) => setDate(val)"
                    />
                  </div>
                  <div
                    class="col-1 p-2 border border-dark border-right-0 d-flex justify-content-center align-items-center"
                    style="height: 108px"
                  >
                    <v-select
                      :disabled="!editable"
                      class="w-100"
                      v-model="
                        lernzielvereinbarungBewertung.prognose
                          .wahrscheinlichkeit1
                      "
                      :options="
                        lernzielvereinbarungBewertung.wahrscheinlichkeiten
                      "
                      label="bezeichnung"
                      placeholder="-"
                      :clearable="false"
                    >
                    </v-select>
                  </div>
                  <div
                    class="col-1 p-2 border border-dark border-right-0 d-flex justify-content-center align-items-center"
                    style="height: 108px"
                  >
                    <v-select
                      :disabled="!editable"
                      class="w-100"
                      v-model="
                        lernzielvereinbarungBewertung.prognose
                          .wahrscheinlichkeit2
                      "
                      :options="
                        lernzielvereinbarungBewertung.wahrscheinlichkeiten
                      "
                      label="bezeichnung"
                      placeholder="-"
                      :clearable="false"
                    >
                    </v-select>
                  </div>
                  <div
                    class="col-3 p-3 border border-dark border-right-0 d-flex justify-content-center align-items-center"
                    style="height: 108px"
                  >
                    <textarea
                      :disabled="!editable"
                      style="min-width: 100%"
                      v-model="lernzielvereinbarungBewertung.prognose.wechsel"
                      :placeholder="$t('global.remarkstransition')"
                      max-rows="2"
                      rows="2"
                      type="text"
                    />
                  </div>
                  <div
                    class="col-3 p-3 border border-dark border-right-0 d-flex justify-content-center align-items-center"
                    style="height: 108px"
                  >
                    <textarea
                      :disabled="!editable"
                      style="min-width: 100%"
                      v-model="lernzielvereinbarungBewertung.prognose.sonstiges"
                      :placeholder="$t('global.otherremarks')"
                      max-rows="2"
                      rows="2"
                      type="text"
                    />
                  </div>
                  <div
                    class="col-2 p-3 border border-dark d-flex justify-content-center align-items-center"
                    style="height: 108px"
                  >
                    <input
                      :disabled="!editable"
                      style="min-width: 100%"
                      v-model="lernzielvereinbarungBewertung.prognose.kuerzel"
                      :placeholder="$t('global.teacherabbreviation')"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            v-if="editable"
            type="button"
            class="btn btn-success"
            @click="speichern"
          >
            {{ $t("global.save") }}
          </button>
          <button
            class="btn btn-primary ml-2"
            @click="excelExport"
            v-if="editable"
          >
            <font-awesome-icon icon="fa-regular fa-file-export" class="mr-1" />
            {{ $t("global.export") }}
          </button>
          <button
            v-if="editable"
            type="button"
            class="btn btn-primary"
            @click="drucken"
          >
            {{ $t("global.print") }}
          </button>
          <button
            v-if="editable"
            type="button"
            class="btn btn-primary"
            @click="vorlageDrucken"
          >
            {{ $t("global.printtemplate") }}
          </button>
          <button type="button" class="btn btn-secondary" @click="closeModal">
            {{ $t("global.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import store from "@/store";
import { modal } from "@/mixins/Modal";

import DatePicker from "@/components/Datepicker";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import FileDownload from "js-file-download";
import ModalCloseButton from "@/components/global/ModalCloseButton.vue";

export default {
  name: "LernzielvereinbarungBewertungModal",
  mixins: [modal],
  components: {
    BSpinner,
    DatePicker,
    ModalCloseButton
  },
  store,
  props: {
    id: {
      type: String,
      required: false,
      default: "lernzielvereinbarung-bewertung-modal",
    },
    anmeldungId: {
      type: String,
      default: null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isBusy: false,
      ladeFehler: false,
      lernzielvereinbarungBewertung: {
        id: null,
        bezeichnung: "",
        info: {
          datum: "",
          faecher: [
            {
              verantwortliche: [{ name: "" }],
              verantwortlicher: { name: "" },
              bezeichnung: "",
            },
          ],
        },
        fortschritte: [
          {
            bezeichnung: "",
            bewertung: {
              lernende: "",
              lehrperson: "",
              kuerzel: "",
            },
          },
        ],
        lernziele: [
          {
            bezeichnung: "",
            bewertung: {
              lernende: "",
              lehrperson: "",
              kuerzel: "",
            },
          },
        ],
      },
    };
  },
  computed: {
    fortschritteFields() {
      return [
        {
          key: "bezeichnung",
          searchable: false,
          sortable: false,
          label: this.$t("global.skillsprogress"),
          thClass: "bg-primary text-white",
          thStyle: { width: "425px" },
        },
        {
          key: "bewertung.lernende",
          searchable: false,
          sortable: false,
          label: this.$t("global.Student"),
          thClass: "bg-primary text-white",
        },
        {
          key: "bewertung.lehrperson",
          searchable: false,
          sortable: false,
          label: this.$t("global.teacher"),
          thClass: "bg-primary text-white",
        },
        {
          key: "bewertung.kuerzel",
          searchable: false,
          sortable: false,
          label: this.$t("global.teacherabbreviation"),
          thClass: "bg-primary text-white",
          thStyle: { width: "50px" },
        },
      ];
    },
    lernzieleFields() {
      return [
        {
          key: "bezeichnung",
          searchable: false,
          sortable: false,
          label: this.$t("global.learningobjectives"),
          thClass: "bg-primary text-white",
          thStyle: { width: "425px" },
        },
        {
          key: "bewertung.lernende",
          searchable: false,
          sortable: false,
          label: this.$t("global.Student"),
          thClass: "bg-primary text-white",
        },
        {
          key: "bewertung.lehrperson",
          searchable: false,
          sortable: false,
          label: this.$t("global.teacher"),
          thClass: "bg-primary text-white",
        },
        {
          key: "bewertung.kuerzel",
          searchable: false,
          sortable: false,
          label: this.$t("global.teacherabbreviation"),
          thClass: "bg-primary text-white",
          thStyle: { width: "50px" },
        },
      ];
    },
  },
  watch: {},
  mounted() {
    this.$bus.$on("openLernzielvereinbarungBewertungModal", (ids) => {
      if (ids.modalId == this.id && !this.shown) {
        this.openModal();
        this.getLernzielvereinbarungBewertung(ids.anmeldungId);
      }
    });
  },
  methods: {
    excelExport() {
      Api.post(
        "/lernzielvereinbarung/export/",
        this.lernzielvereinbarungBewertung,
        { responseType: "blob" } //SWE: notwendig damit OutputExcel nicht beschädigt wird
      )
        .then((response) => {
          this.excelFile = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          FileDownload(
            this.excelFile,
            `Lernzielvereinbarung-${new Date().toLocaleDateString()}.xlsx`
          );
          this.loading = false;
        })
        .catch(() => {
          setTimeout(() => {
            this.$notify({
              type: "error",
              title: this.$t("global.actionfailed"),
              text: "Fehler beim Erstellen der Excel-Datei",
              duration: 7000,
            });
          }, 2000);
          this.loading = false;
        });
    },

    getLernzielvereinbarungBewertung(anmeldungId) {
      this.isBusy = true;

      Api.get("lernzielvereinbarung/anmeldung/", {
        params: { anmeldungId: anmeldungId },
      })
        .then((response) => {
          this.lernzielvereinbarungBewertung = response.data;
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    initializeLernzielvereinbarungBewertung(response) {
      this.lernzielvereinbarungBewertung = response.data;
    },
    setDate(date) {
      this.lernzielvereinbarungBewertung?.fortschritte?.forEach(
        (fortschritt) => {
          fortschritt.bewertung.datum = date;
        }
      );

      this.lernzielvereinbarungBewertung?.lernziele?.forEach((ziel) => {
        ziel.bewertung.datum = date;
      });
    },
    closeModal() {
      this.shown = false;
      $("#lernzielvereinbarung-bewertung-modal").modal("hide");
    },
    speichern() {
      this.isBusy = true;

      var json = Object.assign({}, this.lernzielvereinbarungBewertung);

      Api.post("lernzielvereinbarung/anmeldung/", json, {
        params: { anmeldungId: this.anmeldungId },
      })
        .then((response) => {
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.learningobjectiveagreementsuccessfullysaved"),
          });
          this.ladeFehler = false;
          this.initializeLernzielvereinbarungBewertung(response);
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    drucken() {
      this.speichern();
      this.$emit("lernzieleDrucken");
      this.closeModal();
    },
    vorlageDrucken() {
      this.speichern();
      this.$emit("lernzieleVorlageDrucken");
      this.closeModal();
    },
  },
};
</script>

<style>
.lernzielbewertungFaecher > div:last-child > div {
  border-bottom: 0 !important;
}
</style>

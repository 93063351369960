import { SET_PRAESENZ_STATI } from "./mutations.type";
import { SET_TEILPRAESENZEN } from "./mutations.type";

import { LADE_AUSWAHLWERTE_PRAESENZ } from "./actions.type";

import Api from "@/Api";

export default {
	namespaced: true,
	state: {
		praesenzstati: [],
		teilpraesenzen: [],
	},
	mutations: {
		[SET_PRAESENZ_STATI](state, val) {
			state.praesenzstati = val;
		},
		[SET_TEILPRAESENZEN](state, val) {
			state.teilpraesenzen = val;
		},
	},
	actions: {
		async [LADE_AUSWAHLWERTE_PRAESENZ](context) {
			let response = await Api.get("praesenz/auswahlwerte/");
			context.commit(SET_PRAESENZ_STATI, response.data.praesenzstati);
			context.commit(SET_TEILPRAESENZEN, response.data.teilpraesenzen);
		}
	}
};

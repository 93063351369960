<template>
  <div class="row">
    <div class="col-xl-12">
      <!-- AKTIONEN -->

      <container-headline class="col-xl-12" headline="" :col="3">
        <div class="row">
          <div class="ml-4 mr-2">
            <button
              class="btn btn-success"
              @click="notenSpeichern"
              :disabled="!editable"
            >
              {{ $t("global.saveevaluations") }}
            </button>
          </div>
          <div class="mr-2">
            <button
              class="btn btn-success"
              @click="teilnehmerAktualisieren"
              v-if="editable"
            >
              {{ $t("global.updateparticipantlist") }}
            </button>
          </div>
        </div>
      </container-headline>

      <!-- INHALT -->

      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              class="minh-200-px"
              ref="notenerfassungtable"
              tbody-tr-class="item"
              :items="notenliste"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="isBusy"
              show-empty
              sort-icon-left
              small
              thead-tr-class="pointer"
            >
              <template v-slot:cell(bewertung)="row">
                <b-form-input
                  v-if="eingabetyp == 'noten'"
                  v-model="row.item.bewertung.bewertung"
                  :on-change="checkBewertung(row.index)"
                  :state="row.item.bewertung.invalid"
                  :number="true"
                  :disabled="!editable"
                  required
                  size="sm"
                ></b-form-input>

                <v-select
                  v-model="row.item.note.textwahl_wert"
                  v-if="eingabetyp == 'textwahl'"
                  class="mt-2"
                  label="bezeichnung"
                  :options="textwahlwerte"
                  :disabled="!editable"
                  :placeholder="$t('global.status')"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>

                <b-form-input
                  v-if="eingabetyp == 'freitext'"
                  v-model="row.item.note.textbewertung"
                  required
                  size="sm"
                  :readonly="!editable"
                ></b-form-input>
              </template>

              <template v-slot:cell(dispensiert)="row">
                <b-button
                  class="mr-2"
                  :variant="
                    row.item.note.dispensiert == true ? 'primary' : 'light'
                  "
                  size="sm"
                  style="width: 150px"
                  @click="setDispensiert(row.index)"
                  :disabled="!editable"
                  >{{
                    row.item.note.dispensiert
                      ? $t("global.dispensed")
                      : $t("global.notdispensed")
                  }}</b-button
                >
              </template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>{{ $t("global.errorwhileloading") }}</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>{{ $t("global.nodataavailable") }}</strong>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";
import reiter from "@/mixins/Reiter";

export default {
  components: {
    ContainerHeadline,
    BSpinner,
  },
  mixins: [reiter],
  props: {
    noteneingabe: {
      type: String,
      required: false,
    },
    eingabetyp: {
      type: String,
      required: true,
    },
    person: {
      type: String,
      required: false,
    },
    editable: {
      type: Boolean,
      required: false,
    },
    shown: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      page: 0,
      anzahlProPage: 99,
      isBusy: false,
      ladeFehler: false,
      sortBy: "",
      sortDesc: false,
      invalidFields: {},
      notenliste: [],
      pruefungCount: 0,
      pruefungLabel: [],
    };
  },
  watch: {
    shown(val) {
      if (val && this.notenliste.length == 0) this.getNoten();
    },
  },
  computed: {
    fields() {
      return [
        {
          key: "person.personName",
          sortable: true,
          label: this.$t("global.participants"),
        },
        {
          key: "dispensiert",
          sortable: true,
          class: "text-center",
          label: this.$t("global.dispensed"),
        },
        {
          key: "bewertung",
          class: "text-center",
          sortable: true,
          label: this.$t("global.evaluation"),
        },
      ];
    },
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      if (this.suche) params.suche = this.suche;
      return params;
    },
    textwahlwerte: {
      get() {
        return this.$store.state.noten.textwahlwerte;
      },
    },
  },
  created() {},
  mounted() {
    if (this.shown && this.notenliste.length == 0) this.getNoten();
  },

  methods: {
    checkBewertung(index) {
      let val = this.notenliste[index]?.bewertung.bewertung;

      if (val == undefined || val == "") {
        this.notenliste[index].bewertung.invalid = true;
        this.invalidFields["bewertung" + index] = null;
      } else {
        if (val < 1 || val > 6) {
          this.notenliste[index].bewertung.invalid = false;
          this.invalidFields["bewertung" + index] = true;
        } else {
          this.invalidFields["bewertung" + index] = null;
          this.notenliste[index].bewertung.invalid = true;
        }
      }
    },

    validateForm() {
      let validState = true;

      Object.values(this.invalidFields).forEach((val) => {
        if (val == true) validState = false;
      });

      return validState;
    },

    getNoten() {
      this.isBusy = true;

      Api.get("noten/notenerfassungfrei/", {
        params: {
          noteneingabe: this.noteneingabe,
          page: this.page,
          count: this.anzahlProPage,
        },
      })
        .then((response) => {
          this.notenliste = response.data;
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    setDispensiert(index) {
      this.notenliste[index].note.dispensiert = this.notenliste[index].note
        ? !this.notenliste[index].note.dispensiert
        : true;
    },

    notenSpeichern() {
      if (!this.validateForm()) return;

      this.isBusy = true;

      Api.put("noten/notenerfassungfrei/", this.notenliste, {
        params: { noteneingabe: this.noteneingabe },
      })
        .then(() => {
          this.getNoten();
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.gradessavedsuccessfully"),
          });
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isbusy = false;
        });
    },
    teilnehmerAktualisieren() {
      this.isBusy = true;

      Api.put(
        "noten/teilnehmeraktualisieren/",
        {},
        {
          params: { noteneingabeId: this.noteneingabe },
        }
      )
        .then(() => {
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.listofparticipantssuccessfullyupdated"),
          });
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
          this.getNoten();
        });
    },
  },
};
</script>

import {
  SET_SUBVENTIONS_STATI,
  SET_SUBVENTIONS_KANTONE,
  SET_AUSBILDUNG_STATI,
  SET_ABSCHLUESSE,
  SET_AUSBILDUNGEN
} from "./mutations.type";

import { LADE_AUSWAHLWERTE_SUBVENTION } from "./actions.type";

import Api from "../../Api";

export default {
  namespaced: true,
  state: {
    subventionsstati: [],
    subventionskantone: [],
    ausbildungstati: [],
    abschlusse: [],
    ausbildungen: []
  },
  mutations: {
    [SET_SUBVENTIONS_STATI](state, val) {
      state.subventionsstati = val;
    },
    [SET_SUBVENTIONS_KANTONE](state, val) {
      state.subventionskantone = val;
    },
    [SET_AUSBILDUNG_STATI](state, val) {
      state.ausbildungstati = val;
    },
    [SET_ABSCHLUESSE](state, val) {
      state.abschlusse = val;
    },
    [SET_AUSBILDUNGEN](state, val) {
      state.ausbildungen = val;
    }
  },
  actions: {
    async [LADE_AUSWAHLWERTE_SUBVENTION](context) {
      let response = await Api.get("subvention/auswahlwerte/");
      context.commit(SET_SUBVENTIONS_STATI, response.data.subventionsstati);
      context.commit(SET_SUBVENTIONS_KANTONE, response.data.subventionskantone);
      context.commit(SET_AUSBILDUNG_STATI, response.data.ausbildungsstati);
      context.commit(SET_ABSCHLUESSE, response.data.abschluesse);
      context.commit(SET_AUSBILDUNGEN, response.data.ausbildungen);
    }
  }
};

<template>
  <div>
    <container-headline
      :headline="$t('global.invoiceaddress')"
      :col="6"
    ></container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-6">
              <single-select-firma
                id="rechnungAnFirma"
                :firma="firma"
                :editable="editable"
                :allowOpen="true"
                :label="$t('global.company')"
                @confirmed="setFirma"
              />
            </div>
            <div class="col-xl-6">
              <div class="form-check">
                <input
                  type="checkbox"
                  v-model="firmaOhnePerson"
                  id="firmacheckbox"
                  class="form-check-input"
                  :disabled="!editable"
                />
                <label>{{ $t("global.withoutperson") }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";
import SingleSelectFirma from "@/components/global/SingleSelectFirma.vue";

export default {
  name: "Geschaeftspartner-Rechnungsadresse",
  components: {
    ContainerHeadline,
    SingleSelectFirma,
  },
  mixins: [],
  props: {
    person: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    roles: {
      type: Object,
      default: function () {},
    },
  },
  data() {
    return {
      firma: {},
      firmaOhnePerson: false,
    };
  },
  computed: {},
  watch: {
    person: {
      deep: true,
      handler: function () {
        this.firma = this.person.rechnungan ? this.person.rechnungan : {};
        this.firmaOhnePerson = this.person.rechnunganohneperson
          ? this.person.rechnunganohneperson
          : false;
      },
    },
    firmaOhnePerson: {
      handler: function () {
        this.emitChanges();
      },
    },
  },
  created() {},
  methods: {
    setFirma(firma) {
      this.firma = firma;
      this.emitChanges();
    },
    emitChanges() {
      this.$emit("invoiceToChanged", {
        firma: this.firma,
        ohnePerson: this.firmaOhnePerson,
      });
    },
  },
};
</script>

<style>
</style>


<template>
  <div :id="id" class="modal fade" style="z-index: 9997">
    <div class="modal-dialog modal-xl">
      <div class="modal-content" style="border: 1px solid black">
        <div class="modal-header">
          <modal-close-button @confirmed="abbrechen" />
          <h4 class="modal-title">{{ $t("global.searchperson") }}</h4>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <div class="col-xl-3">
              <single-select-firma
                id="firma"
                :firma="firma"
                :editable="true"
                :allowOpen="true"
                :label="$t('global.company')"
                @confirmed="setFirma"
              />
            </div>
            <div class="col-xl-3">
              <single-select-person
                id="person"
                :person="person"
                :firma="firma"
                :editable="true"
                :allowOpen="true"
                :openCompany="false"
                :label="$t('global.person')"
                @confirmed="setPerson"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <router-link
            v-if="berechtigungen.m_geschaeftspartner.create"
            class="btn btn-success mr-auto"
            :to="{ name: 'geschaeftspartner', params: { anzeige: 0 } }"
            target="_blank"
          >
            <font-awesome-icon icon="fa-regular fa-plus" class="mr-2" />
            {{ $t("global.newPerson") }}
          </router-link>

          <button type="button" class="btn btn-default" @click="abbrechen">
            {{ $t("global.cancel") }}
          </button>
          <button
            type="button"
            class="btn btn-danger mr-2"
            @click="entfernen"
            v-if="filterPerson && filterPerson.name && remove"
          >
            {{ $t("global.remove") }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="uebernehmen"
            :disabled="this.person == null && this.firma == null"
          >
            {{ $t("global.apply") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import ModalCloseButton from "@/components/global/ModalCloseButton";
import SingleSelectFirma from "@/components/global/SingleSelectFirma.vue";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import { BSpinner } from "bootstrap-vue";

export default {
  name: "gpSucheModal",
  components: {
    ModalCloseButton,
    SingleSelectFirma,
    SingleSelectPerson,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: "geschaeftspartner-modal",
    },
    adressTypen: {
      type: Array,
      required: false,
    },
    filterRollen: {
      type: Array,
      required: false,
    },
    filterPerson: {
      type: Object,
      required: false,
    },
    filterFirma: {
      type: Object,
      required: false,
    },
    remove: {
      type: Boolean,
      default: false,
    },
    activeonly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isBusy: false,
      loaded: false,
      anzahlProPage: 99,
      geschaeftspartner: [],
      firma: null,
      person: null,
      vorname: null,
      empfaenger: {},
      empfaengerID: null,
      name: "",
      zip: null,
      selected: [],
      new: null,
    };
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      if (this.activeonly) params.status = "1737FA777AA";

      params.adresstyp = this.adressTypen ? this.adressTypen.join() : null;
      params.rollen = this.filterRollen ? this.filterRollen.join() : null;
      params.firma = this.firma?.id;
      params.filterFirma = this.filterFirma?.id;
      params.name = this.name;
      params.suche = this.vorname;
      params.zip = this.zip;

      params.typ = "17EF8844E6B";
      params.incf = "1";

      return params;
    },
  },
  watch: {
    filterFirma(val) {
      this.resetLoading();
    },
  },
  mounted() {
    this.$bus.$on("openGeschaeftspartnerModal", (id) => {
      if (id == this.id && !this.shown) this.openModal();
    });
    this.$bus.$on("empfaenger-editieren", (empfaenger) => {
      console.log("in bus" * empfaenger);
      if (!this.shown) this.openModal();
      this.empfaengerID = empfaenger.id;
      this.firma = empfaenger.firma;
      this.person = empfaenger.person;
      this.new = true;
      this.resetLoading();
    });
  },
  methods: {
    openModal() {
      this.vorname = null;
      this.name = null;
      this.firma = null;
      this.person = null;
      this.zip = null;
      this.geschaeftspartner = [];

      this.shown = true;

      let that = this;

      if (this.filterPerson?.id) {
        this.vorname = this.filterPerson?.vorname;
        this.name = this.filterPerson?.name;

        this.resetLoading();
      }

      if (this.filterFirma?.id) {
        this.firma = this.filterFirma;
        this.resetLoading();
      }

      $("#" + this.id).on("shown.bs.modal", function () {
        if (that.$refs && that.$refs.editName) that.$refs.editName.focus();
      });

      $("#" + this.id).modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    closeModal() {
      this.shown = false;
      $("#" + this.id).modal("hide");
    },

    onRowSelected(items) {
      this.selected = items;
    },

    rowDblClick(item) {
      this.selected = [item];
      this.uebernehmen();
    },

    setFirma(firma) {
      this.firma = firma;
      this.$emit("updatefirma", firma);
      //this.resetLoading();
    },
    setPerson(person) {
      this.person = person;
      this.$emit("updateperson", person);
      //this.resetLoading();
    },

    resetLoading() {
      this.isBusy = true;

      Api.get("geschaeftspartner/", {
        params: this.queryParams,
      })
        .then((response) => {
          //response.data.shift();
          this.geschaeftspartner = response.data;
        })
        .finally(() => {
          this.isBusy = false;
          this.loaded = true;
        });
    },

    entfernen() {
      this.closeModal();
      this.$emit("confirmed", { personName: this.$t("global.notlinked") });
    },

    uebernehmen() {
      this.empfaenger.person = this.person;
      this.empfaenger.firma = this.firma;
      this.empfaenger.id = this.empfaengerID;
      console.log(this.empfaenger);
      this.closeModal();
      if (!this.new) {
        this.$emit("confirmed", this.empfaenger);
      } else {
        this.$emit("updated", this.empfaenger);
      }
    },

    abbrechen() {
      this.closeModal();
    },
  },
};
</script>

<style></style>

export const DOCS_BASE_URL = 'https://www.bx-education.ch/docs/erp/';

export default {
	data() {
		return {
			noHelpURL: DOCS_BASE_URL,
			helpUrlMap: {
				"Geschaeftspartner" : DOCS_BASE_URL + 'crm/#personen',
				"personen-liste" : DOCS_BASE_URL + 'crm/#personen',

				"Bildungsgang" : DOCS_BASE_URL + 'ausbildung/#Bildungsgang',
				"Klasse" : DOCS_BASE_URL + 'ausbildung/#Klasse',
				"Bildungsliste" : DOCS_BASE_URL + 'ausbildung/#Bildungsgang',
				"Klassenliste" : DOCS_BASE_URL + 'ausbildung/#Klasse',
				"Fach" : DOCS_BASE_URL + 'ausbildung/#Fächer',
				"faecher-liste" : DOCS_BASE_URL + 'ausbildung/#Fächer',

			},
		};
	},
};
